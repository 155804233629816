import { createAsyncThunk } from "@reduxjs/toolkit";
import { setErrorAlert, setLoader } from "../alert/alertSlice";
import { getDataAPI, postDataAPI } from "../../utils/fetchData";
import { setVideoMessage } from "../user/userSlice";
import axios from "axios";


export const get_valut_data_thunk = createAsyncThunk(
    "/vault/get-data",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await getDataAPI('vault/get-data', tempData.token);
            console.log('Raw API response:', response);
            thunkAPI.dispatch(setLoader(false));
            return response.data.data.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
);

export const get_valut_data_by_username_thunk = createAsyncThunk(
    "/vault/get-data-by-username",
    async (tempData, thunkAPI) => {
        console.log(`tempData::`, tempData);
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('vault/get-data-by-username', tempData.body, tempData.token);
            console.log('Raw API response:', response);
            thunkAPI.dispatch(setLoader(false));
            return response.data.data.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
);


export const update_vault_data_thunk = createAsyncThunk(
    "/vault/update-vault-data",
    async (tempData, thunkAPI) => {
        console.log(`tempData::`, tempData);
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI(
                "vault/update",
                tempData.body,
                tempData.token
            );
            thunkAPI.dispatch(setLoader(false));
            return response.data.data.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(
                setErrorAlert(error?.response?.data?.message || "An error occurred")
            );
            return thunkAPI.rejectWithValue(
                error?.response?.data?.message || "An error occurred"
            );
        }
    }
);