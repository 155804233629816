import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { BACKEND_URL } from '../../utils/axios';
import { removeUploadsFromUrl } from '../../components/DizeeComponent/DixeeInput2';
import dizeeLogo from '../../assets/images/dizee logo.svg';
import DizeeWalkthrough from '../../components/DizeeWalkthrough';
import DizeeTutorial from '../../components/DizeeTutorial';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background-color: black;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  background-color: black;
  z-index: 10;
`;

const Logo = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: white;
  letter-spacing: 1px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
`;

const ProfileDropdown = styled.div`
  position: absolute;
  right: 20px;
  top: 65px;
  width: 200px;
  background-color: black;
  border-radius: 4px;
  padding: 12px 0;
  z-index: 50;
  box-shadow: 0 0 0 0.5px rgba(255, 255, 255, 0.1);
  border-top: 0.5px solid rgba(255, 255, 255, 0.1);
`;

const DropdownItem = styled.div`
  padding: 8px 16px;
  font-size: 12px;
  color: white;
  transition: color 0.2s;
  cursor: pointer;
  
  &:hover {
    color: #4d4d4d;
  }
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 140px);
  max-width: 390px;
  margin: 0 auto;
  width: 100%;
  padding-top: 10%; /* Move content up by reducing top padding */
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 40px;
  position: absolute;
  top: 20%; /* Updated: moved up by additional 10% */
  left: 10%; /* Updated: moved left by additional 10% */
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OptionBox = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${props => props.color};
  border-radius: 4px;
  cursor: pointer;
`;

const OptionLabel = styled.div`
  margin-top: 8px;
  font-size: 10px;
  color: ${props => props.textColor || 'white'};
  text-align: center;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  z-index: 10;
  padding: 0;
  height: 50px;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`;

const FooterItem = styled.div`
  position: relative;
  font-size: 9px;
  letter-spacing: 0.02em;
  font-weight: 410;
  line-height: 10.26px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  transition: opacity 0.2s;
  
  &:hover {
    opacity: 0.7;
  }
`;

const ActiveDot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #FF2300;
  border-radius: 50%;
  margin-right: 5px;
`;

const Landing = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showWalkthrough, setShowWalkthrough] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);
  const profilePicRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  // const { user } = useSelector(state => state.auth);  
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('dizeeUser')))
  const { userArticle } = useSelector(state => state.user);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profilePicRef.current &&
        !profilePicRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const tutorialActive = localStorage.getItem('tutorialActive');
    const savedStep = localStorage.getItem('tutorialStep');
    
    if (tutorialActive === 'true' && savedStep) {
      setShowWalkthrough(false);
      setShowTutorial(true);
      setTutorialStep(parseInt(savedStep, 10));
      localStorage.removeItem('tutorialActive');
      localStorage.removeItem('tutorialStep');
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem('dizeeToken');
    localStorage.removeItem('dizeeUser');
    navigate('/');
  };

  const handleDiscoveryClick = () => {
    navigate('/main');
  };

  const handleVaultClick = () => {
    console.log(`valut click::`, user);
    navigate(`/vault/${user.username}`);
  };

  const handleSearchClick = () => {
    navigate('/marketplace');
  };

  const handleNotificationsClick = () => {
    // Placeholder for notifications functionality
    console.log('Notifications clicked');
  };

  const handleProfileClick = () => {
    navigate('/more/setting/profile');
  };

  const getProfileImage = () => {
    if (userArticle?.avatar) {
      return removeUploadsFromUrl(`${userArticle.avatar}`);
    } else if (user?.avatar) {
      return user.avatar.includes('/uploads/')
        ? `${BACKEND_URL}${removeUploadsFromUrl(user.avatar)}`
        : user.avatar;
    }
    return 'https://placehold.co/40x40';
  };

  // Show the tutorial after the walkthrough is closed
  const handleWalkthroughClose = () => {
    setShowWalkthrough(false);
    console.log("Walkthrough closed, showing tutorial next");
    // Add a short delay to ensure the walkthrough is fully closed
    setTimeout(() => {
      setShowTutorial(true);
      console.log("Tutorial should be visible now");
    }, 300);
  };

  return (
    <Container>
      <Header>
        <Logo>D I Z E E</Logo>
        <div style={{ position: 'relative' }}>
          <ProfileImage
            src={getProfileImage()}
            alt="Profile"
            onClick={toggleDropdown}
            ref={profilePicRef}
          />

          {dropdownVisible && (
            <ProfileDropdown ref={dropdownRef}>
              <DropdownItem>
                diz.ee/{userArticle?.domain || user?.domain}
              </DropdownItem>
              <Link to="/more/setting" style={{ textDecoration: 'none' }}>
                <DropdownItem>
                  Settings
                </DropdownItem>
              </Link>
              <DropdownItem onClick={handleLogout}>
                Logout
              </DropdownItem>
            </ProfileDropdown>
          )}
        </div>
      </Header>

      <MainContent>
        <OptionsContainer>
          <OptionWrapper>
            <OptionBox 
              className="builder-app-icon" // Add class for tutorial targeting
              color="#FF3B30" 
              onClick={handleDiscoveryClick} 
            />
            <OptionLabel>Builder</OptionLabel>
          </OptionWrapper>

          <OptionWrapper>
            <OptionBox 
              className="vault-app-icon" // Add class for tutorial targeting
              color="#E5E5EA" 
              onClick={handleVaultClick} 
            />
            <OptionLabel>Vault</OptionLabel>
          </OptionWrapper>
        </OptionsContainer>
      </MainContent>

      <Footer>
        <FooterItem onClick={handleSearchClick}>
          <ActiveDot />
          SEARCH APPS
        </FooterItem>

        <FooterItem onClick={handleNotificationsClick}>
          NOTIFICATIONS
        </FooterItem>

        <FooterItem onClick={handleProfileClick}>
          PROFILE
        </FooterItem>
      </Footer>

      {showWalkthrough && <DizeeWalkthrough onClose={handleWalkthroughClose} />}
      {showTutorial && <DizeeTutorial onClose={() => setShowTutorial(false)} startAtStep={tutorialStep} />}
    </Container>
  );
}

export default Landing; 