import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_user_articles_thunk,
  update_user_article_thunk,
} from "../../store/user/userThunk";
import { clearAlerts } from "../../store/alert/alertSlice";
import {
  articlDataDefaultArray,
  getAccentStyle,
  SOCIAL_PLATFORMS_SVG,
  FILTERS,
} from "../../constants/constants";
import { useNavigate } from "react-router";
import LayoutHeader from "../../layout/LayoutHeader";
import {
  getContrastColor,
  removeUploadsFromUrl,
} from "../../components/DizeeComponent/DixeeInput2";
import { getAllSectionsThunk } from "../../store/addsection/addsectionThunk";
import Event from "../../components/Main/Event";
import Product from "../../components/Main/Product";
import Video from "../../components/Main/Video";
import Playlist from "../../components/Main/Playlist";
import Form from "../../components/Main/Form";
import Plus from "../../svg/Plus";
import More from "../../svg/More";
import PhotoOverlay from "../../components/Main/ArticleEdit/PhotoOverlay";
import {
  setUserArticle,
  updatePhotoOverlay,
  updateSocialApiCall,
} from "../../store/user/userSlice";
import { Socialfeed } from "../../components/Main/Socialfeed";
import Music from "../../components/Main/Music";
import MusicOverlay from "../../components/Main/ArticleEdit/MusicOverlay";
import PlayCircle from "../../svg/PlayCircle";
import CrossArrow from "../../svg/CrossArrow";
import noiseTexture from '../../assets/images/Noise.png';
import debounce from 'lodash/debounce';
import PublishOverlay from '../../components/PublishOverlay';
import styled from 'styled-components';
import DizeeTutorial from '../../components/DizeeTutorial';
import { importSpotifyTopSongs } from "../../utils/musicImport";

const hslToHex = (h, s, l) => {
  s /= 100;
  l /= 100;
  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;
  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (h >= 300 && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255)
    .toString(16)
    .padStart(2, "0");
  g = Math.round((g + m) * 255)
    .toString(16)
    .padStart(2, "0");
  b = Math.round((b + m) * 255)
    .toString(16)
    .padStart(2, "0");
  return `#${r}${g}${b}`;
};

// Add this helper function to convert hex to HSL
const hexToHSL = (hex) => {
  // Remove the # if present
  hex = hex.replace(/^#/, '');

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find min and max values
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100)
  };
};

// Update the bottomNavContainerStyle object at the top of the component
const bottomNavContainerStyle = {
  borderTop: '0.5px solid rgba(255, 255, 255, 0.1)',
  background: '#000000',
  position: 'relative',
  zIndex: 50
};

// Add these styled components for the builder app demo
const BuilderDemoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  position: relative;
  overflow: hidden;
`;

const BuilderTopNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  font-size: 10px;
`;

const BuilderNavItem = styled.div`
  opacity: 0.7;
  padding: 0 8px;
`;

const BuilderNavItems = styled.div`
  display: flex;
`;

const BuilderProfilePic = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333;
`;

const BuilderUrlBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-top: 8px;
`;

const BuilderPublishButton = styled.div`
  font-size: 10px;
  opacity: 0.7;
`;

const BuilderUrl = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.7;
`;

const BuilderUrlArrow = styled.span`
  font-size: 12px;
`;

const BuilderMenuDots = styled.div`
  font-size: 14px;
`;

const BuilderChatSection = styled.div`
  padding: 10px 16px;
  flex: 1;
  overflow-y: auto;
`;

const BuilderChatHeader = styled.div`
  font-size: 8px;
  color: #777;
  margin-bottom: 5px;
  text-align: center;
`;

const BuilderChatBubble = styled.div`
  background-color: ${props => props.isUser ? '#4CD964' : '#333'};
  padding: 8px 12px;
  border-radius: 12px;
  font-size: 9px;
  max-width: 80%;
  margin: ${props => props.isUser ? '5px 0 5px auto' : '5px auto 5px 0'};
`;

const BuilderMusicSection = styled.div`
  margin-top: 16px;
  padding: 10px 16px;
`;

const BuilderMusicHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const BuilderMusicTitle = styled.div`
  font-size: 11px;
  letter-spacing: 1px;
`;

const BuilderMusicThumbnails = styled.div`
  display: flex;
  gap: 8px;
`;

const BuilderMusicThumb = styled.div`
  width: 70px;
  height: 40px;
  background-color: #222;
  border-radius: 4px;
`;

const BuilderBottomNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #222;
  margin-top: auto;
`;

const BuilderEditButton = styled.div`
  font-size: 10px;
  opacity: 0.7;
`;

const BuilderPlusButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
`;

const BuilderHomeButton = styled.div`
  font-size: 10px;
  opacity: 0.7;
`;

export default function Main() {
  const dispatch = useDispatch();
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  let token = localStorage.getItem("dizeeToken");
  const userArticle = useSelector((state) => state.user.userArticle);
  console.log(`userArticle::`, userArticle);
  const videoMessage = useSelector((state) => state.user.videoMessage);
  const music = useSelector((state) => state.addsection.music);
  const event = useSelector((state) => state.addsection.event);
  const product = useSelector((state) => state.addsection.product);
  const video = useSelector((state) => state.addsection.video);
  const playlist = useSelector((state) => state.addsection.playlist);
  const form = useSelector((state) => state.addsection.form);
  const socialfeed = useSelector((state) => state.addsection.socialfeed);

  const [color, setColor] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(false);
  const [backgroundShade, setBackgroundShade] = useState(false);
  const [accentColor, setAccentColor] = useState(false);
  const [accentShade, setAccentShade] = useState(false);
  const [hue, setHue] = useState(0); // Hue state for color sliders
  const [brightness, setBrightness] = useState(50); // Brightness state for shade sliders
  const [styleMenu, setStyleMenu] = useState(false);
  const [hexBackground, setHexBackground] = useState("");
  const [hexAccent, setHexAccent] = useState("");

  // Add initial state values
  const [defaultValues] = useState({
    grayscale: "0",
    noise: "0"
  });

  // Add debounce to prevent rapid state updates
  const debouncedUpdateArticle = useCallback(
    debounce((newValue) => {
      dispatch(setUserArticle(newValue));
    }, 100),
    [dispatch]
  );

  // Handle hue change
  const handleHueChangeBackground = (e) => {
    setHue(e.target.value);
    const hexColor = hslToHex(e.target.value, 100, brightness);
    const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
    setHexBackground(`#${colorValue}`);
    debouncedUpdateArticle({ ...userArticle, background: colorValue });
  };

  // Handle brightness change
  const handleBrightnessChangeBackground = (e) => {
    setBrightness(e.target.value);
    const hexColor = hslToHex(hue, 100, e.target.value);
    const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
    debouncedUpdateArticle({ ...userArticle, background: colorValue });
  };
  
  const handleHueChangeAccent = (e) => {
    setHue(e.target.value);
    const hexColor = hslToHex(e.target.value, 100, brightness);
    const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
    setHexAccent(`#${colorValue}`);
    dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
  };

  // Handle brightness change
  const handleBrightnessChangeAccent = (e) => {
    setBrightness(e.target.value);
    const hexColor = hslToHex(hue, 100, e.target.value);
    const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
    dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
  };

  // Update grayscale handler with safety checks
  const onChangeGrayScale = (e) => {
    try {
      const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
      dispatch(setUserArticle({ 
        ...userArticle, 
        grayscale: value
      }));
    } catch (error) {
      console.error('Grayscale update error:', error);
      dispatch(setUserArticle({ 
        ...userArticle, 
        grayscale: defaultValues.grayscale
      }));
    }
  };

  // Add reset function for when closing controls
  const resetEffects = () => {
    dispatch(setUserArticle({ 
      ...userArticle, 
      grayscale: defaultValues.grayscale,
      noise: defaultValues.noise
    }));
  };

  const [showPublish, setShowPublish] = useState(false);

  const updateUserArticle = async (bool) => {
    dispatch(update_user_article_thunk({ token, body: { ...userArticle, unpublish: bool } }));
  };

  const photoOverlay = useSelector((state) => state.user.isPhotoOverlay);
  const [musicOverlay, setMusicOverlay] = useState(false);
  const [videoOverlay, setVideoOverlay] = useState(false);
  const [productOverlay, setProductOverlay] = useState(false);
  const [eventOverlay, setEventOverlay] = useState(false);
  const [socialfeedOverlay, setSocialfeedOverlay] = useState(false);
  const [playlistOverlay, setPlaylistOverlay] = useState(false);
  const [formOverlay, setFormOverlay] = useState(false);
  const [articlDataDefault, setArticlDataDefault] = useState(
    articlDataDefaultArray
  );
  const [videoPopupOpen, setVideoPopupOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null); // Reference to the video element
  const getPublishVideoMessage = useSelector((state) => 
    state.user.videoMessage?.filter(video => video.publish?.value === "publish")
  );
  const navigation = useNavigate();

  let socialApiCall = useSelector((state) => state.user.socialApiCall);

  // Add a ref to track initial load
  const isInitialMount = useRef(true);

  // Add tutorial state
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(6); // Default to step 7 (index 6)

  useEffect(() => {
    if (token) {
      dispatch(clearAlerts());
      dispatch(get_user_articles_thunk({ token }));
      dispatch(getAllSectionsThunk({ token }));
    }
  }, [token, dispatch]);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", isOverlayVisible);
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOverlayVisible]);

  useEffect(() => {
    if (userArticle?.articlDataDefault) {
      setArticlDataDefault(userArticle.articlDataDefault);
    }
  }, [userArticle?.articlDataDefault]);

  const getPlatformSrc = (value) => {
    const platform = SOCIAL_PLATFORMS_SVG.find((p) => p.value === value);
    if (!platform) return null;

    return {
      Component: platform.Component,
      redirect: platform.redirect,
    };
  };

  const handleSocialClick = (redirect, handle) => {
    const url = `${redirect}${handle}`;
    window.open(url, "_blank");
  };

  const moveSection = (sectionType, direction) => {
    const sectionIndex = articlDataDefault.findIndex(
      (item) => item.type === sectionType
    );

    if (
      (direction === "up" && sectionIndex > 0) ||
      (direction === "down" && sectionIndex < articlDataDefault.length - 1)
    ) {
      const updatedArray = [...articlDataDefault];
      const swapIndex =
        direction === "up" ? sectionIndex - 1 : sectionIndex + 1;
      [updatedArray[sectionIndex], updatedArray[swapIndex]] = [
        updatedArray[swapIndex],
        updatedArray[sectionIndex],
      ];

      setArticlDataDefault(updatedArray);
      dispatch(
        update_user_article_thunk({
          token,
          body: { ...userArticle, articlDataDefault: updatedArray },
        })
      );
      setMusicOverlay(false);
      setVideoOverlay(false);
      setProductOverlay(false);
      setEventOverlay(false);
      setSocialfeedOverlay(false);
      setPlaylistOverlay(false);
      setFormOverlay(false);
    }
  };

  const EditSectionName = (section, title) => {
    try {
      const updatedArray = articlDataDefault.map((item) => {
        if (item.type === section) {
          return {
            ...item,
            title: title,
          };
        }
        return item;
      });

      console.log("Updated Array:", updatedArray, section);

      setArticlDataDefault(updatedArray);
      dispatch(
        update_user_article_thunk({
          token,
          body: { ...userArticle, articlDataDefault: updatedArray },
        })
      );
      setMusicOverlay(false);
      setVideoOverlay(false);
      setProductOverlay(false);
      setEventOverlay(false);
      setSocialfeedOverlay(false);
      setPlaylistOverlay(false);
      setFormOverlay(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  // Function to move 'music' down in the array
  // Example usage for music
  const moveMusicUp = () => moveSection("music", "up");
  const moveMusicDown = () => moveSection("music", "down");

  // Example usage for video
  const moveVideoUp = () => moveSection("video", "up");
  const moveVideoDown = () => moveSection("video", "down");

  // Example usage for product
  const moveProductUp = () => moveSection("product", "up");
  const moveProductDown = () => moveSection("product", "down");

  const moveEventUp = () => moveSection("event", "up");
  const moveEventDown = () => moveSection("event", "down");

  const moveSocialfeedUp = () => moveSection("socialfeed", "up");
  const moveSocialfeedDown = () => moveSection("socialfeed", "down");

  const movePlaylistUp = () => moveSection("playlist", "up");
  const movePlaylistDown = () => moveSection("playlist", "down");

  const moveFormUp = () => moveSection("form", "up");
  const moveFormDown = () => moveSection("form", "down");

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  const handleVideoEnded = () => {
    setIsPlaying(false); // Set back to not playing after video ends
  };

  const sections = {
    music: music?.length > 0 && (
      <Music
        userArticle={userArticle}
        music={music}
        setMusicOverlay={setMusicOverlay}
        title={articlDataDefault.find((item) => item.type === "music")?.title}
      />
    ),
    event: event?.length > 0 && (
      <Event
        userArticle={userArticle}
        event={event}
        setOverlay={setEventOverlay}
        title={articlDataDefault.find((item) => item.type === "event")?.title}
      />
    ),
    product: product?.length > 0 && (
      <Product
        userArticle={userArticle}
        product={product}
        setOverlay={setProductOverlay}
        title={articlDataDefault.find((item) => item.type === "product")?.title}
      />
    ),
    video: video?.length > 0 && (
      <Video
        userArticle={userArticle}
        video={video}
        setOverlay={setVideoOverlay}
        title={articlDataDefault.find((item) => item.type === "video")?.title}
      />
    ),
    socialfeed: socialfeed?.length > 0 && (
      <Socialfeed
        userArticle={userArticle}
        socialfeed={socialfeed}
        setOverlay={setSocialfeedOverlay}
        title={
          articlDataDefault.find((item) => item.type === "socialfeed")?.title
        }
      />
    ),
    playlist: playlist?.length > 0 && (
      <Playlist
        userArticle={userArticle}
        playlist={playlist}
        setOverlay={setPlaylistOverlay}
        title={
          articlDataDefault.find((item) => item.type === "playlist")?.title
        }
      />
    ),
    form: form?.length > 0 && (
      <Form
        userArticle={userArticle}
        form={form}
        setOverlay={setFormOverlay}
        title={articlDataDefault.find((item) => item.type === "form")?.title}
      />
    ),
  };

  const ensureAbsoluteUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url; // Default to HTTPS
    }
    return url;
  }

  const onChangeWarmth = (e) => {
    try {
      const value = Math.min(Math.max(parseInt(e.target.value) || 50, 0), 100);
      // Map 0-100 to subtle sepia filter (0-0.3)
      const sepiaValue = ((value - 50) * 0.006); // Centers at 50, max 0.3
      
      dispatch(setUserArticle({ 
        ...userArticle, 
        warmth: value,
        actualWarmth: sepiaValue
      }));
    } catch (error) {
      console.error('Warmth update error:', error);
      dispatch(setUserArticle({ 
        ...userArticle, 
        warmth: 50,
        actualWarmth: 0
      }));
    }
  };

  const [grayScaleBar, setGrayScaleBar] = useState(false);
  const [noiseBar, setNoiseBar] = useState(false);

  // Add noise handler with canvas noise generation
  const generateNoisePattern = (intensity) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 256;
    canvas.height = 256;
    
    // Add rounded corners to the canvas
    ctx.beginPath();
    ctx.roundRect(0, 0, canvas.width, canvas.height, 20); // 20px border radius
    ctx.clip();
    
    const imageData = ctx.createImageData(canvas.width, canvas.height);
    const data = imageData.data;
    
    for (let i = 0; i < data.length; i += 4) {
      const noise = Math.random() * intensity - intensity/2;
      data[i] = noise;     // red
      data[i+1] = noise;   // green
      data[i+2] = noise;   // blue
      data[i+3] = 255;     // alpha
    }
    
    ctx.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
  };

  const onChangeNoise = (e) => {
    try {
      const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
      dispatch(setUserArticle({ 
        ...userArticle, 
        noise: value  // Keep as number like grayscale
      }));
    } catch (error) {
      console.error('Noise update error:', error);
      dispatch(setUserArticle({ 
        ...userArticle, 
        noise: defaultValues.noise
      }));
    }
  };

  // Update the color input handler
  const handleColorInput = (e) => {
    const colorValue = e.target.value.toUpperCase();
    if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
      setHexBackground(colorValue);
      
      // Only process complete hex colors
      if (colorValue.length === 7) {
        // Convert hex to HSL and update slider
        const hsl = hexToHSL(colorValue);
        setHue(hsl.h);
        setBrightness(hsl.l);
        
        dispatch(setUserArticle({ 
          ...userArticle, 
          background: colorValue.slice(1) 
        }));
      }
    }
  };

  const handleStateTransition = (newState) => {
    // Smoothly transition out current state
    const element = document.querySelector('.fixed');
    if (element) {
      element.style.transform = 'translateY(100%)';
      element.style.opacity = '0';
    }

    // Change state after transition
    setTimeout(() => {
      switch(newState) {
        case 'background':
          setBackgroundColor(true);
          setBackgroundShade(false);
          break;
        case 'backgroundShade':
          setBackgroundColor(false);
          setBackgroundShade(true);
          break;
        // Add other states as needed
      }
    }, 200);
  };

  // Add this handler for accent color input
  const handleAccentColorInput = (e) => {
    const colorValue = e.target.value.toUpperCase();
    if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
      setHexAccent(colorValue);
      
      // Only process complete hex colors
      if (colorValue.length === 7) {
        // Convert hex to HSL and update slider
        const hsl = hexToHSL(colorValue);
        setHue(hsl.h);
        setBrightness(hsl.l);
        
        dispatch(setUserArticle({ 
          ...userArticle, 
          accent: colorValue.slice(1) 
        }));
      }
    }
  };

  // Add new state for edit menu
  const [showEditOptions, setShowEditOptions] = useState(false);

  // Add handler for Brand Page click
  const handleBrandPageClick = () => {
    navigation('/main');
  };
  
  // Add handler for Home click
  const handleHomeClick = () => {
    navigation('/');
  };

  // Check for saved tutorial state on component mount
  useEffect(() => {
    const tutorialActive = localStorage.getItem('tutorialActive');
    const savedStep = localStorage.getItem('tutorialStep');
    
    if (tutorialActive === 'true' && savedStep) {
      // Show tutorial at the correct step
      setShowTutorial(true);
      setTutorialStep(parseInt(savedStep, 10));
      
      // Clear localStorage to avoid showing again on refresh
      localStorage.removeItem('tutorialActive');
      localStorage.removeItem('tutorialStep');
    }
  }, []);
  
  const handleShowTutorial = () => {
    setShowTutorial(true);
    setTutorialStep(6); // Start at step 7 (index 6) by default
  };

  useEffect(() => {
    const importTopSongs = async () => {
      // Check if we have top songs but no music sections yet
      if (
        userArticle?.topSongs && 
        Array.isArray(userArticle.topSongs) && 
        userArticle.topSongs.length > 0 &&
        (!articlDataDefault.some(section => section.type === "music"))
      ) {
        console.log("Importing top songs from user data:", userArticle.topSongs);
        
        // Get the token
        const token = localStorage.getItem('dizeeToken');
        if (!token) return;
        
        try {
          // Import songs using our utility function
          const success = await importSpotifyTopSongs(
            userArticle.topSongs,
            userArticle.name,
            token
          );
          
          if (success) {
            // Refresh the page data to show the new music section
            dispatch(get_user_articles_thunk({ token }));
          }
        } catch (error) {
          console.error("Failed to import top songs:", error);
        }
      }
    };
    
    importTopSongs();
  }, [userArticle, articlDataDefault.length]);

  return (
    <LayoutHeader>
      <div className="w-[350px] sm:max-w-[390px] pb-[100px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black overflow-hidden" >
        <div className="absolute top-4 left-4" style={{ zIndex: 1 }}>
          <button
            onClick={() => setShowPublish(true)}
            className="text-[#FFFFFF] text-[12px] hover:text-white transition-colors duration-200"
          >
            publish
          </button>
        </div>
        <div 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${noiseTexture})`,
            opacity: (userArticle?.noise || 0) / 100,
            mixBlendMode: 'overlay',
            pointerEvents: 'none',
            zIndex: 2,
            borderRadius: '20px'
          }}
        />
        <div className="flex py-4 z-1 ml-4 w-full justify-center items-center text-white">
          <div
            className="cursor-pointer mx-auto flex justify-center items-center gap-x-[4px] text-[12px]"
            onClick={() => {
              if (userArticle.redirect && userArticle.redirect !== "") {
                window.open(
                  ensureAbsoluteUrl(userArticle.redirect),
                  "_blank"
                )
              } else {
                window.open(
                  `${process.env.REACT_APP_BACKEND_URL}/${userArticle?.domain}`,
                  "_blank"
                )
              }
            }}
          >
            diz.ee/{userArticle?.domain}
            <CrossArrow className="h-[12px] w-[12px] cursor-pointer" />
          </div>
          <div className="mr-4 ">
            <More
              className="h-[20px] w-[20px] absolute top-4 right-4 cursor-pointer"
              onClick={() => dispatch(updatePhotoOverlay(true))}
            />
          </div>
        </div>
        <div
          className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]"
          style={{ 
            filter: `grayscale(${userArticle?.grayscale}%)`,
            backgroundColor: `${userArticle?.background ? '#' + userArticle?.background : '#000000'}`,
            position: 'relative'
          }}
        >
          <div className="relative z-2 w-full">
            <div className="w-full h-[550px] relative rounded-t-[20px] rounded-br-[30px] overflow-hidden mb-[40px]">
              <img
                src={removeUploadsFromUrl(`${userArticle?.avatar}`)}
                alt="photoimage"
                className="w-full h-full object-cover object-center"
                style={{ minHeight: '550px' }}
              />
              <div
                className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end p-4"
                style={{
                  backgroundImage: `linear-gradient(to top, #000000, transparent)`,
                }}
              >
                <div className="flex flex-col justify-center pb-2">
                  <p
                    className="text-xl text-white font-bold"
                    style={{ color: "#FBFBFD" }}
                  >
                    {userArticle?.username}
                  </p>
                  <div className="flex flex-row mt-2  space-x-2 ">
                    {userArticle?.social &&
                      Object.keys(userArticle.social).map((key) => {
                        const platformData = getPlatformSrc(key);
                        if (platformData && platformData.Component && userArticle.social[key] !== "") {
                          const SocialIcon = platformData.Component;
                          return (
                            <div
                              key={key}
                              className="h-[15px] w-[15px]cursor-pointer"
                              onClick={() =>
                                handleSocialClick(
                                  platformData.redirect,
                                  userArticle.social[key]
                                )
                              }
                            >
                              <SocialIcon
                                className="w-[15px] h-[15px] cursor-pointer"
                                color={"#FBFBFD"}
                              />
                            </div>
                          );
                        }

                        return null;
                      })}
                  </div>
                </div>
                <div>
                  {getPublishVideoMessage?.length > 0 && (
                    <div className="relative" style={{ width: '60px', height: '60px' }}>
                      <div className="relative w-full h-full rounded-full border-white border-[1px] overflow-hidden">
                        <video
                          onClick={() => setVideoPopupOpen(true)}
                          style={{ 
                            filter: getPublishVideoMessage[0]?.filter === "diz-overlay" || 
                                   getPublishVideoMessage[0]?.filter === "blue-overlay" ? 
                                   "none" : getPublishVideoMessage[0]?.filter,
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            transform: 'scaleX(-1)'
                          }}
                          src={getPublishVideoMessage[0]?.link}
                          className="rounded-full cursor-pointer"
                          muted
                          autoPlay={false}
                          playsInline
                          loop
                          controls={false}
                          onMouseOver={(e) => e.target.play()}
                          onMouseOut={(e) => {
                            e.target.pause();
                            e.target.currentTime = 0;
                          }}
                        />
                        
                        {getPublishVideoMessage[0]?.filter === "blue-overlay" && (
                          <div 
                            className="absolute inset-0 pointer-events-none" 
                            style={{
                              backgroundColor: 'rgba(0, 0, 255, 0.5)',
                              mixBlendMode: 'multiply'
                            }}
                          />
                        )}
                        
                        {getPublishVideoMessage[0]?.filter === "diz-overlay" && (
                          <>
                            <div 
                              className="absolute inset-0 pointer-events-none" 
                              style={{
                                background: 'rgba(0, 0, 0, 0.9)',
                                mixBlendMode: 'saturation',
                                filter: 'grayscale(100%) contrast(150%) brightness(0.8)'
                              }}
                            />
                            <div 
                              className="absolute inset-0 pointer-events-none" 
                              style={{
                                background: `radial-gradient(
                                  circle at center,
                                  rgba(255, 0, 0, 0.2) 0%,
                                  rgba(255, 0, 0, 0.35) 50%,
                                  rgba(255, 0, 0, 0.5) 100%
                                )`,
                                mixBlendMode: 'multiply'
                              }}
                            />
                          </>
                        )}
                        {getPublishVideoMessage[0]?.filter === "diz-bw" && (
                          <div 
                            className="absolute inset-0 pointer-events-none" 
                            style={{
                              background: 'rgba(0, 0, 0, 0.5)',
                              mixBlendMode: 'saturation'
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {articlDataDefault.map(({ type }) => sections[type] || null)}

          <div
            className="w-full  flex flex-col justify-center items-start gap-y-[16px] px-4 mt-[60px] text-[12px]"
            style={getAccentStyle(userArticle?.accent)}
          >
            <div className="space-y-[15px]">
              <div className="flex flex-row gap-x-[10px] items-center">
                <p className="uppercase font-bold">{userArticle?.username}</p>
                <p className="pb-[2px]">x</p>
                <p className="uppercase text-[11px] tracking-[3px]">DIZEE</p>
              </div>
              <p>Privacy Policy</p>
            </div>
          </div>
        </div>

        {/* Render DizeeTutorial */}
        {showTutorial && (
          <DizeeTutorial 
            onClose={() => setShowTutorial(false)} 
            startAtStep={tutorialStep}
          />
        )}
      </div>
      {!color && !showEditOptions && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setShowEditOptions(true)}
            >
              EDIT
            </button>
            <button
              className="text-white text-center py-2 px-4 rounded-lg plus-button"
              onClick={() => navigation("/add-section")}
            >
              <Plus className="h-[18px] w-[18px]" />
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={handleHomeClick}
            >
              HOME
            </button>
          </div>
        </div>
      )}
      
      {!color && showEditOptions && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setStyleMenu(true)}
            >
              RETOUCH
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setShowEditOptions(false)}
            >
              BACK
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70 color-button"
              onClick={() => {
                setColor(true);
                setShowEditOptions(false);
              }}
            >
              COLOR
            </button>
          </div>
        </div>
      )}
      
      {color && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setBackgroundColor(true);
                setColor(false);
              }}
            >
              BACKGROUND
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setAccentColor(true);
                setColor(false);
              }}
            >
              ACCENT
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setColor(false);
                setShowEditOptions(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {backgroundColor && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setBackgroundShade(true)}
            >
              SHADE
            </button>
            <div className="flex items-center space-x-4">
              <input
                type="text"
                value={(hexBackground || "#000000").toUpperCase()}
                onChange={handleColorInput}
                className="bg-transparent w-[60px] text-center outline-none border-none transition-colors duration-200"
                style={{ 
                  caretColor: 'white',
                  appearance: 'none',
                  WebkitAppearance: 'none'
                }}
              />
              <input
                type="range"
                min="0"
                max="360"
                value={hue}
                onChange={handleHueChangeBackground}
                className="hue-slider"
                style={{ width: "140px" }}
              />
            </div>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => updateUserArticle(false)}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setBackgroundColor(false);
                setBackgroundShade(false);
                setAccentColor(false);
                setAccentShade(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {/* Background Shade Selection */}
      {backgroundShade && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => {
                setBackgroundShade(false);
                setBackgroundColor(true);
              }}
            >
              COLOR
            </button>
            <input
              type="range"
              min="0"
              max="100"
              value={brightness}
              onChange={handleBrightnessChangeBackground}
              className="brightness-slider"
              style={{
                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                margin: 0,
                width: "180px",
              }}
            />
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => updateUserArticle(false)}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => {
                setBackgroundColor(false);
                setBackgroundShade(false);
                setAccentColor(false);
                setAccentShade(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}
      {accentColor && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setAccentShade(true)}
            >
              SHADE
            </button>
            <div className="flex items-center space-x-4">
              <input
                type="text"
                value={(hexAccent || "#000000").toUpperCase()}
                onChange={handleAccentColorInput}
                className="bg-transparent w-[60px] text-center outline-none border-none"
                style={{ 
                  caretColor: 'white',
                  appearance: 'none',
                  WebkitAppearance: 'none'
                }}
              />
              <input
                type="range"
                min="0"
                max="360"
                value={hue}
                onChange={handleHueChangeAccent}
                className="hue-slider"
                style={{ width: "140px" }}
              />
            </div>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => updateUserArticle(false)}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setBackgroundColor(false);
                setBackgroundShade(false);
                setAccentColor(false);
                setAccentShade(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {/* Background Shade Selection */}
      {accentShade && (
        <div className="fixed max-w-[350px] bottom-0 w-full text-white font-[410] text-[9px] leading-[10.26px] tracking-[0.02em]">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => {
                setAccentShade(false);
                setAccentColor(true);
              }}
            >
              COLOR
            </button>
            <input
              type="range"
              min="0"
              max="100"
              value={brightness}
              onChange={handleBrightnessChangeAccent}
              className="brightness-slider"
              style={{
                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                margin: 0,
                width: "180px",
              }}
            />
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => updateUserArticle(false)}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => {
                setBackgroundColor(false);
                setBackgroundShade(false);
                setAccentColor(false);
                setAccentShade(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {styleMenu && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setGrayScaleBar(true);
                setStyleMenu(false);
              }}
            >
              GRAYSCALE
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setNoiseBar(true);
                setStyleMenu(false);
              }}
            >
              NOISE
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setStyleMenu(false)}
            >
              BACK
            </button>
          </div>
        </div>
      )}
      {grayScaleBar && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button className="text-center py-2 rounded-lg">
              GRAYSCALE
            </button>
            <div className="flex items-center space-x-4">
              <span className="text-white min-w-[20px]">
                {userArticle?.grayscale || 0}%
              </span>
              <input
                type="range"
                min="0"
                max="100"
                value={userArticle?.grayscale ?? 0}
                onChange={onChangeGrayScale}
                className="grayscale-slider"
                style={{ width: "140px" }}
              />
            </div>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => { updateUserArticle(false); setGrayScaleBar(false); }}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setGrayScaleBar(false)}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {noiseBar && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button className="text-center py-2 rounded-lg">
              NOISE
            </button>
            <div className="flex items-center space-x-4">
              <span className="text-white min-w-[20px]">
                {userArticle?.noise || 0}%
              </span>
              <input
                type="range"
                min="0"
                max="100"
                value={userArticle?.noise ?? 0}
                onChange={onChangeNoise}
                className="grayscale-slider"
                style={{ width: "140px" }}
              />
            </div>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => { updateUserArticle(false); setNoiseBar(false); }}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setNoiseBar(false);
                resetEffects();
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {photoOverlay && <PhotoOverlay userArticle={userArticle} />}
      {musicOverlay && (
        <MusicOverlay
          userArticle={userArticle}
          setOverlay={setMusicOverlay}
          onMoveUp={moveMusicUp}
          onMoveDown={moveMusicDown}
          onEdit={"add-section/add-music"}
          {...{ EditSectionName, type: "music" }}
          title={articlDataDefault.find((item) => item.type === "music")?.title}
        />
      )}

      {videoOverlay && (
        <MusicOverlay
          userArticle={userArticle}
          setOverlay={setVideoOverlay}
          onMoveUp={moveVideoUp}
          onMoveDown={moveVideoDown}
          onEdit={"add-section/add-video"}
          {...{ EditSectionName, type: "video" }}
          title={articlDataDefault.find((item) => item.type === "video")?.title}
        />
      )}

      {productOverlay && (
        <MusicOverlay
          userArticle={userArticle}
          setOverlay={setProductOverlay}
          onMoveUp={moveProductUp}
          onMoveDown={moveProductDown}
          onEdit={"add-section/add-product"}
          {...{ EditSectionName, type: "product" }}
          title={
            articlDataDefault.find((item) => item.type === "product")?.title
          }
        />
      )}

      {eventOverlay && (
        <MusicOverlay
          userArticle={userArticle}
          setOverlay={setEventOverlay}
          onMoveUp={moveEventUp}
          onMoveDown={moveEventDown}
          onEdit={"add-section/add-event"}
          {...{ EditSectionName, type: "event" }}
          title={articlDataDefault.find((item) => item.type === "event")?.title}
        />
      )}

      {socialfeedOverlay && (
        <MusicOverlay
          userArticle={userArticle}
          setOverlay={setSocialfeedOverlay}
          onMoveUp={moveSocialfeedUp}
          onMoveDown={moveSocialfeedDown}
          onEdit="add-section/add-social-feed"
          {...{ EditSectionName, type: "socialfeed" }}
          title={
            articlDataDefault.find((item) => item.type === "socialfeed")?.title
          }
        />
      )}

      {playlistOverlay && (
        <MusicOverlay
          userArticle={userArticle}
          setOverlay={setPlaylistOverlay}
          onMoveUp={movePlaylistUp}
          onMoveDown={movePlaylistDown}
          onEdit={"add-section/add-playlist"}
          {...{ EditSectionName, type: "playlist" }}
          title={
            articlDataDefault.find((item) => item.type === "playlist")?.title
          }
        />
      )}

      {formOverlay && (
        <MusicOverlay
          userArticle={userArticle}
          setOverlay={setFormOverlay}
          onMoveUp={moveFormUp}
          onMoveDown={moveFormDown}
          onEdit={"add-section/add-form"}
          {...{ EditSectionName, type: "form" }}
          title={articlDataDefault.find((item) => item.type === "form")?.title}
        />
      )}

      {videoPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="relative w-[300px] h-[300px] flex items-center justify-center rounded-full">
                <div className="relative w-[280px] h-[280px] rounded-full overflow-hidden">
                    <video 
                        ref={videoRef}
                        src={getPublishVideoMessage[0]?.link}
                        className="absolute w-full h-full object-cover"
                        style={{ 
                            filter: getPublishVideoMessage[0]?.filter === "diz-overlay" ? 
                                   FILTERS.BW_FILTER : 
                                   (getPublishVideoMessage[0]?.filter === "blue-overlay" ? 
                                   "none" : getPublishVideoMessage[0]?.filter),
                            transform: 'scaleX(-1)'
                        }} 
                        muted 
                        controls={false} 
                        onEnded={handleVideoEnded}
                    />
                    
                    <div 
                        className="absolute inset-0 pointer-events-none"
                        style={{
                            background: `url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
                            opacity: '0.03',
                            mixBlendMode: 'overlay'
                        }}
                    />
                    
                    {getPublishVideoMessage[0]?.filter === "diz-overlay" && (
                        <>
                            <div 
                              className="absolute inset-0 pointer-events-none" 
                              style={{
                                background: 'rgba(0, 0, 0, 0.9)',
                                mixBlendMode: 'saturation',
                                filter: 'grayscale(100%) contrast(150%) brightness(0.8)'
                              }}
                            />
                            <div 
                              className="absolute inset-0 pointer-events-none" 
                              style={{
                                background: `radial-gradient(
                                  circle at center,
                                  rgba(255, 0, 0, 0.2) 0%,
                                  rgba(255, 0, 0, 0.35) 50%,
                                  rgba(255, 0, 0, 0.5) 100%
                                )`,
                                mixBlendMode: 'multiply'
                              }}
                            />
                        </>
                    )}
                    
                    {getPublishVideoMessage[0]?.filter === "diz-bw" && (
                        <div 
                            className="absolute inset-0 pointer-events-none" 
                            style={{
                                background: 'rgba(0, 0, 0, 0.5)',
                                mixBlendMode: 'saturation'
                            }}
                        />
                    )}
                    
                    {getPublishVideoMessage[0]?.filter === "blue-overlay" && (
                        <div 
                            className="absolute inset-0" 
                            style={{
                                backgroundColor: 'rgba(0, 0, 255, 0.5)',
                                mixBlendMode: 'multiply',
                                pointerEvents: 'none'
                            }}
                        />
                    )}
                </div>

                <button
                    className="absolute text-black rounded-full px-4 py-2"
                    onClick={handlePlayPause}
                >
                    {isPlaying ? "" : <PlayCircle className="h-[40px] w-[40px]" />}
                </button>

                <button
                    className="absolute bottom-2 right-2 text-white rounded-full cursor-pointer"
                    onClick={() => setVideoPopupOpen(false)}
                >
                    Close
                </button>
            </div>
        </div>
      )}

      {showPublish && <PublishOverlay setShowPublish={setShowPublish} />}

      {/* Optional: Tutorial button */}
      <div className="absolute top-4 right-16" style={{ zIndex: 3 }}>
        <button
          onClick={handleShowTutorial}
          className="text-[#FFFFFF] text-[12px] hover:text-white transition-colors duration-200"
        >
          help
        </button>
      </div>
    </LayoutHeader>
  );
}
