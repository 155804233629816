import React, { useRef, useState, useEffect, useCallback } from 'react';
import more from '../../assets/images/components/More.png';
import { getContrastColor, maxLength } from '../DizeeComponent/DixeeInput2';
import { getAccentColor, getAccentStyle } from '../../constants/constants';
import More from '../../svg/More';

export default function Product(props) {
    const { userArticle, product, setOverlay, title } = props;
    const scrollRef = useRef(null);
    const [arrowsVisible, setArrowsVisible] = useState({ left: false, right: false });

    console.log('Product component received:', {
        productLength: product?.length,
        productItems: product,
        userArticle
    });

    // Filter for only published items - matching Music implementation
    const publishedProducts = product?.filter(p => p?.publish?.value === 'publish');

    console.log('Published product items:', publishedProducts);

    // Scroll functions
    const scrollLeft = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -318, behavior: 'smooth' });
        }
    }, []);

    const scrollRight = useCallback(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 318, behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        let timeoutId;
        
        const updateArrowVisibility = () => {
            if (scrollRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
                
                setArrowsVisible(prev => {
                    const newState = {
                        left: scrollLeft > 0,
                        right: scrollWidth > scrollLeft + clientWidth
                    };
                    
                    // Only update if values actually changed
                    if (prev.left !== newState.left || prev.right !== newState.right) {
                        return newState;
                    }
                    return prev;
                });
            }
        };

        const debouncedUpdateArrows = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(updateArrowVisibility, 100);
        };

        const scrollElement = scrollRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', debouncedUpdateArrows);
            window.addEventListener('resize', debouncedUpdateArrows);
            
            // Initial check
            updateArrowVisibility();
        }

        return () => {
            if (scrollElement) {
                scrollElement.removeEventListener('scroll', debouncedUpdateArrows);
                window.removeEventListener('resize', debouncedUpdateArrows);
            }
            clearTimeout(timeoutId);
        };
    }, []); // Empty dependency array since we're using refs

    return (
        <>
            {publishedProducts?.length > 0 && (
                <div className='w-full mb-[60px] px-4 relative'>
                    <div className="text-[#FDFAFA] my-4 flex w-full flex-row justify-between items-center">
                        <div>
                            <p className="mr-2" style={userArticle?.accent ? { color: `#${userArticle?.accent}` } : { color: '#ffffff' }}>
                                {title || "MERCH"}
                            </p>
                        </div>
                        <More className='h-[20px] w-[20px] cursor-pointer' color={getContrastColor(userArticle?.background) ? `#${getContrastColor(userArticle?.background)}` : '#ffffff'} onClick={() => setOverlay(true)} />
                    </div>

                    {/* Left Arrow */}
                    {arrowsVisible.left && <button
                        className="absolute left-0 top-1/2 mt-[25px] transform -translate-y-1/2 text-[white] pl-1 z-10"
                        onClick={scrollLeft}
                        style={getAccentStyle(getContrastColor(userArticle?.background))}
                    >
                        &lt;
                    </button>
                    }
                    {/* Horizontal scroll wrapper */}
                    <div className='w-full overflow-x-auto relative' ref={scrollRef}>
                        <div className='flex'>
                            {publishedProducts.map((item, index) => (
                                <div key={index} className='flex-shrink-0 flex w-full gap-x-[25px]'>
                                    <img 
                                        src={item?.image} 
                                        onClick={() => window.open(`${item.links[0]?.url}`, '_blank')} 
                                        alt='sp' 
                                        className='h-[100px] w-[100px] cursor-pointer rounded-[3px] object-cover'
                                    />
                                    <div className='flex flex-col w-full justify-between gap-y-[20px] text-white'>
                                        <p className='text-[12px]' style={getAccentStyle(userArticle?.accent)}>{maxLength(item?.productName, 30)}</p>
                                        <p className='text-[12px]' style={getAccentStyle(userArticle?.accent)}>${item?.price}</p>
                                        <p className='text-[12px]' style={getAccentStyle(userArticle?.accent)}>{maxLength(item?.description, 80)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Right Arrow */}
                    {arrowsVisible.right && <button
                        className="absolute right-0 mt-[25px] top-1/2 transform -translate-y-1/2 text-white pr-1 z-10"
                        onClick={scrollRight}
                        style={getAccentStyle(getContrastColor(userArticle?.background))}
                    >
                        &gt;
                    </button>}
                </div>
            )}
        </>
    );
}