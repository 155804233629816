import React, { useState, useEffect } from 'react'
import LayoutHeader from '../../../layout/LayoutHeader'
import More from '../../../svg/More'
import CrossArrow from '../../../svg/CrossArrow'
import { useDispatch } from 'react-redux'

function ImageHostApp() {
    const [imageData, setImageData] = useState({
        title: "Summer Festival 2024",
        subtitle: "Photo Gallery",
        headerImage: "/favicon.png",
        accent: "FF5733",
        background: "000000",
        shows: [
            {
                date: "Mar 15",
                venue: "Main Stage",
                location: "Downtown",
                photoLink: "https://dropbox.com/gallery1",
                photoCount: "156"
            },
            {
                date: "Mar 16",
                venue: "Beach Arena",
                location: "Coastal",
                photoLink: "https://dropbox.com/gallery2",
                photoCount: "243"
            },
            {
                date: "Mar 17",
                venue: "Forest Dome",
                location: "Woodland",
                photoLink: "https://dropbox.com/gallery3",
                photoCount: "189"
            },
            {
                date: "Mar 18",
                venue: "Sky Lounge",
                location: "Rooftop",
                photoLink: "https://dropbox.com/gallery4",
                photoCount: "127"
            },
            {
                date: "Mar 19",
                venue: "River Stage",
                location: "Waterfront",
                photoLink: "https://dropbox.com/gallery5",
                photoCount: "215"
            }
        ]
    })

    const handleViewPhotos = (link) => {
        window.open(link, '_blank')
    }

    return (
        <LayoutHeader>
            <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black">
                <div className='flex py-4 z-1 ml-4 w-full items-center text-white'>
                    <div className="mx-auto">
                        Photo Gallery App
                    </div>
                    <div className="mr-4">
                        <More className='h-[20px] w-[20px] cursor-pointer' />
                    </div>
                </div>

                <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]" 
                    style={{ backgroundColor: `#${imageData.background}` }}>
                    
                    <div className="max-w-[350px] sm:max-w-[390px] h-[490px] flex relative">
                        <img 
                            src={imageData.headerImage}
                            alt="Event Header"
                            className="w-full h-full object-cover rounded-t-[20px]"
                        />
                        
                        <div className="absolute bottom-0 left-0 w-full h-1/2 flex flex-col justify-end p-4"
                            style={{
                                background: `linear-gradient(to top, 
                                    #${imageData.background} 12%,
                                    #${imageData.background}94 65%,
                                    transparent 100%
                                )`
                            }}>
                            <div className="space-y-1">
                                <p className='text-[16px] text-white'>
                                    {imageData.subtitle}
                                </p>
                                <p className='text-[20px] font-bold text-white'>
                                    {imageData.title}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-4 mt-4">
                        <div className="border-t" style={{ borderColor: `#${imageData.accent}`, opacity: "0.2" }} />
                    </div>

                    <div className="w-full px-4 mt-4">
                        <div className="space-y-4">
                            {imageData.shows.map((show, index) => (
                                <div key={index} 
                                    className="grid grid-cols-4 gap-2 items-center" 
                                    style={{ color: `#${imageData.accent}`, fontSize: '10px' }}
                                >
                                    <span className="truncate">{show.date}</span>
                                    <span className="truncate">{show.location}</span>
                                    <span className="truncate">{show.photoCount} photos</span>
                                    <button 
                                        onClick={() => handleViewPhotos(show.photoLink)}
                                        className="flex items-center justify-end gap-x-1"
                                    >
                                        <span className="truncate">View</span>
                                        <CrossArrow color={`#${imageData.accent}`} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='w-full px-4 mt-auto pt-[50px] pb-4 text-[12px]'>
                        <div className='space-y-[15px]'>
                            <div className='flex items-center gap-x-[10px]' style={{ color: `#${imageData.accent}` }}>
                                <p className='uppercase font-bold'>PHOTO GALLERY</p>
                                <p>x</p>
                                <p className='uppercase text-[11px]'>D I Z E E</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    )
}

export default ImageHostApp 