import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutPersonalHeader from "../../layout/LayoutPersonalHeader";
import { getExploreUserThunk } from "../../store/auth/authThunk";
import { followProUserThunk } from "../../store/user/userThunk";
import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import BottomBar from "../../components/Personal/BottomBar";

export default function Explore() {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const userArticle = useSelector((state) => state.user.userArticle);
  const token = localStorage.getItem("dizeeToken");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await dispatch(getExploreUserThunk({ username: userArticle?._id, size: 12 }));
        if (res?.payload?.status === 200) {
          const fetchedUsers = res.payload?.data?.response.map(user => ({
            ...user,
            isFollowing: user.followers?.includes(userArticle?._id)
          }));
          setUsers(fetchedUsers);
        }
      } catch (err) {
        console.error("Error fetching users:", err);
      }
    };

    if (userArticle?._id) {
      fetchUsers();
    }
  }, [userArticle?._id, dispatch]);

  // Spring animation setup
  const [{ x }, api] = useSpring(() => ({
    x: 0,
    config: { tension: 300, friction: 20 }
  }));

  const handleSwipe = async (direction) => {
    if (currentIndex >= users.length) return;

    const swipeDistance = direction === 'right' ? 500 : -500;
    
    // Animate the card off screen
    await api.start({ x: swipeDistance });

    try {
      await dispatch(followProUserThunk({
        user: userArticle,
        target: users[currentIndex],
        token
      }));

      setCurrentIndex(prev => prev + 1);
      // Reset position immediately for next card
      api.start({ x: 0, immediate: true });
    } catch (err) {
      console.error("Error handling swipe:", err);
      // Reset position on error
      api.start({ x: 0 });
    }
  };

  // Gesture binding
  const bind = useDrag(({ down, movement: [mx], direction: [xDir], velocity }) => {
    const trigger = velocity > 0.2;
    const dir = xDir < 0 ? -1 : 1;

    if (!down && trigger) {
      handleSwipe(dir > 0 ? 'right' : 'left');
    } else {
      api.start({ x: down ? mx : 0, immediate: down });
    }
  }, {
    bounds: { left: -500, right: 500 },
    rubberband: true
  });

  // Show message when no more profiles
  if (!users[currentIndex]) {
    return (
      <LayoutPersonalHeader>
        <div className="w-full h-[80vh] flex justify-center items-center">
          <p className="text-white">No more profiles to show</p>
        </div>
      </LayoutPersonalHeader>
    );
  }

  return (
    <LayoutPersonalHeader>
      <div className="w-[300px] sm:max-w-[390px] pb-[100px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black">
        <animated.div
          {...bind()}
          style={{
            x,
            touchAction: 'none',
          }}
          className="absolute will-change-transform cursor-grab active:cursor-grabbing"
        >
          <div className="w-[340px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]">
            <div className="max-w-[340px] sm:max-w-[390px] h-[480px] flex relative rounded-t-[20px] overflow-hidden">
              <img
                src={users[currentIndex].avatar}
                alt={users[currentIndex].username}
                className="w-full h-full object-cover"
                onLoad={() => setImageLoaded(true)}
                onError={() => setImageLoaded(true)}
              />
              <div 
                className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end pl-4"
                style={{ backgroundImage: 'linear-gradient(to top, #000000, transparent)' }}
              >
                <div className="flex flex-col justify-center pb-2">
                  <div className="text-xl flex flex-row gap-x-[5px] items-center text-white font-bold">
                    {users[currentIndex].username}
                  </div>
                  <div className="text-sm text-gray-300 mt-1">
                    diz.ee/{users[currentIndex].domain}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </animated.div>
        <BottomBar />
      </div>
    </LayoutPersonalHeader>
  );
}
