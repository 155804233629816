import React from 'react'
import { useDispatch } from 'react-redux';
import { maxLength } from '../../../DizeeComponent/DixeeInput2';
import More from '../../../../svg/More';
import { updatePreviousUrl } from '../../../../store/focuseventdata/focuseventdataSlice';


export default function FocusAddedEvent({ item, setLinkForBackend, setOverlayVisible, setItemForBackend }) {
    const dispatch = useDispatch();

    return (
        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
            <div className='items-center flex flex-row text-white' style={{ width: 'calc(100% - 40px)' }}>
                <div className='flex flex-col items-start justify-start  h-full py-2'>
                    <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>{maxLength(item?.venue, 50)}</p>
                    <p className='text-[white]' style={{ fontSize: '12px' }}>
                        {maxLength(item?.date, 50)}
                    </p>
                </div>
            </div>
            <div 
                className="cursor-pointer text-white text-[20px] leading-none flex items-center"
                onClick={() => {
                    setItemForBackend(item);
                    setOverlayVisible(true);
                    dispatch(updatePreviousUrl(item?.link))
                }}
            >
                …
            </div>
        </div>
    );
}
