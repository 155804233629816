import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { updateCustomForFocusThunk, getDataForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';
import { uploadImage } from '../../../../../utils/upload';

export default function EditMusicContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    // Get data from navigation state
    const pageData = location.state?.pageData;
    const pageId = location.state?.pageId;

    // Redirect if no pageData
    useEffect(() => {
        if (!pageData || !pageId) {
            navigate('/focus-page/custom-page');
        }
    }, [pageData, pageId, navigate]);

    // Initialize state with existing music content
    const [musicItems, setMusicItems] = useState(
        location.state?.musicContent?.map(item => ({
            ...item,
            preview: item.avatar,
            image: null
        })) || []
    );

    const [currentMusic, setCurrentMusic] = useState({
        sectionTitle: location.state?.sectionTitle || '',
        primaryText: '',
        secondaryText: '',
        link: '',
        image: null,
        preview: null,
        type: 'music'
    });

    // Overlay state
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedMusic, setSelectedMusic] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Form validation flags
    const isFormComplete = currentMusic.preview && 
                          currentMusic.primaryText && 
                          currentMusic.secondaryText && 
                          currentMusic.sectionTitle.trim() !== '';

    const isDoneValid = () => {
        return musicItems.length > 0 || isFormComplete;
    };

    // Handle link change
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentMusic({ ...currentMusic, link: value });
    };

    // Form validation
    const validateForm = () => {
        if (!currentMusic.sectionTitle.trim()) {
            dispatch(setErrorAlert('Please enter a section title'));
            return false;
        }

        if (!currentMusic.preview) {
            dispatch(setErrorAlert('Please upload an image'));
            return false;
        }

        if (!currentMusic.primaryText.trim() || !currentMusic.secondaryText.trim()) {
            dispatch(setErrorAlert('Please enter both title and subtitle'));
            return false;
        }

        if (currentMusic.link && !isValidUrl(currentMusic.link)) {
            dispatch(setErrorAlert('Please enter a valid URL'));
            return false;
        }

        return true;
    };

    // Handle image upload with validation
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                dispatch(setErrorAlert('Image size should be less than 5MB'));
                return;
            }

            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            if (!validTypes.includes(file.type)) {
                dispatch(setErrorAlert('Please upload a valid image file (JPEG, PNG, GIF, or WEBP)'));
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentMusic({
                    ...currentMusic,
                    image: file,
                    preview: reader.result
                });
            };
            reader.readAsDataURL(file);
        }
        e.target.value = '';
    };

    // Handle music click for editing
    const handleMusicClick = (item, index) => {
        setSelectedMusic({
            ...item,
            type: 'music'
        });
        setSelectedIndex(index);
        
        // Populate the currentMusic with the selected item's data
        setCurrentMusic({
            sectionTitle: item.sectionTitle,
            primaryText: item.primaryText,
            secondaryText: item.secondaryText || '',
            link: item.link || '',
            preview: item.avatar,
            image: null,
            type: 'music'
        });
        
        setIsOverlayVisible(true);
    };

    // Handle edit music
    const handleEditMusic = () => {
        if (selectedMusic && selectedIndex !== null) {
            // Don't remove the item, just populate the form for editing
            setCurrentMusic({
                sectionTitle: selectedMusic.sectionTitle,
                primaryText: selectedMusic.primaryText,
                secondaryText: selectedMusic.secondaryText || '',
                link: selectedMusic.link || '',
                preview: selectedMusic.avatar,
                image: null,
                type: 'music'
            });
            setIsOverlayVisible(false);
            // Keep selectedIndex for position tracking
        }
    };

    // Handle move up/down
    const handleMoveUp = () => {
        if (selectedIndex > 0) {
            const updatedItems = [...musicItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex - 1]] = 
            [updatedItems[selectedIndex - 1], updatedItems[selectedIndex]];
            setMusicItems(updatedItems);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (selectedIndex < musicItems.length - 1) {
            const updatedItems = [...musicItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex + 1]] = 
            [updatedItems[selectedIndex + 1], updatedItems[selectedIndex]];
            setMusicItems(updatedItems);
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle delete
    const handleDeleteMusic = () => {
        setMusicItems(musicItems.filter((_, index) => index !== selectedIndex));
        setIsOverlayVisible(false);
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!validateForm()) return;

        if (selectedIndex !== null) {
            // If editing, update in place
            const updatedItems = [...musicItems];
            updatedItems[selectedIndex] = {
                type: 'music',
                sectionTitle: currentMusic.sectionTitle,
                primaryText: currentMusic.primaryText,
                secondaryText: currentMusic.secondaryText,
                link: currentMusic.link || '',
                avatar: currentMusic.preview,
                preview: currentMusic.preview,
                image: currentMusic.image
            };
            setMusicItems(updatedItems);
            setSelectedIndex(null);
        } else {
            // Add new item
            setMusicItems([...musicItems, {
                type: 'music',
                sectionTitle: currentMusic.sectionTitle,
                primaryText: currentMusic.primaryText,
                secondaryText: currentMusic.secondaryText,
                link: currentMusic.link || '',
                avatar: currentMusic.preview,
                preview: currentMusic.preview,
                image: currentMusic.image
            }]);
        }

        // Reset form except section title
        setCurrentMusic({
            sectionTitle: currentMusic.sectionTitle,
            primaryText: '',
            secondaryText: '',
            link: '',
            image: null,
            preview: null,
            type: 'music'
        });
    };

    // Handle done
    const handleDone = async () => {
        if (!pageData?.page) {
            dispatch(setErrorAlert('Invalid page data'));
            return;
        }

        // Create final music items array
        let finalMusicItems = [...musicItems];
        
        // If we have content in the form, add it before submitting
        if (isFormComplete) {
            if (selectedIndex !== null) {
                finalMusicItems[selectedIndex] = {
                    type: 'music',
                    sectionTitle: currentMusic.sectionTitle,
                    primaryText: currentMusic.primaryText,
                    secondaryText: currentMusic.secondaryText,
                    link: currentMusic.link || '',
                    avatar: currentMusic.preview,
                    preview: currentMusic.preview,
                    image: currentMusic.image
                };
            } else {
                finalMusicItems.push({
                    type: 'music',
                    sectionTitle: currentMusic.sectionTitle,
                    primaryText: currentMusic.primaryText,
                    secondaryText: currentMusic.secondaryText,
                    link: currentMusic.link || '',
                    avatar: currentMusic.preview,
                    preview: currentMusic.preview,
                    image: currentMusic.image
                });
            }
        }

        if (finalMusicItems.length === 0) {
            dispatch(setErrorAlert('Please add at least one music item'));
            return;
        }

        dispatch(setLoader(true));

        try {
            // Process music items
            const processedMusic = await Promise.all(finalMusicItems.map(async (entry) => {
                let avatarUrl = entry.avatar;
                
                if (entry.image) {
                    avatarUrl = await uploadImage(entry.image);
                    if (!avatarUrl) {
                        throw new Error('Failed to upload image');
                    }
                }

                return {
                    type: 'music',
                    sectionTitle: entry.sectionTitle,
                    primaryText: entry.primaryText,
                    secondaryText: entry.secondaryText,
                    link: entry.link || '',
                    avatar: avatarUrl
                };
            }));

            const updateData = {
                token,
                payload: {
                    ...pageData,
                    page: pageData.page,
                    type: 'custom',
                    headeractive: pageData.headeractive ?? true,
                    background: pageData.background ?? '1A1A25',
                    accent: pageData.accent ?? 'FFFFFF',
                    content: [
                        ...(pageData.content || []).filter(item => item.type !== 'music'),
                        ...processedMusic
                    ],
                    headerImage: pageData.headerImage,
                    headerImageUrl: pageData.headerImageUrl,
                    originalPage: pageData.page
                }
            };

            const result = await dispatch(updateCustomForFocusThunk(updateData)).unwrap();
            
            if (result.status === 200) {
                await dispatch(getDataForFocusThunk({ token, pageId }));
                dispatch(clearFocusSection());
                navigate('/focus-page/custom-page');
            } else {
                throw new Error(result.message || 'Update failed');
            }
        } catch (error) {
            console.error('Error updating music content:', error);
            dispatch(setErrorAlert(error?.message || 'Failed to update music content'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Edit content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        {isFormComplete && (
                            <button 
                                onClick={handleAddAnother}
                                className="text-white cursor-pointer"
                            >
                                Another
                            </button>
                        )}
                        <button 
                            onClick={handleDone} 
                            className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}
                        >
                            Done
                        </button>
                        <p 
                            onClick={() => navigate(-1)} 
                            className='text-white cursor-pointer'
                        >
                            Go back
                        </p>
                    </div>
                </div>

                {/* Content */}
                <div className="w-full px-4 overflow-y-auto pb-[100px]">
                    {/* Input Section */}
                    <div className="mb-8">
                        {/* Section Title */}
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter section title (applies to all content)"
                                value={currentMusic.sectionTitle}
                                onChange={(e) => setCurrentMusic({ 
                                    ...currentMusic, 
                                    sectionTitle: e.target.value 
                                })}
                            />
                            {currentMusic.sectionTitle && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        {/* Primary Text */}
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter title"
                                value={currentMusic.primaryText}
                                onChange={(e) => setCurrentMusic({ 
                                    ...currentMusic, 
                                    primaryText: e.target.value 
                                })}
                            />
                            {currentMusic.primaryText && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        {/* Secondary Text */}
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter subtitle"
                                value={currentMusic.secondaryText}
                                onChange={(e) => setCurrentMusic({ 
                                    ...currentMusic, 
                                    secondaryText: e.target.value 
                                })}
                            />
                            {currentMusic.secondaryText && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        {/* Link */}
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter link (optional)"
                                onChange={handleLinkChange}
                                value={currentMusic.link}
                            />
                            {isValidUrl(currentMusic.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        {/* Image Upload */}
                        <div className="w-full mt-5">
                            <p 
                                className="text-white text-[12px] mb-2 cursor-pointer"
                                onClick={() => document.getElementById('file-input').click()}
                            >
                                {currentMusic.preview ? 'Change image' : 'Upload image'}
                            </p>
                            {currentMusic.preview && (
                                <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                                    <img src={currentMusic.preview} alt="preview" className="w-full h-full object-cover" />
                                </div>
                            )}
                            <input
                                id="file-input"
                                type="file"
                                onChange={handleImageChange}
                                className="hidden"
                                accept="image/*"
                            />
                        </div>
                    </div>

                    {/* Added Music Items */}
                    {musicItems.length > 0 && (
                        <div className="flex flex-col w-full mt-[40px]">
                            <div className='pb-[20px] flex w-full items-center'>
                                <div className='flex flex-row text-white w-full'>
                                    <p>Added Music</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                {musicItems.map((item, index) => (
                                    <AddedCustom
                                        key={index}
                                        item={item}
                                        setOverlayVisible={setIsOverlayVisible}
                                        setItemForBackend={(item) => handleMusicClick(item, index)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Fixed Footer */}
                <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Edit your music content.</p>
                            <p>Image, title, and subtitle are required.</p>
                        </div>
                    </div>
                </div>

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedMusic}
                        handleEdit={handleEditMusic}
                        handleDelete={handleDeleteMusic}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                        canMoveUp={selectedIndex > 0}
                        canMoveDown={selectedIndex < musicItems.length - 1}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 