import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { addCustomForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { uploadImage } from '../../../../../utils/upload';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';

export default function AddGalleryContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const custom = useSelector(state => state.focuspage.custom);

    // Initialize state with existing gallery content
    const existingGalleryContent = custom?.content?.filter(item => item.type === 'gallery') || [];
    const [galleryItems, setGalleryItems] = useState(existingGalleryContent.map(item => ({
        ...item,
        preview: item.avatar, // Use the existing avatar as preview
        image: null // We can't restore the original File object
    })));
    
    // Get the existing section title
    const existingGallerySection = custom?.content?.find(item => item.type === 'gallery');
    const [sectionTitle, setSectionTitle] = useState(existingGallerySection?.sectionTitle || '');

    // Rest of the state
    const [currentGallery, setCurrentGallery] = useState({
        link: '',
        image: null,
        preview: null,
        type: 'gallery'
    });
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Form validation
    const isFormComplete = currentGallery.preview && 
                          currentGallery.link && 
                          isValidUrl(currentGallery.link) && 
                          sectionTitle.trim() !== '';

    const isDoneValid = () => {
        return galleryItems.length > 0 || isFormComplete;
    };

    // Handle image upload
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // File size validation (5MB limit)
            if (file.size > 5 * 1024 * 1024) {
                dispatch(setErrorAlert('Image size should be less than 5MB'));
                return;
            }

            // File type validation
            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            if (!validTypes.includes(file.type)) {
                dispatch(setErrorAlert('Please upload a valid image file (JPEG, PNG, GIF, or WEBP)'));
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentGallery({
                    ...currentGallery,
                    image: file,
                    preview: reader.result
                });
            };
            reader.readAsDataURL(file);
        }
        e.target.value = '';
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!validateForm()) return;

        setGalleryItems([...galleryItems, {
            ...currentGallery,
            type: 'gallery',
            sectionTitle: sectionTitle,
            primaryText: currentGallery.link,
        }]);

        // Reset form
        setCurrentGallery({
            link: '',
            image: null,
            preview: null,
            type: 'gallery'
        });
    };

    // Handle edit
    const handleEditGallery = () => {
        if (selectedGallery) {
            setCurrentGallery(selectedGallery);
            const updatedGalleries = galleryItems.filter((_, index) => index !== selectedIndex);
            setGalleryItems(updatedGalleries);
            setIsOverlayVisible(false);
        }
    };

    // Handle delete
    const handleDeleteGallery = () => {
        const filteredItems = galleryItems.filter((_, index) => index !== selectedIndex);
        setGalleryItems(filteredItems);
        setIsOverlayVisible(false);
    };

    // Handle form submission
    const handleDone = async () => {
        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one gallery item'));
            return;
        }

        dispatch(setLoader(true));

        try {
            // Get existing content
            let finalContent = [...custom?.content || []];
            // Filter out existing gallery items
            finalContent = finalContent.filter(item => item.type !== 'gallery');
            
            // Process images one at a time
            const processedGallery = [];
            for (const entry of galleryItems) {
                let url = '';
                if (entry.image) {
                    // Only upload if it's a new image
                    url = await uploadImage(entry.image);
                    if (!url) {
                        throw new Error('Image cannot contain nudity, violence or drugs');
                    }
                } else {
                    // Use existing avatar if no new image was uploaded
                    url = entry.avatar || entry.preview;
                }

                processedGallery.push({
                    type: 'gallery',
                    sectionTitle: sectionTitle,
                    link: entry.link || '',
                    avatar: url
                });
            }

            // Maintain header content
            const existingHeader = custom?.content?.find(item => item.type === 'header');
            
            const dataCopy = {
                page: custom.page,
                type: 'custom',
                headeractive: true,
                background: custom?.background || '1A1A25',
                accent: custom?.accent || 'FFFFFF',
                content: [
                    existingHeader || {
                        type: 'header',
                        avatar: custom?.avatar || '',
                        title: custom?.headerText || '',
                        subtitle: custom?.subheaderText || ''
                    },
                    ...processedGallery
                ]
            };

            const result = await dispatch(addCustomForFocusThunk({ 
                token, 
                payload: dataCopy 
            })).unwrap();
            
            if (result.status === 200) {
                dispatch(clearFocusSection());
                navigate("/focus-page");
            } else {
                throw new Error(result.message || 'Failed to create focus page');
            }
        } catch (error) {
            console.error('Error updating gallery content:', error);
            dispatch(setErrorAlert(error?.message || 'Error updating gallery content'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    // Update link handling
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentGallery({ ...currentGallery, link: value });
    };

    // Update form validation
    const validateForm = () => {
        // Section title is required
        if (!sectionTitle.trim()) {
            dispatch(setErrorAlert('Please enter a section title'));
            return false;
        }

        // Image is required
        if (!currentGallery.preview) {
            dispatch(setErrorAlert('Please upload an image'));
            return false;
        }

        // Link is required and must be valid
        if (!currentGallery.link || !isValidUrl(currentGallery.link)) {
            dispatch(setErrorAlert('Please enter a valid URL'));
            return false;
        }

        return true;
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Add content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        {isFormComplete && (
                            <button onClick={handleAddAnother}
                                className="text-white cursor-pointer">
                                Another
                            </button>
                        )}
                        <button onClick={handleDone} 
                            className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}>
                            Done
                        </button>
                        <p onClick={() => navigate(-1)} 
                            className='text-white cursor-pointer'>
                            Go back
                        </p>
                    </div>
                </div>

                {/* Scrollable Content Area */}
                <div className="w-full px-4 overflow-y-auto pb-[100px]">
                    {/* Input Section */}
                    <div className="mb-8">
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter section title (applies to all content)"
                                onChange={(e) => setSectionTitle(e.target.value)}
                                value={sectionTitle}
                            />
                        </div>

                        {/* Link and Image Upload Controls */}
                        <div className="flex flex-col">
                            <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <div className="flex items-center w-full">
                                        <DizeeInput
                                            placeholder="Enter link"
                                            onChange={handleLinkChange}
                                            value={currentGallery.link}
                                        />
                                        {isValidUrl(currentGallery.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mt-5">
                                <p 
                                    className="text-white text-[12px] mb-2 cursor-pointer"
                                    onClick={() => document.getElementById('file-input').click()}
                                >
                                    {currentGallery.preview ? 'Change image' : 'Upload image'}
                                </p>
                                {currentGallery.preview && (
                                    <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                                        <img src={currentGallery.preview} alt="preview" className="w-full h-full object-cover" />
                                    </div>
                                )}
                                <input
                                    id="file-input"
                                    type="file"
                                    onChange={handleImageChange}
                                    className="hidden"
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Added Items Section */}
                    {galleryItems.length > 0 && (
                        <div className="flex flex-col w-full mt-[40px]">
                            <div className='pb-[20px] flex w-full items-center'>
                                <div className='flex flex-row text-white w-full'>
                                    <p>Added Gallery Items</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                {galleryItems.map((item, index) => (
                                    <AddedCustom
                                        key={index}
                                        item={{
                                            ...item,
                                            primaryText: `Image ${index + 1}`,
                                        }}
                                        setOverlayVisible={setIsOverlayVisible}
                                        setItemForBackend={(item) => {
                                            setSelectedGallery(item);
                                            setSelectedIndex(index);
                                            setIsOverlayVisible(true);
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Fixed Footer with Gradient */}
                <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Add your gallery content to display in your focus page.</p>
                            <p>Image and link are required for each gallery item.</p>
                        </div>
                    </div>
                </div>

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={{
                            ...selectedGallery,
                            primaryText: `Image ${selectedIndex + 1}`,
                        }}
                        handleEdit={handleEditGallery}
                        handleDelete={handleDeleteGallery}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 