import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { addCustomForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';
import { addCustomForBrandThunk } from '../../../../../store/addsection/addsectionThunk';

export default function AddEventContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    useSelector(state => {
        console.log(`state::`, state);
    })
    const custom = useSelector(state => state.focuspage.custom);

    const [sectionTitle, setSectionTitle] = useState('');
    const [currentEvent, setCurrentEvent] = useState({
        content1: '',
        content2: '',
        content3: '',
        content4: '',
        link: '',
        type: 'event'
    });
    const [eventItems, setEventItems] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const isFormComplete = currentEvent.content1 || currentEvent.content2 || 
                          currentEvent.content3 || currentEvent.content4;

    const isDoneValid = () => {
        return eventItems.length > 0 || isFormComplete;
    };

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentEvent({ ...currentEvent, link: value });
    };

    const handleAddAnother = () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please add at least one content field'));
            return;
        }

        setEventItems([...eventItems, {
            ...currentEvent,
            primaryText: currentEvent.content1,
            secondaryText: currentEvent.content2,
            time: currentEvent.content1,
            date: currentEvent.content2,
            venue: currentEvent.content3,
            description: currentEvent.content4,
            type: 'event',
            sectionTitle
        }]);

        setCurrentEvent({
            content1: '',
            content2: '',
            content3: '',
            content4: '',
            link: '',
            type: 'event'
        });
    };

    const handleEditEvent = () => {
        if (selectedEvent) {
            setCurrentEvent(selectedEvent);
            const updatedEventItems = eventItems.filter((_, index) => index !== selectedIndex);
            setEventItems(updatedEventItems);
            setIsOverlayVisible(false);
        }
    };

    const handleDeleteEvent = () => {
        if (selectedIndex !== null) {
            const updatedEventItems = eventItems.filter((_, index) => index !== selectedIndex);
            setEventItems(updatedEventItems);
            setIsOverlayVisible(false);
        }
    };

    const handleSubmit = async () => {
        // if (!custom?.page) {
        //     dispatch(setErrorAlert("Please set a page name first"));
        //     return;
        // }

        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one event'));
            return;
        }

        dispatch(setLoader(true));

        try {
            // Get existing header content if available
            const existingHeader = custom?.content?.find(item => item.type === 'header');
            const existingContent = custom?.content?.filter(item => 
                item.type !== 'event' && item.type !== 'header'
            ) || [];

            // Prepare final event items including current form if complete
            let finalEventItems = [...eventItems];
            if (isFormComplete) {
                const currentEventData = {
                    ...currentEvent,
                    primaryText: currentEvent.content1,
                    secondaryText: currentEvent.content2,
                    time: currentEvent.content1,
                    date: currentEvent.content2,
                    venue: currentEvent.content3,
                    description: currentEvent.content4,
                    type: 'event',
                    sectionTitle
                };
                finalEventItems.push(currentEventData);
            }

            const dataCopy = {
                ...custom,
                page: custom.page,
                type: 'custom',
                headeractive: true,
                content: [
                    existingHeader || {
                        type: 'header',
                        avatar: custom?.avatar,
                        title: custom?.headerText,
                        subtitle: custom?.subheaderText
                    },
                    ...existingContent,
                    ...finalEventItems
                ]
            };

            console.log(`dataCopy::`, dataCopy);

            // const result = await dispatch(addCustomForBrandThunk({ token, payload: dataCopy })).unwrap();
            
            // if (result.status === 200) {
            //     dispatch(clearFocusSection());
            //     navigate("/focus-page");
            // }
        } catch (error) {
            console.error('Error creating custom focus page:', error);
            dispatch(setErrorAlert(error?.message || 'Error creating focus page'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-y-auto pb-[120px]">
                        {/* Header Section */}
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div className="text-white cursor-pointer" onClick={handleAddAnother}>
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50' : ''}`}
                                    onClick={() => isDoneValid() && handleSubmit()}
                                >
                                    Done
                                </div>
                                <div className="text-white cursor-pointer" onClick={() => navigate(-1)}>
                                    Go back
                                </div>
                            </div>
                        </div>

                        {/* Content Section */}
                        <div className="w-full px-4 mt-[120px] relative z-10">
                            {/* Section Title Input */}
                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter section title (applies to all content)"
                                    onChange={(e) => setSectionTitle(e.target.value)}
                                    value={sectionTitle}
                                />
                            </div>

                            <div className="flex flex-col">
                                {/* Content Inputs */}
                                <div className='flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter primary text"
                                            value={currentEvent.content1}
                                            onChange={(e) => setCurrentEvent({ ...currentEvent, content1: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter secondary text"
                                            value={currentEvent.content2}
                                            onChange={(e) => setCurrentEvent({ ...currentEvent, content2: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter tertiary text"
                                            value={currentEvent.content3}
                                            onChange={(e) => setCurrentEvent({ ...currentEvent, content3: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter additional text"
                                            value={currentEvent.content4}
                                            onChange={(e) => setCurrentEvent({ ...currentEvent, content4: e.target.value })}
                                        />
                                    </div>
                                </div>

                                {/* Link Input */}
                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <div className="flex items-center w-full">
                                            <DizeeInput
                                                placeholder="Enter link (optional)"
                                                value={currentEvent.link}
                                                onChange={handleLinkChange}
                                            />
                                            {isValidUrl(currentEvent.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                                        </div>
                                    </div>
                                </div>

                                {/* Added Events List */}
                                {eventItems.length > 0 && (
                                    <div className="flex flex-col w-full mt-[40px]">
                                        <div className='pb-[20px] flex w-full items-center'>
                                            <div className='flex flex-row text-white w-full'>
                                                <p>Added Events</p>
                                            </div>
                                        </div>
                                        {eventItems.map((item, index) => (
                                            <AddedCustom
                                                key={index}
                                                item={item}
                                                setOverlayVisible={setIsOverlayVisible}
                                                setItemForBackend={(item) => {
                                                    setSelectedEvent(item);
                                                    setSelectedIndex(index);
                                                    setIsOverlayVisible(true);
                                                }}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Replace the existing footer with this new structure */}
                    <div className="w-full absolute bottom-0 left-0">
                        <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                        <div className="w-full bg-black px-4 py-6">
                            <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                                <p>Add your event content to display in your focus page.</p>
                                <p>Add at least one content field to enhance the presentation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Move overlay outside main container */}
            {isOverlayVisible && (
                <OverlayCustom
                    isOverlayVisible={isOverlayVisible}
                    setOverlayVisible={setIsOverlayVisible}
                    selectedMusic={selectedEvent}
                    handleEdit={handleEditEvent}
                    handleDelete={handleDeleteEvent}
                />
            )}
        </LayoutHeader>
    );
} 