import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { update_focus_data_thunk, getDataForFocusThunk, addCustomForFocusThunk, updateCustomForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { uploadImage } from '../../../../../utils/upload';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';

export default function EditGalleryContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    // Get data from navigation state
    const pageData = location.state?.pageData;
    const pageId = location.state?.pageId;

    // Redirect if no pageData
    useEffect(() => {
        if (!pageData || !pageId) {
            navigate('/focus-page/custom-page');
        }
    }, [pageData, pageId, navigate]);

    // Initialize state with existing gallery content
    const [galleryItems, setGalleryItems] = useState(
        location.state?.galleryContent?.map(item => ({
            ...item,
            preview: item.avatar,
            image: null
        })) || []
    );
    
    const [sectionTitle, setSectionTitle] = useState(location.state?.sectionTitle || '');
    const [currentGallery, setCurrentGallery] = useState({
        link: '',
        image: null,
        preview: null,
        type: 'gallery'
    });
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Add originalPage state
    const [originalPage] = useState(location.state?.pageData?.page || '');

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Form validation
    const isFormComplete = currentGallery.preview && 
                          currentGallery.link && 
                          isValidUrl(currentGallery.link) && 
                          sectionTitle.trim() !== '';

    // Image handler
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        // Preview
        const reader = new FileReader();
        reader.onloadend = () => {
            setCurrentGallery(prev => ({
                ...prev,
                image: file,
                preview: reader.result
            }));
        };
        reader.readAsDataURL(file);
    };

    // Add gallery item
    const handleAddGallery = () => {
        if (!isFormComplete) return;

        setGalleryItems(prev => [...prev, {
            ...currentGallery,
            avatar: currentGallery.preview
        }]);

        // Reset current gallery
        setCurrentGallery({
            link: '',
            image: null,
            preview: null,
            type: 'gallery'
        });
    };

    // Edit gallery item
    const handleEditGallery = (editedItem) => {
        setGalleryItems(prev => 
            prev.map((item, idx) => 
                idx === selectedIndex ? {
                    ...item,
                    link: editedItem.link,
                    avatar: editedItem.preview || editedItem.avatar,
                    preview: editedItem.preview || editedItem.avatar,
                    image: editedItem.image,
                    type: 'gallery',
                    sectionTitle: sectionTitle
                } : item
            )
        );
        setIsOverlayVisible(false);
    };

    // Delete gallery item
    const handleDeleteGallery = () => {
        setGalleryItems(prev => 
            prev.filter((_, idx) => idx !== selectedIndex)
        );
        setIsOverlayVisible(false);
    };

    const handleDone = async () => {
        if (!pageData?.page) {
            dispatch(setErrorAlert('Invalid page data'));
            return;
        }

        if (galleryItems.length === 0) {
            dispatch(setErrorAlert('Please add at least one gallery item'));
            return;
        }

        dispatch(setLoader(true));

        try {
            // Process gallery items
            const processedGallery = await Promise.all(galleryItems.map(async (entry) => {
                let avatarUrl = entry.avatar;
                
                if (entry.image) {
                    avatarUrl = await uploadImage(entry.image);
                    if (!avatarUrl) {
                        throw new Error('Failed to upload image');
                    }
                }

                return {
                    type: 'gallery',
                    sectionTitle: sectionTitle,
                    link: entry.link || '',
                    avatar: avatarUrl
                };
            }));

            // Structure the update data, preserving existing fields and adding originalPage
            const updateData = {
                token,
                payload: {
                    ...pageData,
                    page: pageData.page,
                    type: 'custom',
                    headeractive: pageData.headeractive ?? true,
                    background: pageData.background ?? '1A1A25',
                    accent: pageData.accent ?? 'FFFFFF',
                    content: [
                        ...(pageData.content || []).filter(item => item.type !== 'gallery'),
                        ...processedGallery
                    ],
                    headerImage: pageData.headerImage,
                    headerImageUrl: pageData.headerImageUrl,
                    originalPage: originalPage
                }
            };

            console.log('Sending update data:', updateData);

            const result = await dispatch(updateCustomForFocusThunk(updateData)).unwrap();
            
            if (result.status === 200) {
                await dispatch(getDataForFocusThunk({ token }));
                dispatch(clearFocusSection());
                navigate('/focus-page/custom-page');
            } else {
                throw new Error(result.message || 'Update failed');
            }

        } catch (error) {
            dispatch(setLoader(false));
            dispatch(setErrorAlert(error?.message || 'Failed to update gallery'));
            console.error('Error updating gallery:', error);
        }
    };

    // Add validation function
    const isDoneValid = () => {
        return galleryItems.length > 0 || isFormComplete;
    };

    // Add link handler
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentGallery({ ...currentGallery, link: value });
    };

    // Add form validation
    const validateForm = () => {
        if (!sectionTitle.trim()) {
            dispatch(setErrorAlert('Please enter a section title'));
            return false;
        }

        if (!currentGallery.preview) {
            dispatch(setErrorAlert('Please upload an image'));
            return false;
        }

        if (!currentGallery.link || !isValidUrl(currentGallery.link)) {
            dispatch(setErrorAlert('Please enter a valid URL'));
            return false;
        }

        return true;
    };

    // Add another handler
    const handleAddAnother = () => {
        if (!validateForm()) return;

        setGalleryItems([...galleryItems, {
            ...currentGallery,
            type: 'gallery',
            sectionTitle: sectionTitle,
            primaryText: currentGallery.link,
        }]);

        // Reset form
        setCurrentGallery({
            link: '',
            image: null,
            preview: null,
            type: 'gallery'
        });
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Edit content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        {isFormComplete && (
                            <button 
                                onClick={handleAddAnother}
                                className="text-white cursor-pointer"
                            >
                                Another
                            </button>
                        )}
                        <button 
                            onClick={handleDone} 
                            className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}
                        >
                            Done
                        </button>
                        <p 
                            onClick={() => navigate(-1)} 
                            className='text-white cursor-pointer'
                        >
                            Go back
                        </p>
                    </div>
                </div>

                {/* Scrollable Content Area */}
                <div className="w-full px-4 overflow-y-auto pb-[100px]">
                    {/* Input Section */}
                    <div className="mb-8">
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter section title (applies to all content)"
                                onChange={(e) => setSectionTitle(e.target.value)}
                                value={sectionTitle}
                            />
                        </div>

                        {/* Link and Image Upload Controls */}
                        <div className="flex flex-col">
                            <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <div className="flex items-center w-full">
                                        <DizeeInput
                                            placeholder="Enter link"
                                            onChange={handleLinkChange}
                                            value={currentGallery.link}
                                        />
                                        {isValidUrl(currentGallery.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mt-5">
                                <p 
                                    className="text-white text-[12px] mb-2 cursor-pointer"
                                    onClick={() => document.getElementById('file-input').click()}
                                >
                                    {currentGallery.preview ? 'Change image' : 'Upload image'}
                                </p>
                                {currentGallery.preview && (
                                    <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                                        <img src={currentGallery.preview} alt="preview" className="w-full h-full object-cover" />
                                    </div>
                                )}
                                <input
                                    id="file-input"
                                    type="file"
                                    onChange={handleImageChange}
                                    className="hidden"
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Added Items Section */}
                    {galleryItems.length > 0 && (
                        <div className="flex flex-col w-full mt-[40px]">
                            <div className='pb-[20px] flex w-full items-center'>
                                <div className='flex flex-row text-white w-full'>
                                    <p>Added Gallery Items</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                {galleryItems.map((item, index) => (
                                    <AddedCustom
                                        key={index}
                                        item={{
                                            ...item,
                                            primaryText: `Image ${index + 1}`,
                                        }}
                                        setOverlayVisible={setIsOverlayVisible}
                                        setItemForBackend={(item) => {
                                            setSelectedGallery(item);
                                            setSelectedIndex(index);
                                            setIsOverlayVisible(true);
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Fixed Footer with Gradient */}
                <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Edit your gallery content.</p>
                            <p>Image and link are required for each gallery item.</p>
                        </div>
                    </div>
                </div>

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={{
                            ...selectedGallery,
                            primaryText: `Image ${selectedIndex + 1}`,
                        }}
                        handleEdit={handleEditGallery}
                        handleDelete={handleDeleteGallery}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 