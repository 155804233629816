import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DotSvg from '../../../svg/DotSvg';
import { setWholeFocusEvent } from '../../../store/focuspage/focuspageSlice';
import CrossArrow from '../../../svg/CrossArrow';
import Delete from '../../../svg/Delete';
import { deleteFocusEvenThunk, getDataForFocusThunk } from '../../../store/focuspage/focuspageThunk';

export default function EventRedirectCard({ txt, link, dotimgclss, item, isDelete }) {
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getDataForFocusThunk({ token: token }))
    }, [])
    return (
        <div className='p-4 py-6 flex w-full justify-between items-center'>
            <div className='flex items-center'>
                <span className='text-white'>{txt}</span>
                {dotimgclss && (

                    <DotSvg width={6} height={6} color={'red'} className='ml-2' />

                )}
            </div>
            <div className="flex flex-row items-center gap-x-[15px]">
                <button onClick={() => {
                    dispatch(setWholeFocusEvent(item))
                    navigate(link)
                }} >
                    <CrossArrow className='h-[14px] w-[14px]  cursor-pointer' />
                </button>
                {
                    isDelete &&
                    <button onClick={() => {
                        dispatch(deleteFocusEvenThunk({
                            token, payload: {
                                ...item
                            }
                        }))
                    }} >
                        {/* <CrossArrow className='h-[14px] w-[14px]  cursor-pointer' /> */}
                        <Delete className='h-[14px] w-[14px]  cursor-pointer' />
                    </button>
                }
            </div>
        </div>
    );
}
