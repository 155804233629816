import { createAsyncThunk } from "@reduxjs/toolkit";
import { setErrorAlert, setLoader } from "../alert/alertSlice";
import { getDataAPI, postDataAPI, putDataAPI } from "../../utils/fetchData";
import axios from "axios";

// Add this import or use the environment variable
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

export const update_focus_data_thunk = createAsyncThunk(
    "/focus/update-focus-data",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await putDataAPI('focus/update-focus-data', tempData.body, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data.data.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
);

export const searchMusicForReleaseFocusThunk = createAsyncThunk(
    "/focuspage/search-music-for-release",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('user/search-music-for-release', { search: tempData.search }, tempData.token);
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
);

export const getDataForFocusThunk = createAsyncThunk(
    "/focus/get-data-for-focus",
    async (tempData, thunkAPI) => {
        try {
            // thunkAPI.dispatch(setLoader(true));
            const response = await getDataAPI('focus/get-data-for-focus', tempData.token);
            // thunkAPI.dispatch(setLoader(false));
            return response?.data?.data?.response;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
)

// music
export const addMusicForFocusThunk = createAsyncThunk(
    "/focus/add-music-for-focus",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('focus/add-music-for-focus', { payload: tempData.payload }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updateMusicForFocusThunk = createAsyncThunk(
    "/focus/updateMusicForFocus",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('focus/update-music-for-focus', tempData.body, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
        }
    }
);

export const checkExtensionAvailableForMusicThunk = createAsyncThunk(
    "/focus/check-extension-available-for-music",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('focus/check-extension-available-for-music', { extension: tempData.extension }, tempData.token);
            return response.data;
        } catch (error) {
            // thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const checkPageAvailableForMusicThunk = createAsyncThunk(
    "/focus/check-page-available-for-music",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('focus/check-page-available-for-music', { page: tempData.page }, tempData.token);
            return response.data;
        } catch (error) {
            // thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

// product
export const addProductForFocusThunk = createAsyncThunk(
    "/focus/add-product-for-focus",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('focus/add-product-for-focus', { payload: tempData.payload }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const checkExtensionAvailableForProductThunk = createAsyncThunk(
    "/focus/check-extension-available-for-product",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('focus/check-extension-available-for-product', { extension: tempData.extension }, tempData.token);
            return response.data;
        } catch (error) {
            // thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const checkPageAvailableForProductThunk = createAsyncThunk(
    "/focus/check-page-available-for-product",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('focus/check-page-available-for-product', { page: tempData.page }, tempData.token);
            return response.data;
        } catch (error) {
            // thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)
// event
export const addEventForFocusThunk = createAsyncThunk(
    "/focus/add-event-for-focus",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('focus/add-event-for-focus', { payload: tempData.payload }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const checkExtensionAvailableForEventThunk = createAsyncThunk(
    "/focus/check-extension-available-for-event",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('focus/check-extension-available-for-event', { extension: tempData.extension }, tempData.token);
            return response.data;
        } catch (error) {
            // thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const checkPageAvailableForEventThunk = createAsyncThunk(
    "/focus/check-page-available-for-event",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('focus/check-page-available-for-event', { page: tempData.page }, tempData.token);
            return response.data;
        } catch (error) {
            // thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)
// newsletter   
export const addNewsletterForFocusThunk = createAsyncThunk(
    "/focus/add-newsletter-for-focus",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI('focus/add-newsletter-for-focus', { payload: tempData.payload }, tempData.token);
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const checkExtensionAvailableForNewsletterThunk = createAsyncThunk(
    "/focus/check-extension-available-for-newsletter",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('focus/check-extension-available-for-newsletter', { extension: tempData.extension }, tempData.token);
            return response.data;
        } catch (error) {
            // thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const checkPageAvailableForNewsletterThunk = createAsyncThunk(
    "/focus/check-page-available-for-newsletter",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('focus/check-page-available-for-newsletter', { page: tempData.page }, tempData.token);
            return response.data;
        } catch (error) {
            // thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

// Custom focus page thunks
export const addCustomForFocusThunk = createAsyncThunk(
    "/focus/add-custom-for-focus",
    async (tempData, thunkAPI) => {
        try {
            thunkAPI.dispatch(setLoader(true));
            const response = await postDataAPI(
                'focus/add-custom-for-focus', 
                { payload: tempData.payload }, 
                tempData.token
            );
            thunkAPI.dispatch(setLoader(false));
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(setLoader(false));
            thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
);

export const checkExtensionAvailableForCustomThunk = createAsyncThunk(
    "/focus/check-extension-available-for-custom",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI(
                'focus/check-extension-available-for-custom',
                { extension: tempData.extension },
                tempData.token
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const checkPageAvailableForCustomThunk = createAsyncThunk(
    "/focus/check-page-available-for-custom",
    async (tempData, thunkAPI) => {
        try {
            const response = await postDataAPI('focus/check-page-available-for-custom', { page: tempData.page }, tempData.token);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.response || 'An error occurred');
        }
    }
)

export const updateCustomForFocusThunk = createAsyncThunk(
    "focuspage/updateCustomForFocus",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log('Token present:', !!token);
            console.log('Payload:', JSON.stringify(payload, null, 2));

            if (!payload?.page) {
                console.error('Invalid payload:', payload);
                return rejectWithValue({
                    message: 'Invalid payload: missing page identifier',
                    status: 400
                });
            }

            const response = await postDataAPI(
                'focus/update-custom-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);

export const updateMultiProductForFocusThunk = createAsyncThunk(
    "focuspage/updateMultiProductForFocus",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log('Token present:', !!token);
            console.log('Payload:', JSON.stringify(payload, null, 2));

            const response = await postDataAPI(
                'focus/update-multi-product-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);
 
export const updateProductForFocusThunk = createAsyncThunk(
    "focuspage/updateProductForFocus",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log('Token present:', !!token);
            console.log('Payload:', JSON.stringify(payload, null, 2));

            const response = await postDataAPI(
                'focus/update-product-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);

export const updateNewsletterForFocusThunk = createAsyncThunk(
    "focuspage/updateNewsletterForFocusThunk",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log('Token present:', !!token);
            console.log('Payload:', JSON.stringify(payload, null, 2));

            const response = await postDataAPI(
                'focus/update-newsletter-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);

export const updateMultiNewsletterForFocusThunk = createAsyncThunk(
    "focuspage/updateMultiNewsletterForFocusThunk",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log('Token present:', !!token);
            console.log('Payload:', JSON.stringify(payload, null, 2));

            const response = await postDataAPI(
                'focus/update-multi-newsletter-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);

 
export const updateEventForFocusThunk = createAsyncThunk(
    "focuspage/updateEventForFocus",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log('Token present:', !!token);
            console.log('Payload:', JSON.stringify(payload, null, 2));

            const response = await postDataAPI(
                'focus/update-event-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);
 
export const updateMultiEventForFocusThunk = createAsyncThunk(
    "focuspage/updateMultiEventForFocusThunk",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log('Token present:', !!token);
            console.log('Payload:', JSON.stringify(payload, null, 2));

            const response = await postDataAPI(
                'focus/update-multi-event-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);
 
export const deleteFocusEvenThunk = createAsyncThunk(
    "focuspage/deleteFocusEvenThunk",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log(`deleteFocusEvenThunk::`, token, payload);
            const response = await postDataAPI(
                'focus/delete-event-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);

export const deleteFocusProductThunk = createAsyncThunk(
    "focuspage/deleteFocusProductThunk",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log(`deleteFocusEvenThunk::`, token, payload);
            const response = await postDataAPI(
                'focus/delete-product-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);

export const deleteFocusMusicThunk = createAsyncThunk(
    "focuspage/deleteFocusMusicThunk",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log(`deleteFocusEvenThunk::`, token, payload);
            const response = await postDataAPI(
                'focus/delete-music-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);

export const deleteFocusNewsletterThunk = createAsyncThunk(
    "focuspage/deleteFocusNewsletterThunk",
    async ({ token, payload }, { rejectWithValue }) => {
        try {
            // Debug logs
            console.log(`deleteFocusNewsletterThunk::`, token, payload);
            const response = await postDataAPI(
                'focus/delete-newsletter-for-focus',
                { payload },
                token
            );

            if (!response?.data) {
                return rejectWithValue({
                    message: 'No response data received',
                    status: 500
                });
            }

            // Add loader dispatch if needed
            return response.data;

        } catch (error) {
            console.error('Thunk error:', error);
            
            // Handle axios errors with more detail
            if (error.response) {
                console.error('Response error:', error.response.data);
                return rejectWithValue({
                    message: error.response.data.message || 'Server error',
                    status: error.response.status
                });
            }

            return rejectWithValue({
                message: error.message || 'Unknown error occurred',
                status: 500
            });
        }
    }
);
