import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { DizeeInput2, DizeeNumberInput } from '../../../../../components/DizeeComponent/DixeeInput2';
import CopyLink from '../../../../../svg/CopyLink';
import ImageSelectionCard from '../../../../../components/ImageSelectionCard';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from '../../../../../utils/upload';
import { clearAlerts, setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { clearFocusSection, setFocusProduct } from '../../../../../store/focuspage/focuspageSlice';
import { addProductForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import FocusAddedProduct from '../../../../../components/Focuspage/Type/Product/FocusAddedProduct';
import FocusProductOverlay from '../../../../../components/Focuspage/Type/Product/FocusProductOverlay';
import Check from '../../../../../svg/Check';

export default function Step7() {
    const product = useSelector((state) => state.focuspage?.product);
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const [link, setLink] = useState('');
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [previousUrl, setPreviousUrl] = useState('');
    const [previousSource, setPreviousSource] = useState('');
    const dispatch = useDispatch();

    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [itemForBackend, setItemForBackend] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const resetImage = () => {
        setSelectedImage(null);
        setImagePreview(null);
    };

    useEffect(() => {
        dispatch(clearAlerts())
    }, [dispatch, token])

    const filterLink = (link) => {
        if (product?.length > 0) {
            for (let m of product) {
                for (let productLink of m.links) {
                    if (productLink.url === link) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setLink(value);
    };

    const isFormValid = () => {
        return productName && link && (selectedImage || imagePreview) && price && description;
    };

    const isDoneValid = () => {
        return (product?.product?.length > 0) || isFormValid();
    };

    const handleAddProduct = async () => {
        const isExistLink = filterLink(link);
        dispatch(clearAlerts());

        if (!previousUrl && isExistLink) {
            dispatch(setErrorAlert('Link already exists'));
            return;
        }

        let updatedData = product?.product || [];

        if (previousUrl) {
            updatedData = updatedData.filter(item => {
                return item?.links[0]?.url !== previousUrl;
            });
        }

        // Ensure all necessary fields are filled
        if (!productName || !link || (!selectedImage && !imagePreview) || !price || !description) {
            dispatch(setErrorAlert('Please fill all the fields'));
            return;
        }

        dispatch(setLoader(true));

        // Handle image upload for product cover image (not header)
        let url = '';
        if (selectedImage) {
            url = await uploadImage(selectedImage);
            if (!url) {
                dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                return;
            }
        }

        let payload = {
            productName: productName,
            description: description,
            price: price,
            image: url ? url : imagePreview,
            links: [
                {
                    source: previousSource ? previousSource : 'custom',
                    url: link
                }
            ]
        };

        updatedData = [...updatedData, payload];
        dispatch(setFocusProduct({ product: updatedData }));

        // Reset form fields
        setProductName('');
        setDescription('');
        setLink('');
        setPrice('');
        setSelectedImage(null);
        setImagePreview(null);
        setPreviousUrl(null);
        dispatch(setLoader(false));
    };

    const handleAddProductFocus = async () => {
        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one product'));
            return;
        }

        dispatch(setLoader(true));

        try {
            const dataCopy = { ...product };

            if (isFormValid()) {
                let url = '';
                if (selectedImage) {
                    url = await uploadImage(selectedImage);
                    if (!url) {
                        dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                        return;
                    }
                }

                const currentProduct = {
                    productName: productName,
                    description: description,
                    price: price,
                    image: url ? url : imagePreview,
                    links: [{
                        source: 'custom',
                        url: link
                    }]
                };

                if (!dataCopy.product) {
                    dataCopy.product = [];
                }
                dataCopy.product = [...dataCopy.product, currentProduct];
            }

            dataCopy.headeractive = true;

            dispatch(addProductForFocusThunk({ token: token, payload: dataCopy })).then((res) => {
                if (res.payload.status === 200) {
                    dispatch(clearFocusSection())
                    navigate("/focus-page");
                }
            });
        } catch (error) {
            console.error('Error adding products:', error);
            dispatch(setErrorAlert('An error occurred'));
        } finally {
            dispatch(setLoader(false));
        }
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Add products</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        <button 
                            onClick={handleAddProductFocus} 
                            className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}
                        >
                            Done
                        </button>
                        <button 
                            onClick={handleAddProduct}
                            className={`text-white cursor-pointer ${!isFormValid() ? 'opacity-50 pointer-events-none' : ''}`}
                        >
                            Another
                        </button>
                        <button onClick={() => navigate('/focus-page/product-type/step5')} className='text-white cursor-pointer'>
                            Go back
                        </button>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full gap-y-[50px]">
                    <div className='w-full'>
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <div className="flex items-center w-full">
                                    <DizeeInput2
                                        label="Link"
                                        placeholder="Enter a link"
                                        className="dizee-input w-full"
                                        value={link}
                                        onChange={handleLinkChange}
                                    />
                                    {isValidUrl(link) && <Check className="h-[12px] w-[18px] ml-2" />}
                                </div>
                            </div>
                        </div>

                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Product name"
                                    className="dizee-input w-full"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Description"
                                    className="dizee-input w-full"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeNumberInput
                                    label="Link"
                                    placeholder="Price"
                                    type="number"
                                    className="dizee-input w-full"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>
                        </div>

                        <ImageSelectionCard
                            txt="Add cover image"
                            dotimgclss={false}
                            onImageChange={handleImageChange}
                            imagePreview={imagePreview}
                            resetImage={resetImage}
                        />
                    </div>

                    {product && product?.product?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>Added Products</p>
                                </div>
                            </div>
                            {product?.product?.length > 0 && product?.product?.map((item, index) => (
                                <FocusAddedProduct 
                                    key={index} 
                                    item={item} 
                                    setOverlayVisible={setOverlayVisible} 
                                    setItemForBackend={setItemForBackend} 
                                    setPreviousUrl={setPreviousUrl}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {isOverlayVisible && (
                <FocusProductOverlay 
                    isOverlayVisible={isOverlayVisible} 
                    setOverlayVisible={setOverlayVisible} 
                    setLink={setLink} 
                    setProductName={setProductName} 
                    setPrice={setPrice} 
                    setDescription={setDescription} 
                    setImagePreview={setImagePreview} 
                    itemForBackend={itemForBackend} 
                />
            )}
        </LayoutHeader>
    );
}
