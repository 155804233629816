import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { MUSIC_PLATFORMS } from '../../../../../constants/constants';
import MusicPlatform from '../../../../../components/AddSection/Music/MusicPlatform';
import { update_focus_data_thunk } from '../../../../../store/focuspage/focuspageThunk';
import { setLoader, setErrorAlert, clearAlerts } from '../../../../../store/alert/alertSlice';
import { setFocusMusic } from '../../../../../store/focuspage/focuspageSlice';

export default function EditSingleMusicPlatforms() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');
    
    const { musicItem, originalData } = location.state;
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);

    useEffect(() => {
        if (musicItem?.links) {
            const existingPlatforms = musicItem.links.map(link => link.source);
            setSelectedPlatforms(existingPlatforms);
        }
    }, [musicItem]);

    const handleTogglePlatform = (platform) => {
        setSelectedPlatforms(prevSelected =>
            prevSelected.includes(platform)
                ? prevSelected.filter(item => item !== platform)
                : [...prevSelected, platform]
        );
    };

    const handleUpdateMusic = async () => {
        if (selectedPlatforms.length === 0) {
            dispatch(setErrorAlert("Please select at least one platform"));
            return;
        }

        const selectedLinks = musicItem?.links?.filter(link => 
            selectedPlatforms.includes(link.source)
        ) || [];

        let payload = {
            type: 0,
            id: musicItem.songstats_track_id,
            avatar: musicItem.avatar,
            title: musicItem.title,
            artist: musicItem.artists[0].name,
            links: selectedLinks,
            songstats_track_id: musicItem.songstats_track_id
        };

        const updatedData = {
            ...originalData,
            music: [payload]
        };

        dispatch(setLoader(true));
        try {
            await dispatch(update_focus_data_thunk({
                token,
                pageId: originalData._id,
                data: updatedData
            })).unwrap();

            dispatch(setFocusMusic(updatedData));

            navigate(-1, {
                state: {
                    updatedMusic: updatedData,
                    musicData: originalData
                }
            });
        } catch (error) {
            dispatch(setErrorAlert(error.message || "Failed to update music"));
        }
        dispatch(setLoader(false));
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white'>Select platforms for display</p>
                    <div className='flex gap-4'>
                        <button 
                            onClick={handleUpdateMusic} 
                            className='text-white cursor-pointer'
                        >
                            Update
                        </button>
                        <button 
                            onClick={() => navigate(-1)} 
                            className='text-white cursor-pointer'
                        >
                            Back
                        </button>
                    </div>
                </div>

                {MUSIC_PLATFORMS.map((platform, index) => {
                    const availableLinks = musicItem?.links?.filter(
                        link => link.source === platform.value
                    ) || [];

                    return availableLinks.length > 0 && (
                        <MusicPlatform
                            key={index}
                            txt={platform.label}
                            selected={selectedPlatforms.includes(platform.value)}
                            onSelect={() => handleTogglePlatform(platform.value)}
                        />
                    );
                })}
            </div>
        </LayoutHeader>
    );
} 