import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { BACKEND_URL } from '../../utils/axios';
import { removeUploadsFromUrl } from '../../components/DizeeComponent/DixeeInput2';
import dizeeLogo from '../../assets/images/dizee logo.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background-color: black;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  background-color: black;
  z-index: 10;
`;

const Logo = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: white;
  letter-spacing: 1px;
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 140px);
  max-width: 390px;
  margin: 0 auto;
  width: 100%;
  padding-top: 10%; /* Move content up by reducing top padding */
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 40px;
  position: absolute;
  top: 20%; /* Updated: moved up by additional 10% */
  left: 10%; /* Updated: moved left by additional 10% */
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OptionBox = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${props => props.color};
  border-radius: 4px;
  cursor: pointer;
`;

const OptionLabel = styled.div`
  margin-top: 8px;
  font-size: 10px;
  color: ${props => props.textColor || 'white'};
  text-align: center;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly; /* Updated: increased spacing between items */
  align-items: center;
  background-color: black;
  z-index: 10;
  padding: 0;
  height: 50px;
  width: 395px; /* Increased width by 20% from 330px to 395px */
  max-width: 395px;
  margin: 0 auto;
`;

const FooterItem = styled.div`
  position: relative;
  font-size: 7px;
  letter-spacing: 0.02em;
  font-weight: 400;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 8px; /* Increased padding for more spacing */
`;

const ActiveDot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #FF2300;
  border-radius: 50%;
  margin-right: 5px;
`;

function PersonalLanding() {
  const navigate = useNavigate();
  const userArticle = useSelector((state) => state.user.userArticle);
  let user = localStorage.getItem('dizeeUser');
  user = JSON.parse(user);
  
  const handleDiscoveryClick = () => {
    if (userArticle?.professionRole === "personal") {
      navigate('/explore');
    } else {
      navigate('/marketplace');
    }
  };
  
  const handleVaultClick = () => {
    navigate(`/${userArticle?.domain || ''}`);
  };
  
  const handleSearchClick = () => {
    navigate('/apps');
  };
  
  const handleNotificationsClick = () => {
    // Placeholder for notifications functionality
    console.log('Notifications clicked');
  };
  
  const handleProfileClick = () => {
    navigate(`/${userArticle?.domain || ''}`);
  };

  const getProfileImage = () => {
    if (user?.avatar) {
      return user.avatar.includes('/uploads/') 
        ? `${BACKEND_URL}${removeUploadsFromUrl(user.avatar)}` 
        : user.avatar;
    }
    return 'https://placehold.co/32x32';
  };

  return (
    <Container>
      <Header>
        <Logo>D I Z E E</Logo>
        <ProfileImage 
          src={getProfileImage()} 
          alt="Profile" 
          onClick={handleProfileClick}
        />
      </Header>
      
      <MainContent>
        <OptionsContainer>
          <OptionWrapper>
            <OptionBox color="#FF3B30" onClick={handleDiscoveryClick} />
            <OptionLabel>Discovery</OptionLabel>
          </OptionWrapper>
          
          <OptionWrapper>
            <OptionBox color="#E5E5EA" onClick={handleVaultClick} />
            <OptionLabel>Vault</OptionLabel>
          </OptionWrapper>
        </OptionsContainer>
      </MainContent>
      
      <Footer>
        <FooterItem onClick={handleSearchClick}>
          <ActiveDot />
          SEARCH APPS
        </FooterItem>
        
        <FooterItem onClick={handleNotificationsClick}>
          NOTIFICATIONS
        </FooterItem>
        
        <FooterItem onClick={handleProfileClick}>
          PROFILE
        </FooterItem>
      </Footer>
    </Container>
  );
}

export default PersonalLanding; 