import React from 'react';
import CrossArrow from '../svg/CrossArrow';
import { getAccentStyle } from '../constants/constants';
import { postDataAPI } from '../utils/fetchData';
import { useParams } from 'react-router-dom';

const CusFocusSocial = ({ imgurl, item, userArticle }) => {
    const { domain: dynamicVar } = useParams();
    const accentColor = userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF';

    const handleClickSocial = async () => {
        if (!item?.link) return;
        
        let url = item.link;
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
        }
        window.open(url, '_blank');
        
        try {
            await postDataAPI('analytics/create-social-analytics', {
                link: url,
                domain: dynamicVar
            }, null);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="w-[150px] flex-shrink-0 flex flex-col">
            {/* Image Section - matches Socialfeed dimensions */}
            <div className="relative group cursor-pointer" onClick={handleClickSocial}>
                <img
                    src={imgurl}
                    alt="social"
                    className="h-[190px] w-[150px] object-cover rounded-[3px]"
                />
                {/* Hover overlay with link indicator */}
                <div className="absolute bottom-0 right-0 p-2 opacity-0 group-hover:opacity-100 transition-opacity">
                    <CrossArrow color={accentColor} />
                </div>
            </div>
        </div>
    );
};

export default CusFocusSocial; 