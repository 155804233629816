import React from 'react';
import LayoutHeader from '../../layout/LayoutHeader';
import AddCard from '../../components/Focuspage/AddCard';

export default function Appshub() {
    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white'>Your Apps</p>
                </div>

                <AddCard 
                    txt="Create a new app" 
                    link="/apps/create" 
                    dotimgclss={false} 
                />
            </div>
        </LayoutHeader>
    );
}
