import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { DizeeInput2, maxLength, removeUploadsFromUrl } from '../../../../../components/DizeeComponent/DixeeInput2';
import {
    searchMusicForReleaseFocusThunk,
    updateMusicForFocusThunk,
    update_focus_data_thunk,
    getDataForFocusThunk
} from '../../../../../store/focuspage/focuspageThunk';
import {
    clearSearchMusic,
    setFocusMusic
} from '../../../../../store/focuspage/focuspageSlice';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import FocusMusicCard from '../../../../../components/Focuspage/Type/Music/FocusMusicCard';
import { ClipLoader } from 'react-spinners';
import searchImg from '../../../../../assets/images/components/search.png';
import FocusAddedMusicCard from '../../../../../components/Focuspage/Type/Music/FocusAddedMusicCard';
import EditFocusMusicCard from '../../../../../components/Focuspage/Type/Music/EditFocusMusicCard';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';

export default function EditSingleMusic() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const musicLoading = useSelector((state) => state.focuspage.musicLoading);
    const searchMusic = useSelector((state) => state.focuspage.searchMusic);

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const originalMusicData = location.state?.musicData;

    // Change from const to let for state updates
    const [currentMusic, setCurrentMusic] = useState(originalMusicData?.music?.[0] || null);

    // Add effect to update current music when returning from platforms page
    useEffect(() => {
        if (location.state?.updatedMusic?.music?.[0]) {
            setCurrentMusic(location.state.updatedMusic.music[0]);
        }
    }, [location.state]);

    // Search debouncing
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 300);
        return () => clearTimeout(handler);
    }, [search]);

    // Search handling
    useEffect(() => {
        if (debouncedSearch.length < 6) {
            dispatch(clearSearchMusic());
            return;
        }
        dispatch(searchMusicForReleaseFocusThunk({ token, search: debouncedSearch }));
    }, [debouncedSearch, dispatch, token]);

    // Add this selector
    const music = useSelector((state) => state.focuspage.music?.music);

    // Update the currentMusic state to use Redux store
    useEffect(() => {
        if (music?.[0]) {
            setCurrentMusic(music[0]);
        }
    }, [music]);

    // Update handleMusicSelect to not include returnTo
    const handleMusicSelect = (selectedMusic) => {
        navigate('/focus-page/music-type/edit-multiple-platforms', {
            state: {
                musicItem: selectedMusic,
                originalData: originalMusicData,
                currentMusic: []
            }
        });
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[90vh] bg-black flex flex-col items-center relative">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white' style={{ fontSize: '12px' }}>Search for a release for change</p>
                    <div className='flex justify-center items-center gap-4'>
                        <button
                            onClick={() => navigate(-1)}
                            className='text-white cursor-pointer'
                            style={{ fontSize: '12px' }}
                        >
                            Done
                        </button>
                        <button
                            onClick={() => navigate(-1)}
                            className='text-white cursor-pointer'
                            style={{ fontSize: '12px' }}
                        >
                            Go back
                        </button>
                    </div>
                </div>

                <div className='flex w-full flex-col gap-y-[50px]'>
                    <div className="flex flex-col justify-center items-center w-full">
                        <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    placeholder="Search by song, album, URL, UPC or ISRC"
                                    className="dizee-input w-full"
                                    search={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                            <img src={searchImg} alt='sp' className='h-[12px] w-[12px] mx-1' />
                        </div>

                        {musicLoading ? (
                            <ClipLoader
                                color="white"
                                loading={true}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        ) : (
                            searchMusic?.length > 0 &&
                            searchMusic?.map((item, index) => (
                                <EditFocusMusicCard
                                    key={index}
                                    item={item}
                                    onClick={() => handleMusicSelect(item)}
                                />
                            ))
                        )}
                    </div>

                    {/* Show current music if exists */}
                    {currentMusic && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>Current Music</p>
                                </div>
                            </div>
                            <div className='w-[350px] sm:w-[390px] p-5 flex flex-row justify-between items-center'>
                                <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                                    <img
                                        src={removeUploadsFromUrl(currentMusic?.avatar)}
                                        alt='Music Cover'
                                        className='object-cover h-[50px] w-[50px] rounded-[3px]'
                                    />
                                    <div className='flex flex-col items-start justify-start h-full p-2'>
                                        <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
                                            {maxLength(currentMusic?.title, 30)}
                                        </p>
                                        <p className='text-[#696974]' style={{ fontSize: '12px' }}>
                                            {maxLength(currentMusic?.artist, 30)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                </div>

                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedProduct}
                        handleEdit={() => { }}
                        handleDelete={() => { }}
                        handleMoveUp={() => { }}
                        handleMoveDown={() => { }}
                        canMoveUp={false}
                        canMoveDown={false}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 