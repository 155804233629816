import { useEffect, useState } from "react";
import LayoutHeader from "../../../../../layout/LayoutHeader";
import { useDispatch, useSelector } from "react-redux";
import { setFocusNewsletter } from "../../../../../store/focuspage/focuspageSlice";
import { useNavigate } from "react-router";
import { uploadImage } from "../../../../../utils/upload";
import { setLoader, setErrorAlert } from "../../../../../store/alert/alertSlice";
import DizeeInput from "../../../../../components/DizeeComponent/DizeeInput";
import Check from '../../../../../svg/Check';

export default function Step5() {
    const [preview, setPreview] = useState(null);
    const [file, setFile] = useState(null);
    const [headerTitle, setHeaderTitle] = useState('');
    const [headerSubtitle, setHeaderSubtitle] = useState('');
    
    let newsletter = useSelector(state => state.focuspage.newsletter);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMultipleFlow = newsletter?.type === 'multiple';

    useEffect(() => {
        if (newsletter?.avatarPreview) {
            setPreview(newsletter?.avatarPreview);
        }
        if (newsletter?.headerTitle) setHeaderTitle(newsletter.headerTitle);
        if (newsletter?.headerSubtitle) setHeaderSubtitle(newsletter.headerSubtitle);
    }, [newsletter]);

    const changeAvatar = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreview(URL.createObjectURL(selectedFile));
            dispatch(setFocusNewsletter({ 
                avatar: selectedFile,
                avatarPreview: URL.createObjectURL(selectedFile)
            }));
        }
    };

    const handleNext = async () => {
        if (file) {
            dispatch(setLoader(true));
            try {
                const url = await uploadImage(file);
                if (!url) {
                    dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                    return;
                }
                
                dispatch(setFocusNewsletter({ 
                    avatar: url,
                    headeractive: true,
                    headerTitle,
                    headerSubtitle
                }));
                
                navigate(isMultipleFlow ? "/focus-page/newsletter-type/step7" : "/focus-page/newsletter-type/step6");
            } catch (error) {
                console.error('Error uploading image:', error);
                dispatch(setErrorAlert('Error uploading image'));
            } finally {
                dispatch(setLoader(false));
            }
        } else {
            navigate(isMultipleFlow ? "/focus-page/newsletter-type/step7" : "/focus-page/newsletter-type/step6");
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                    <div className="px-4 my-[50px] flex w-full justify-between">
                        <p className="text-white">Add header image</p>
                        <div className="flex gap-[30px]">
                            <div
                                className={`text-white cursor-pointer ${file || newsletter?.avatar ? "opacity-100" : "opacity-50"}`}
                                onClick={handleNext}
                            >
                                Next
                            </div>
                            <div 
                                className="text-white cursor-pointer"
                                onClick={() => navigate("/focus-page/newsletter-type/step4")}
                            >
                                Go back
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-4">
                        <div
                            className="text-white cursor-pointer"
                            onClick={() => document.getElementById("avatar").click()}
                        >
                            {preview ? "Change header image" : "Upload header image"}
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            id="avatar"
                            onChange={changeAvatar}
                        />
                        {preview ? (
                            <div className="profile-picture my-8 min-h-[100px]">
                                <div className="avatar">
                                    <img
                                        src={preview}
                                        className="h-[80px] w-[80px] rounded-full"
                                        alt="header"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="mb-8" />
                        )}

                        <div className="mt-6">
                            <DizeeInput
                                placeholder="Add header title (optional)"
                                onChange={(e) => setHeaderTitle(e.target.value)}
                                value={headerTitle}
                                className="w-full"
                            />
                        </div>

                        <div className="mt-6">
                            <DizeeInput
                                placeholder="Add header subtitle (optional)"
                                onChange={(e) => setHeaderSubtitle(e.target.value)}
                                value={headerSubtitle}
                                className="w-full"
                            />
                        </div>
                    </div>

                    <div className="w-full px-4 absolute bottom-[27px] left-0">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Create a powerful first impression with your header.</p>
                            <p>A strong visual identity sets the tone for your entire newsletter.</p>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
}

