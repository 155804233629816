import { useState, useEffect } from "react";
import styled from "styled-components";
import TypeWriter from "../../../../components/OnBoard/Typewriter";
import { NextButton } from "../../../../components/DizeeComponent/NextButton";
import dizeeLogo from "../../../../assets/images/dizee logo.svg";
import asapRocky from "../../../../assets/images/components/ASAP-ROCKY.png";
import charliXcx from "../../../../assets/images/components/CHARLI-XCX.png";
import domDolla from "../../../../assets/images/components/DOM-DOLLA.png";
import katVonD from "../../../../assets/images/components/KAT-VON-D.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  
  @media (max-width: 768px) {
    padding-bottom: 120px; /* Add space at the bottom on mobile */
  }
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
  
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

const TemplatesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  width: 280px;
  
  @media (max-width: 768px) {
    gap: 16px 16px; /* Horizontal gap stays the same, vertical gap reduced */
    grid-template-rows: auto auto; /* Explicit rows */
    margin-top: 10px; /* Less space at top */
    margin-bottom: 110px; /* More space after grid for footer and buttons */
  }
`;

const TemplateCard = styled.div`
  cursor: pointer;
  position: relative;
  aspect-ratio: 9/14;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;

  @media (max-width: 768px) {
    &:nth-child(3), &:nth-child(4) {
      margin-top: -40px; /* Move bottom row cards up */
    }
  }

  &:hover {
    transform: scale(1.02);
  }

  &.selected {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  }
`;

const TemplateImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  
  @media (max-width: 768px) {
    position: absolute;
    bottom: 30px; /* Move buttons higher up (changed from -80px) */
    left: 0;
    z-index: 10; /* Ensure buttons appear above other content */
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  margin-bottom: 20px;
`;

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  height: calc(100% - 80px);
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-radius: 8px;
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #000000;
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const ModalButtons = styled.div`
  padding: 20px 0;
  display: flex;
  gap: 22px;
  position: fixed;
  width: 280px;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background: #000000;
  padding-left: 0;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
  
  @media (max-width: 768px) {
    bottom: 15px;
    z-index: 5; /* Ensure footer is above other content but below buttons */
  }
`;

const LogoImage = styled.img`  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const FooterHeader = styled.div`
  position: relative;
  width: 100%;
`;

const TemplateSelection = ({ setStep, setUser, user }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [modalTemplate, setModalTemplate] = useState(null);

  // Add this useEffect to disable scrolling on mobile when component mounts
  useEffect(() => {
    // Save original body style
    const originalStyle = window.getComputedStyle(document.body).overflow;
    
    // Function to handle window resize
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = originalStyle;
      }
    };
    
    // Set initial state
    handleResize();
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Cleanup when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const templates = [
    { id: 1, image: asapRocky },
    { id: 2, image: charliXcx },
    { id: 3, image: domDolla },
    { id: 4, image: katVonD },
  ];

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setUser({ ...user, template: template.id });
  };

  const handleTemplateClick = (template, e) => {
    e.stopPropagation(); // Prevent triggering selection when opening modal
    setModalTemplate(template);
  };

  const closeModal = () => {
    setModalTemplate(null);
  };

  return (
    <Container>
      <FormSection>
        <Header>
          <TypeWriter text="Select a template" speed={45} />
        </Header>

        <TemplatesGrid>
          {templates.map((template) => (
            <TemplateCard
              key={template.id}
              onClick={(e) => handleTemplateClick(template, e)}
              className={selectedTemplate?.id === template.id ? "selected" : ""}
            >
              <TemplateImage src={template.image} alt={`Template ${template.id}`} />
            </TemplateCard>
          ))}
        </TemplatesGrid>

        {modalTemplate && (
          <>
            <Modal onClick={closeModal}>
              <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalImage src={modalTemplate.image} alt="Full template view" />
              </ModalContent>
            </Modal>
            <ModalButtons>
              <NextButton 
                className="cursor-pointer mr-[22px]"
                onClick={() => {
                  handleTemplateSelect(modalTemplate);
                  closeModal();
                  setStep(7);
                }}
              >
                Select
              </NextButton>
              <NextButton 
                className="cursor-pointer"
                onClick={closeModal}
              >
                Close
              </NextButton>
            </ModalButtons>
          </>
        )}

        {!modalTemplate && (
          <ButtonContainer>
            <NextButton
              className="cursor-pointer mr-[22px]"
              onClick={() => setStep(7)}
            >
              Skip
            </NextButton>
            <NextButton className="cursor-pointer" onClick={() => setStep(5)}>
              Go back
            </NextButton>
          </ButtonContainer>
        )}
      </FormSection>

      {/* Only show Footer when modal is not open */}
      {!modalTemplate && (
        <Footer>
          <FooterHeader>
            <LogoImage src={dizeeLogo} alt="DIZEE" />
          </FooterHeader>
          <FooterText>
            Choose from our curated selection of templates or skip to create your own custom design. 
          </FooterText>
        </Footer>
      )}
    </Container>
  );
};

export default TemplateSelection; 
