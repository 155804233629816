import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { addCustomForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';

export default function AddFormContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const custom = useSelector(state => state.focuspage.custom);

    // State management
    const [currentForm, setCurrentForm] = useState({
        sectionTitle: '',     // Section title
        primaryText: '',      // Main text
        secondaryText: '',    // Optional text
        buttonText: '',       // Button text
        link: '',             // Add link field
        type: 'form'
    });
    const [formItems, setFormItems] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Add cleanup effect
    useEffect(() => {
        // Clear form items when component mounts
        setFormItems([]);
        setCurrentForm({
            sectionTitle: '',
            primaryText: '',
            secondaryText: '',
            buttonText: '',
            link: '',
            type: 'form'
        });
        
        // Optional: cleanup when component unmounts
        return () => {
            setFormItems([]);
            setCurrentForm({
                sectionTitle: '',
                primaryText: '',
                secondaryText: '',
                buttonText: '',
                link: '',
                type: 'form'
            });
        };
    }, []); // Empty dependency array means this runs once on mount

    // Form validation
    const isFormComplete = currentForm.sectionTitle && 
                          currentForm.primaryText && 
                          currentForm.buttonText;

    const isDoneValid = () => {
        return formItems.length > 0 || isFormComplete;
    };

    // Add URL validation (same as in AddTextContent)
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentForm({ ...currentForm, link: value });
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in all required fields'));
            return;
        }

        setFormItems([...formItems, {
            type: 'form',
            sectionTitle: currentForm.sectionTitle,
            primaryText: currentForm.primaryText,
            secondaryText: currentForm.secondaryText,
            buttonText: currentForm.buttonText,
            link: currentForm.link || ''  // Include link in form item
        }]);

        // Reset form except section title
        setCurrentForm({
            sectionTitle: currentForm.sectionTitle,
            primaryText: '',
            secondaryText: '',
            buttonText: '',
            link: '',         // Reset link
            type: 'form'
        });
    };

    // Handle editing form item
    const handleEditForm = () => {
        if (selectedForm && selectedIndex !== null) {
            setCurrentForm({
                sectionTitle: selectedForm.sectionTitle,
                primaryText: selectedForm.primaryText,
                secondaryText: selectedForm.secondaryText || '',
                buttonText: selectedForm.buttonText || '',
                link: selectedForm.link || '',    // Include link
                type: 'form'
            });
            
            const updatedItems = formItems.filter((_, index) => index !== selectedIndex);
            setFormItems(updatedItems);
            setIsOverlayVisible(false);
        }
    };

    // Handle deleting form item
    const handleDeleteForm = () => {
        if (selectedIndex !== null) {
            const updatedItems = formItems.filter((_, index) => index !== selectedIndex);
            setFormItems(updatedItems);
            setIsOverlayVisible(false);
        }
    };

    // Add these new handler functions after handleDeleteForm
    const handleMoveUp = () => {
        if (selectedIndex > 0) {
            const updatedItems = [...formItems];
            // Swap current item with the one above it
            [updatedItems[selectedIndex], updatedItems[selectedIndex - 1]] = 
            [updatedItems[selectedIndex - 1], updatedItems[selectedIndex]];
            setFormItems(updatedItems);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (selectedIndex < formItems.length - 1) {
            const updatedItems = [...formItems];
            // Swap current item with the one below it
            [updatedItems[selectedIndex], updatedItems[selectedIndex + 1]] = 
            [updatedItems[selectedIndex + 1], updatedItems[selectedIndex]];
            setFormItems(updatedItems);
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle form submission
    const handleDone = async () => {
        if (!custom?.page) {
            dispatch(setErrorAlert("Please set a page name first"));
            return;
        }

        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one form content'));
            return;
        }

        dispatch(setLoader(true));
        try {
            let contentToSubmit = [...formItems];
            if (isFormComplete) {
                contentToSubmit.push({
                    type: 'form',
                    sectionTitle: currentForm.sectionTitle,
                    primaryText: currentForm.primaryText,
                    secondaryText: currentForm.secondaryText,
                    buttonText: currentForm.buttonText,
                    link: currentForm.link || ''
                });
            }

            // Maintain header content only
            const existingHeader = custom?.content?.find(item => item.type === 'header');
            
            const dataCopy = {
                page: custom.page,
                type: 'custom',
                headeractive: true,
                content: [
                    existingHeader || {
                        type: 'header',
                        avatar: custom?.avatar || '',
                        title: custom?.headerText || '',
                        subtitle: custom?.subheaderText || ''
                    },
                    ...contentToSubmit
                ]
            };

            const result = await dispatch(addCustomForFocusThunk({ 
                token, 
                payload: dataCopy 
            })).unwrap();
            
            if (result.status === 200) {
                dispatch(clearFocusSection());
                navigate("/focus-page");
            } else {
                throw new Error(result.message || 'Failed to create focus page');
            }
        } catch (error) {
            console.error('Error creating form content:', error);
            dispatch(setErrorAlert(error?.message || 'Error creating form content'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-y-auto pb-[120px]">
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20 after:content-[''] after:absolute after:left-0 after:right-0 after:top-[-50px] after:h-[50px] after:bg-black">
                            <p className="text-white">Add form content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div className="text-white cursor-pointer" onClick={handleAddAnother}>
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50' : ''}`}
                                    onClick={() => isDoneValid() && handleDone()}
                                >
                                    Done
                                </div>
                                <div className="text-white cursor-pointer" onClick={() => navigate(-1)}>
                                    Go back
                                </div>
                            </div>
                        </div>

                        <div className="mt-[120px] px-4 flex flex-col gap-y-4">
                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter section title"
                                    onChange={(e) => setCurrentForm({ ...currentForm, sectionTitle: e.target.value })}
                                    value={currentForm.sectionTitle}
                                    maxLength={30}
                                />
                                {currentForm.sectionTitle && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter main text"
                                    onChange={(e) => setCurrentForm({ ...currentForm, primaryText: e.target.value })}
                                    value={currentForm.primaryText}
                                    multiline
                                    maxLength={200}
                                />
                                {currentForm.primaryText && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter optional text"
                                    onChange={(e) => setCurrentForm({ ...currentForm, secondaryText: e.target.value })}
                                    value={currentForm.secondaryText}
                                    multiline
                                    maxLength={100}
                                />
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter button text"
                                    onChange={(e) => setCurrentForm({ ...currentForm, buttonText: e.target.value })}
                                    value={currentForm.buttonText}
                                    maxLength={20}
                                />
                                {currentForm.buttonText && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            {/* Add link input field */}
                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter link (optional)"
                                    onChange={handleLinkChange}
                                    value={currentForm.link}
                                />
                                {isValidUrl(currentForm.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            {formItems.length > 0 && (
                                <div className="flex flex-col w-full mt-[40px]">
                                    <div className='pb-[20px] flex w-full items-center'>
                                        <div className='flex flex-row text-white w-full'>
                                            <p>Added Forms</p>
                                        </div>
                                    </div>
                                    {formItems.map((item, index) => (
                                        <AddedCustom
                                            key={index}
                                            item={item}
                                            setOverlayVisible={setIsOverlayVisible}
                                            setItemForBackend={(item) => {
                                                setSelectedForm(item);
                                                setSelectedIndex(index);
                                                setIsOverlayVisible(true);
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="w-full absolute bottom-0 left-0">
                        <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                        <div className="w-full bg-black px-4 py-6">
                            <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                                <p>Add your form content to display in your focus page.</p>
                                <p>Section title, main text, and button text are required.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isOverlayVisible && (
                <OverlayCustom
                    isOverlayVisible={isOverlayVisible}
                    setOverlayVisible={setIsOverlayVisible}
                    selectedItem={selectedForm}
                    handleEdit={handleEditForm}
                    handleDelete={handleDeleteForm}
                    handleMoveUp={handleMoveUp}
                    handleMoveDown={handleMoveDown}
                    canMoveUp={selectedIndex > 0}
                    canMoveDown={selectedIndex < formItems.length - 1}
                />
            )}
        </LayoutHeader>
    );
} 