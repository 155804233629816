import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { updateCustomForFocusThunk, getDataForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';
import { uploadImage } from '../../../../../utils/upload';

export default function EditVideoContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    // Get data from navigation state
    const pageData = location.state?.pageData;
    const pageId = location.state?.pageId;

    // Initialize state with existing video content
    const [videoItems, setVideoItems] = useState(
        location.state?.videoContent?.map(item => ({
            ...item,
            preview: item.avatar,
            image: null
        })) || []
    );

    const [currentVideo, setCurrentVideo] = useState({
        sectionTitle: location.state?.sectionTitle || '',
        primaryText: '',
        link: '',
        image: null,
        preview: null,
        type: 'video'
    });

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Form validation flags
    const isFormComplete = currentVideo.preview && 
                          currentVideo.link && 
                          isValidUrl(currentVideo.link) && 
                          currentVideo.sectionTitle.trim() !== '';

    const isDoneValid = () => {
        return videoItems.length > 0 || isFormComplete;
    };

    // Handle link change
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentVideo({ ...currentVideo, link: value });
    };

    // Handle image upload
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                dispatch(setErrorAlert('Image size should be less than 5MB'));
                return;
            }

            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            if (!validTypes.includes(file.type)) {
                dispatch(setErrorAlert('Please upload a valid image file (JPEG, PNG, GIF, or WEBP)'));
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentVideo({
                    ...currentVideo,
                    image: file,
                    preview: reader.result
                });
            };
            reader.readAsDataURL(file);
        }
        e.target.value = '';
    };

    // Handle video click for editing
    const handleVideoClick = (item, index) => {
        setSelectedVideo({
            ...item,
            type: 'video'
        });
        setSelectedIndex(index);
        
        setCurrentVideo({
            sectionTitle: item.sectionTitle,
            primaryText: item.primaryText || '',
            link: item.link || '',
            preview: item.avatar,
            image: null,
            type: 'video'
        });
        
        setIsOverlayVisible(true);
    };

    // Handle edit
    const handleEditVideo = () => {
        if (selectedVideo && selectedIndex !== null) {
            const updatedItems = [...videoItems];
            updatedItems[selectedIndex] = {
                ...updatedItems[selectedIndex],
                sectionTitle: currentVideo.sectionTitle,
                primaryText: currentVideo.primaryText,
                link: currentVideo.link,
                avatar: currentVideo.preview,
                preview: currentVideo.preview,
                image: currentVideo.image,
                type: 'video'
            };
            setVideoItems(updatedItems);
            setIsOverlayVisible(false);
        }
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!validateForm()) return;

        if (selectedIndex !== null) {
            const updatedItems = [...videoItems];
            updatedItems[selectedIndex] = {
                type: 'video',
                sectionTitle: currentVideo.sectionTitle,
                primaryText: currentVideo.primaryText,
                link: currentVideo.link,
                avatar: currentVideo.preview,
                preview: currentVideo.preview,
                image: currentVideo.image
            };
            setVideoItems(updatedItems);
            setSelectedIndex(null);
        } else {
            setVideoItems([...videoItems, {
                type: 'video',
                sectionTitle: currentVideo.sectionTitle,
                primaryText: currentVideo.primaryText,
                link: currentVideo.link,
                avatar: currentVideo.preview,
                preview: currentVideo.preview,
                image: currentVideo.image
            }]);
        }

        // Reset form
        setCurrentVideo({
            sectionTitle: currentVideo.sectionTitle,
            primaryText: '',
            link: '',
            image: null,
            preview: null,
            type: 'video'
        });
    };

    // Form validation
    const validateForm = () => {
        if (!currentVideo.sectionTitle.trim()) {
            dispatch(setErrorAlert('Please enter a section title'));
            return false;
        }

        if (!currentVideo.preview) {
            dispatch(setErrorAlert('Please upload an image'));
            return false;
        }

        if (!currentVideo.link || !isValidUrl(currentVideo.link)) {
            dispatch(setErrorAlert('Please enter a valid URL'));
            return false;
        }

        return true;
    };

    // Handle delete
    const handleDeleteVideo = () => {
        setVideoItems(videoItems.filter((_, index) => index !== selectedIndex));
        setIsOverlayVisible(false);
    };

    // Handle move up/down
    const handleMoveUp = () => {
        if (selectedIndex > 0) {
            const updatedItems = [...videoItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex - 1]] = 
            [updatedItems[selectedIndex - 1], updatedItems[selectedIndex]];
            setVideoItems(updatedItems);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (selectedIndex < videoItems.length - 1) {
            const updatedItems = [...videoItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex + 1]] = 
            [updatedItems[selectedIndex + 1], updatedItems[selectedIndex]];
            setVideoItems(updatedItems);
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle done
    const handleDone = async () => {
        if (!pageData?.page) {
            dispatch(setErrorAlert('Invalid page data'));
            return;
        }

        let finalVideoItems = [...videoItems];
        if (isFormComplete) {
            if (selectedIndex !== null) {
                finalVideoItems[selectedIndex] = {
                    type: 'video',
                    sectionTitle: currentVideo.sectionTitle,
                    primaryText: currentVideo.primaryText,
                    link: currentVideo.link,
                    avatar: currentVideo.preview,
                    preview: currentVideo.preview,
                    image: currentVideo.image
                };
            } else {
                finalVideoItems.push({
                    type: 'video',
                    sectionTitle: currentVideo.sectionTitle,
                    primaryText: currentVideo.primaryText,
                    link: currentVideo.link,
                    avatar: currentVideo.preview,
                    preview: currentVideo.preview,
                    image: currentVideo.image
                });
            }
        }

        if (finalVideoItems.length === 0) {
            dispatch(setErrorAlert('Please add at least one video'));
            return;
        }

        dispatch(setLoader(true));

        try {
            const processedVideos = await Promise.all(finalVideoItems.map(async (entry) => {
                let avatarUrl = entry.avatar;
                
                if (entry.image) {
                    avatarUrl = await uploadImage(entry.image);
                    if (!avatarUrl) {
                        throw new Error('Failed to upload image');
                    }
                }

                return {
                    type: 'video',
                    sectionTitle: currentVideo.sectionTitle,
                    primaryText: entry.primaryText || '',
                    link: entry.link || '',
                    avatar: avatarUrl
                };
            }));

            const updateData = {
                token,
                payload: {
                    ...pageData,
                    page: pageData.page,
                    type: 'custom',
                    headeractive: pageData.headeractive ?? true,
                    background: pageData.background ?? '1A1A25',
                    accent: pageData.accent ?? 'FFFFFF',
                    content: [
                        ...(pageData.content || []).filter(item => item.type !== 'video'),
                        ...processedVideos
                    ],
                    headerImage: pageData.headerImage,
                    headerImageUrl: pageData.headerImageUrl,
                    originalPage: location.state?.pageData?.page || ''
                }
            };

            const result = await dispatch(updateCustomForFocusThunk(updateData)).unwrap();
            
            if (result.status === 200) {
                await dispatch(getDataForFocusThunk({ token, pageId }));
                dispatch(clearFocusSection());
                navigate('/focus-page/custom-page');
            } else {
                throw new Error(result.message || 'Update failed');
            }
        } catch (error) {
            console.error('Error updating video content:', error);
            dispatch(setErrorAlert(error?.message || 'Failed to update videos'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-y-auto pb-[120px]">
                        {/* Header - Updated to match AddVideoContent */}
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20">
                            <p className="text-white">Edit content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div className="text-white cursor-pointer" onClick={handleAddAnother}>
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50' : ''}`}
                                    onClick={() => isDoneValid() && handleDone()}
                                >
                                    Done
                                </div>
                                <div className="text-white cursor-pointer" onClick={() => navigate(-1)}>
                                    Go back
                                </div>
                            </div>
                        </div>

                        {/* Content Section - Updated to match AddVideoContent */}
                        <div className="w-full px-4 mt-[120px] relative z-10">
                            {/* Section Title */}
                            <div className='flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <DizeeInput
                                        placeholder="Enter section title (applies to all content)"
                                        value={currentVideo.sectionTitle}
                                        onChange={(e) => setCurrentVideo({ 
                                            ...currentVideo, 
                                            sectionTitle: e.target.value 
                                        })}
                                    />
                                </div>
                            </div>

                            {/* Primary Text */}
                            <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <DizeeInput
                                        placeholder="Enter title"
                                        value={currentVideo.primaryText}
                                        onChange={(e) => setCurrentVideo({ 
                                            ...currentVideo, 
                                            primaryText: e.target.value 
                                        })}
                                    />
                                </div>
                            </div>

                            {/* Link */}
                            <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <div className="flex items-center w-full">
                                        <DizeeInput
                                            placeholder="Enter link"
                                            onChange={handleLinkChange}
                                            value={currentVideo.link}
                                        />
                                        {isValidUrl(currentVideo.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                                    </div>
                                </div>
                            </div>

                            {/* Image Upload - Updated to match AddVideoContent */}
                            <div className="w-full mt-5">
                                <p 
                                    className="text-white text-[12px] mb-2 cursor-pointer"
                                    onClick={() => document.getElementById('file-input').click()}
                                >
                                    {currentVideo.preview ? 'Change image' : 'Upload image'}
                                </p>
                                {currentVideo.preview && (
                                    <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                                        <img src={currentVideo.preview} alt="preview" className="w-full h-full object-cover" />
                                    </div>
                                )}
                                <input
                                    id="file-input"
                                    type="file"
                                    onChange={handleImageChange}
                                    className="hidden"
                                    accept="image/*"
                                />
                            </div>

                            {/* Added Videos Section */}
                            {videoItems.length > 0 && (
                                <div className="flex flex-col w-full mt-[40px]">
                                    <div className='pb-[20px] flex w-full items-center'>
                                        <div className='flex flex-row text-white w-full'>
                                            <p>Added Content</p>
                                        </div>
                                    </div>
                                    {videoItems.map((item, index) => (
                                        <AddedCustom
                                            key={index}
                                            item={item}
                                            setOverlayVisible={setIsOverlayVisible}
                                            setItemForBackend={(item) => handleVideoClick(item, index)}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="w-full absolute bottom-0 left-0">
                        <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                        <div className="w-full bg-black px-4 py-6">
                            <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                                <p>Edit your content to display in your focus page.</p>
                                <p>Only an image is required. Add title and link to enhance the presentation.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedMusic={selectedVideo}
                        handleEdit={handleEditVideo}
                        handleDelete={handleDeleteVideo}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 