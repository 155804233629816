import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import LayoutHeader from '../../../../layout/LayoutHeader';
import Check from '../../../../svg/Check';
import dizeeLogo from '../../../../assets/images/dizee logo.svg';

const Container = styled.div`
  width: 390px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyleOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 12px;
  color: white;
`;

const StyleOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
`;

const PreviewBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
  align-items: center;
`;

const PreviewBox = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  width: ${props => props.width};
  height: ${props => props.height};
  margin-bottom: ${props => props.marginBottom || '0'};
`;

const PreviewContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const OptionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 350px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  margin-left: -18px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.5;
`;

const Line = styled.div`
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  width: ${props => props.width};
  margin-bottom: ${props => props.marginBottom || '0'};
`;

const STYLE_LAYOUTS = {
  music: [
    { width: '40px', height: '40px' },
    { width: '40px', height: '40px' },
    { width: '40px', height: '40px' }
  ],
  video: [
    { width: '62px', height: '80px' }
  ],
  event: [
    { type: 'container', style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      children: [
        { type: 'line', width: '100px' },
        { type: 'line', width: '100px' },
        { type: 'line', width: '100px' }
      ]
    }}
  ],
  product: [
    { width: '40px', height: '40px' },
    { type: 'container', style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginLeft: '8px',
      children: [
        { type: 'line', width: '100px' },
        { type: 'line', width: '80px' },
        { type: 'line', width: '60px' }
      ]
    }}
  ],
  social: [
    { width: '40px', height: '52px' },
    { width: '40px', height: '52px' },
    { width: '40px', height: '52px' }
  ],
  text: [
    { type: 'container', style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      children: [
        { type: 'line', width: '100px' },
        { type: 'line', width: '100px' }
      ]
    }}
  ],
  form: [
    { type: 'container', style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      children: [
        { type: 'line', width: '100px' },
        { width: '40px', height: '12px' }
      ]
    }}
  ]
};

const STYLE_DESCRIPTIONS = {
  music: "Display your music with cover art, title, and artist information in a horizontal scrolling layout.",
  video: "Present videos with thumbnails and titles in a clean, engaging format.",
  event: "Show upcoming events with dates, locations, and details in an organized list view.",
  product: "Showcase products with images and details in a grid layout.",
  social: "Display social media posts in a dynamic feed format.",
  text: "Add formatted text content with customizable styles and layouts.",
  form: "Create interactive forms to collect information from your audience."
};

export default function SelectStyle() {
  const navigate = useNavigate();
  const location = useLocation();
  const { sectionTitle } = location.state;
  const [selectedStyle, setSelectedStyle] = useState(null);

  const handleNext = () => {
    if (selectedStyle) {
      console.log(`selectedStyle::`, selectedStyle);
      navigate(`/add-section/custom/${selectedStyle}/add-content`, {
        // state: { 
        //   template: selectedStyle,
        //   sectionTitle 
        // }
      });
    }
  };

  return (
    <LayoutHeader>
      <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
        <div className='px-4 my-[50px] flex w-full justify-between'>
          <p className='text-white'>Select Style</p>
          <div className='flex gap-[30px]'>
            <button
              onClick={handleNext}
              disabled={!selectedStyle}
              className={`text-white cursor-pointer ${!selectedStyle ? 'opacity-50' : ''}`}>
              Next
            </button>
            <p onClick={() => navigate(-1)} className='text-white cursor-pointer'>
              Go back
            </p>
          </div>
        </div>

        <div className='w-full px-4'>
          <StyleOptions>
            {Object.keys(STYLE_DESCRIPTIONS).map((style) => (
              <StyleOption key={style} onClick={() => setSelectedStyle(style)}>
                <OptionHeader>
                  <span className="capitalize">{style} Style</span>
                  {selectedStyle === style && <Check className="h-3 w-3" />}
                </OptionHeader>
                
                {selectedStyle === style && (
                  <PreviewBoxes>
                    {STYLE_LAYOUTS[style].map((item, i) => (
                      item.type === 'container' ? (
                        <PreviewContainer key={i} style={item.style}>
                          {item.style.children.map((child, j) => (
                            child.type === 'line' ? 
                              <Line key={`${i}-${j}`} {...child} /> :
                              <PreviewBox key={`${i}-${j}`} {...child} />
                          ))}
                        </PreviewContainer>
                      ) : item.type === 'line' ? (
                        <Line key={i} {...item} />
                      ) : (
                        <PreviewBox key={i} {...item} />
                      )
                    ))}
                  </PreviewBoxes>
                )}
              </StyleOption>
            ))}
          </StyleOptions>
        </div>

        <Footer>
          <FooterText>
            {selectedStyle ? STYLE_DESCRIPTIONS[selectedStyle] : 
             "Select a style that best fits your content. Each style is optimized for different types of media and information."}
          </FooterText>
        </Footer>
      </div>
    </LayoutHeader>
  );
} 