import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import LayoutHeader from "../../../../layout/LayoutHeader";
import { getContrastColor, maxLength } from '../../../../components/DizeeComponent/DixeeInput2';
import CusCardMusic from '../../../../components/CusCardMusic';
import CusCardVideo from '../../../../components/CusCardVideo';
import CusFocusEvent from '../../../../components/CusFocusEvent';
import CusFocusProduct from '../../../../components/CusFocusProduct';
import CusFocusSocial from '../../../../components/CusFocusSocial';
import CusFocusText from '../../../../components/CusFocusText';
import CusFocusForm from '../../../../components/CusFocusForm';
import CusFocusGallery from '../../../../components/CusFocusGallery';
import { useNavigate } from 'react-router-dom';
import More from '../../../../svg/More';
import { updateCustomOverlay } from '../../../../store/focuspage/focuspageSlice';
import CustomEdit from '../../../../components/Focuspage/MainEdit/CustomEdit';
import CrossArrow from '../../../../svg/CrossArrow';
import { setUserArticle } from '../../../../store/user/userSlice';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import Plus from '../../../../svg/Plus';
import { update_user_article_thunk } from '../../../../store/user/userThunk';

// Add this shuffle utility function
const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
};

// Add this function before the component
const calculateContainerHeight = (numImages) => {
    // Calculate optimal number of columns based on image count
    const numColumns = Math.ceil(Math.sqrt(numImages));
    const numRows = Math.ceil(numImages / numColumns);

    // Base unit height (same as in getImageStyle)
    const unitHeight = 200;

    // Calculate total height based on rows and feature images
    const hasFeatureImages = numImages > 1;
    const maxRowSpan = hasFeatureImages ? 2 : 1;

    return numRows * unitHeight * maxRowSpan;
};

const bottomNavContainerStyle = {
    borderTop: '0.5px solid rgba(255, 255, 255, 0.1)',
    background: '#000000',
    position: 'relative',
    zIndex: 50
};


const hslToHex = (h, s, l) => {
    s /= 100;
    l /= 100;
    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = l - c / 2;
    let r = 0,
        g = 0,
        b = 0;
    if (h >= 0 && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (h >= 180 && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (h >= 240 && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (h >= 300 && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    r = Math.round((r + m) * 255)
        .toString(16)
        .padStart(2, "0");
    g = Math.round((g + m) * 255)
        .toString(16)
        .padStart(2, "0");
    b = Math.round((b + m) * 255)
        .toString(16)
        .padStart(2, "0");
    return `#${r}${g}${b}`;
};

// Add this helper function to convert hex to HSL
const hexToHSL = (hex) => {
    // Remove the # if present
    hex = hex.replace(/^#/, '');

    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16) / 255;
    let g = parseInt(hex.substring(2, 4), 16) / 255;
    let b = parseInt(hex.substring(4, 6), 16) / 255;

    // Find min and max values
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);

    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100)
    };
};

export default function CustomFocusPage() {
    const dispatch = useDispatch();
    const custom = useSelector(state => state.focuspage.custom);
    const navigate = useNavigate();
    const userArticle = useSelector(state => state.user.userArticle);
    const customPageOverlay = useSelector((state) => state.focuspage.isCustomOverlay);
    
    // Add state for shuffled gallery items
    const [shuffledGalleryItems, setShuffledGalleryItems] = useState(null);
    
    let token = localStorage.getItem("dizeeToken");
    const [color, setColor] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(false);
    const [backgroundShade, setBackgroundShade] = useState(false);
    const [accentColor, setAccentColor] = useState(false);
    const [accentShade, setAccentShade] = useState(false);
    const [hue, setHue] = useState(0); // Hue state for color sliders
    const [brightness, setBrightness] = useState(50); // Brightness state for shade sliders
    const [styleMenu, setStyleMenu] = useState(false);
    const [hexBackground, setHexBackground] = useState("");
    const [hexAccent, setHexAccent] = useState("");

    const [showEditOptions, setShowEditOptions] = useState(false);

    // Add handler for Home click
    const handleHomeClick = () => {
        navigate('/');
    };

    const [grayScaleBar, setGrayScaleBar] = useState(false);
    const [noiseBar, setNoiseBar] = useState(false);

    const onChangeNoise = (e) => {
        try {
            const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
            dispatch(setUserArticle({
                ...userArticle,
                noise: value  // Keep as number like grayscale
            }));
        } catch (error) {
            console.error('Noise update error:', error);
            dispatch(setUserArticle({
                ...userArticle,
                noise: defaultValues.noise
            }));
        }
    };

    // Add this handler for accent color input
    const handleAccentColorInput = (e) => {
        const colorValue = e.target.value.toUpperCase();
        if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
            setHexAccent(colorValue);

            // Only process complete hex colors
            if (colorValue.length === 7) {
                // Convert hex to HSL and update slider
                const hsl = hexToHSL(colorValue);
                setHue(hsl.h);
                setBrightness(hsl.l);

                dispatch(setUserArticle({
                    ...userArticle,
                    accent: colorValue.slice(1)
                }));
            }
        }
    };

    // Update the color input handler
    const handleColorInput = (e) => {
        const colorValue = e.target.value.toUpperCase();
        if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
            setHexBackground(colorValue);

            // Only process complete hex colors
            if (colorValue.length === 7) {
                // Convert hex to HSL and update slider
                const hsl = hexToHSL(colorValue);
                setHue(hsl.h);
                setBrightness(hsl.l);

                dispatch(setUserArticle({
                    ...userArticle,
                    background: colorValue.slice(1)
                }));
            }
        }
    };

    const updateUserArticle = async (bool) => {
        dispatch(update_user_article_thunk({ token, body: { ...userArticle, unpublish: bool } }));
    };

    // Add initial state values
    const [defaultValues] = useState({
        grayscale: "0",
        noise: "0"
    });

    // Add debounce to prevent rapid state updates
    const debouncedUpdateArticle = useCallback(
        debounce((newValue) => {
            dispatch(setUserArticle(newValue));
        }, 100),
        [dispatch]
    );

    // Handle hue change
    const handleHueChangeBackground = (e) => {
        setHue(e.target.value);
        const hexColor = hslToHex(e.target.value, 100, brightness);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        setHexBackground(`#${colorValue}`);
        debouncedUpdateArticle({ ...userArticle, background: colorValue });
    };

    // Handle brightness change
    const handleBrightnessChangeBackground = (e) => {
        setBrightness(e.target.value);
        const hexColor = hslToHex(hue, 100, e.target.value);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        debouncedUpdateArticle({ ...userArticle, background: colorValue });
    };

    const handleHueChangeAccent = (e) => {
        setHue(e.target.value);
        const hexColor = hslToHex(e.target.value, 100, brightness);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        setHexAccent(`#${colorValue}`);
        dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
    };

    // Handle brightness change
    const handleBrightnessChangeAccent = (e) => {
        setBrightness(e.target.value);
        const hexColor = hslToHex(hue, 100, e.target.value);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
    };

    // Update grayscale handler with safety checks
    const onChangeGrayScale = (e) => {
        try {
            const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
            dispatch(setUserArticle({
                ...userArticle,
                grayscale: value
            }));
        } catch (error) {
            console.error('Grayscale update error:', error);
            dispatch(setUserArticle({
                ...userArticle,
                grayscale: defaultValues.grayscale
            }));
        }
    };

    // Add reset function for when closing controls
    const resetEffects = () => {
        dispatch(setUserArticle({
            ...userArticle,
            grayscale: defaultValues.grayscale,
            noise: defaultValues.noise
        }));
    };

    // Add debug log for background color
    console.log("Background color from Redux:", {
        background: custom?.background,
        accent: custom?.accent,
        fullCustomObject: custom
    });

    // Debug logs
    console.log("Raw custom data:", {
        headerText: custom?.headerText,
        subheaderText: custom?.subheaderText,
        content: custom?.content
    });

    // Debug logs for header-related data
    console.log("Custom data:", {
        avatar: custom?.avatar,
        avatarPreview: custom?.avatarPreview,
        headerText: custom?.headerText,
        subheaderText: custom?.subheaderText,
        content: custom?.content,
        background: custom?.background,
        headeractive: custom?.headeractive
    });

    // If there's content, log the header content
    if (custom?.content?.length > 0) {
        console.log("Header content:", custom.content.find(item => item.type === 'header'));
    }

    // Add this log right before rendering the image
    console.log("Image URL being used:", custom?.avatarPreview || custom?.avatar);

    // Add this before the return statement
    console.log("Full custom data structure:", {
        content: custom?.content,
        headerData: custom?.content?.find(item => item.type === 'header'),
        directHeaderImage: custom?.avatar,
        uploadedUrl: custom?.content?.find(item => item.type === 'header')?.avatar
    });

    // Add this before the return statement
    console.log("Step by step debug:", {
        hasContent: Boolean(custom?.content),
        contentLength: custom?.content?.length,
        firstContentItem: custom?.content?.[0],
        headerActive: custom?.headeractive,
        rawAvatar: custom?.avatar,
        rawPreview: custom?.avatarPreview
    });

    // Get header content from the content array
    const headerContent = custom?.content?.find(item => item.type === 'header');
    console.log("Header content:", headerContent);

    // Use header content if available, otherwise fall back to root level data
    const headerImage = headerContent?.avatar || custom?.avatar;
    const headerTitle = headerContent?.title || custom?.headerText;
    const headerSubtitle = headerContent?.subtitle || custom?.subheaderText;

    console.log("Final header values:", {
        headerImage,
        headerTitle,
        headerSubtitle
    });

    // Add these debug logs
    console.log("Page-specific data:", {
        pageId: custom?.page,
        background: custom?.background,
        fullCustom: custom
    });

    // Add this debug log in CustomFocusPage to check what content we're receiving
    useEffect(() => {
        console.log("Custom page content:", custom?.content);
    }, [custom]);

    const renderSocialSection = (socialItems, sectionTitle) => {
        if (!socialItems?.length) return null;

        return (
            <div className="w-full mb-[40px]">
                {/* Section Title with Edit Button */}
                {sectionTitle && (
                    <div className="px-4 mb-4 flex justify-between items-center">
                        <p className="text-white text-[14px]">{sectionTitle}</p>
                        <button
                            onClick={() => handleSocialEditClick(custom, sectionTitle)}
                            className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                            style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                        >
                            Edit
                        </button>
                    </div>
                )}

                {/* Horizontal Scroll Container */}
                <div className="w-full overflow-x-auto hide-scrollbar">
                    <div className="flex gap-[10px] px-4">
                        {socialItems.map((item, index) => (
                            <CusFocusSocial
                                key={index}
                                imgurl={item.avatar}
                                link={item.link}
                                item={item}
                                userArticle={userArticle}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    // Group social items by section title
    const socialSections = useMemo(() => {
        const sections = {};
        custom?.content?.forEach(item => {
            if (item.type === 'social') {
                if (!sections[item.sectionTitle]) {
                    sections[item.sectionTitle] = [];
                }
                sections[item.sectionTitle].push(item);
            }
        });
        return sections;
    }, [custom?.content]);

    // Get original gallery items
    const galleryItems = custom?.content?.filter(item => item.type === 'gallery') || [];

    // Modify the handleShuffle function
    const handleShuffle = () => {
        const shuffled = shuffleArray(galleryItems);
        setShuffledGalleryItems(shuffled);
    };

    // In the edit button click handler
    const handleEditClick = (custom) => {
        navigate('/focus-page/custom-type/gallery/edit-content', {
            state: {
                pageData: custom,
                pageId: custom?.page,
                galleryContent: custom?.content?.filter(item => item.type === 'gallery') || [],
                sectionTitle: custom?.content?.find(item => item.type === 'gallery')?.sectionTitle || ''
            }
        });
    };

    const handleFormClick = (item) => {
        console.log("Form clicked, item:", item); // Debug log
        if (item?.link) {
            let url = item.link;
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'https://' + url;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    // Add this handler for form editing
    const handleFormEditClick = (custom) => {
        navigate('/focus-page/custom-type/form/edit-content', {
            state: {
                pageData: custom,
                pageId: custom?.page,
                formContent: custom?.content?.filter(item => item.type === 'form') || [],
                sectionTitle: custom?.content?.find(item => item.type === 'form')?.sectionTitle || ''
            }
        });
    };

    // Add this handler function
    const handleTextEditClick = (custom) => {
        navigate('/focus-page/custom-type/text/edit-content', {
            state: {
                pageData: custom,
                pageId: custom?.page,
                textContent: custom?.content?.filter(item => item.type === 'text') || [],
                sectionTitle: custom?.content?.find(item => item.type === 'text')?.sectionTitle || ''
            }
        });
    };

    // Add this handler function with the other handlers
    const handleSocialEditClick = (custom, sectionTitle) => {
        navigate('/focus-page/custom-type/social/edit-content', {
            state: {
                pageData: custom,
                pageId: custom?.page,
                socialContent: custom?.content?.filter(item => item.type === 'social') || [],
                sectionTitle: sectionTitle
            }
        });
    };

    // Add this handler function with the other handlers
    const handleProductEditClick = (custom) => {
        navigate('/focus-page/custom-type/product/edit-content', {
            state: {
                pageData: custom,
                pageId: custom?.page,
                productContent: custom?.content?.filter(item => item.type === 'product') || [],
                sectionTitle: custom?.content?.find(item => item.type === 'product')?.sectionTitle || ''
            }
        });
    };

    // Add this handler function with the other handlers
    const handleEventEditClick = (custom) => {
        navigate('/focus-page/custom-type/event/edit-content', {
            state: {
                pageData: custom,
                pageId: custom?.page,
                eventContent: custom?.content?.filter(item => item.type === 'event') || [],
                sectionTitle: custom?.content?.find(item => item.type === 'event')?.sectionTitle || ''
            }
        });
    };

    // Add this handler function with the other handlers
    const handleVideoEditClick = (custom) => {
        navigate('/focus-page/custom-type/video/edit-content', {
            state: {
                pageData: custom,
                pageId: custom?.page,
                videoContent: custom?.content?.filter(item => item.type === 'video') || [],
                sectionTitle: custom?.content?.find(item => item.type === 'video')?.sectionTitle || ''
            }
        });
    };

    // Add this handler function with the other handlers
    const handleMusicEditClick = (custom) => {
        navigate('/focus-page/custom-type/music/edit-content', {
            state: {
                pageData: custom,
                pageId: custom?.page,
                musicContent: custom?.content?.filter(item => item.type === 'music') || [],
                sectionTitle: custom?.content?.find(item => item.type === 'music')?.sectionTitle || ''
            }
        });
    };

    return (
        <LayoutHeader>
            <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative">
                <div className="flex py-4 z-1 ml-4 w-full items-center text-white">
                    <div className="mx-auto">
                        diz.ee/{userArticle?.domain}/{custom?.extension}
                    </div>
                    <div className="mr-4">
                        <More
                            className='h-[20px] w-[20px] cursor-pointer'
                            onClick={() => dispatch(updateCustomOverlay(true))}
                        />
                    </div>
                </div>

                <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]"
                    style={{
                        backgroundColor: `#${custom?.background || '1A1A25'}`,
                        color: `#${custom?.accent || 'FFFFFF'}`
                    }}
                >
                    <div className="max-w-[350px] sm:max-w-[390px] h-[490px] flex relative">
                        <img
                            src={headerImage}
                            alt="header"
                            className="w-full h-full object-cover rounded-t-[20px]"
                        />
                        {(headerContent?.title || headerContent?.subtitle ||
                            custom?.content?.find(item => item.type === 'music')?.primaryText ||
                            custom?.content?.find(item => item.type === 'music')?.secondaryText) && (
                                <div
                                    className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end p-4"
                                    style={{
                                        backgroundImage: `linear-gradient(to top, ${custom?.background ? `#${custom.background}` : '#1A1A25'}, transparent)`,
                                    }}
                                >
                                    <div className="w-full">
                                        <p className="text-[16px] text-white">
                                            {headerContent?.subtitle || custom?.content?.find(item => item.type === 'music')?.secondaryText || ""}
                                        </p>
                                        <p className="text-[20px] text-white font-bold">
                                            {headerContent?.title || custom?.content?.find(item => item.type === 'music')?.primaryText || ""}
                                        </p>
                                    </div>
                                </div>
                            )}
                    </div>

                    {/* Music Section Title */}
                    {custom?.content?.filter(item => item.type === 'music').length > 0 && (
                        <>
                            {/* Music Section Title */}
                            <div className='w-full text-white flex flex-col px-4 justify-center items-start gap-y-[16px] mt-[25px]'>
                                <div className='w-full flex justify-between items-center'>
                                    <p className='text-[12px]' style={{ color: `#${custom?.accent || 'FFFFFF'}` }}>
                                        {custom.content.find(item => item.type === 'music').sectionTitle}
                                    </p>
                                    <button
                                        onClick={() => handleMusicEditClick(custom)}
                                        className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                                        style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>

                            {/* Music Content */}
                            <div className="w-full mb-[60px] overflow-x-auto px-4 flex flex-row gap-x-4 mt-4">
                                {custom?.content?.filter(item => item.type === 'music').map((item, index) => (
                                    <CusCardMusic
                                        key={index}
                                        imgurl={item?.avatar}
                                        txt={item?.primaryText}
                                        artist={item?.secondaryText}
                                        userArticle={custom}
                                        links={item?.links}
                                        item={item}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    {/* Video Content */}
                    {custom?.content?.filter(item => item.type === 'video').length > 0 && (
                        <>
                            {/* Video Section Title */}
                            <div className='w-full text-white flex flex-col px-4 justify-center items-start gap-y-[16px] mt-[25px]'>
                                <div className='w-full flex justify-between items-center'>
                                    <p className='text-[12px]' style={{ color: `#${custom?.accent || 'FFFFFF'}` }}>
                                        {custom.content.find(item => item.type === 'video').sectionTitle}
                                    </p>
                                    <button
                                        onClick={() => handleVideoEditClick(custom)}
                                        className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                                        style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>

                            {/* Video Cards - Vertical Stack */}
                            <div className="w-full mb-[60px] px-4 mt-4">
                                <div className="flex flex-col gap-y-[24px]">
                                    {custom?.content?.filter(item => item.type === 'video').map((item, index) => (
                                        <React.Fragment key={index}>
                                            {index > 0 && (
                                                <div
                                                    style={{
                                                        height: '0.5px',
                                                        background: `#${custom?.accent || 'FFFFFF'}`,
                                                        opacity: '0.3'
                                                    }}
                                                />
                                            )}
                                            <CusCardVideo
                                                imgurl={item?.avatar}
                                                txt={item?.primaryText}
                                                userArticle={custom}
                                                link={item?.link}
                                                item={item}
                                            />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}

                    {/* Event Content */}
                    {custom?.content?.filter(item => item.type === 'event').length > 0 && (
                        <>
                            {/* Event Section Title */}
                            <div className='w-full text-white flex flex-col px-4 justify-center items-start gap-y-[16px] mt-[25px]'>
                                <div className='w-full flex justify-between items-center'>
                                    <p className='text-[12px]' style={{ color: `#${custom?.accent || 'FFFFFF'}` }}>
                                        {custom.content.find(item => item.type === 'event').sectionTitle}
                                    </p>
                                    <button
                                        onClick={() => handleEventEditClick(custom)}
                                        className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                                        style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>

                            {/* Section Divider */}
                            <div className="w-full px-4 mt-4">
                                <div className="border-t" style={{ borderColor: `#${custom?.accent}`, opacity: "0.2" }} />
                            </div>

                            {/* Event Cards */}
                            <div className="w-full mt-4">
                                {custom?.content?.filter(item => item.type === 'event').map((item, index) => (
                                    <CusFocusEvent
                                        key={index}
                                        item={item}
                                        userArticle={custom}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    {/* Product Content */}
                    {custom?.content?.filter(item => item.type === 'product').length > 0 &&
                        !custom?.content?.some(item => item.type === 'event') && (
                            <>
                                {/* Product Section Title with Edit Button */}
                                <div className='w-full text-white flex flex-col px-4 justify-center items-start mt-[25px]'>
                                    <div className='w-full flex justify-between items-center'>
                                        <p className='text-[12px]' style={{ color: `#${custom?.accent || 'FFFFFF'}` }}>
                                            {custom.content.find(item => item.type === 'product').sectionTitle}
                                        </p>
                                        <button
                                            onClick={() => handleProductEditClick(custom)}
                                            className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                                            style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </div>

                                {/* Product Cards - Vertical Layout */}
                                <div className="w-full mb-[60px] px-4 mt-4">
                                    <div className="flex flex-col gap-y-[24px]">
                                        {custom?.content?.filter(item => item.type === 'product').map((item, index) => (
                                            <React.Fragment key={index}>
                                                {index > 0 && (
                                                    <div
                                                        style={{
                                                            height: '0.5px',
                                                            background: `#${custom?.accent || 'FFFFFF'}`,
                                                            opacity: '0.3'
                                                        }}
                                                    />
                                                )}
                                                <CusFocusProduct
                                                    imgurl={item?.avatar}
                                                    item={item}
                                                    userArticle={custom}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}

                    {/* Text Content */}
                    {custom?.content?.filter(item => item.type === 'text').length > 0 && (
                        <>
                            {/* Text Section Title with Edit Button */}
                            <div className='w-full text-white flex flex-col px-4 justify-center items-start mt-[25px]'>
                                <div className='w-full flex justify-between items-center'>
                                    <p className='text-[12px]' style={{ color: `#${custom?.accent || 'FFFFFF'}` }}>
                                        {custom.content.find(item => item.type === 'text').sectionTitle}
                                    </p>
                                    <button
                                        onClick={() => handleTextEditClick(custom)}
                                        className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                                        style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>

                            {/* Text Cards */}
                            <div className="w-full mb-[60px] px-4 flex flex-col gap-y-4 mt-4">
                                {custom?.content?.filter(item => item.type === 'text').map((item, index) => (
                                    <CusFocusText
                                        key={index}
                                        item={item}
                                        userArticle={custom}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    {/* Form Content */}
                    {custom?.content?.filter(item => item.type === 'form').length > 0 && (
                        <>
                            {/* Form Section Title with Edit Button */}
                            <div className='w-full text-white flex flex-col px-4 justify-center items-start mt-[25px]'>
                                <div className='w-full flex justify-between items-center'>
                                    <p className='text-[12px]' style={{ color: `#${custom?.accent || 'FFFFFF'}` }}>
                                        {custom.content.find(item => item.type === 'form').sectionTitle}
                                    </p>
                                    <button
                                        onClick={() => handleFormEditClick(custom)}
                                        className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                                        style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>

                            {/* Form Cards */}
                            <div className="w-full mb-[60px] px-4 flex flex-col gap-y-4 mt-4">
                                {custom?.content?.filter(item => item.type === 'form').map((item, index) => (
                                    <CusFocusForm
                                        key={index}
                                        item={item}
                                        userArticle={custom}
                                        onFormClick={handleFormClick}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    {/* Gallery Content */}
                    {galleryItems.length > 0 && (
                        <>
                            {/* Gallery Section Title with Shuffle and Edit Buttons */}
                            <div className='w-full text-white flex flex-col px-4 justify-center items-start gap-y-[16px] mt-[25px]'>
                                <div className='w-full flex justify-between items-center'>
                                    <p className='text-[12px]'
                                        style={{ color: `#${custom?.accent || 'FFFFFF'}` }}>
                                        {custom.content.find(item => item.type === 'gallery').sectionTitle}
                                    </p>
                                    <div className="flex gap-x-4">
                                        <button
                                            onClick={() => handleEditClick(custom)}
                                            className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                                            style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={handleShuffle}
                                            className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                                            style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                                        >
                                            Shuffle
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Gallery Cards */}
                            <div className="w-full mb-[60px] px-4 mt-[24px]">
                                <div className="w-full relative overflow-hidden rounded-[3px]"
                                    style={{
                                        backgroundColor: `#${custom?.background || '1A1A25'}`
                                    }}
                                >
                                    <div
                                        className="relative gallery-grid"
                                        style={{ height: '700px' }}
                                    >
                                        {(shuffledGalleryItems || galleryItems).map((item, index) => (
                                            <CusFocusGallery
                                                key={`${item.id || index}-${Date.now()}`}
                                                imgurl={item?.avatar}
                                                item={item}
                                                userArticle={custom}
                                                totalItems={galleryItems.length}
                                                index={index}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {/* Social Sections */}
                    {Object.entries(socialSections).map(([title, items]) =>
                        renderSocialSection(items, title)
                    )}

                    {/* Footer section */}
                    <div className='w-full flex flex-col justify-center items-start gap-y-[16px] px-4 mt-[50px] text-[12px]'>
                        <div className='space-y-[15px]'>
                            <div className='flex flex-row gap-x-[10px] items-center'>
                                <p className='uppercase font-bold' style={{ color: `#${custom?.accent}` }}>{userArticle?.username}</p>
                                <p className='pb-[2px]' style={{ color: `#${custom?.accent}` }}>x</p>
                                <p className='uppercase text-[11px] tracking-[3px]' style={{ color: `#${custom?.accent}` }}>DIZEE</p>
                            </div>
                            <p style={{ color: `#${custom?.accent}` }}>Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>

            {!color && !showEditOptions && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setShowEditOptions(true)}
                        >
                            EDIT
                        </button>
                        <button
                            className="text-white text-center py-2 px-4 rounded-lg plus-button"
                            onClick={() => navigate("/add-section")}
                        >
                            <Plus className="h-[18px] w-[18px]" />
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={handleHomeClick}
                        >
                            HOME
                        </button>
                    </div>
                </div>
            )}

            {!color && showEditOptions && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setStyleMenu(true)}
                        >
                            RETOUCH
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setShowEditOptions(false)}
                        >
                            BACK
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70 color-button"
                            onClick={() => {
                                setColor(true);
                                setShowEditOptions(false);
                            }}
                        >
                            COLOR
                        </button>
                    </div>
                </div>
            )}

            {color && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setBackgroundColor(true);
                                setColor(false);
                            }}
                        >
                            BACKGROUND
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setAccentColor(true);
                                setColor(false);
                            }}
                        >
                            ACCENT
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setColor(false);
                                setShowEditOptions(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {backgroundColor && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setBackgroundShade(true)}
                        >
                            SHADE
                        </button>
                        <div className="flex items-center space-x-4">
                            <input
                                type="text"
                                value={(hexBackground || "#000000").toUpperCase()}
                                onChange={handleColorInput}
                                className="bg-transparent w-[60px] text-center outline-none border-none transition-colors duration-200"
                                style={{
                                    caretColor: 'white',
                                    appearance: 'none',
                                    WebkitAppearance: 'none'
                                }}
                            />
                            <input
                                type="range"
                                min="0"
                                max="360"
                                value={hue}
                                onChange={handleHueChangeBackground}
                                className="hue-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {/* Background Shade Selection */}
            {backgroundShade && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setBackgroundShade(false);
                                setBackgroundColor(true);
                            }}
                        >
                            COLOR
                        </button>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={brightness}
                            onChange={handleBrightnessChangeBackground}
                            className="brightness-slider"
                            style={{
                                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                                margin: 0,
                                width: "180px",
                            }}
                        />
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}
            {accentColor && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setAccentShade(true)}
                        >
                            SHADE
                        </button>
                        <div className="flex items-center space-x-4">
                            <input
                                type="text"
                                value={(hexAccent || "#000000").toUpperCase()}
                                onChange={handleAccentColorInput}
                                className="bg-transparent w-[60px] text-center outline-none border-none"
                                style={{
                                    caretColor: 'white',
                                    appearance: 'none',
                                    WebkitAppearance: 'none'
                                }}
                            />
                            <input
                                type="range"
                                min="0"
                                max="360"
                                value={hue}
                                onChange={handleHueChangeAccent}
                                className="hue-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {/* Background Shade Selection */}
            {accentShade && (
                <div className="fixed max-w-[350px] bottom-0 w-full text-white font-[410] text-[9px] leading-[10.26px] tracking-[0.02em]">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setAccentShade(false);
                                setAccentColor(true);
                            }}
                        >
                            COLOR
                        </button>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={brightness}
                            onChange={handleBrightnessChangeAccent}
                            className="brightness-slider"
                            style={{
                                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                                margin: 0,
                                width: "180px",
                            }}
                        />
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {styleMenu && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setGrayScaleBar(true);
                                setStyleMenu(false);
                            }}
                        >
                            GRAYSCALE
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setNoiseBar(true);
                                setStyleMenu(false);
                            }}
                        >
                            NOISE
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setStyleMenu(false)}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}
            {grayScaleBar && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button className="text-center py-2 rounded-lg">
                            GRAYSCALE
                        </button>
                        <div className="flex items-center space-x-4">
                            <span className="text-white min-w-[20px]">
                                {userArticle?.grayscale || 0}%
                            </span>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={userArticle?.grayscale ?? 0}
                                onChange={onChangeGrayScale}
                                className="grayscale-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => { updateUserArticle(false); setGrayScaleBar(false); }}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setGrayScaleBar(false)}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {noiseBar && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button className="text-center py-2 rounded-lg">
                            NOISE
                        </button>
                        <div className="flex items-center space-x-4">
                            <span className="text-white min-w-[20px]">
                                {userArticle?.noise || 0}%
                            </span>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={userArticle?.noise ?? 0}
                                onChange={onChangeNoise}
                                className="grayscale-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => { updateUserArticle(false); setNoiseBar(false); }}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setNoiseBar(false);
                                resetEffects();
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}
            {customPageOverlay && <CustomEdit customArticle={custom} />}
        </LayoutHeader>
    );
} 