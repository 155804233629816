import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DizeeInput2 } from '../../DizeeComponent/DixeeInput2';
import { setWholeFocusCustom, updateCustomOverlay } from '../../../store/focuspage/focuspageSlice';
import { setErrorAlert, setLoader } from '../../../store/alert/alertSlice';
import { uploadImage } from '../../../utils/upload';
import { update_focus_data_thunk, getDataForFocusThunk } from '../../../store/focuspage/focuspageThunk';
import ImageEditCard from '../../Main/ArticleEdit/ImageEditCard';
import Check from '../../../svg/Check';
import { updateCustomForFocusThunk, addCustomForFocusThunk } from '../../../store/focuspage/focuspageThunk';

export default function CustomEdit({ customArticle }) {
    const token = localStorage.getItem('dizeeToken');
    const focusdata = useSelector((state) => state.focuspage.focusData);
    const userData = useSelector((state) => state.user.userArticle);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [localHeaderTitle, setLocalHeaderTitle] = useState('');
    const [localHeaderSubtitle, setLocalHeaderSubtitle] = useState('');
    const [originalPage] = useState(customArticle?.page || '');

    // Initialize local state with existing values
    useEffect(() => {
        if (customArticle) {
            // Find header content from the content array
            const headerContent = customArticle.content?.find(item => item.type === 'header');
            
            // Set local state only if values are different
            const newTitle = headerContent?.title || customArticle.headerTitle || '';
            const newSubtitle = headerContent?.subtitle || customArticle.headerSubtitle || '';
            
            if (localHeaderTitle !== newTitle) {
                setLocalHeaderTitle(newTitle);
            }
            if (localHeaderSubtitle !== newSubtitle) {
                setLocalHeaderSubtitle(newSubtitle);
            }

            // Only dispatch if necessary
            const currentState = {
                headerImage: headerContent?.avatar || customArticle.headerImage || customArticle.avatar || '',
                extension: customArticle.extension || '',
                page: customArticle.page || '',
                type: 'custom',
                content: customArticle.content || []
            };

            // Check if we need to update the state
            if (JSON.stringify(customArticle) !== JSON.stringify({ ...customArticle, ...currentState })) {
                dispatch(setWholeFocusCustom({
                    ...customArticle,
                    ...currentState
                }));
            }
        }
    }, [customArticle]); // Remove dispatch from dependencies

    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            dispatch(updateCustomOverlay(false));
        }
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const updatedAvatar = URL.createObjectURL(file);
            if (updatedAvatar !== customArticle.headerImage) {
                dispatch(setWholeFocusCustom({ 
                    ...customArticle, 
                    headerImage: updatedAvatar 
                }));
                setSelectedImage(file);
            }
        }
    };

    const handleHeaderTitleChange = (e) => {
        const value = e.target.value;
        if (value !== localHeaderTitle) {
            setLocalHeaderTitle(value);
        }
    };

    const handleHeaderSubtitleChange = (e) => {
        const value = e.target.value;
        if (value !== localHeaderSubtitle) {
            setLocalHeaderSubtitle(value);
        }
    };

    const updateUserArticle = async () => {
        try {
            dispatch(setLoader(true));
            
            // Debug logs
            console.log('customArticle:', customArticle);
            console.log('originalPage:', originalPage);

            // Validate required data
            if (!customArticle || !customArticle.page) {
                throw new Error('Invalid article data: missing page name');
            }
            
            // Start with the existing article to maintain all current content
            let updatedCustomArticle = {
                ...customArticle,
                type: 'custom', // Ensure type is set
                page: customArticle.page, // Ensure page is set
                content: [...(customArticle.content || [])],
                originalPage: originalPage || customArticle.page // Fallback to current page if no original
            };

            // Update the header content within the existing content array
            const contentWithoutHeader = updatedCustomArticle.content.filter(item => item.type !== 'header');
            const headerContent = {
                type: 'header',
                avatar: selectedImage ? await uploadImage(selectedImage) : (customArticle.headerImage || customArticle.avatar || ''),
                title: localHeaderTitle || '',
                subtitle: localHeaderSubtitle || ''
            };

            // Update content array while preserving other content items
            updatedCustomArticle.content = [
                headerContent,
                ...contentWithoutHeader
            ];

            console.log('Final payload:', updatedCustomArticle);
            
            const result = await dispatch(updateCustomForFocusThunk({
                token,
                payload: updatedCustomArticle
            })).unwrap();

            if (result) {
                await dispatch(getDataForFocusThunk({ token }));
                dispatch(updateCustomOverlay(false));
            }
            
            dispatch(setLoader(false));
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(setErrorAlert(error?.message || 'An error occurred'));
            console.error('Error updating article:', error);
        }
    };

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-1"
            onClick={handleOverlayClick}
        >
            <div className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" 
                onClick={e => e.stopPropagation()}>
                
                <div className='pb-[10px] flex justify-between items-center'>
                    <p className="">Domain extension</p>
                    <div className="flex items-center">
                        <span className="mr-2">diz.ee/{userData?.domain}/</span>
                        <DizeeInput2
                            placeholder="Extension"
                            className="dizee-input"
                            value={customArticle?.extension || ''}
                            onChange={(e) => {
                                dispatch(setWholeFocusCustom({ 
                                    ...customArticle, 
                                    extension: e.target.value 
                                }));
                            }}
                        />
                    </div>
                </div>

                <div className='pb-[10px] flex justify-between'>
                    <p className="">Page title</p>
                    <DizeeInput2
                        placeholder="Page title"
                        className="dizee-input"
                        value={customArticle?.page || ''}
                        onChange={(e) => {
                            dispatch(setWholeFocusCustom({ 
                                ...customArticle, 
                                page: e.target.value 
                            }));
                        }}
                    />
                </div>

                <div className='pb-[10px] flex justify-between'>
                    <p className="">Header title</p>
                    <DizeeInput2
                        placeholder="Header title"
                        className="dizee-input"
                        value={localHeaderTitle}
                        onChange={handleHeaderTitleChange}
                    />
                </div>

                <div className='pb-[10px] flex justify-between'>
                    <p className="">Header subtitle</p>
                    <DizeeInput2
                        placeholder="Header subtitle"
                        className="dizee-input"
                        value={localHeaderSubtitle}
                        onChange={handleHeaderSubtitleChange}
                    />
                </div>

                <ImageEditCard
                    txt="Edit Header Image"
                    dotimgclss={false}
                    onImageChange={handleImageChange}
                    imagePreview={customArticle?.headerImage}
                />

                <button className="cursor-pointer" onClick={updateUserArticle}>Done</button>
            </div>
        </div>
    );
} 