import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { setErrorAlert, setLoader } from '../../../../store/alert/alertSlice';
import { updateCustomForFocusThunk, getDataForFocusThunk, updateProductForFocusThunk, updateMultiProductForFocusThunk, updateMultiEventForFocusThunk, updateMultiNewsletterForFocusThunk } from '../../../../store/focuspage/focuspageThunk';
import { clearFocusSection } from '../../../../store/focuspage/focuspageSlice';
import { uploadImage } from '../../../../utils/upload';
import LayoutHeader from '../../../../layout/LayoutHeader';
import DizeeInput from '../../../../components/DizeeComponent/DizeeInput';
import AddedCustom from '../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../components/Focuspage/Type/Custom/OverlayCustom';
import Check from '../../../../svg/Check';
import AddedProduct from '../../../../components/Focuspage/Type/Product/AddedProduct';
import AddedEvent from '../../../../components/AddSection/Event/AddedEvent';
import AddedEventComp from '../../../../components/Focuspage/Type/Event/AddedEventComp';
import FocusAddedNewsletter from '../../../../components/Focuspage/Type/Newsletter/FocusAddedNewsletter';

export default function EditMultiNewsletterContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    // Get data from navigation state
    const extension = location.state?.extension;
    const page = location.state?.page;

    console.log(`location.state::`, location.state)

    const [productItems, setProductItems] = useState(
        location.state?.newsletter?.map(item => ({
            ...item,
        })) || []
    );

    console.log(`productItems::`, productItems);

    const [currentProduct, setCurrentProduct] = useState({
        title: '',
        content: '',
    });

    const [description, setDescription] = useState({
        ...location.state.description
    })

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Form validation flags
    const isFormComplete = currentProduct.title != '' && currentProduct.text != ''

    const isDoneValid = () => {
        return productItems.length > 0 || isFormComplete;
    };

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Handle link change
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentProduct({ ...currentProduct, link: value });
    };

    // Handle product click for editing
    const handleProductClick = (item, index) => {
        console.log(`item::`, item);
        setSelectedProduct({
            ...item
        });
        setSelectedIndex(index);
        setCurrentProduct({
            title: item.title,
            content: item.content
        });
        setIsOverlayVisible(true);
    };

    // Handle edit
    const handleEditProduct = () => {
        if (selectedProduct && selectedIndex !== null) {
            setCurrentProduct({
                link: selectedProduct.link,
                location: selectedProduct.location,
                venue: selectedProduct.venue || '',
                date: selectedProduct.date || '',
            });
            setIsOverlayVisible(false);
        }
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in all required fields'));
            return;
        }

        if (selectedIndex !== null) {
            const updatedItems = [...productItems];
            updatedItems[selectedIndex] = {
                title: currentProduct.title,
                content: currentProduct.content,
            };
            setProductItems(updatedItems);
            setSelectedIndex(null);
        } else {
            setProductItems([...productItems, {
                title: currentProduct.title,
                content: currentProduct.content,
            }]);
        }

        setCurrentProduct({
            title: '',
            content: '',
        });
    };

    // Handle delete
    const handleDeleteProduct = () => {
        const filteredItems = productItems.filter((_, index) => index !== selectedIndex);
        setProductItems(filteredItems);
        setIsOverlayVisible(false);
    };

    // Handle move up/down
    const handleMoveUp = () => {
        if (selectedIndex > 0) {
            const updatedItems = [...productItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex - 1]] =
                [updatedItems[selectedIndex - 1], updatedItems[selectedIndex]];
            setProductItems(updatedItems);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (selectedIndex < productItems.length - 1) {
            const updatedItems = [...productItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex + 1]] =
                [updatedItems[selectedIndex + 1], updatedItems[selectedIndex]];
            setProductItems(updatedItems);
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle done
    const handleDone = async () => {
        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one product'));
            return;
        }

        dispatch(setLoader(true));

        try {
            let finalProductItems = [...productItems];

            if (selectedIndex !== null && isFormComplete) {
                finalProductItems[selectedIndex] = {
                    title: currentProduct.title,
                    content: currentProduct.content,
                };
            } else if (isFormComplete) {
                finalProductItems.push({
                    title: currentProduct.title,
                    content: currentProduct.content,
                });
            }

            console.log(`finalProductItems::`, finalProductItems);

            const updateData = {
                token,
                payload: {
                    newsletter: finalProductItems,
                    description,
                    page,
                    extension
                }
            };

            const result = await dispatch(updateMultiNewsletterForFocusThunk(updateData)).unwrap();

            if (result.status === 200) {
                navigate('/focus-page/multiple-newsletter-page');
            } else {
                throw new Error(result.message || 'Update failed');
            }

        } catch (error) {
            dispatch(setLoader(false));
            dispatch(setErrorAlert(error?.message || 'Failed to update products'));
            console.error('Error updating products:', error);
        }
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Edit content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        {isFormComplete && (
                            <button
                                onClick={handleAddAnother}
                                className="text-white cursor-pointer"
                            >
                                Another
                            </button>
                        )}
                        <button
                            onClick={handleDone}
                            className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}
                        >
                            Done
                        </button>
                        <p
                            onClick={() => navigate(-1)}
                            className="text-white cursor-pointer"
                        >
                            Go back
                        </p>
                    </div>
                </div>

                {/* Form Content */}
                <div className="w-full px-4">

                    <div className='flex items-center gap-x-[16px] mb-8'>
                        <span className='text-white'>Description</span>
                    </div>

                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Description Title"
                            onChange={(e) => setDescription({
                                ...description,
                                title: e.target.value
                            })}
                            value={description?.title}
                        />
                    </div>

                    {/* Description */}
                    <div className="flex items-center mb-8">
                        <DizeeInput
                            placeholder="Description Content"
                            value={description?.text}
                            onChange={(e) => setDescription({
                                ...description,
                                text: e.target.value
                            })}
                            className="w-full bg-transparent text-white placeholder-[rgba(255,255,255,0.5)] px-0 py-3 min-h-[50px] resize-y outline-none text-[12px]"
                        />
                    </div>

                    <div className='flex items-center gap-x-[16px] mb-8'>
                        <span className='text-white'>Newsletter</span>
                    </div>

                    {/* Section Title */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Newsletter Title"
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                title: e.target.value
                            })}
                            value={currentProduct?.title}
                        />
                        {currentProduct?.title && <Check className="h-[12px] w-[18px] ml-2" />}
                    </div>

                    {/* Description */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Newsletter Content"
                            value={currentProduct?.content}
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                content: e.target.value
                            })}
                            className="w-full bg-transparent text-white placeholder-[rgba(255,255,255,0.5)] px-0 py-3 min-h-[50px] resize-y outline-none text-[12px]"
                        />
                    </div>


                    {/* Image Upload */}
                    {/* <div className="flex items-center mb-4">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="hidden"
                            id="productImage"
                        />
                        <label
                            htmlFor="productImage"
                            className="cursor-pointer text-white text-[12px] opacity-50 hover:opacity-100"
                        >
                            {currentProduct.preview ? 'Change Image' : 'Upload Image'}
                        </label>
                        {currentProduct.preview && (
                            <div className="ml-4">
                                <img
                                    src={currentProduct.preview}
                                    alt="Preview"
                                    className="h-[40px] w-[40px] object-cover rounded"
                                />
                            </div>
                        )}
                    </div> */}



                    {/* Added Products */}
                    {productItems.length > 0 && (
                        <div className="flex flex-col w-full mt-[40px]">
                            <div className='pb-[20px] flex w-full items-center'>
                                <div className='flex flex-row text-white w-full'>
                                    <p>Added Newsletter</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                {productItems.map((item, index) => (
                                    <FocusAddedNewsletter
                                        key={index}
                                        item={item}
                                        setOverlayVisible={setIsOverlayVisible}
                                        setItemForBackend={(item) => handleProductClick(item, index)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Footer */}
                {/* <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Edit your product content.</p>
                            <p>Image is required. Add description and link to enhance the presentation.</p>
                        </div>
                    </div>
                </div> */}

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedProduct}
                        handleEdit={handleEditProduct}
                        handleDelete={handleDeleteProduct}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                        canMoveUp={selectedIndex > 0}
                        canMoveDown={selectedIndex < productItems.length - 1}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 