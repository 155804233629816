import React, { useState } from 'react'

export default function CrossArrow({ color = '#FFFFFF', className }) {
    const [isHovered, setIsHovered] = useState(false);

    // Use the passed color for both states
    const currentColor = color;

    return (
        <svg
            height={16}
            width={16}
            fill="none"
            viewBox="0 0 24 24"
            className={className}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <path
                stroke={currentColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 6h12M9 6V4h6v2M10 11v5m4-5v5M5 6l1 14h12l1-14"
            />
        </svg>
    )
}
