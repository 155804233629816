import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import LayoutHeader from "../../../../layout/LayoutHeader";
import DizeeInput from "../../../../components/DizeeComponent/DizeeInput";
import { useDispatch } from "react-redux";
import { setErrorAlert, setLoader } from "../../../../store/alert/alertSlice";
import { updateEventForFocusThunk, updateProductForFocusThunk } from "../../../../store/focuspage/focuspageThunk";
import { uploadImage } from "../../../../utils/upload";
import { formatDate } from "../../../../components/DizeeComponent/DixeeInput2";

const EventEditContent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    console.log(`location::`, location.state);

    const [currentProduct, setCurrentProduct] = useState({
        ...location.state,
    });

    console.log(`curretnProduct::`, currentProduct);

    const isDoneValid = () => {
        return currentProduct.link != '' && currentProduct.location != '' && currentProduct.venue != '' && currentProduct.date
    };

    const handleDone = async () => {
        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add all information'));
            return;
        }
        dispatch(setLoader(true));

        dispatch(updateEventForFocusThunk({
            token,
            payload: {
                ...currentProduct
            }
        }));

        dispatch(setLoader(false));
        navigate(`/focus-page/event-page`);
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Edit content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        <button
                            onClick={handleDone}
                            // className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}
                            className={`text-white cursor-pointer`}
                        >
                            Done
                        </button>
                        <p
                            onClick={() => navigate(-1)}
                            className="text-white cursor-pointer"
                        >
                            Go back
                        </p>
                    </div>
                </div>

                {/* Form Content */}
                <div className="w-full px-4">
                    {/* Section Title */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Enter Link"
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                link: e.target.value
                            })}
                            value={currentProduct.link}
                        />
                        {/* {currentProduct.sectionTitle && <Check className="h-[12px] w-[18px] ml-2" />} */}
                    </div>

                    {/* Description */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Enter Location"
                            value={currentProduct.location}
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                location: e.target.value
                            })}
                            className="w-full bg-transparent text-white placeholder-[rgba(255,255,255,0.5)] px-0 py-3 resize-y outline-none text-[12px]"
                        />
                    </div>

                    {/* Link */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Enter Venue"
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                venue: e.target.value
                            })}
                            value={currentProduct.venue}
                        />
                        {/* {isValidUrl(currentProduct.link) && <Check className="h-[12px] w-[18px] ml-2" />} */}
                    </div>

                    {!currentProduct.date ?
                        <div className='py-4 flex w-full justify-between items-center' >
                            <div className='items-center flex flex-row text-white w-full'>
                                <button className='text-white text-[12px]' style={{ fontSize: '12px' }} onClick={(e) => {
                                    e.preventDefault()
                                    navigate('/focus-page/event-type/date-picker', { state: { route: '/focus-page/event-type/edit-content', prevData: currentProduct } })
                                }
                                }
                                >Select a date</button>
                            </div>
                        </div>
                        :
                        <div className="flex flex-col justify-center items-center w-full ">
                            <div className='py-4 flex w-full justify-between items-center ' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white'>
                                    <button className='text-white text-[12px]' style={{ fontSize: '12px' }} onClick={(e) => {
                                        e.preventDefault()
                                        navigate('/focus-page/event-type/date-picker', { state: { route: '/focus-page/event-type/edit-content', prevData: currentProduct } })
                                    }
                                    }
                                    >Change date</button>
                                </div>
                                <div className=''>
                                    <p className='text-white text-[12px]'>{formatDate(currentProduct.date)}</p>
                                </div>
                            </div>
                        </div>

                    }

                </div>

                {/* Footer */}
                <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Edit your product content.</p>
                            <p>Image is required. Add description and link to enhance the presentation.</p>
                        </div>
                    </div>
                </div>

                {/* Overlay
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedProduct}
                        handleEdit={handleEditProduct}
                        handleDelete={handleDeleteProduct}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                        canMoveUp={selectedIndex > 0}
                        canMoveDown={selectedIndex < productItems.length - 1}
                    />
                )} */}
            </div>
        </LayoutHeader>
    )
}

export default EventEditContent;