import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { updateCustomForFocusThunk, getDataForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';

export default function EditEventContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    // Get data from navigation state
    const pageData = location.state?.pageData;
    const pageId = location.state?.pageId;

    // Redirect if no pageData
    useEffect(() => {
        if (!pageData || !pageId) {
            navigate('/focus-page/custom-page');
        }
    }, [pageData, pageId, navigate]);

    // Initialize state with existing event content
    const [eventItems, setEventItems] = useState(
        location.state?.eventContent?.map(item => ({
            ...item,
            content1: item.time || item.primaryText,
            content2: item.date || item.secondaryText,
            content3: item.venue,
            content4: item.description
        })) || []
    );

    const [currentEvent, setCurrentEvent] = useState({
        sectionTitle: location.state?.sectionTitle || '',
        content1: '',
        content2: '',
        content3: '',
        content4: '',
        link: '',
        type: 'event'
    });

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Form validation
    const isFormComplete = currentEvent.content1 || currentEvent.content2 || 
                          currentEvent.content3 || currentEvent.content4;

    const isDoneValid = () => {
        return eventItems.length > 0 || isFormComplete;
    };

    // URL validation and handlers
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentEvent({ ...currentEvent, link: value });
    };

    // Handle event click for editing
    const handleEventClick = (item, index) => {
        setSelectedEvent({
            ...item,
            type: 'event'
        });
        setSelectedIndex(index);
        setCurrentEvent({
            sectionTitle: item.sectionTitle,
            content1: item.time || item.primaryText,
            content2: item.date || item.secondaryText,
            content3: item.venue,
            content4: item.description,
            link: item.link || '',
            type: 'event'
        });
        setIsOverlayVisible(true);
    };

    // Handle edit
    const handleEditEvent = () => {
        if (selectedEvent && selectedIndex !== null) {
            setCurrentEvent({
                sectionTitle: selectedEvent.sectionTitle,
                content1: selectedEvent.time || selectedEvent.primaryText,
                content2: selectedEvent.date || selectedEvent.secondaryText,
                content3: selectedEvent.venue,
                content4: selectedEvent.description,
                link: selectedEvent.link || '',
                type: 'event'
            });
            setIsOverlayVisible(false);
        }
    };

    // Handle delete
    const handleDeleteEvent = () => {
        const filteredItems = eventItems.filter((_, index) => index !== selectedIndex);
        setEventItems(filteredItems);
        setIsOverlayVisible(false);
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in at least one content field'));
            return;
        }

        if (selectedIndex !== null) {
            const updatedItems = [...eventItems];
            updatedItems[selectedIndex] = {
                type: 'event',
                sectionTitle: currentEvent.sectionTitle,
                time: currentEvent.content1,
                date: currentEvent.content2,
                venue: currentEvent.content3,
                description: currentEvent.content4,
                primaryText: currentEvent.content1,
                secondaryText: currentEvent.content2,
                link: currentEvent.link || ''
            };
            setEventItems(updatedItems);
            setSelectedIndex(null);
        } else {
            setEventItems([...eventItems, {
                type: 'event',
                sectionTitle: currentEvent.sectionTitle,
                time: currentEvent.content1,
                date: currentEvent.content2,
                venue: currentEvent.content3,
                description: currentEvent.content4,
                primaryText: currentEvent.content1,
                secondaryText: currentEvent.content2,
                link: currentEvent.link || ''
            }]);
        }

        setCurrentEvent({
            sectionTitle: currentEvent.sectionTitle,
            content1: '',
            content2: '',
            content3: '',
            content4: '',
            link: '',
            type: 'event'
        });
    };

    // Handle move up/down
    const handleMoveUp = () => {
        if (selectedIndex > 0) {
            const updatedItems = [...eventItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex - 1]] = 
            [updatedItems[selectedIndex - 1], updatedItems[selectedIndex]];
            setEventItems(updatedItems);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (selectedIndex < eventItems.length - 1) {
            const updatedItems = [...eventItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex + 1]] = 
            [updatedItems[selectedIndex + 1], updatedItems[selectedIndex]];
            setEventItems(updatedItems);
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle done/submit
    const handleDone = async () => {
        if (!pageData?.page) {
            dispatch(setErrorAlert('Invalid page data'));
            return;
        }

        let finalEventItems = [...eventItems];
        
        if (selectedIndex !== null && isFormComplete) {
            finalEventItems[selectedIndex] = {
                type: 'event',
                sectionTitle: currentEvent.sectionTitle,
                time: currentEvent.content1,
                date: currentEvent.content2,
                venue: currentEvent.content3,
                description: currentEvent.content4,
                primaryText: currentEvent.content1,
                secondaryText: currentEvent.content2,
                link: currentEvent.link || ''
            };
        } else if (isFormComplete) {
            finalEventItems.push({
                type: 'event',
                sectionTitle: currentEvent.sectionTitle,
                time: currentEvent.content1,
                date: currentEvent.content2,
                venue: currentEvent.content3,
                description: currentEvent.content4,
                primaryText: currentEvent.content1,
                secondaryText: currentEvent.content2,
                link: currentEvent.link || ''
            });
        }

        if (finalEventItems.length === 0) {
            dispatch(setErrorAlert('Please add at least one event item'));
            return;
        }

        dispatch(setLoader(true));

        try {
            const updateData = {
                token,
                payload: {
                    ...pageData,
                    page: pageData.page,
                    type: 'custom',
                    headeractive: pageData.headeractive ?? true,
                    background: pageData.background ?? '1A1A25',
                    accent: pageData.accent ?? 'FFFFFF',
                    content: [
                        ...(pageData.content || []).filter(item => item.type !== 'event'),
                        ...finalEventItems
                    ],
                    headerImage: pageData.headerImage,
                    headerImageUrl: pageData.headerImageUrl,
                    originalPage: location.state?.pageData?.page || ''
                }
            };

            const result = await dispatch(updateCustomForFocusThunk(updateData)).unwrap();
            
            if (result.status === 200) {
                await dispatch(getDataForFocusThunk({ token, pageId }));
                dispatch(clearFocusSection());
                navigate('/focus-page/custom-page');
            } else {
                throw new Error(result.message || 'Update failed');
            }
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(setErrorAlert(error?.message || 'Failed to update events'));
            console.error('Error updating events:', error);
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-y-auto pb-[120px]">
                        {/* Header Section */}
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20">
                            <p className="text-white">Edit content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div className="text-white cursor-pointer" onClick={handleAddAnother}>
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50' : ''}`}
                                    onClick={() => isDoneValid() && handleDone()}
                                >
                                    Done
                                </div>
                                <div className="text-white cursor-pointer" onClick={() => navigate(-1)}>
                                    Go back
                                </div>
                            </div>
                        </div>

                        {/* Content Section */}
                        <div className="w-full px-4 mt-[120px] relative z-10">
                            {/* Section Title Input */}
                            <div className='flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <DizeeInput
                                        placeholder="Enter section title (applies to all content)"
                                        value={currentEvent.sectionTitle}
                                        onChange={(e) => setCurrentEvent({ 
                                            ...currentEvent, 
                                            sectionTitle: e.target.value 
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col">
                                {/* Primary Text Input (Time) */}
                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter primary text"
                                            value={currentEvent.content1}
                                            onChange={(e) => setCurrentEvent({ 
                                                ...currentEvent, 
                                                content1: e.target.value 
                                            })}
                                        />
                                    </div>
                                </div>

                                {/* Secondary Text Input (Date) */}
                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter secondary text"
                                            value={currentEvent.content2}
                                            onChange={(e) => setCurrentEvent({ 
                                                ...currentEvent, 
                                                content2: e.target.value 
                                            })}
                                        />
                                    </div>
                                </div>

                                {/* Tertiary Text Input (Venue) */}
                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter tertiary text"
                                            value={currentEvent.content3}
                                            onChange={(e) => setCurrentEvent({ 
                                                ...currentEvent, 
                                                content3: e.target.value 
                                            })}
                                        />
                                    </div>
                                </div>

                                {/* Additional Text Input (Description) */}
                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter additional text"
                                            value={currentEvent.content4}
                                            onChange={(e) => setCurrentEvent({ 
                                                ...currentEvent, 
                                                content4: e.target.value 
                                            })}
                                        />
                                    </div>
                                </div>

                                {/* Link Input */}
                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <div className="flex items-center w-full">
                                            <DizeeInput
                                                placeholder="Enter link (optional)"
                                                value={currentEvent.link}
                                                onChange={handleLinkChange}
                                            />
                                            {isValidUrl(currentEvent.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                                        </div>
                                    </div>
                                </div>

                                {/* Added Events List */}
                                {eventItems.length > 0 && (
                                    <div className="flex flex-col w-full mt-[40px]">
                                        <div className='pb-[20px] flex w-full items-center'>
                                            <div className='flex flex-row text-white w-full'>
                                                <p>Added Events</p>
                                            </div>
                                        </div>
                                        <div className="space-y-4">
                                            {eventItems.map((item, index) => (
                                                <AddedCustom
                                                    key={index}
                                                    item={item}
                                                    setOverlayVisible={setIsOverlayVisible}
                                                    setItemForBackend={(item) => handleEventClick(item, index)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="w-full absolute bottom-0 left-0">
                        <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                        <div className="w-full bg-black px-4 py-6">
                            <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                                <p>Edit your event content.</p>
                                <p>Add at least one content field to enhance the presentation.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedEvent}
                        handleEdit={handleEditEvent}
                        handleDelete={handleDeleteEvent}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                        canMoveUp={selectedIndex > 0}
                        canMoveDown={selectedIndex < eventItems.length - 1}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 