import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import TypeWriter from '../../components/OnBoard/Typewriter';
import LayoutHeader from '../../layout/LayoutHeader';

const Container = styled.div`
  width: 390px;
  height: 80vh;
  flex-direction: column;
  justify-start;
  items-center;
  relative;
  z-index: 0;
`;

const HeaderSection = styled.div`
  padding: 0 16px;
  margin: 50px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ChatSection = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(50vh - 400px);
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const TypewriterWrapper = styled.div`
  position: absolute;
  top: 200px;
  width: 280px;
`;

const ChatContainer = styled.div`
  width: 280px;
  min-height: 200px;
  overflow-y: auto;
  margin: 20px 0 40px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Message = styled.div`
  font-size: 12px;
  color: white;
  min-height: 18px;
  line-height: 18px;
  margin: ${props => props.isGreeting ? '0 0 40px 0' : '0'};
  text-align: ${props => props.isUser ? 'right' : 'left'};
`;

const OptionsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Input = styled.input`
  background: transparent;
  border: none;
  font-size: 12px;
  width: 280px;
  color: white;
  height: 18px;
  line-height: 18px;
  padding: 0;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    caret-color: white;
  }
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.3)' : 'white'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  height: 18px;
  line-height: 18px;
  margin-right: 43px;
`;

const OptionButton = styled.div`
  font-size: 12px;
  color: white;
  cursor: pointer;
  opacity: 0.8;
  padding: 4px 0;
  
  &:hover {
    opacity: 1;
  }
`;

const Footer = styled.div`
  width: 320px;
  position: absolute;
  bottom: 27px;
  padding-left: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.5;
`;

export default function CreateApp() {
    const [messages, setMessages] = useState([
        {
            type: 'assistant',
            content: "Hello! I'll help you create your app. Select an option below or ask me what other types of apps I can help create.",
            options: [
                { label: 'Project Management', value: 'project' },
                { label: 'Gated Content', value: 'gated' },
                { label: 'Fan Club', value: 'fan' }
            ]
        }
    ]);
    const [userInput, setUserInput] = useState('');
    const messagesEndRef = useRef(null);

    const handleOptionClick = (option) => {
        const newMessages = [...messages, {
            type: 'user',
            content: option.label
        }, {
            type: 'assistant',
            content: "Perfect! You've selected to create a " + option.label + ". What would you like to name your app? This name will be visible to your users.",
            options: []
        }];

        setMessages(newMessages);
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const handleSendMessage = () => {
        if (!userInput.trim()) return;

        // Check if this is the first message (response to name question)
        const isNameResponse = messages.length === 3; // Initial greeting + user's app type selection + name question

        const newMessages = [...messages, {
            type: 'user',
            content: userInput
        }];

        if (isNameResponse) {
            newMessages.push({
                type: 'assistant',
                content: "Great! Now, let's set up some basic configurations for your app. Would you like to start with customizing the appearance or setting up user permissions?",
                options: [
                    { label: 'Customize Appearance', value: 'appearance' },
                    { label: 'User Permissions', value: 'permissions' }
                ]
            });
        }

        setMessages(newMessages);
        setUserInput('');
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const handleConfigOptionClick = (option) => {
        let followUpMessage;
        
        if (option.value === 'appearance') {
            followUpMessage = {
                type: 'assistant',
                content: "Let's customize how your app looks. Which theme would you prefer to start with?",
                options: [
                    { label: 'Light Theme', value: 'light' },
                    { label: 'Dark Theme', value: 'dark' },
                    { label: 'Custom Theme', value: 'custom' }
                ]
            };
        } else if (option.value === 'permissions') {
            followUpMessage = {
                type: 'assistant',
                content: "Let's set up who can access your app. What type of access control would you like to implement?",
                options: [
                    { label: 'Public Access', value: 'public' },
                    { label: 'Invite Only', value: 'invite' },
                    { label: 'Password Protected', value: 'password' }
                ]
            };
        }

        const newMessages = [...messages, {
            type: 'user',
            content: option.label
        }, followUpMessage];

        setMessages(newMessages);
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const handleThemeOptionClick = (option) => {
        let followUpMessage;
        
        if (option.value === 'light' || option.value === 'dark') {
            followUpMessage = {
                type: 'assistant',
                content: "Great choice! Would you like to customize any specific elements?",
                options: [
                    { label: 'Color Scheme', value: 'colors' },
                    { label: 'Font Style', value: 'fonts' },
                    { label: 'Keep Default', value: 'default' }
                ]
            };
        } else if (option.value === 'custom') {
            followUpMessage = {
                type: 'assistant',
                content: "Perfect! Let's start with the primary colors for your custom theme.",
                options: [
                    { label: 'Choose Colors', value: 'choose_colors' },
                    { label: 'Import Brand Colors', value: 'import_colors' }
                ]
            };
        }

        const newMessages = [...messages, {
            type: 'user',
            content: option.label
        }, followUpMessage];

        setMessages(newMessages);
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const handlePermissionOptionClick = (option) => {
        let followUpMessage;
        
        if (option.value === 'public') {
            followUpMessage = {
                type: 'assistant',
                content: "Would you like to add any optional registration features for your public app?",
                options: [
                    { label: 'Email Registration', value: 'email_reg' },
                    { label: 'Social Login', value: 'social_login' },
                    { label: 'No Registration', value: 'no_reg' }
                ]
            };
        } else if (option.value === 'invite') {
            followUpMessage = {
                type: 'assistant',
                content: "How would you like to manage invites?",
                options: [
                    { label: 'Email Invites', value: 'email_invite' },
                    { label: 'Access Codes', value: 'access_codes' },
                    { label: 'Both Options', value: 'both' }
                ]
            };
        } else if (option.value === 'password') {
            followUpMessage = {
                type: 'assistant',
                content: "How would you like to distribute the password?",
                options: [
                    { label: 'Email Distribution', value: 'email_dist' },
                    { label: 'Manual Sharing', value: 'manual' },
                    { label: 'Generate Access Links', value: 'access_links' }
                ]
            };
        }

        const newMessages = [...messages, {
            type: 'user',
            content: option.label
        }, followUpMessage];

        setMessages(newMessages);
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const handleFeatureSetupClick = (option) => {
        let followUpMessage;

        if (option.value === 'colors' || option.value === 'fonts' || option.value === 'default' ||
            option.value === 'choose_colors' || option.value === 'import_colors' ||
            option.value === 'email_reg' || option.value === 'social_login' || option.value === 'no_reg' ||
            option.value === 'email_invite' || option.value === 'access_codes' || option.value === 'both' ||
            option.value === 'email_dist' || option.value === 'manual' || option.value === 'access_links') {
            
            followUpMessage = {
                type: 'assistant',
                content: "Great! The basic setup is complete. Would you like to configure any additional features?",
                options: [
                    { label: 'Analytics Setup', value: 'analytics' },
                    { label: 'Notification Settings', value: 'notifications' },
                    { label: 'Integration Options', value: 'integrations' },
                    { label: 'Complete Setup', value: 'complete' }
                ]
            };
        } else if (option.value === 'analytics' || option.value === 'notifications' || option.value === 'integrations') {
            followUpMessage = {
                type: 'assistant',
                content: "Feature configured successfully! Would you like to set up another feature or complete the setup?",
                options: [
                    { label: 'Configure More Features', value: 'more_features' },
                    { label: 'Complete Setup', value: 'complete' }
                ]
            };
        } else if (option.value === 'complete') {
            followUpMessage = {
                type: 'assistant',
                content: "Perfect! Your app is now configured and ready to use. You can always modify these settings later from your app's dashboard. Would you like to:",
                options: [
                    { label: 'View Your App', value: 'view_app' },
                    { label: 'Go to Dashboard', value: 'dashboard' },
                    { label: 'Create Another App', value: 'new_app' }
                ]
            };
        }

        const newMessages = [...messages, {
            type: 'user',
            content: option.label
        }, followUpMessage];

        setMessages(newMessages);
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100);
    };

    const handleFinalOptionClick = (option) => {
        // Here you can handle navigation based on the final choice
        if (option.value === 'view_app') {
            // Navigate to the new app
        } else if (option.value === 'dashboard') {
            // Navigate to dashboard
        } else if (option.value === 'new_app') {
            // Reset the state to start over
            setMessages([{
                type: 'assistant',
                content: "Hello! I'll help you create your app. Select an option below or ask me what other types of apps I can help create.",
                options: [
                    { label: 'Project Management', value: 'project' },
                    { label: 'Gated Content', value: 'gated' },
                    { label: 'Fan Club', value: 'fan' }
                ]
            }]);
        }
    };

    return (
        <LayoutHeader>
            <Container>
                <HeaderSection>
                    <TypeWriter text="Let's create your app" speed={45} />
                </HeaderSection>
                
                <ChatSection>
                    <ChatContainer>
                        <MessagesWrapper>
                            {messages.map((message, index) => (
                                <Message 
                                    key={index} 
                                    isUser={message.type === 'user'}
                                    isGreeting={index === 0}
                                >
                                    {message.content}
                                    {message.options && message.options.length > 0 && (
                                        <OptionsContainer>
                                            {message.options.map((option, optionIndex) => (
                                                <OptionButton
                                                    key={optionIndex}
                                                    onClick={() => {
                                                        if (message.options[0].value === 'appearance') {
                                                            handleConfigOptionClick(option);
                                                        } else if (message.options[0].value === 'light' || message.options[0].value === 'dark' || message.options[0].value === 'custom') {
                                                            handleThemeOptionClick(option);
                                                        } else if (message.options[0].value === 'permissions') {
                                                            handleConfigOptionClick(option);
                                                        } else if (message.options[0].value === 'public' || message.options[0].value === 'invite' || message.options[0].value === 'password') {
                                                            handlePermissionOptionClick(option);
                                                        } else if (message.options[0].value === 'view_app' || message.options[0].value === 'dashboard' || message.options[0].value === 'new_app') {
                                                            handleFinalOptionClick(option);
                                                        } else if (option.value === 'complete' || option.value.includes('_reg') || option.value.includes('_invite') || 
                                                                 option.value.includes('colors') || option.value.includes('fonts') || option.value === 'default' ||
                                                                 option.value === 'analytics' || option.value === 'notifications' || option.value === 'integrations') {
                                                            handleFeatureSetupClick(option);
                                                        } else {
                                                            handleOptionClick(option);
                                                        }
                                                    }}
                                                >
                                                    {option.label}
                                                </OptionButton>
                                            ))}
                                        </OptionsContainer>
                                    )}
                                </Message>
                            ))}
                            <div ref={messagesEndRef} />
                        </MessagesWrapper>
                    </ChatContainer>

                    <Input
                        placeholder="Type your response..."
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    />

                    <ButtonContainer>
                        <ActionButton 
                            onClick={handleSendMessage}
                            disabled={!userInput.trim()}
                        >
                            Send
                        </ActionButton>
                    </ButtonContainer>
                </ChatSection>

                <Footer>
                    <FooterText>
                        <p>Our AI assistant will guide you through creating your perfect app, from choosing themes to setting up user permissions.</p>
                       
                    </FooterText>
                </Footer>
            </Container>
        </LayoutHeader>
    );
}
