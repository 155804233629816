import React, { useState, useRef } from 'react';
import { getContrastColor, maxLength } from './DizeeComponent/DixeeInput2';
import { getAccentStyle } from '../constants/constants';
import PlayCircle from '../svg/PlayCircle';
import { useParams } from 'react-router-dom';
import { postDataAPI } from '../utils/fetchData';

const CusCardVideo = ({ imgurl, txt, userArticle, link, item }) => {
    const { domain: dynamicVar } = useParams();
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleVideoUrl = (url) => {
        if (!url) return '';
        
        try {
            let videoId;
            const cleanUrl = url.trim();
            
            if (cleanUrl.includes('youtu.be/')) {
                videoId = cleanUrl.split('youtu.be/')[1].split('?')[0];
                return `https://www.youtube.com/embed/${videoId}`;
            }
            
            if (cleanUrl.includes('youtube.com/shorts/')) {
                videoId = cleanUrl.split('/shorts/')[1].split('?')[0];
                return `https://www.youtube.com/embed/${videoId}`;
            }
            
            if (cleanUrl.includes('youtube.com/watch')) {
                const urlObj = new URL(cleanUrl);
                videoId = urlObj.searchParams.get('v');
                if (videoId) {
                    return `https://www.youtube.com/embed/${videoId}`;
                }
            }
            
            return url;
        } catch (error) {
            console.error("Invalid URL:", url);
            return '';
        }
    };

    const handleClick = async () => {
        if (!link) return;

        try {
            // Track analytics
            await postDataAPI('analytics/create-video-analytics', {
                link: link,
                domain: dynamicVar,
                title: txt,
                source: item?.source
            }, null);

            // Open video link
            window.open(link, '_blank');
        } catch (err) {
            console.error(err);
        }
    };

    const isYouTubeUrl = link && (
        link.includes('youtube.com') || 
        link.includes('youtu.be')
    );

    return (
        <div className="w-[318px] flex-shrink-0 flex flex-col h-auto">
            <div className="relative cursor-pointer" onClick={handleClick}>
                {/* Thumbnail Image */}
                <div className="w-full rounded-[3px] overflow-hidden">
                    <img 
                        src={imgurl} 
                        alt={txt} 
                        className="w-full h-[600px] object-cover"
                    />
                </div>
            </div>

            {/* Title */}
            <div className="w-full pt-3">
                <p 
                    className="text-[12px]"
                    style={getAccentStyle(userArticle?.accent)}
                >
                    {txt}
                </p>
            </div>
        </div>
    );
};

export default CusCardVideo; 