import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../layout/LayoutHeader';
import More from '../../../../svg/More';
import { getContrastColor, maxLength } from '../../../../components/DizeeComponent/DixeeInput2';
import { getAccentStyle } from '../../../../constants/constants';
import NewsletterEdit from '../../../../components/Focuspage/MainEdit/NewsletterEdit';
import { updateNewsletterOverlay } from '../../../../store/focuspage/focuspageSlice';
import CrossArrow from '../../../../svg/CrossArrow';
import { setUserArticle } from '../../../../store/user/userSlice';
import { debounce } from 'lodash';
import Plus from '../../../../svg/Plus';
import { useNavigate } from 'react-router';
import { update_user_article_thunk } from '../../../../store/user/userThunk';

const bottomNavContainerStyle = {
    borderTop: '0.5px solid rgba(255, 255, 255, 0.1)',
    background: '#000000',
    position: 'relative',
    zIndex: 50
};

const hslToHex = (h, s, l) => {
    s /= 100;
    l /= 100;
    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = l - c / 2;
    let r = 0,
        g = 0,
        b = 0;
    if (h >= 0 && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (h >= 180 && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (h >= 240 && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (h >= 300 && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    r = Math.round((r + m) * 255)
        .toString(16)
        .padStart(2, "0");
    g = Math.round((g + m) * 255)
        .toString(16)
        .padStart(2, "0");
    b = Math.round((b + m) * 255)
        .toString(16)
        .padStart(2, "0");
    return `#${r}${g}${b}`;
};

// Add this helper function to convert hex to HSL
const hexToHSL = (hex) => {
    // Remove the # if present
    hex = hex.replace(/^#/, '');

    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16) / 255;
    let g = parseInt(hex.substring(2, 4), 16) / 255;
    let b = parseInt(hex.substring(4, 6), 16) / 255;

    // Find min and max values
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);

    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100)
    };
};


export default function MultipleNewsletterPage() {
    const dispatch = useDispatch();
    const newsletterArticle = useSelector((state) => state.focuspage.newsletter);
    useSelector((state) => {
        console.log(`state::`, state.focuspage.newsletter);
    });
    const newsOverlay = useSelector((state) => state.focuspage.isNewsletterOverlay);
    const userArticle = useSelector((state) => state.user.userArticle);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const ensureValidUrl = (url) => {
        if (!url) return '';
        // Remove localhost if present
        url = url.replace(/^(?:https?:\/\/)?(?:localhost:[0-9]+\/)?/i, '');
        // Add https if no protocol is present
        if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }
        return url;
    };

    let token = localStorage.getItem("dizeeToken");
    const [color, setColor] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(false);
    const [backgroundShade, setBackgroundShade] = useState(false);
    const [accentColor, setAccentColor] = useState(false);
    const [accentShade, setAccentShade] = useState(false);
    const [hue, setHue] = useState(0); // Hue state for color sliders
    const [brightness, setBrightness] = useState(50); // Brightness state for shade sliders
    const [styleMenu, setStyleMenu] = useState(false);
    const [hexBackground, setHexBackground] = useState("");
    const [hexAccent, setHexAccent] = useState("");

    const [showEditOptions, setShowEditOptions] = useState(false);
    const navigate = useNavigate();

    // Add handler for Home click
    const handleHomeClick = () => {
        navigate('/');
    };

    const [grayScaleBar, setGrayScaleBar] = useState(false);
    const [noiseBar, setNoiseBar] = useState(false);

    const onChangeNoise = (e) => {
        try {
            const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
            dispatch(setUserArticle({
                ...userArticle,
                noise: value  // Keep as number like grayscale
            }));
        } catch (error) {
            console.error('Noise update error:', error);
            dispatch(setUserArticle({
                ...userArticle,
                noise: defaultValues.noise
            }));
        }
    };

    // Add this handler for accent color input
    const handleAccentColorInput = (e) => {
        const colorValue = e.target.value.toUpperCase();
        if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
            setHexAccent(colorValue);

            // Only process complete hex colors
            if (colorValue.length === 7) {
                // Convert hex to HSL and update slider
                const hsl = hexToHSL(colorValue);
                setHue(hsl.h);
                setBrightness(hsl.l);

                dispatch(setUserArticle({
                    ...userArticle,
                    accent: colorValue.slice(1)
                }));
            }
        }
    };

    // Update the color input handler
    const handleColorInput = (e) => {
        const colorValue = e.target.value.toUpperCase();
        if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
            setHexBackground(colorValue);

            // Only process complete hex colors
            if (colorValue.length === 7) {
                // Convert hex to HSL and update slider
                const hsl = hexToHSL(colorValue);
                setHue(hsl.h);
                setBrightness(hsl.l);

                dispatch(setUserArticle({
                    ...userArticle,
                    background: colorValue.slice(1)
                }));
            }
        }
    };

    const updateUserArticle = async (bool) => {
        dispatch(update_user_article_thunk({ token, body: { ...userArticle, unpublish: bool } }));
    };

    // Add initial state values
    const [defaultValues] = useState({
        grayscale: "0",
        noise: "0"
    });

    // Add debounce to prevent rapid state updates
    const debouncedUpdateArticle = useCallback(
        debounce((newValue) => {
            dispatch(setUserArticle(newValue));
        }, 100),
        [dispatch]
    );

    // Handle hue change
    const handleHueChangeBackground = (e) => {
        setHue(e.target.value);
        const hexColor = hslToHex(e.target.value, 100, brightness);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        setHexBackground(`#${colorValue}`);
        debouncedUpdateArticle({ ...userArticle, background: colorValue });
    };

    // Handle brightness change
    const handleBrightnessChangeBackground = (e) => {
        setBrightness(e.target.value);
        const hexColor = hslToHex(hue, 100, e.target.value);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        debouncedUpdateArticle({ ...userArticle, background: colorValue });
    };

    const handleHueChangeAccent = (e) => {
        setHue(e.target.value);
        const hexColor = hslToHex(e.target.value, 100, brightness);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        setHexAccent(`#${colorValue}`);
        dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
    };

    // Handle brightness change
    const handleBrightnessChangeAccent = (e) => {
        setBrightness(e.target.value);
        const hexColor = hslToHex(hue, 100, e.target.value);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
    };

    // Update grayscale handler with safety checks
    const onChangeGrayScale = (e) => {
        try {
            const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
            dispatch(setUserArticle({
                ...userArticle,
                grayscale: value
            }));
        } catch (error) {
            console.error('Grayscale update error:', error);
            dispatch(setUserArticle({
                ...userArticle,
                grayscale: defaultValues.grayscale
            }));
        }
    };

    // Add reset function for when closing controls
    const resetEffects = () => {
        dispatch(setUserArticle({
            ...userArticle,
            grayscale: defaultValues.grayscale,
            noise: defaultValues.noise
        }));
    };

    const handleNewsletterEditClick = (article) => {
        console.log(`article::`, article);
        navigate('/focus-page/newsletter-type/edit-multi-content', {
            state: {
                newsletter: article?.newsletter,
                description: article?.description,
                extension: article?.extension
            }
        });
    }

    return (
        <LayoutHeader>

            <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black">
                <div className='flex py-4 z-1 ml-4 w-full items-center text-white' >
                    <div className=" mx-auto">
                        diz.ee/{userArticle?.domain}/{newsletterArticle?.extension}
                    </div>
                    <div className="mr-4">
                        <More
                            className='h-[20px] w-[20px] cursor-pointer'
                            onClick={() => dispatch(updateNewsletterOverlay(true))}

                        />
                    </div>
                </div>
                <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]" style={newsletterArticle?.background ? { backgroundColor: `#${newsletterArticle?.background}` } : { backgroundColor: '#000000' }}>
                    <div className="max-w-[350px] sm:max-w-[390px] h-[490px] flex relative">
                        <img
                            src={newsletterArticle?.avatar}
                            alt="photoimage"
                            className="w-full h-full object-cover rounded-t-[20px]"
                        />
                        {(newsletterArticle?.headeractive && (newsletterArticle?.headerTitle || newsletterArticle?.headerSubtitle)) && (
                            <div
                                className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end p-4"
                                style={{
                                    backgroundImage: `linear-gradient(to top, ${newsletterArticle?.background ? `#${newsletterArticle.background}` : 'black'}, transparent)`,
                                }}
                            >
                                <div className="flex w-full flex-row items-start justify-between">
                                    <div>
                                        {newsletterArticle?.headerTitle && (
                                            <p
                                                className='text-[20px] font-bold'
                                                style={{ color: `#${newsletterArticle?.accent}` }}
                                            >
                                                {newsletterArticle?.headerTitle}
                                            </p>
                                        )}
                                        {newsletterArticle?.headerSubtitle && (
                                            <p
                                                className='text-[16px]'
                                                style={{ color: `#${newsletterArticle?.accent}` }}
                                            >
                                                {newsletterArticle?.headerSubtitle}
                                            </p>
                                        )}
                                    </div>
                                    <button
                                        onClick={() => handleNewsletterEditClick(newsletterArticle)}
                                        className="text-[12px] opacity-50 hover:opacity-100 transition-opacity mt-[10px]"
                                        // style={{ color: `#${custom?.accent || 'FFFFFF'}` }}
                                        style={{ color: `#${newsletterArticle?.accent}` }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>


                    {/* <div className="text-[#FDFAFA] cursor-pointer my-4 flex flex-row justify-between items-center" onClick={() => navigation('/add-section')}>
                    <Plus className='h-[12px] w-[12px] cursor-pointer mx-2' />
                    <p style={{ fontSize: '12px' }}>Add a section</p>
                </div> */}

                    <div className='w-full text-white flex flex-col px-4 justify-center items-start gap-y-[16px] mt-[25px]'>
                        {/* Main Title */}
                        <p className="text-[12px]"
                            style={newsletterArticle?.accent ?
                                { color: `#${newsletterArticle?.accent}` } :
                                { color: '#ffffff' }}>
                            {newsletterArticle?.mainTitle}
                        </p>

                        {newsletterArticle?.description && (
                            <div className='w-full space-y-[25px]'>
                                <p className='text-[12px]'
                                    style={newsletterArticle?.accent ?
                                        { color: `#${newsletterArticle?.accent}` } :
                                        { color: '#ffffff' }}>
                                    {newsletterArticle.description.title}
                                </p>
                                <div
                                    style={{
                                        height: '0.5px',
                                        background: `#${newsletterArticle?.accent}`,
                                        opacity: '0.3',
                                        marginTop: '8px'
                                    }}
                                />
                                <div className="w-full">
                                    <div className="flex items-start gap-x-[20px] w-full">
                                        {newsletterArticle.description.image && (
                                            <img
                                                src={newsletterArticle.description.image}
                                                alt="Description"
                                                className="w-[80px] h-[80px] object-cover rounded-[10px] cursor-pointer flex-shrink-0"
                                                onClick={() => newsletterArticle.description.link && window.open(ensureValidUrl(newsletterArticle.description.link), '_blank')}
                                            />
                                        )}
                                        <div className="flex-1 w-full relative min-h-[80px]">
                                            <p className='text-[10px] pr-[30px] w-full cursor-pointer overflow-wrap-anywhere'
                                                style={newsletterArticle?.accent ?
                                                    { color: `#${newsletterArticle?.accent}` } :
                                                    { color: '#ffffff' }}
                                                onClick={() => newsletterArticle.description.link && window.open(ensureValidUrl(newsletterArticle.description.link), '_blank')}>
                                                {newsletterArticle.description.text}
                                            </p>
                                            {newsletterArticle.description.link && (
                                                <div className="cursor-pointer absolute right-0 top-[30px]"
                                                    onClick={() => newsletterArticle.description.link && window.open(ensureValidUrl(newsletterArticle.description.link), '_blank')}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4 12L12 4" stroke={newsletterArticle?.accent ? `#${newsletterArticle.accent}` : '#ffffff'} strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M4 4H12V12" stroke={newsletterArticle?.accent ? `#${newsletterArticle.accent}` : '#ffffff'} strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        height: '0.5px',
                                        background: `#${newsletterArticle?.accent}`,
                                        opacity: '0.3',
                                        marginTop: '8px'
                                    }}
                                />
                            </div>
                        )}

                        {newsletterArticle?.newsletter?.map((item, index) => (
                            <div key={index} className='w-full'>
                                <div
                                    className='w-full py-4 cursor-pointer'
                                    onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
                                >
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[12px]'
                                            style={newsletterArticle?.accent ?
                                                { color: `#${newsletterArticle?.accent}` } :
                                                { color: '#ffffff' }}>
                                            {item.title}
                                        </p>
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={`transform transition-transform ${expandedIndex === index ? 'rotate-180' : ''}`}
                                        >
                                            <path
                                                d="M4 6L8 10L12 6"
                                                stroke={newsletterArticle?.accent ? `#${newsletterArticle.accent}` : '#ffffff'}
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>

                                {expandedIndex === index && (
                                    <div className='space-y-[25px] mt-[20px] mb-[40px]'>
                                        <p className='text-[10px] overflow-x-auto max-w-[316px]'
                                            style={newsletterArticle?.accent ?
                                                { color: `#${newsletterArticle?.accent}`, whiteSpace: 'pre-wrap' } :
                                                { color: '#ffffff', whiteSpace: 'pre-wrap' }}>
                                            {item?.content}
                                        </p>
                                    </div>
                                )}
                                <div
                                    style={{
                                        height: '0.5px',
                                        background: `#${newsletterArticle?.accent}`,
                                        opacity: '0.3',
                                        marginTop: '8px'
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <div className='w-full text-white flex flex-col justify-center items-start gap-y-[16px] px-4 mt-[50px] text-[12px]' style={getAccentStyle(newsletterArticle?.accent)}>
                        <div className='space-y-[15px]'>
                            <div className='flex flex-row gap-x-[10px]'>
                                <p className='uppercase font-bold'>{userArticle?.username}</p>
                                <p className=''>x</p>
                                <p className='uppercase text-[11px]'>D I Z E E</p>
                            </div>
                            <p>Privacy Policy</p>
                        </div>


                    </div>
                </div>
            </div>
            {!color && !showEditOptions && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setShowEditOptions(true)}
                        >
                            EDIT
                        </button>
                        <button
                            className="text-white text-center py-2 px-4 rounded-lg plus-button"
                            onClick={() => navigate("/add-section")}
                        >
                            <Plus className="h-[18px] w-[18px]" />
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={handleHomeClick}
                        >
                            HOME
                        </button>
                    </div>
                </div>
            )}

            {!color && showEditOptions && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setStyleMenu(true)}
                        >
                            RETOUCH
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setShowEditOptions(false)}
                        >
                            BACK
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70 color-button"
                            onClick={() => {
                                setColor(true);
                                setShowEditOptions(false);
                            }}
                        >
                            COLOR
                        </button>
                    </div>
                </div>
            )}

            {color && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setBackgroundColor(true);
                                setColor(false);
                            }}
                        >
                            BACKGROUND
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setAccentColor(true);
                                setColor(false);
                            }}
                        >
                            ACCENT
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setColor(false);
                                setShowEditOptions(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {backgroundColor && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setBackgroundShade(true)}
                        >
                            SHADE
                        </button>
                        <div className="flex items-center space-x-4">
                            <input
                                type="text"
                                value={(hexBackground || "#000000").toUpperCase()}
                                onChange={handleColorInput}
                                className="bg-transparent w-[60px] text-center outline-none border-none transition-colors duration-200"
                                style={{
                                    caretColor: 'white',
                                    appearance: 'none',
                                    WebkitAppearance: 'none'
                                }}
                            />
                            <input
                                type="range"
                                min="0"
                                max="360"
                                value={hue}
                                onChange={handleHueChangeBackground}
                                className="hue-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {/* Background Shade Selection */}
            {backgroundShade && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setBackgroundShade(false);
                                setBackgroundColor(true);
                            }}
                        >
                            COLOR
                        </button>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={brightness}
                            onChange={handleBrightnessChangeBackground}
                            className="brightness-slider"
                            style={{
                                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                                margin: 0,
                                width: "180px",
                            }}
                        />
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}
            {accentColor && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setAccentShade(true)}
                        >
                            SHADE
                        </button>
                        <div className="flex items-center space-x-4">
                            <input
                                type="text"
                                value={(hexAccent || "#000000").toUpperCase()}
                                onChange={handleAccentColorInput}
                                className="bg-transparent w-[60px] text-center outline-none border-none"
                                style={{
                                    caretColor: 'white',
                                    appearance: 'none',
                                    WebkitAppearance: 'none'
                                }}
                            />
                            <input
                                type="range"
                                min="0"
                                max="360"
                                value={hue}
                                onChange={handleHueChangeAccent}
                                className="hue-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {/* Background Shade Selection */}
            {accentShade && (
                <div className="fixed max-w-[350px] bottom-0 w-full text-white font-[410] text-[9px] leading-[10.26px] tracking-[0.02em]">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setAccentShade(false);
                                setAccentColor(true);
                            }}
                        >
                            COLOR
                        </button>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={brightness}
                            onChange={handleBrightnessChangeAccent}
                            className="brightness-slider"
                            style={{
                                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                                margin: 0,
                                width: "180px",
                            }}
                        />
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {styleMenu && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setGrayScaleBar(true);
                                setStyleMenu(false);
                            }}
                        >
                            GRAYSCALE
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setNoiseBar(true);
                                setStyleMenu(false);
                            }}
                        >
                            NOISE
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setStyleMenu(false)}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}
            {grayScaleBar && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button className="text-center py-2 rounded-lg">
                            GRAYSCALE
                        </button>
                        <div className="flex items-center space-x-4">
                            <span className="text-white min-w-[20px]">
                                {userArticle?.grayscale || 0}%
                            </span>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={userArticle?.grayscale ?? 0}
                                onChange={onChangeGrayScale}
                                className="grayscale-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => { updateUserArticle(false); setGrayScaleBar(false); }}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setGrayScaleBar(false)}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {noiseBar && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button className="text-center py-2 rounded-lg">
                            NOISE
                        </button>
                        <div className="flex items-center space-x-4">
                            <span className="text-white min-w-[20px]">
                                {userArticle?.noise || 0}%
                            </span>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={userArticle?.noise ?? 0}
                                onChange={onChangeNoise}
                                className="grayscale-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => { updateUserArticle(false); setNoiseBar(false); }}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setNoiseBar(false);
                                resetEffects();
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}
            {newsOverlay && <NewsletterEdit newsletterArticle={newsletterArticle} />}
        </LayoutHeader>
    );
}
