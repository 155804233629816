import React from 'react';
import LayoutHeader from '../../layout/LayoutHeader';
import styled from 'styled-components';

const Container = styled.div`
  width: 390px;
  padding: 20px;
  color: white;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
`;

const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns || 5}, 1fr);
  gap: 12px;
`;

const Box = styled.div`
  aspect-ratio: 1;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
`;

const FlowSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`;

export default function DesignOverview() {
  return (
    <LayoutHeader>
      <Container>
        <Section>
          <SectionTitle>Focus Pages: By Default</SectionTitle>
          <BoxGrid columns={5}>
            {[...Array(5)].map((_, i) => (
              <Box key={i} />
            ))}
          </BoxGrid>
        </Section>

        <Section>
          <SectionTitle>Focus Pages: Frame Ready</SectionTitle>
          <BoxGrid columns={6}>
            {[...Array(6)].map((_, i) => (
              <Box key={i} />
            ))}
          </BoxGrid>
        </Section>

        <Section>
          <SectionTitle>Modules</SectionTitle>
          <BoxGrid columns={3}>
            {[...Array(6)].map((_, i) => (
              <Box key={i} />
            ))}
          </BoxGrid>
        </Section>

        <Section>
          <SectionTitle>Apps</SectionTitle>
          <BoxGrid columns={4}>
            {[...Array(8)].map((_, i) => (
              <Box key={i} />
            ))}
          </BoxGrid>
        </Section>

        <Section>
          <SectionTitle>Flow</SectionTitle>
          <FlowSection>
            <BoxGrid columns={4}>
              {[...Array(4)].map((_, i) => (
                <Box key={i} />
              ))}
            </BoxGrid>
            <BoxGrid columns={4}>
              {[...Array(4)].map((_, i) => (
                <Box key={i} />
              ))}
            </BoxGrid>
          </FlowSection>
        </Section>
      </Container>
    </LayoutHeader>
  );
}