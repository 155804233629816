import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { setFocusCustom, clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { addCustomForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { uploadImage } from '../../../../../utils/upload';
import Check from '../../../../../svg/Check';
import QueuedMusicCard from '../../../../../components/Cards/QueuedMusicCard';
import AddedMusicCard from '../../../../../components/Cards/AddedMusicCard';
import CustomOverlay from '../../../../../components/Focuspage/Type/Custom/CustomOverlay';
import { checkPageAvailableForMusicThunk } from '../../../../../store/focuspage/focuspageThunk';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';

export default function AddMusicContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const custom = useSelector(state => state.focuspage.custom);

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const [sectionTitle, setSectionTitle] = useState('');
    const [primaryText, setPrimaryText] = useState('');
    const [secondaryText, setSecondaryText] = useState('');
    const [link, setLink] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [musicItems, setMusicItems] = useState([]);
    const [currentMusic, setCurrentMusic] = useState({
        primaryText: '',
        secondaryText: '',
        link: '',
        image: null,
        preview: null
    });
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedMusic, setSelectedMusic] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [itemForBackend, setItemForBackend] = useState(null);
    
    const isFormComplete = currentMusic.preview;

    const isDoneValid = () => {
        return musicItems.length > 0 || isFormComplete;
    };

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentMusic({ ...currentMusic, link: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentMusic({
                    ...currentMusic,
                    image: file,
                    preview: reader.result
                });
            };
            reader.readAsDataURL(file);
        }
        e.target.value = '';
    };

    const handleAddAnother = () => {
        if (!currentMusic.preview) {
            dispatch(setErrorAlert('Please upload an image'));
            return;
        }

        setMusicItems([...musicItems, currentMusic]);
        // Clear form except sectionTitle
        setCurrentMusic({
            primaryText: '',
            secondaryText: '',
            link: '',
            image: null,
            preview: null
        });
    };

    const handleEditMusic = () => {
        if (selectedMusic) {
            setCurrentMusic(selectedMusic);
            const updatedMusicItems = musicItems.filter((_, index) => index !== selectedIndex);
            setMusicItems(updatedMusicItems);
            setIsOverlayVisible(false);
        }
    };

    const handleDeleteMusic = () => {
        if (selectedIndex !== null) {
            const updatedMusicItems = musicItems.filter((_, index) => index !== selectedIndex);
            setMusicItems(updatedMusicItems);
            setIsOverlayVisible(false);
        }
    };

    const handleSubmit = async () => {
        if (!custom?.page) {
            dispatch(setErrorAlert("Please set a page name first"));
            return;
        }

        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one music item'));
            return;
        }

        let contentToSubmit = [...musicItems];
        if (isFormComplete) {
            contentToSubmit.push(currentMusic);
        }

        dispatch(setLoader(true));

        try {
            const processedMusic = await Promise.all(
                contentToSubmit.map(async (entry) => {
                    let url = '';
                    if (entry.image) {
                        url = await uploadImage(entry.image);
                        if (!url) {
                            throw new Error('Image cannot contain nudity, violence or drugs');
                        }
                    }

                    return {
                        type: 'music',
                        sectionTitle,
                        primaryText: entry.primaryText,
                        secondaryText: entry.secondaryText,
                        link: entry.link,
                        avatar: url
                    };
                })
            );

            // Get existing header content if available
            const existingHeader = custom?.content?.find(item => item.type === 'header');
            
            const dataCopy = {
                ...custom,
                page: custom.page,
                type: 'custom',
                headeractive: true,
                content: [
                    existingHeader || {
                        type: 'header',
                        avatar: custom?.avatar,
                        title: custom?.headerText,
                        subtitle: custom?.subheaderText
                    },
                    ...processedMusic
                ]
            };

            const result = await dispatch(addCustomForFocusThunk({ token, payload: dataCopy })).unwrap();
            
            if (result.status === 200) {
                dispatch(clearFocusSection());
                navigate("/focus-page");
            } else {
                throw new Error(result.message || 'Failed to create focus page');
            }
        } catch (error) {
            console.error('Error creating custom focus page:', error);
            dispatch(setErrorAlert(error?.message || 'Error creating focus page'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-y-auto pb-[120px]">
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div
                                        className="text-white cursor-pointer"
                                        onClick={handleAddAnother}
                                    >
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50' : ''}`}
                                    onClick={() => isDoneValid() && handleSubmit()}
                                >
                                    Done
                                </div>
                                <div className="text-white cursor-pointer" onClick={() => navigate(-1)}>
                                    Go back
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4 mt-[120px] relative z-10">
                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter section title (applies to all content)"
                                    onChange={(e) => setSectionTitle(e.target.value)}
                                    value={sectionTitle}
                                />
                            </div>

                            <div className="flex flex-col">
                                <div className='flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter text content 1"
                                            onChange={(e) => setCurrentMusic({ ...currentMusic, primaryText: e.target.value })}
                                            value={currentMusic.primaryText}
                                        />
                                    </div>
                                </div>

                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <DizeeInput
                                            placeholder="Enter text content 2"
                                            onChange={(e) => setCurrentMusic({ ...currentMusic, secondaryText: e.target.value })}
                                            value={currentMusic.secondaryText}
                                        />
                                    </div>
                                </div>

                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <div className="flex items-center w-full">
                                            <DizeeInput
                                                placeholder="Enter link"
                                                onChange={handleLinkChange}
                                                value={currentMusic.link}
                                            />
                                            {isValidUrl(currentMusic.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full mt-5">
                                    <p 
                                        className="text-white text-[12px] mb-2 cursor-pointer"
                                        onClick={() => document.getElementById('file-input').click()}
                                    >
                                        {currentMusic.preview ? 'Change image' : 'Upload image'}
                                    </p>
                                    {currentMusic.preview && (
                                        <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                                            <img src={currentMusic.preview} alt="preview" className="w-full h-full object-cover" />
                                        </div>
                                    )}
                                    <input
                                        id="file-input"
                                        type="file"
                                        onChange={handleImageChange}
                                        className="hidden"
                                        accept="image/*"
                                    />
                                </div>
                            </div>

                            {musicItems.length > 0 && (
                                <div className="flex flex-col w-full mt-[40px]">
                                    <div className='pb-[20px] flex w-full items-center'>
                                        <div className='flex flex-row text-white w-full'>
                                            <p>Added Content</p>
                                        </div>
                                    </div>
                                    {musicItems.map((item, index) => (
                                        <AddedCustom
                                            key={index}
                                            item={item}
                                            setOverlayVisible={setIsOverlayVisible}
                                            setItemForBackend={(item) => {
                                                setSelectedMusic(item);
                                                setSelectedIndex(index);
                                                setIsOverlayVisible(true);
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="w-full absolute bottom-0 left-0">
                        <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                        <div className="w-full bg-black px-4 py-6">
                            <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                                <p>Add your content to display in your focus page.</p>
                                <p>Only an image is required. Add text and links to enhance the presentation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isOverlayVisible && (
                <OverlayCustom
                    isOverlayVisible={isOverlayVisible}
                    setOverlayVisible={setIsOverlayVisible}
                    selectedMusic={selectedMusic}
                    handleEdit={handleEditMusic}
                    handleDelete={handleDeleteMusic}
                />
            )}
        </LayoutHeader>
    );
} 