import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import "./App.css";
import "./assets/font/stylesheet.css";

import Alert from "./components/Alert";
import AuthRoutes from "./routing/AuthRoutes";
import PrivateRoutes from "./routing/PrivateRoutes";
import { useDispatch, useSelector } from "react-redux";
import SupportHub from './pages/CustomerService/SupportHub';
import ServiceChart from './pages/CustomerService/ServiceChart';

function App() {
  let tokenState = localStorage.getItem('dizeeToken');
  let bool = false
  if (tokenState) {
    bool = true
  }
  const [currentUser, setCurrentUser] = useState(bool);

  const auth = useSelector((state) => state.auth?.isLogin);
  const dispatch = useDispatch()
  
  useEffect(() => {
    const token = localStorage.getItem('dizeeToken');
    const isLoggedIn = token;
    setCurrentUser(isLoggedIn);
  }, [dispatch, auth, currentUser]);

  // Enhanced keyboard handling with position fixing
  useEffect(() => {
    let isKeyboardOpen = false;
    const activeInputs = new Set();
    let originalScrollPosition = 0;
    let originalBodyStyles = {};
    
    // Directly prevent touchmove events when keyboard is open
    const preventTouchMove = (e) => {
      // Only prevent default if keyboard is open
      if (isKeyboardOpen) {
        let target = e.target;
        let shouldAllowScroll = false;
        
        // Check if the target or any parent is a focused input or a scrollable element
        while (target && target !== document.body) {
          if (activeInputs.has(target) || 
              (getComputedStyle(target).overflow === 'auto' || 
               getComputedStyle(target).overflow === 'scroll' ||
               getComputedStyle(target).overflowY === 'auto' ||
               getComputedStyle(target).overflowY === 'scroll')) {
            shouldAllowScroll = true;
            break;
          }
          target = target.parentElement;
        }
        
        if (!shouldAllowScroll) {
          e.preventDefault();
        }
      }
    };
    
    // Save original page state before fixing position
    const saveOriginalState = () => {
      originalScrollPosition = window.pageYOffset;
      originalBodyStyles = {
        position: document.body.style.position,
        top: document.body.style.top,
        left: document.body.style.left,
        width: document.body.style.width,
        height: document.body.style.height,
        overflow: document.body.style.overflow
      };
    };
    
    // Fix position only for iOS devices
    const fixPosition = () => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isIOS) {
        document.body.style.position = 'fixed';
        document.body.style.top = `-${originalScrollPosition}px`;
        document.body.style.left = '0';
        document.body.style.width = '100%';
      }
      // No changes for non-iOS devices
    };
    
    // Restore original position and scroll state
    const restorePosition = () => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isIOS) {
        // Only restore styles that were modified
        document.body.style.position = originalBodyStyles.position || '';
        document.body.style.top = originalBodyStyles.top || '';
        document.body.style.left = originalBodyStyles.left || '';
        document.body.style.width = originalBodyStyles.width || '';
        
        // Restore scroll position only for iOS
        window.scrollTo(0, originalScrollPosition);
      }
    };
    
    // Track focused inputs
    const handleFocus = (e) => {
      if (e.target.tagName === 'INPUT' || 
          e.target.tagName === 'TEXTAREA' || 
          e.target.tagName === 'SELECT') {
        
        // First input being focused
        if (activeInputs.size === 0) {
          saveOriginalState();
          fixPosition();
        }
        
        activeInputs.add(e.target);
        isKeyboardOpen = true;
      }
    };
    
    const handleBlur = (e) => {
      if (e.target.tagName === 'INPUT' || 
          e.target.tagName === 'TEXTAREA' || 
          e.target.tagName === 'SELECT') {
        
        activeInputs.delete(e.target);
        
        // Only restore position if no inputs are active
        if (activeInputs.size === 0) {
          isKeyboardOpen = false;
          restorePosition();
        }
      }
    };
    
    // Handle device orientation changes and keyboard visibility
    const handleResize = () => {
      // Improved resize logic - only restore if keyboard was open and now seems closed
      if (isKeyboardOpen && activeInputs.size === 0) {
        isKeyboardOpen = false;
        restorePosition();
      }
    };
    
    // Set up all event listeners
    document.addEventListener('touchmove', preventTouchMove, { passive: false, capture: true });
    document.addEventListener('focus', handleFocus, true);
    document.addEventListener('blur', handleBlur, true);
    window.addEventListener('resize', handleResize);
    
    return () => {
      document.removeEventListener('touchmove', preventTouchMove, { passive: false, capture: true });
      document.removeEventListener('focus', handleFocus, true);
      document.removeEventListener('blur', handleBlur, true);
      window.removeEventListener('resize', handleResize);
      
      // Only restore if we're still in a fixed position state
      if (document.body.style.position === 'fixed') {
        restorePosition();
      }
    };
  }, []);
  
  return (
    <>
      <Alert />

      <BrowserRouter>
        <Routes>
          {
            !currentUser ?
              <>
                <Route path='/*' element={<AuthRoutes />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
              :
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
          }
          <Route path="/support" element={<SupportHub />} />
          <Route path="/support/chart" element={<ServiceChart />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
