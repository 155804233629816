import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { addCustomForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { uploadImage } from '../../../../../utils/upload';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';

export default function AddSocialContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const custom = useSelector(state => state.focuspage.custom);

    // State management
    const [sectionTitle, setSectionTitle] = useState('');
    const [currentSocial, setCurrentSocial] = useState({
        link: '',
        image: null,
        preview: null,
        type: 'social'
    });
    const [socialItems, setSocialItems] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedSocial, setSelectedSocial] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Form validation
    const isFormComplete = currentSocial.preview && 
                          currentSocial.link && 
                          isValidUrl(currentSocial.link) && 
                          sectionTitle.trim() !== '';

    const isDoneValid = () => {
        return socialItems.length > 0 || isFormComplete;
    };

    // Handle link input
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentSocial({ ...currentSocial, link: value });
    };

    // Handle image upload
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // File size validation (5MB limit)
            if (file.size > 5 * 1024 * 1024) {
                dispatch(setErrorAlert('Image size should be less than 5MB'));
                return;
            }

            // File type validation
            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            if (!validTypes.includes(file.type)) {
                dispatch(setErrorAlert('Please upload a valid image file (JPEG, PNG, GIF, or WEBP)'));
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentSocial({
                    ...currentSocial,
                    image: file,
                    preview: reader.result
                });
            };
            reader.readAsDataURL(file);
        }
        e.target.value = '';
    };

    // Form validation
    const validateForm = () => {
        // Section title is required
        if (!sectionTitle.trim()) {
            dispatch(setErrorAlert('Please enter a section title'));
            return false;
        }

        // Image is required
        if (!currentSocial.preview) {
            dispatch(setErrorAlert('Please upload an image'));
            return false;
        }

        // Link is required and must be valid
        if (!currentSocial.link || !isValidUrl(currentSocial.link)) {
            dispatch(setErrorAlert('Please enter a valid URL'));
            return false;
        }

        return true;
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!validateForm()) return;

        setSocialItems([...socialItems, {
            ...currentSocial,
            type: 'social',
            sectionTitle: sectionTitle,
            primaryText: currentSocial.link,
        }]);

        // Reset form
        setCurrentSocial({
            link: '',
            image: null,
            preview: null,
            type: 'social'
        });
    };

    // Handle edit and delete
    const handleEditSocial = () => {
        if (selectedSocial) {
            setCurrentSocial(selectedSocial);
            const updatedSocials = socialItems.filter((_, index) => index !== selectedIndex);
            setSocialItems(updatedSocials);
            setIsOverlayVisible(false);
        }
    };

    const handleDeleteSocial = () => {
        setSocialItems(socialItems.filter((_, index) => index !== selectedIndex));
        setIsOverlayVisible(false);
    };

    // Handle final submission
    const handleDone = async () => {
        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one social link'));
            return;
        }

        dispatch(setLoader(true));

        try {
            // Get existing content
            let finalContent = [...custom?.content || []];
            // Filter out existing social items
            finalContent = finalContent.filter(item => item.type !== 'social');
            
            let contentToSubmit = [...socialItems];
            if (isFormComplete) {
                contentToSubmit.push(currentSocial);
            }

            // Process images one at a time
            const processedSocials = [];
            for (const entry of contentToSubmit) {
                let url = '';
                if (entry.image) {
                    url = await uploadImage(entry.image);
                    if (!url) {
                        throw new Error('Image cannot contain nudity, violence or drugs');
                    }
                }

                processedSocials.push({
                    type: 'social',
                    sectionTitle: sectionTitle,
                    link: entry.link || '',
                    avatar: url || entry.avatar
                });
            }

            // Maintain header content
            const existingHeader = custom?.content?.find(item => item.type === 'header');
            
            const dataCopy = {
                page: custom.page,
                type: 'custom',
                headeractive: true,
                content: [
                    existingHeader || {
                        type: 'header',
                        avatar: custom?.avatar || '',
                        title: custom?.headerText || '',
                        subtitle: custom?.subheaderText || ''
                    },
                    ...processedSocials
                ]
            };

            const result = await dispatch(addCustomForFocusThunk({ 
                token, 
                payload: dataCopy 
            })).unwrap();
            
            if (result.status === 200) {
                dispatch(clearFocusSection());
                navigate("/focus-page");
            } else {
                throw new Error(result.message || 'Failed to create focus page');
            }
        } catch (error) {
            console.error('Error creating custom focus page:', error);
            dispatch(setErrorAlert(error?.message || 'Error creating focus page'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-y-auto pb-[120px]">
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div className="text-white cursor-pointer" onClick={handleAddAnother}>
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50' : ''}`}
                                    onClick={() => isDoneValid() && handleDone()}
                                >
                                    Done
                                </div>
                                <div className="text-white cursor-pointer" onClick={() => navigate(-1)}>
                                    Go back
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4 mt-[120px] relative z-10">
                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter section title (applies to all content)"
                                    onChange={(e) => setSectionTitle(e.target.value)}
                                    value={sectionTitle}
                                />
                            </div>

                            <div className="flex flex-col">
                                <div className='flex w-full justify-between items-center mt-5' style={{ fontSize: '12px' }}>
                                    <div className='items-center flex flex-row text-white w-full'>
                                        <div className="flex items-center w-full">
                                            <DizeeInput
                                                placeholder="Enter link"
                                                onChange={handleLinkChange}
                                                value={currentSocial.link}
                                            />
                                            {isValidUrl(currentSocial.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full mt-5">
                                    <p 
                                        className="text-white text-[12px] mb-2 cursor-pointer"
                                        onClick={() => document.getElementById('file-input').click()}
                                    >
                                        {currentSocial.preview ? 'Change image' : 'Upload image'}
                                    </p>
                                    {currentSocial.preview && (
                                        <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                                            <img src={currentSocial.preview} alt="preview" className="w-full h-full object-cover" />
                                        </div>
                                    )}
                                    <input
                                        id="file-input"
                                        type="file"
                                        onChange={handleImageChange}
                                        className="hidden"
                                        accept="image/*"
                                    />
                                </div>

                                {socialItems.length > 0 && (
                                    <div className="flex flex-col w-full mt-[40px]">
                                        <div className='pb-[20px] flex w-full items-center'>
                                            <div className='flex flex-row text-white w-full'>
                                                <p>Added Social Links</p>
                                            </div>
                                        </div>
                                        {socialItems.map((item, index) => (
                                            <AddedCustom
                                                key={index}
                                                item={{
                                                    ...item,
                                                    primaryText: item.link,
                                                }}
                                                setOverlayVisible={setIsOverlayVisible}
                                                setItemForBackend={(item) => {
                                                    setSelectedSocial(item);
                                                    setSelectedIndex(index);
                                                    setIsOverlayVisible(true);
                                                }}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="w-full absolute bottom-0 left-0">
                        <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                        <div className="w-full bg-black px-4 py-6">
                            <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                                <p>Add your social content to display in your focus page.</p>
                                <p>Image and link are required to add social content.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isOverlayVisible && (
                <OverlayCustom
                    isOverlayVisible={isOverlayVisible}
                    setOverlayVisible={setIsOverlayVisible}
                    selectedItem={selectedSocial}
                    handleEdit={handleEditSocial}
                    handleDelete={handleDeleteSocial}
                />
            )}
        </LayoutHeader>
    );
} 