import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { MUSIC_PLATFORMS } from '../../../../../constants/constants';
import MusicPlatform from '../../../../../components/AddSection/Music/MusicPlatform';
import { update_focus_data_thunk, updateMusicForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setLoader, setErrorAlert, clearAlerts } from '../../../../../store/alert/alertSlice';

export default function EditMultipleMusicPlatforms() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    const { musicItem, originalData, currentMusic } = location.state;
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);

    useEffect(() => {
        if (musicItem?.links) {
            const existingPlatforms = musicItem.links.map(link => link.source);
            setSelectedPlatforms(existingPlatforms);
        }
    }, [musicItem]);

    const handleTogglePlatform = (platform) => {
        setSelectedPlatforms(prevSelected =>
            prevSelected.includes(platform)
                ? prevSelected.filter(item => item !== platform)
                : [...prevSelected, platform]
        );
    };

    const handleAddMusic = async () => {
        const selectedLinks = musicItem?.links?.filter(link =>
            selectedPlatforms.includes(link.source)
        ) || [];

        const newMusicItem = {
            id: musicItem.songstats_track_id,
            avatar: musicItem.avatar,
            title: musicItem.title,
            artist: musicItem.artists[0].name,
            links: selectedLinks
        };

        const updatedMusic = [...currentMusic, newMusicItem];

        try {
            await dispatch(updateMusicForFocusThunk({
                token,
                body: {
                    ...originalData,
                    music: updatedMusic
                }
            })).unwrap();
            navigate(-1);
        } catch (error) {
            dispatch(setErrorAlert(error.message || "Failed to update music"));
        }
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white'>Select platforms for display</p>
                    <div className='flex gap-4'>
                        <button
                            onClick={handleAddMusic}
                            className='text-white cursor-pointer'
                        >
                            Add
                        </button>
                        <button
                            onClick={() => navigate(-1)}
                            className='text-white cursor-pointer'
                        >
                            Back
                        </button>
                    </div>
                </div>

                {MUSIC_PLATFORMS.map((platform, index) => {
                    const availableLinks = musicItem.links?.filter(
                        link => link.source === platform.value
                    );

                    return availableLinks?.length > 0 && (
                        <MusicPlatform
                            key={index}
                            txt={platform.label}
                            selected={selectedPlatforms.includes(platform.value)}
                            onSelect={() => handleTogglePlatform(platform.value)}
                        />
                    );
                })}
            </div>
        </LayoutHeader>
    );
} 