import { postDataAPI } from './fetchData';

/**
 * Imports artist's top songs from Spotify into the music section
 * @param {Array} topSongs - Array of top songs from Spotify API
 * @param {string} artistName - Artist name for attribution
 * @param {string} token - User authentication token
 * @returns {Promise<boolean>} - Success status
 */
export const importSpotifyTopSongs = async (topSongs, artistName, token) => {
  if (!topSongs || !Array.isArray(topSongs) || topSongs.length === 0) {
    console.log("No top songs available to import");
    return false;
  }

  try {
    // Create a music section if one doesn't exist
    const musicSection = {
      title: `Top Tracks by ${artistName || 'Artist'}`,
      description: "Automatically imported from Spotify",
      links: topSongs.map(song => {
        // Handle both string-only and object formats
        const songName = typeof song === 'string' ? song : song.name || song.title;
        const songArtist = typeof song === 'string' ? artistName : (song.artists || artistName);
        const imageUrl = typeof song === 'string' ? '' : (song.albumImage || song.image || '');
        const songUrl = typeof song === 'string' ? '' : (song.spotifyUrl || song.previewUrl || song.externalUrl || '');
        
        return {
          title: songName,
          description: songArtist,
          image: imageUrl,
          url: songUrl,
          platform: "spotify"
        };
      })
    };

    // Post the music section to the API
    const response = await postDataAPI('user/music/add', musicSection, token);
    
    console.log("Imported Spotify top songs:", response.data);
    return response.data?.status === 200;
  } catch (error) {
    console.error("Error importing Spotify songs:", error);
    return false;
  }
};
