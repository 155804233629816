import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import LayoutHeader from "../../../../layout/LayoutHeader";
import DizeeInput from "../../../../components/DizeeComponent/DizeeInput";
import { useDispatch } from "react-redux";
import { setErrorAlert, setLoader } from "../../../../store/alert/alertSlice";
import { updateNewsletterForFocusThunk, updateProductForFocusThunk } from "../../../../store/focuspage/focuspageThunk";
import { uploadImage } from "../../../../utils/upload";

const NewsletterEditContent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    console.log(`location::`, {
        ...location.state,
        preview: location.state?.description?.image
    });

    const [currentProduct, setCurrentProduct] = useState({
        ...location.state,
        preview: location.state?.description?.image
    });

    const isDoneValid = () => {
        return currentProduct.description.title != '' && currentProduct.description.content != '' && currentProduct.newsletter.title != '' && currentProduct.newsletter.content && currentProduct.description.image
    };

    const handleDone = async () => {
        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add all information'));
            return;
        }
        dispatch(setLoader(true));

        if (typeof currentProduct.description.image == 'string') {
            let body = {
                ...currentProduct
            }
            delete body.preview;
            dispatch(updateNewsletterForFocusThunk({
                token,
                payload: {
                    ...body
                }
            }));
        } else {
            let imageUrl = await uploadImage(currentProduct.description.image);
            if (!imageUrl) {
                throw new Error('Failed to upload image');
            }
            let body = {
                ...currentProduct,
                description: {
                    ...currentProduct.description,
                    image: imageUrl
                }
            }
            delete body.preview;
            let data = {
                token,
                payload: {
                    ...body
                }
            }
            dispatch(updateNewsletterForFocusThunk(data));
        }
        dispatch(setLoader(false));
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                dispatch(setErrorAlert('Image size should be less than 5MB'));
                return;
            }

            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            if (!validTypes.includes(file.type)) {
                dispatch(setErrorAlert('Please upload a valid image file (JPEG, PNG, GIF, or WEBP)'));
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentProduct({
                    ...currentProduct,
                    preview: reader.result,
                    description: {
                        ...currentProduct.description,
                        image: file
                    }
                });
            };
            reader.readAsDataURL(file);
        }
        e.target.value = '';
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Edit content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        <button
                            onClick={handleDone}
                            // className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}
                            className={`text-white cursor-pointer`}
                        >
                            Done
                        </button>
                        <p
                            onClick={() => navigate(-1)}
                            className="text-white cursor-pointer"
                        >
                            Go back
                        </p>
                    </div>
                </div>

                {/* Form Content */}
                <div className="w-full px-4">
                    {/* Section Title */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Description Title"
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                description: {
                                    ...currentProduct.description,
                                    title: e.target.value
                                }
                            })}
                            value={currentProduct?.description?.title}
                        />
                        {/* {currentProduct.sectionTitle && <Check className="h-[12px] w-[18px] ml-2" />} */}
                    </div>

                    {/* Description */}
                    <div className="flex items-center mb-4">
                        <textarea
                            placeholder="Description Content"
                            value={currentProduct?.description?.text}
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                description: {
                                    ...currentProduct.description,
                                    text: e.target.value
                                }
                            })}
                            className="w-full bg-transparent text-white placeholder-[rgba(255,255,255,0.5)] px-0 py-3 min-h-[150px] resize-y outline-none text-[12px]"
                        />
                    </div>

                    {/* Image Upload */}
                    <div className="flex items-center mb-4">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="hidden"
                            id="productImage"
                        />
                        <label
                            htmlFor="productImage"
                            className="cursor-pointer text-white text-[12px] opacity-50 hover:opacity-100"
                        >
                            {currentProduct.preview ? 'Change Image' : 'Upload Image'}
                        </label>
                        {currentProduct.preview && (
                            <div className="ml-4">
                                <img
                                    src={currentProduct.preview}
                                    alt="Preview"
                                    className="h-[40px] w-[40px] object-cover rounded"
                                />
                            </div>
                        )}
                    </div>

                    {/* Link */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Newsletter Title"
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                newsletter: {
                                    ...currentProduct.newsletter,
                                    title: e.target.value
                                }
                            })}
                            value={currentProduct?.newsletter?.title}
                        />
                    </div>

                    {/* Link */}
                    <div className="flex items-center mb-4">
                        <textarea
                            placeholder="Newsletter Content"
                            className="w-full bg-transparent text-white placeholder-[rgba(255,255,255,0.5)] px-0 py-3 min-h-[150px] resize-y outline-none text-[12px]"
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                newsletter: {
                                    ...currentProduct.newsletter,
                                    content: e.target.value
                                }
                            })}
                            value={currentProduct?.newsletter?.content}
                        />
                    </div>

                    {/* Added Products */}
                    {/* {productItems.length > 0 && (
                        <div className="flex flex-col w-full mt-[40px]">
                            <div className='pb-[20px] flex w-full items-center'>
                                <div className='flex flex-row text-white w-full'>
                                    <p>Added Products</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                {productItems.map((item, index) => (
                                    <AddedCustom
                                        key={index}
                                        item={item}
                                        setOverlayVisible={setIsOverlayVisible}
                                        setItemForBackend={(item) => handleProductClick(item, index)}
                                    />
                                ))}
                            </div>
                        </div>
                    )} */}
                </div>

                {/* Footer */}
                <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Edit your product content.</p>
                            <p>Image is required. Add description and link to enhance the presentation.</p>
                        </div>
                    </div>
                </div>

                {/* Overlay
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedProduct}
                        handleEdit={handleEditProduct}
                        handleDelete={handleDeleteProduct}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                        canMoveUp={selectedIndex > 0}
                        canMoveDown={selectedIndex < productItems.length - 1}
                    />
                )} */}
            </div>
        </LayoutHeader>
    )
}

export default NewsletterEditContent;