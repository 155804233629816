import { createSlice } from "@reduxjs/toolkit";
import { get_valut_data_by_username_thunk, get_valut_data_thunk, update_vault_data_thunk } from "./vaultdataThunk";

const initialState = {
    headerTitle: '',
    title: '',
    description: '',
    image: '',
    musicList: [],
    isImageOverlay: false,
    curPlayStreamData: null,
}

const slice = createSlice({
    name: "vaultData",
    initialState,
    reducers: {
        updateHeaderTitle: (state, action) => {
            state.headerTitle = action.payload
        },
        updateTitle: (state, action) => {
            state.title = action.payload
        },
        updateDescription: (state, action) => {
            state.description = action.payload
        },
        updateImage: (state, action) => {
            state.image = action.payload
        },
        updateImageOverlay(state, action) {
            state.isImageOverlay = action.payload
        },
        updateCurPlayStreamData(state, action) {
            state.curPlayStreamData = action.payload
        },
        clearVault: (state) => {
            state.title = ''
            state.description = ''
            state.image = ''
            state.musicList = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(get_valut_data_thunk.pending, (state) => {
            console.log(`get_valut_data_thunk pending`)
        }).addCase(get_valut_data_thunk.fulfilled, (state, action) => {
            console.log(`vault data thunk..`, action.payload)
            let { headerTitle, title, description, image, musicList } = action.payload
            state.headerTitle = headerTitle;
            state.title = title;
            state.description = description;
            state.image = image;
            state.musicList = musicList;
        }).addCase(get_valut_data_thunk.rejected, (state, action) => {
            // state = initialState
            console.log(`get_valut_data_thunk failed--`, state)
        })

        builder.addCase(get_valut_data_by_username_thunk.pending, (state) => {
            state.loading = true;
            state.error = null;
            console.log(`get_valut_data_by_username_thunk pending`)
        }).addCase(get_valut_data_by_username_thunk.fulfilled, (state, action) => {
            let { headerTitle, title, description, image, musicList } = action.payload
            state.headerTitle = headerTitle;
            state.title = title;
            state.description = description;
            state.image = image;
            state.musicList = musicList;
        }).addCase(get_valut_data_by_username_thunk.rejected, (state, action) => {
            console.log(`get_valut_data_by_username_thunk failed`)
            state.loading = false;
            state.error = action.payload || 'Redux Cases Error'
        })

        builder.addCase(update_vault_data_thunk.pending, (state) => {
            state.loading = true;
            state.error = null;
            console.log(`update_vault_data_thunk pending`)
        }).addCase(update_vault_data_thunk.fulfilled, (state, action) => {
            let { headerTitle, title, description, image, musicList } = action.payload
            state.headerTitle = headerTitle;
            state.title = title;
            state.description = description;
            state.image = image;
            state.musicList = musicList;
        }).addCase(update_vault_data_thunk.rejected, (state, action) => {
            console.log(`update_vault_data_thunk failed`)
            state.loading = false;
            state.error = action.payload || 'Redux Cases Error'
        })
    }
})

export const { updateHeaderTitle, updateTitle, updateDescription,
    updateCurPlayStreamData,
    updateImageOverlay, updateImage, clearVault } = slice.actions;

export default slice.reducer;