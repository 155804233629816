import React, { useEffect } from 'react';
import DizeeInput from "../../../components/DizeeComponent/DizeeInput";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import AppleMusic from "../../../svg/Social/AppleMusic";
import Facebook from "../../../svg/Social/Facebook";
import Linkedin from "../../../svg/Social/Linkedin";
import Snapchat from "../../../svg/Social/Snapchat";
import Spotify from "../../../svg/Social/Spotify";
import Thread from "../../../svg/Social/Thread";
import TwitterX from "../../../svg/Social/TwitterX";
import Youtube from "../../../svg/Social/Youtube";
import Instagram from "../../../svg/SocialFeedPlatform/Instagram";
import Tiktok from "../../../svg/SocialFeedPlatform/Tiktok";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ScrollableSection = styled.div`
  max-height: 240px;
  overflow-y: auto;
  margin-bottom: 40px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const componentMapping = {
  IG: <Instagram />,
  TT: <Tiktok />,
  X: <TwitterX className="h-[15px] w-[15px]" />,
  FB: <Facebook className="h-[15px] w-[15px]" />,
  SC: <Snapchat className="h-[15px] w-[15px]" />,
  YT: <Youtube className="h-[15px] w-[15px]" />,
  SF: <Spotify className="h-[15px] w-[15px]" />,
  AM: <AppleMusic className="h-[15px] w-[15px]" />,
  TH: <Thread className="h-[15px] w-[15px]" />,
  LI: <Linkedin />,
};

const Step9 = ({ setStep, setUser, user }) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  return (
    <Container>
      <FormSection>
        <div className="w-[200px]">
          <div className="font-default mb-[68px]">
            <TypeWriter text={"Add your social handles"} speed={45} />
          </div>

          <ScrollableSection>
            <div className="mb-[27px] flex font-default gap-x-[10px]">
              {componentMapping["IG"]}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.ig}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, ig: e.target.value },
                  })
                }
              />
            </div>
            <div className="mb-[27px] flex font-default gap-x-[10px]">
              {componentMapping["TT"]}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.tt}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, tt: e.target.value },
                  })
                }
              />
            </div>
            <div className="mb-[27px] flex font-default gap-x-[10px]">
              {componentMapping["X"]}
              {/* &nbsp; */}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.x}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, x: e.target.value },
                  })
                }
              />
            </div>
            <div className="mb-[27px] flex font-default gap-x-[10px]">
              {componentMapping["FB"]}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.fb}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, fb: e.target.value },
                  })
                }
              />
            </div>
            <div className="mb-[27px] flex font-default gap-x-[10px]">
              {componentMapping["SC"]}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.sc}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, sc: e.target.value },
                  })
                }
              />
            </div>
            <div className="mb-[27px] flex font-default gap-x-[10px]">
              {componentMapping["YT"]}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.yt}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, yt: e.target.value },
                  })
                }
              />
            </div>
            <div className="mb-[27px] flex font-default gap-x-[10px]">
              {componentMapping["SF"]}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.sf}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, sf: e.target.value },
                  })
                }
              />
            </div>
            <div className="mb-[27px] flex font-default gap-x-[10px]">
              {componentMapping["AM"]}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.am}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, am: e.target.value },
                  })
                }
              />
            </div>
            <div className="mb-[27px] flex font-default gap-x-[10px]">
              {componentMapping["TH"]}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.th}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, th: e.target.value },
                  })
                }
              />
            </div>
            <div className="mb-3 flex font-default gap-x-[10px]">
              {componentMapping["LI"]}
              <DizeeInput
                placeholder={"Username or URL "}
                value={user?.social?.li}
                onChange={(e) =>
                  setUser({
                    ...user,
                    social: { ...user.social, li: e.target.value },
                  })
                }
              />
            </div>
          </ScrollableSection>

          <div className="font-default flex flex-row">
            <div
              className="mr-[43px] cursor-pointer"
              onClick={() => setStep(22)}
            >
              Confirm
            </div>
            <div className="cursor-pointer" onClick={() => setStep(7)}>
              Go back
            </div>
          </div>
        </div>
      </FormSection>
      
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          These will be loaded on your brand page header but can be changed at any time
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step9;
