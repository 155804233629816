import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { updateCustomForFocusThunk, getDataForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';

export default function EditTextContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    // Get data from navigation state
    const pageData = location.state?.pageData;
    const pageId = location.state?.pageId;

    // Initialize state
    const [textItems, setTextItems] = useState(
        location.state?.textContent?.map(item => ({
            ...item
        })) || []
    );
    
    const [currentText, setCurrentText] = useState({
        sectionTitle: location.state?.sectionTitle || '',
        secondaryText: '',
        optionalText: '',
        link: '',
        type: 'text'
    });

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedText, setSelectedText] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Form validation
    const isFormComplete = currentText.sectionTitle && currentText.secondaryText;
    
    const isDoneValid = () => {
        return textItems.length > 0 || isFormComplete;
    };

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Handle link changes
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentText({ ...currentText, link: value });
    };

    // Handle text click for editing
    const handleTextClick = (item, index) => {
        setSelectedText(item);
        setSelectedIndex(index);
        setIsOverlayVisible(true);
    };

    // Handle edit
    const handleEditText = () => {
        if (selectedText && selectedIndex !== null) {
            setCurrentText({
                sectionTitle: selectedText.sectionTitle,
                secondaryText: selectedText.primaryText,
                optionalText: selectedText.secondaryText || '',
                link: selectedText.link || '',
                type: 'text'
            });
            setIsOverlayVisible(false);
        }
    };

    // Handle move up/down
    const handleMoveUp = () => {
        if (selectedIndex > 0) {
            const updatedItems = [...textItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex - 1]] = 
            [updatedItems[selectedIndex - 1], updatedItems[selectedIndex]];
            setTextItems(updatedItems);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (selectedIndex < textItems.length - 1) {
            const updatedItems = [...textItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex + 1]] = 
            [updatedItems[selectedIndex + 1], updatedItems[selectedIndex]];
            setTextItems(updatedItems);
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle delete
    const handleDeleteText = () => {
        const filteredItems = textItems.filter((_, index) => index !== selectedIndex);
        setTextItems(filteredItems);
        setIsOverlayVisible(false);
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in all required fields'));
            return;
        }

        if (selectedIndex !== null) {
            const updatedItems = [...textItems];
            updatedItems[selectedIndex] = {
                type: 'text',
                sectionTitle: currentText.sectionTitle,
                primaryText: currentText.secondaryText,
                secondaryText: currentText.optionalText,
                link: currentText.link || ''
            };
            setTextItems(updatedItems);
            setSelectedIndex(null);
        } else {
            setTextItems([...textItems, {
                type: 'text',
                sectionTitle: currentText.sectionTitle,
                primaryText: currentText.secondaryText,
                secondaryText: currentText.optionalText,
                link: currentText.link || ''
            }]);
        }

        setCurrentText({
            sectionTitle: currentText.sectionTitle,
            secondaryText: '',
            optionalText: '',
            link: '',
            type: 'text'
        });
    };

    // Handle form submission
    const handleDone = async () => {
        if (!pageData?.page) {
            dispatch(setErrorAlert('Invalid page data'));
            return;
        }

        // Create final text items array
        let finalTextItems = [...textItems];
        
        // If we're editing, update in place
        if (selectedIndex !== null && isFormComplete) {
            finalTextItems[selectedIndex] = {
                type: 'text',
                sectionTitle: currentText.sectionTitle,
                primaryText: currentText.secondaryText,
                secondaryText: currentText.optionalText,
                link: currentText.link || ''
            };
        } else if (isFormComplete) {
            // If not editing but form is complete, add as new item
            finalTextItems.push({
                type: 'text',
                sectionTitle: currentText.sectionTitle,
                primaryText: currentText.secondaryText,
                secondaryText: currentText.optionalText,
                link: currentText.link || ''
            });
        }

        if (finalTextItems.length === 0) {
            dispatch(setErrorAlert('Please add at least one text item'));
            return;
        }

        dispatch(setLoader(true));

        try {
            const processedTexts = finalTextItems.map(item => ({
                type: 'text',
                sectionTitle: item.sectionTitle,
                primaryText: item.primaryText,
                secondaryText: item.secondaryText,
                link: item.link || ''
            }));

            console.log("Processed texts being sent:", processedTexts);
            console.log("Existing content:", pageData.content);

            const updateData = {
                token,
                payload: {
                    ...pageData,
                    page: pageData.page,
                    type: 'custom',
                    headeractive: pageData.headeractive ?? true,
                    background: pageData.background ?? '1A1A25',
                    accent: pageData.accent ?? 'FFFFFF',
                    content: [
                        ...(pageData.content || []).filter(item => item.type !== 'text'),
                        ...processedTexts
                    ],
                    headerImage: pageData.headerImage,
                    headerImageUrl: pageData.headerImageUrl,
                    originalPage: location.state?.pageData?.page || ''
                }
            };

            console.log("Final update data:", updateData);

            const result = await dispatch(updateCustomForFocusThunk(updateData)).unwrap();
            
            if (result.status === 200) {
                await dispatch(getDataForFocusThunk({ token, pageId }));
                dispatch(clearFocusSection());
                navigate('/focus-page/custom-page');
            } else {
                throw new Error(result.message || 'Update failed');
            }
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(setErrorAlert(error?.message || 'Failed to update texts'));
            console.error('Error updating texts:', error);
        }
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className="text-white">Edit content</p>
                    <div className='flex gap-[20px]'>
                        {isFormComplete && (
                            <button 
                                onClick={handleAddAnother}
                                className="text-white cursor-pointer"
                            >
                                Another
                            </button>
                        )}
                        <button 
                            onClick={handleDone} 
                            className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50' : ''}`}
                        >
                            Done
                        </button>
                        <button 
                            onClick={() => navigate(-1)} 
                            className='text-white cursor-pointer'
                        >
                            Go back
                        </button>
                    </div>
                </div>

                {/* Content Area */}
                <div className="w-full px-4 overflow-y-auto pb-[100px]">
                    {/* Input Fields */}
                    <div className="mb-8">
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter section title"
                                onChange={(e) => setCurrentText({ ...currentText, sectionTitle: e.target.value })}
                                value={currentText.sectionTitle}
                                maxLength={30}
                            />
                            {currentText.sectionTitle && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter main text"
                                onChange={(e) => setCurrentText({ ...currentText, secondaryText: e.target.value })}
                                value={currentText.secondaryText}
                                multiline
                                maxLength={200}
                            />
                            {currentText.secondaryText && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter optional text"
                                onChange={(e) => setCurrentText({ ...currentText, optionalText: e.target.value })}
                                value={currentText.optionalText}
                                multiline
                                maxLength={100}
                            />
                        </div>

                        <div className="flex items-center">
                            <DizeeInput
                                placeholder="Enter link (optional)"
                                onChange={handleLinkChange}
                                value={currentText.link}
                            />
                            {isValidUrl(currentText.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>
                    </div>

                    {/* Added Texts List */}
                    {textItems.length > 0 && (
                        <div className="flex flex-col w-full mt-[40px]">
                            <div className='pb-[20px] flex w-full items-center'>
                                <div className='flex flex-row text-white w-full'>
                                    <p>Added Texts</p>
                                </div>
                            </div>
                            {textItems.map((item, index) => (
                                <AddedCustom
                                    key={index}
                                    item={item}
                                    setOverlayVisible={setIsOverlayVisible}
                                    setItemForBackend={(item) => handleTextClick(item, index)}
                                />
                            ))}
                        </div>
                    )}
                </div>

                {/* Footer */}
                <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Edit your text content.</p>
                            <p>Main text is required. Optional text and link are optional.</p>
                        </div>
                    </div>
                </div>

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedText}
                        handleEdit={handleEditText}
                        handleDelete={handleDeleteText}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                        canMoveUp={selectedIndex > 0}
                        canMoveDown={selectedIndex < textItems.length - 1}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 