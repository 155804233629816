import React from 'react';
import { postDataAPI } from '../utils/fetchData';
import { useParams } from 'react-router-dom';

const CusFocusText = ({ item, userArticle }) => {
    const { domain: dynamicVar } = useParams();
    const accentColor = userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF';

    const handleClickText = async () => {
        if (!item?.link) return;
        
        let url = item.link;
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
        }
        window.open(url, '_blank');

        // Add analytics
        try {
            await postDataAPI('analytics/create-text-analytics', {
                link: url,
                domain: dynamicVar
            }, null);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="w-full flex-shrink-0">
            <div 
                className="w-full flex flex-col gap-y-2 cursor-pointer"
                onClick={handleClickText}
            >
                {/* Main Text */}
                <div className="text-white text-[10px]">
                    {item.primaryText}
                </div>

                {/* Optional Text */}
                {item.secondaryText && (
                    <div className="text-[rgba(255,255,255,0.5)] text-[10px]">
                        {item.secondaryText}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CusFocusText; 