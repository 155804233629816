import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { DizeeInput2, DizeeNumberInput } from '../../../../../components/DizeeComponent/DixeeInput2';
import CopyLink from '../../../../../svg/CopyLink';
import ImageSelectionCard from '../../../../../components/ImageSelectionCard';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from '../../../../../utils/upload';
import { clearAlerts, setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { addProductToSectionThunk, getProductThunk } from '../../../../../store/addsection/addsectionThunk';
import AddedProductCard from '../../../../../components/AddSection/Product/AddedProductCard';
import ProductOverlay from '../../../../../components/AddSection/Product/ProductOverlay';
import FocusAddedProduct from '../../../../../components/Focuspage/Type/Product/FocusAddedProduct';
import { clearFocusSection, setFocusNewsletter, setFocusProduct } from '../../../../../store/focuspage/focuspageSlice';
import { addNewsletterForFocusThunk, addProductForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import FocusAddedNewsletter from '../../../../../components/Focuspage/Type/Newsletter/FocusAddedNewsletter';
import FocusNewsOverlay from '../../../../../components/Focuspage/Type/Newsletter/FocusNewsOverlay';
import DizeeInput from "../../../../../components/DizeeComponent/DizeeInput";
import Check from '../../../../../svg/Check';

export default function Step6() {
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();
    const newsletter = useSelector((state) => state.focuspage?.newsletter);

    // Required newsletter content states
    const [newsletterTitle, setNewsletterTitle] = useState('');
    const [newsletterContent, setNewsletterContent] = useState('');

    // Optional description section states
    const [showDescription, setShowDescription] = useState(false);
    const [descriptionTitle, setDescriptionTitle] = useState('');
    const [descriptionImage, setDescriptionImage] = useState(null);
    const [descriptionImagePreview, setDescriptionImagePreview] = useState(null);
    const [descriptionText, setDescriptionText] = useState('');
    const [descriptionLink, setDescriptionLink] = useState('');

    useEffect(() => {
        dispatch(clearAlerts())
    }, [dispatch, token])

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setDescriptionImage(file);
            setDescriptionImagePreview(URL.createObjectURL(file));
        }
    };

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const handleLinkChange = (e, setDescriptionLink) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setDescriptionLink(value);
    };

    // Add this function to check if form is valid
    const isFormValid = () => {
        if (!newsletterTitle || !newsletterContent) {
            return false;
        }
        
        if (showDescription) {
            if (!descriptionTitle || !descriptionText) {
                return false;
            }
        }
        
        return true;
    };

    const handleAddNewsletterFocus = async () => {
        dispatch(clearAlerts());

        if (!isFormValid()) {
            dispatch(setErrorAlert('Please fill in all required fields'));
            return;
        }

        dispatch(setLoader(true));
        try {
            const dataCopy = {
                type: 'single',
                extension: newsletter?.extension,
                page: newsletter?.page,
                background: newsletter?.background,
                accent: newsletter?.accent,
                newsletter: [{
                    title: newsletterTitle,
                    content: newsletterContent
                }]
            };

            // Add header data from Step5 if it exists
            if (newsletter?.headeractive) {
                dataCopy.avatar = newsletter.avatar;
                dataCopy.headeractive = true;
                dataCopy.headerTitle = newsletter.headerTitle;
                dataCopy.headerSubtitle = newsletter.headerSubtitle;
            }

            // Handle description section if enabled
            if (showDescription) {
                let descriptionImageUrl = '';
                if (descriptionImage) {
                    descriptionImageUrl = await uploadImage(descriptionImage);
                    if (!descriptionImageUrl) {
                        dispatch(setErrorAlert('Description image cannot contain nudity, violence or drugs'));
                        return;
                    }
                }

                dataCopy.description = {
                    title: descriptionTitle,
                    text: descriptionText,
                    ...(descriptionImageUrl && { image: descriptionImageUrl }),
                    ...(descriptionLink && { link: descriptionLink })
                };
            }

            dispatch(addNewsletterForFocusThunk({ token, payload: dataCopy })).then((res) => {
                if (res.payload.status === 200) {
                    dispatch(clearFocusSection());
                    navigate("/focus-page");
                }
            });
        } catch (error) {
            console.error('Error:', error);
            dispatch(setErrorAlert('An error occurred'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Newsletter content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        <button 
                            onClick={handleAddNewsletterFocus} 
                            className={`text-white cursor-pointer ${!isFormValid() ? 'opacity-50 pointer-events-none' : ''}`}
                        >
                            Done
                        </button>
                        <p onClick={() => navigate('/focus-page/newsletter-type/step5')} 
                            className='text-white cursor-pointer'>
                            Go back
                        </p>
                    </div>
                </div>

                <div className="w-full px-4 overflow-y-auto pb-[100px]">
                    {/* Optional Description Section */}
                    <div className="mb-8">
                        <button 
                            onClick={() => setShowDescription(!showDescription)}
                            className="text-white flex items-center gap-x-2"
                        >
                            Add description
                            {showDescription && <Check className="h-[12px] w-[18px]" />}
                        </button>

                        {showDescription && (
                            <div className="mt-6">
                                <DizeeInput
                                    placeholder="Add description title"
                                    value={descriptionTitle}
                                    onChange={(e) => setDescriptionTitle(e.target.value)}
                                    className="w-full mb-6"
                                />

                                <div className="mb-6">
                                    {!descriptionImagePreview ? (
                                        <div
                                            className="text-white cursor-pointer mb-2"
                                            onClick={() => document.getElementById("descImage").click()}
                                        >
                                            Add image (optional)
                                        </div>
                                    ) : (
                                        <div
                                            className="text-white cursor-pointer mb-2"
                                            onClick={() => {
                                                setDescriptionImage(null);
                                                setDescriptionImagePreview(null);
                                            }}
                                        >
                                            Remove image
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        id="descImage"
                                        onChange={handleImageChange}
                                    />
                                    {descriptionImagePreview && (
                                        <img
                                            src={descriptionImagePreview}
                                            alt="Description"
                                            className="h-[80px] w-[80px] rounded-md"
                                        />
                                    )}
                                </div>

                                <textarea
                                    placeholder="Add description text"
                                    value={descriptionText}
                                    onChange={(e) => setDescriptionText(e.target.value)}
                                    className="w-full bg-transparent text-white placeholder-[rgba(255,255,255,0.5)] px-0 py-3 min-h-[70px] resize-y outline-none mb-6"
                                />

                                <div className="mt-6">
                                    <div className="flex items-center">
                                        <DizeeInput
                                            placeholder="Add link (optional)"
                                            value={descriptionLink}
                                            onChange={(e) => handleLinkChange(e, setDescriptionLink)}
                                            className="w-full"
                                        />
                                        {isValidUrl(descriptionLink) && <Check className="h-[12px] w-[18px] ml-2" />}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Required Newsletter Content */}
                    <div className="mt-6">
                        <DizeeInput
                            placeholder="Add newsletter title"
                            value={newsletterTitle}
                            onChange={(e) => setNewsletterTitle(e.target.value)}
                            className="w-full mb-6"
                        />
                        <textarea
                            placeholder="Add newsletter content (max 1500 characters)"
                            value={newsletterContent}
                            onChange={(e) => {
                                if (e.target.value.length <= 1500) {
                                    setNewsletterContent(e.target.value);
                                }
                            }}
                            className="w-full bg-transparent text-white placeholder-[rgba(255,255,255,0.5)] px-0 py-3 min-h-[150px] resize-y outline-none"
                        />
                    </div>
                </div>

                <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Create your newsletter content.</p>
                            <p>Optional: Add a description section to provide more context.</p>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
}
