import React from 'react';
import More from '../../../../svg/More';
import { maxLength } from '../../../DizeeComponent/DixeeInput2';

export default function AddedEventComp({ item, setOverlayVisible, setItemForBackend }) {
    return (
        <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center -ml-4'>
            <div className='flex flex-col items-start justify-start'>
                <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
                    {maxLength(item?.link, 50)}
                </p>
                <p className='text-[white]' style={{ fontSize: '12px' }}>
                    {maxLength(item?.location, 50)}
                </p>
            </div>
            <More
                className='h-[20px] w-[20px] cursor-pointer ml-7 text-white'
                onClick={() => {
                    setItemForBackend(item);
                    setOverlayVisible(true);
                }}
            />
        </div>
    );
} 