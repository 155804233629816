import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearAlerts, setLoader, setErrorAlert, setSuccessAlert } from "../../../store/alert/alertSlice";
import { postDataAPI } from "../../../utils/fetchData";
import { DizeeInput2 } from "../../../components/DizeeComponent/DixeeInput2";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import { NextButton } from "../../../components/DizeeComponent/NextButton";
import styled from "styled-components";
import Check from "../../../svg/Check";
import Spotify from "../../../svg/Social/Spotify";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const ResultsContainer = styled.div`
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ResultItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
  color: white;
`;

const LoadingSpinner = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 8px;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Add this styled component for consistent text sizing
const DizeeText = styled.span`
  font-size: 12px;
  color: white;
`;

const Step4AI = ({ setStep, setUser, user }) => {
  const [spotifyLink, setSpotifyLink] = useState("");
  const [isValidLink, setIsValidLink] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [discoveredLinks, setDiscoveredLinks] = useState(null);
  const [linksFound, setLinksFound] = useState(0);
  const [artistName, setArtistName] = useState("");
  const dispatch = useDispatch();

  // Control page scroll
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  // Validate Spotify link
  const validateSpotifyLink = (link) => {
    if (!link) return false;
    try {
      const url = new URL(link);
      return url.hostname.includes('spotify.com');
    } catch {
      return false;
    }
  };

  // Handle link input change
  const handleLinkChange = (e) => {
    const value = e.target.value;
    // Format with https:// if not present
    const formattedValue = value.startsWith('http') ? value : `https://${value}`;
    setSpotifyLink(formattedValue);
    setIsValidLink(validateSpotifyLink(formattedValue));
  };

  // Process with AI to discover links
  const discoverLinks = async () => {
    if (!isValidLink) return;
    
    setIsProcessing(true);
    dispatch(clearAlerts());
    dispatch(setLoader(true));
    
    try {
      console.log("Sending request to discover links for:", spotifyLink);
      const response = await postDataAPI("user/discoverLinksWithAI", { spotifyUrl: spotifyLink });
      
      console.log("Received response:", response.data);
      
      if (response.data?.status === 200) {
        const links = response.data?.data?.response;
        console.log("Discovered links:", links);
        
        // Save all links to state
        setDiscoveredLinks({
          spotify: links?.spotify || "",
          instagram: links?.instagram || "",
          tiktok: links?.tiktok || "",
          twitter: links?.twitter || "",
          facebook: links?.facebook || "",
          youtube: links?.youtube || "",
          soundcloud: links?.soundcloud || "",
          appleMusic: links?.appleMusic || "",
          topSongs: links?.topSongs || [],
          topVideo: links?.topVideo || "",
          events: links?.events || []
        });
        
        // Calculate number of links found
        const validLinkCount = Object.entries(links)
          .filter(([key, value]) => 
            key !== 'artistName' && 
            key !== 'topSongs' && 
            key !== 'events' && 
            key !== 'profileImage' &&
            value && 
            (typeof value === 'string' ? value.trim() !== '' : value.length > 0)
          ).length;
          
        setLinksFound(validLinkCount);
        setArtistName(links?.artistName || "your artist");
        
        // Update user data with discovered links
        setUser(prevUser => ({
          ...prevUser,
          name: prevUser.name || links?.artistName,
          avatar: links?.profileImage || prevUser.avatar,
          topSongs: links?.topSongs || [],
          social: {
            ...prevUser?.social,
            sf: links?.spotify || prevUser?.social?.sf || "",
            ig: links?.instagram || prevUser?.social?.ig || "",
            tt: links?.tiktok || prevUser?.social?.tt || "",
            x: links?.twitter || prevUser?.social?.x || "",
            fb: links?.facebook || prevUser?.social?.fb || "",
            yt: links?.youtube || prevUser?.social?.yt || "",
            sc: links?.soundcloud || prevUser?.social?.sc || "",
            am: links?.appleMusic || prevUser?.social?.am || "",
          },
        }));
        
        // Show success message
        dispatch(setSuccessAlert(`Found links for ${links?.artistName || "your artist"}`));
      } else {
        dispatch(setErrorAlert(response.data?.message || "Couldn't find related links. Please try a different Spotify URL."));
      }
    } catch (error) {
      console.error("Error discovering links:", error);
      dispatch(setErrorAlert(error?.response?.data?.message || "Error connecting to service. Please try again."));
    } finally {
      setIsProcessing(false);
      dispatch(setLoader(false));
    }
  };

  const getFooterText = () => {
    if (!spotifyLink) {
      return "Enter your Spotify artist link to automatically discover and import your social media profiles and content.";
    }
    
    if (!isValidLink) {
      return "Please enter a valid Spotify artist link (e.g., https://open.spotify.com/artist/...)";
    }
    
    if (isProcessing) {
      return "Our AI is finding all your connected profiles and content...";
    }
    
    if (discoveredLinks) {
      return "We've found these links connected to your Spotify profile. You can edit them in the next steps.";
    }
    
    return "Click 'Discover' to find your connected profiles, or skip to enter them manually.";
  };

  const getPlatformLabel = (platform) => {
    const labels = {
      spotify: "Spotify",
      instagram: "Instagram",
      tiktok: "TikTok",
      twitter: "Twitter/X",
      facebook: "Facebook", 
      youtube: "YouTube",
      soundcloud: "SoundCloud",
      appleMusic: "Apple Music",
      topVideo: "Top Video"
    };
    
    return labels[platform] || platform;
  };

  return (
    <Container>
      <FormSection>
        <TypeWriter text={"Let's find your online presence"} speed={45} />
        <div style={{ marginTop: '2px' }}>
          <div className="flex items-center gap-x-[10px] mb-4">
            <Spotify className="h-[15px] w-[15px]" />
            <DizeeInput2
              placeholder={"Enter your Spotify artist link"}
              value={spotifyLink}
              onChange={handleLinkChange}
              className={"dizee-input w-[200px]"}
            />
          </div>
          
          {/* Left-justified Discover button */}
          {isValidLink && !isProcessing && !discoveredLinks && (
            <div className="mt-2">
              <NextButton 
                onClick={discoverLinks}
                className="cursor-pointer"
              >
                Discover
              </NextButton>
            </div>
          )}
        </div>

        {/* Left-justified loading state with consistent font size */}
        {isProcessing && (
          <div className="flex flex-col mt-6 mb-4">
            <div className="flex items-center">
              <LoadingSpinner className="w-[16px] h-[16px]" />
              <DizeeText className="ml-2">Discovering your links...</DizeeText>
            </div>
            <DizeeText style={{ fontSize: '10px', opacity: 0.7 }} className="mt-2">
              This may take a moment
            </DizeeText>
          </div>
        )}

        {/* Left-justified success message with consistent font size */}
        {discoveredLinks && !isProcessing && (
          <div className="flex flex-col mt-6 mb-4">
            <div className="mt-2">
              <DizeeText>
                Found <span className="font-bold">{linksFound}</span> links for {artistName}
              </DizeeText>
              {discoveredLinks.topSongs && discoveredLinks.topSongs.length > 0 && (
                <DizeeText style={{ fontSize: '10px', opacity: 0.7 }} className="block mt-1">
                  Including {discoveredLinks.topSongs.length} top songs
                </DizeeText>
              )}
            </div>
          </div>
        )}

        <ButtonContainer>
          <NextButton
            className="cursor-pointer mr-[22px]"
            onClick={() => setStep(7)}
          >
            {discoveredLinks ? "Next" : "Skip"}
          </NextButton>
          <NextButton 
            className="cursor-pointer" 
            onClick={() => setStep(4)}
          >
            Go back
          </NextButton>
        </ButtonContainer>
      </FormSection>
      
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          {getFooterText()}
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step4AI; 