import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import LayoutHeader from '../../../../layout/LayoutHeader';
import CopyLink from '../../../../svg/CopyLink';
import { DizeeInput2 } from '../../../../components/DizeeComponent/DixeeInput2';
import { postDataAPI } from '../../../../utils/fetchData';
import { ClipLoader } from 'react-spinners';
import { clearAlerts, setErrorAlert } from '../../../../store/alert/alertSlice';
import { getVideoThunk } from '../../../../store/addsection/addsectionThunk';
import { useDispatch, useSelector } from 'react-redux';
import Youtube from '../../../../svg/SocialFeedPlatform/Youtube';
import Vimeo from '../../../../svg/SocialFeedPlatform/Vimeo';
import VideoOverlay from '../../../../components/AddSection/Video/VideoOverlay';
import AddedVideoCard from '../../../../components/AddSection/Video/AddedVideoCard';
import ImportAddVideo from '../../../../components/AddSection/Video/ImportAddVideo';

const componentMapping = {
    youtube: <Youtube />,
    vimeo: <Vimeo />
};

export default function AddLinkVideo() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { iconKey = 'youtube', name = 'YouTube' } = location.state || {};
    const IconComponent = componentMapping[iconKey];
    const [link, setLink] = useState('');
    const [debouncedLink, setDebouncedLink] = useState('');
    const [data, setData] = useState({});
    const token = localStorage.getItem('dizeeToken');
    const [loading, setLoading] = useState(false);
    const video = useSelector((state) => state.addsection.video);

    const [isExist, setIsExist] = useState(false);
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [avatarForBackend, setAvatarForBackend] = useState('');

    useEffect(() => {
        dispatch(clearAlerts());
        dispatch(getVideoThunk({ token }));
    }, [dispatch, token]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedLink(link);
        }, 500); // Adjust the delay as needed

        return () => {
            clearTimeout(handler);
        };
    }, [link]);

    // useEffect(() => {
    //     if (debouncedLink.length < 6) {
    //         setData({});
    //         setIsExist(false);
    //         return;
    //     }

    //     const fetchVideoData = async () => {
    //         setLoading(true);
    //         try {
    //             dispatch(clearAlerts());
    //             const response = await postDataAPI('user/getVideoUrlMetadata', { url: debouncedLink, type: name }, token);
    //             if (response.data.data.response.title === 'Page not available') {
    //                 setData({});
    //                 setIsExist(false);
    //                 dispatch(setErrorAlert("Please enter a valid link"));
    //             } else {
    //                 setData(response.data.data.response);
    //                 setIsExist(filterLink(response.data.data.response?.url));
    //             }
    //         } catch (err) {
    //             dispatch(setErrorAlert("Please enter a valid link"));
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchVideoData();
    // }, [debouncedLink, dispatch, token]);

    const convertYouTubeUrl = (url) => {
        if (!url) return { url: '', videoId: null };
        
        try {
            let videoId;
            const cleanUrl = url.trim();
            
            // Extract video ID from various YouTube URL formats
            if (cleanUrl.match(/youtu\.be\/([^/?&]+)/)) {
                videoId = cleanUrl.match(/youtu\.be\/([^/?&]+)/)[1];
            } else if (cleanUrl.match(/youtube\.com\/shorts\/([^/?&]+)/)) {
                videoId = cleanUrl.match(/youtube\.com\/shorts\/([^/?&]+)/)[1];
            } else if (cleanUrl.match(/youtube\.com\/embed\/([^/?&]+)/)) {
                videoId = cleanUrl.match(/youtube\.com\/embed\/([^/?&]+)/)[1];
            } else if (cleanUrl.match(/youtube\.com\/watch\?v=([^/?&]+)/)) {
                videoId = cleanUrl.match(/youtube\.com\/watch\?v=([^/?&]+)/)[1];
            } else if (cleanUrl.match(/youtube\.com\/v\/([^/?&]+)/)) {
                videoId = cleanUrl.match(/youtube\.com\/v\/([^/?&]+)/)[1];
            } else {
                console.log("Could not identify YouTube video ID format:", cleanUrl);
                return { url: cleanUrl, videoId: null };
            }
            
            console.log("Extracted YouTube video ID:", videoId);
            return { 
                url: `https://www.youtube.com/watch?v=${videoId}`,
                videoId
            };
        } catch (error) {
            console.error("Error processing YouTube URL:", error, url);
            return { url, videoId: null };
        }
    };

    const videoSearch = async () => {
        if (debouncedLink.length < 6) {
            setData({});
            setIsExist(false);
            return;
        }

        setLoading(true);

        try {
            dispatch(clearAlerts());
            // Extract video ID first
            const { url: convertedUrl, videoId } = convertYouTubeUrl(debouncedLink);
            console.log("Extracted YouTube video ID:", videoId);
            
            // If we have a valid YouTube video ID, we can bypass API issues
            if (videoId && name.toLowerCase() === 'youtube') {
                console.log("Using direct YouTube data construction");
                
                // Create YouTube metadata directly without relying on API
                const directYouTubeData = {
                    title: `YouTube Video (${videoId})`,
                    description: 'YouTube video',
                    image: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
                    url: convertedUrl,
                    provider: 'YouTube',
                    youtubeVideoId: videoId
                };
                
                // Simple attempt to get a better title
                try {
                    fetch(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`)
                        .then(res => res.json())
                        .then(data => {
                            if (data && data.title) {
                                console.log("Got better title:", data.title);
                                // Just update the title part of our existing data
                                setData(prevData => ({
                                    ...prevData,
                                    title: data.title
                                }));
                            }
                        });
                } catch (titleError) {
                    // Silently ignore any errors in the title fetch
                    console.log("Title fetch failed, using default title");
                }
                
                // Set initial data first, will be updated if title fetch succeeds
                setData(directYouTubeData);
                setIsExist(filterLink(convertedUrl));
                setLoading(false);
                return;
            }
            
            // For non-YouTube or if no videoId, use the API approach
            const response = await postDataAPI('user/getVideoUrlMetadata', { url: convertedUrl, type: name }, token);
            console.log("API response:", response.data);

            if (response.data.data.response.title === 'Page not available') {
                setData({});
                setIsExist(false);
                dispatch(setErrorAlert("Please enter a valid link"));
            } else {
                setData(response.data.data.response);
                setIsExist(filterLink(response.data.data.response?.url));
            }
        } catch (err) {
            console.error("Video metadata fetch error:", err);
            dispatch(setErrorAlert("Please enter a valid link"));
        } finally {
            setLoading(false);
        }
    };

    const filterLink = (link) => {
        if (video?.length > 0) {
            for (let m of video) {
                for (let vlink of m.links) {
                    if (vlink.url === link) {
                        setIsExist(true);
                        return true;
                    }
                }
            }
        }
        setIsExist(false);
        return false;
    };

    const handleVideoSubmissionSuccess = () => {
        setLink('');
        setData({});
        dispatch(getVideoThunk({ token }));
        setOverlayVisible(false);
        navigate('/add-section/add-video');
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-auto bg-black flex flex-col justify-start items-center relative">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        {IconComponent}
                        <span className='text-white' style={{ fontSize: '12px' }}>{name}</span>
                    </div>
                    <div className='flex gap-x-[15px]'>
                        <p className='text-white cursor-pointer' style={{ fontSize: '12px' }}
                            onClick={() => videoSearch()}>Next</p>
                        <Link to="/add-section">
                            <p className='text-white cursor-pointer' style={{ fontSize: '12px' }}>Go back</p>
                        </Link>
                    </div>
                </div>

                <div className='flex w-full flex-col gap-y-[50px]'>
                    <div className="flex flex-col justify-center items-center w-full">
                        <div className='p-4 pb-[40px] flex w-full justify-between items-center'>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Enter a link"
                                    className="dizee-input w-full"
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                />
                            </div>
                            <CopyLink className='h-[14px] w-[14px] mx-1' />
                        </div>
                        {
                            loading ? <ClipLoader
                                color="white"
                                loading={true}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            /> :
                                Object.keys(data).length > 0 && (
                                    <ImportAddVideo 
                                        key={0} 
                                        title={data.title || `YouTube Video (${data.youtubeVideoId || ''})`} 
                                        avatar={
                                            data.image || 
                                            (data.youtubeVideoId ? 
                                                `https://img.youtube.com/vi/${data.youtubeVideoId}/maxresdefault.jpg` : 
                                                null)
                                        } 
                                        artists={data.description || 'YouTube video'} 
                                        data={{
                                            ...data,
                                            // Ensure these critical fields are set for ImportAddVideo
                                            title: data.title || `YouTube Video (${data.youtubeVideoId || ''})`,
                                            image: data.image || (data.youtubeVideoId ? 
                                                `https://img.youtube.com/vi/${data.youtubeVideoId}/maxresdefault.jpg` : 
                                                null)
                                        }} 
                                        isExist={isExist} 
                                        setLink={setLink} 
                                        name={data.provider || 'YouTube'} 
                                        setData={setData} 
                                    />
                                )
                        }
                    </div>

                    {
                        video?.length > 0 && <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>Added Video</p>
                                </div>
                            </div>
                            {
                                video?.length > 0 && video?.map((item, index) => <AddedVideoCard key={index} video={item} setAvatarForBackend={setAvatarForBackend} setOverlayVisible={setOverlayVisible} />)
                            }
                        </div>
                    }
                </div>
            </div>
            {isOverlayVisible && <VideoOverlay isOverlayVisible={isOverlayVisible} setOverlayVisible={setOverlayVisible} avatarForBackend={avatarForBackend} onSubmissionSuccess={handleVideoSubmissionSuccess} />}
        </LayoutHeader>
    );
}
