import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { updateCustomForFocusThunk, getDataForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { uploadImage } from '../../../../../utils/upload';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';
import Check from '../../../../../svg/Check';

export default function EditProductContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    // Get data from navigation state
    const pageData = location.state?.pageData;
    const pageId = location.state?.pageId;

    // Redirect if no pageData
    useEffect(() => {
        if (!pageData || !pageId) {
            navigate('/focus-page/custom-page');
        }
    }, [pageData, pageId, navigate]);

    // Initialize state with existing product content
    const [productItems, setProductItems] = useState(
        location.state?.productContent?.map(item => ({
            ...item,
            preview: item.avatar,
            secondaryText: item.description,
            image: null
        })) || []
    );

    const [currentProduct, setCurrentProduct] = useState({
        sectionTitle: location.state?.sectionTitle || '',
        secondaryText: '',
        link: '',
        image: null,
        preview: null,
        type: 'product'
    });

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Form validation flags
    const isFormComplete = currentProduct.preview && currentProduct.sectionTitle.trim() !== '';

    const isDoneValid = () => {
        return productItems.length > 0 || isFormComplete;
    };

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Handle link change
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentProduct({ ...currentProduct, link: value });
    };

    // Handle image upload
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                dispatch(setErrorAlert('Image size should be less than 5MB'));
                return;
            }

            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            if (!validTypes.includes(file.type)) {
                dispatch(setErrorAlert('Please upload a valid image file (JPEG, PNG, GIF, or WEBP)'));
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentProduct({
                    ...currentProduct,
                    image: file,
                    preview: reader.result
                });
            };
            reader.readAsDataURL(file);
        }
        e.target.value = '';
    };

    // Handle product click for editing
    const handleProductClick = (item, index) => {
        setSelectedProduct({
            ...item,
            type: 'product'
        });
        setSelectedIndex(index);
        setCurrentProduct({
            sectionTitle: item.sectionTitle,
            secondaryText: item.description || item.secondaryText || '',
            link: item.link || '',
            preview: item.avatar,
            type: 'product'
        });
        setIsOverlayVisible(true);
    };

    // Handle edit
    const handleEditProduct = () => {
        if (selectedProduct && selectedIndex !== null) {
            setCurrentProduct({
                sectionTitle: selectedProduct.sectionTitle,
                secondaryText: selectedProduct.description || '',
                link: selectedProduct.link || '',
                preview: selectedProduct.avatar,
                type: 'product'
            });
            setIsOverlayVisible(false);
        }
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in all required fields'));
            return;
        }

        if (selectedIndex !== null) {
            const updatedItems = [...productItems];
            updatedItems[selectedIndex] = {
                type: 'product',
                sectionTitle: currentProduct.sectionTitle,
                description: currentProduct.secondaryText,
                link: currentProduct.link,
                avatar: currentProduct.preview,
                preview: currentProduct.preview,
                image: currentProduct.image
            };
            setProductItems(updatedItems);
            setSelectedIndex(null);
        } else {
            setProductItems([...productItems, {
                type: 'product',
                sectionTitle: currentProduct.sectionTitle,
                description: currentProduct.secondaryText,
                link: currentProduct.link,
                avatar: currentProduct.preview,
                preview: currentProduct.preview,
                image: currentProduct.image
            }]);
        }

        setCurrentProduct({
            sectionTitle: currentProduct.sectionTitle,
            secondaryText: '',
            link: '',
            image: null,
            preview: null,
            type: 'product'
        });
    };

    // Handle delete
    const handleDeleteProduct = () => {
        const filteredItems = productItems.filter((_, index) => index !== selectedIndex);
        setProductItems(filteredItems);
        setIsOverlayVisible(false);
    };

    // Handle move up/down
    const handleMoveUp = () => {
        if (selectedIndex > 0) {
            const updatedItems = [...productItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex - 1]] = 
            [updatedItems[selectedIndex - 1], updatedItems[selectedIndex]];
            setProductItems(updatedItems);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (selectedIndex < productItems.length - 1) {
            const updatedItems = [...productItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex + 1]] = 
            [updatedItems[selectedIndex + 1], updatedItems[selectedIndex]];
            setProductItems(updatedItems);
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle done
    const handleDone = async () => {
        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one product'));
            return;
        }

        dispatch(setLoader(true));

        try {
            let finalProductItems = [...productItems];
            
            if (selectedIndex !== null && isFormComplete) {
                finalProductItems[selectedIndex] = {
                    type: 'product',
                    sectionTitle: currentProduct.sectionTitle,
                    description: currentProduct.secondaryText,
                    link: currentProduct.link,
                    avatar: currentProduct.preview,
                    preview: currentProduct.preview,
                    image: currentProduct.image
                };
            } else if (isFormComplete) {
                finalProductItems.push({
                    type: 'product',
                    sectionTitle: currentProduct.sectionTitle,
                    description: currentProduct.secondaryText,
                    link: currentProduct.link,
                    avatar: currentProduct.preview,
                    preview: currentProduct.preview,
                    image: currentProduct.image
                });
            }

            const processedProducts = await Promise.all(finalProductItems.map(async (item) => {
                let avatarUrl = item.avatar;
                
                if (item.image) {
                    avatarUrl = await uploadImage(item.image);
                    if (!avatarUrl) {
                        throw new Error('Failed to upload image');
                    }
                }

                return {
                    type: 'product',
                    sectionTitle: currentProduct.sectionTitle,
                    description: item.description || '',
                    link: item.link || '',
                    avatar: avatarUrl
                };
            }));

            const updateData = {
                token,
                payload: {
                    ...pageData,
                    page: pageData.page,
                    type: 'custom',
                    headeractive: pageData.headeractive ?? true,
                    background: pageData.background ?? '1A1A25',
                    accent: pageData.accent ?? 'FFFFFF',
                    content: [
                        ...(pageData.content || []).filter(item => item.type !== 'product'),
                        ...processedProducts
                    ],
                    headerImage: pageData.headerImage,
                    headerImageUrl: pageData.headerImageUrl,
                    originalPage: location.state?.pageData?.page || ''
                }
            };

            const result = await dispatch(updateCustomForFocusThunk(updateData)).unwrap();
            
            if (result.status === 200) {
                await dispatch(getDataForFocusThunk({ token, pageId }));
                dispatch(clearFocusSection());
                navigate('/focus-page/custom-page');
            } else {
                throw new Error(result.message || 'Update failed');
            }
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(setErrorAlert(error?.message || 'Failed to update products'));
            console.error('Error updating products:', error);
        }
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Edit content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        {isFormComplete && (
                            <button 
                                onClick={handleAddAnother}
                                className="text-white cursor-pointer"
                            >
                                Another
                            </button>
                        )}
                        <button 
                            onClick={handleDone} 
                            className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}
                        >
                            Done
                        </button>
                        <p 
                            onClick={() => navigate(-1)} 
                            className="text-white cursor-pointer"
                        >
                            Go back
                        </p>
                    </div>
                </div>

                {/* Form Content */}
                <div className="w-full px-4">
                    {/* Section Title */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Enter section title (applies to all content)"
                            onChange={(e) => setCurrentProduct({ 
                                ...currentProduct, 
                                sectionTitle: e.target.value 
                            })}
                            value={currentProduct.sectionTitle}
                        />
                        {currentProduct.sectionTitle && <Check className="h-[12px] w-[18px] ml-2" />}
                    </div>

                    {/* Description */}
                    <div className="flex items-center mb-4">
                        <textarea
                            placeholder="Enter description"
                            value={currentProduct.secondaryText}
                            onChange={(e) => setCurrentProduct({ 
                                ...currentProduct, 
                                secondaryText: e.target.value 
                            })}
                            className="w-full bg-transparent text-white placeholder-[rgba(255,255,255,0.5)] px-0 py-3 min-h-[150px] resize-y outline-none text-[12px]"
                        />
                    </div>

                    {/* Link */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Enter link"
                            onChange={handleLinkChange}
                            value={currentProduct.link}
                        />
                        {isValidUrl(currentProduct.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                    </div>

                    {/* Image Upload */}
                    <div className="flex items-center mb-4">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="hidden"
                            id="productImage"
                        />
                        <label
                            htmlFor="productImage"
                            className="cursor-pointer text-white text-[12px] opacity-50 hover:opacity-100"
                        >
                            {currentProduct.preview ? 'Change Image' : 'Upload Image'}
                        </label>
                        {currentProduct.preview && (
                            <div className="ml-4">
                                <img
                                    src={currentProduct.preview}
                                    alt="Preview"
                                    className="h-[40px] w-[40px] object-cover rounded"
                                />
                            </div>
                        )}
                    </div>

                    {/* Added Products */}
                    {productItems.length > 0 && (
                        <div className="flex flex-col w-full mt-[40px]">
                            <div className='pb-[20px] flex w-full items-center'>
                                <div className='flex flex-row text-white w-full'>
                                    <p>Added Products</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                {productItems.map((item, index) => (
                                    <AddedCustom
                                        key={index}
                                        item={item}
                                        setOverlayVisible={setIsOverlayVisible}
                                        setItemForBackend={(item) => handleProductClick(item, index)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Footer */}
                <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Edit your product content.</p>
                            <p>Image is required. Add description and link to enhance the presentation.</p>
                        </div>
                    </div>
                </div>

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedProduct}
                        handleEdit={handleEditProduct}
                        handleDelete={handleDeleteProduct}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                        canMoveUp={selectedIndex > 0}
                        canMoveDown={selectedIndex < productItems.length - 1}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 