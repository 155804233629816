import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import LayoutHeader from '../../layout/LayoutHeader';
import Check from '../../svg/Check';
import { DizeeInput2 } from '../../components/DizeeComponent/DixeeInput2';
import { resetMusicFileByIdx, updateDescription, updateImage, updateMusicData, updateMusicFileByIdx, updateMusicPriceByIdx, updateMusicTitleByIdx, updateMusicTypeByIdx, updateTitle, updateVaultContentToDB } from '../../store/vaultData/vaultdataSlice';
import AddSongCard from '../../components/AddSongCard';
import Plus from '../../svg/Plus';
import ImageSelectionCard from '../../components/ImageSelectionCard';
import { uploadImage } from '../../utils/upload';
import { setErrorAlert, setLoader } from '../../store/alert/alertSlice';
import DizeeSelect from '../../components/DizeeComponent/DizeeSelect';
import { update_vault_data_thunk } from '../../store/vaultData/vaultdataThunk';
import { postDataAPI } from '../../utils/fetchData';

const StyleOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 12px;
  color: white;
`;

export default function AddVault() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem('dizeeToken');
  const userArticle = useSelector((state) => state.user.userArticle);

  // const title = useSelector((state) => state.vaultData.title);
  // const description = useSelector((state) => state.vaultData.description);
  // const musicData = useSelector((state) => {
  //   console.log(`state.vaultData.musicData::`, state.vaultData);
  //   if (state.vaultData.musicData)
  //     return state.vaultData.musicData
  //   else return []
  // });

  const vaultData = useSelector((state) => state.vaultData);
  useSelector((state) => {
    console.log(state.vaultData);
  });
  const [data, setData] = useState();

  console.log(`vaultData::`, vaultData);

  const [musicList, setMusicList] = useState([]);

  useEffect(() => {
    let tmp = {};
    tmp.title = vaultData.title ? vaultData.title : ''
    tmp.headerTitle = vaultData.headerTitle ? vaultData.headerTitle : ''
    tmp.description = vaultData.description ? vaultData.description : ''
    tmp.musicList = vaultData.musicList ? vaultData.musicList : []
    tmp.image = vaultData.image ? vaultData.image : []
    console.log(`tmp::`, tmp);
    setData(tmp);
  }, [])

  const handleAddMusic = () => {
    setData(data => ({
      ...data, // Copy previous state
      musicList: [
        ...data.musicList, // Copy previous music list
        {
          title: '',
          price: '',
          type: false,
          file: null
        }
      ]
    }));
  }

  const uploadProcess = async (files, key, list) => {
    console.log(`key::`, key)

    if (key >= files.length) {
      console.log(`list::`, list)
      return list;
    }
    try {
      if (typeof files[key].file == "string" || typeof files[key].file == "String") {
        list.push(files[key].file);
      } else {
        const formData = new FormData();
        formData.append('video', files[key].file);
        const videoResponse = await postDataAPI('aws/videoUpload', formData);
        const url = videoResponse?.data?.data?.videoUrl;
        console.log(`url::`, url);
        if (!url) url = '';
        list.push(url);
        // Handle the response data as needed
      }
    } catch (error) {
      list.push('');
      console.error('Error uploading video:', error);
    }
    return uploadProcess(files, key + 1, list);
  }

  const confirm = async () => {
    console.log(`confirm::`, data);
    let urls = await uploadProcess(data.musicList, 0, []);
    console.log(`urls::`, urls);
    let body = { ...data }
    let _musicList = [];
    body.musicList.forEach((music, key) => {
      _musicList.push({
        ...music,
        file: urls[key]
      })
    })
    body.musicList = [..._musicList];
    // setData(_data);

    dispatch(update_vault_data_thunk({ token, body }))
    navigate(`/vault/${userArticle.username}`)
  }

  return (
    <LayoutHeader>
      <div className="flex justify-center w-full">
        <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
          <div className="px-4 my-[50px] flex w-full justify-between">
            <p className="text-white">Add Vault Content</p>
            <div className="flex gap-[30px]">
              <button
                onClick={confirm}
                // disabled={!selectedStyle}
                className={`text-white cursor-pointer`}
              >
                Confirm
              </button>
              <div
                className="text-white cursor-pointer"
                onClick={() => { navigate(`/vault/${userArticle.username}`) }}
              >
                Go back
              </div>
            </div>
          </div>

          <div className="w-full px-4">
            <StyleOptions>
              <div className='flex w-full flex-col gap-y-[50px]'>
                <div className="flex flex-col justify-center items-center w-full">

                  <div className="w-full p-4 mt-4" onClick={handleAddMusic}>
                    <span className="text-white cursor-pointer flex flex-row items-center">Add Music
                      <Plus className="h-[12px] w-[12px] ml-[12px]" />
                    </span>
                  </div>
                  {
                    console.log(`data length-->`, data?.musicList?.length)
                  }

                  {
                    data && data.musicList.length > 0 && data.musicList.map((music, key) => (
                      <div className="flex flex-col w-full">
                        <div key={key} className='p-4 flex flex-col w-full gap-y-[10px]'>
                          <div className='px-4 py-3 flex gap-x-8'>
                            <DizeeInput2
                              label="Link"
                              placeholder={`Music Title`}
                              className="dizee-input w-full"
                              value={music.title}
                              onChange={(e) => {
                                let _data = { ...data };
                                _data.musicList[key] = {
                                  ..._data.musicList[key],
                                  title: e.target.value
                                }
                                setData(_data);
                              }}
                            />
                            <div className='py-4 flex w-full'>
                              {
                                music.file ?
                                  (
                                    <div className='flex items-center'>
                                      <button
                                        onClick={(e) => {
                                          let _data = { ...data };
                                          _data.musicList[key] = {
                                            ..._data.musicList[key],
                                            file: null
                                          }
                                          setData(_data);
                                        }}
                                        // onClick={(e) => removeFile(key)}
                                        className='text-white'
                                      >
                                        Remove File
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      <input
                                        type="file"
                                        accept="audio/mp3"
                                        style={{ display: 'none' }}
                                        id={`image-upload-${key}`}
                                        onChange={(e) => {
                                          let _data = { ...data };
                                          _data.musicList[key] = {
                                            ..._data.musicList[key],
                                            file: e.target.files[0]
                                          }
                                          setData(_data);
                                        }}
                                      // onChange={(e) => { handleFile(e, key); }}
                                      />
                                      <label htmlFor={`image-upload-${key}`}>
                                        {/* <Plus className='h-[12px] w-[12px] cursor-pointer' /> */}
                                        <div>Upload File</div>
                                      </label>
                                    </>
                                  )}
                            </div>
                          </div>
                          <div className='flex'>
                            <DizeeSelect selected={music.type} txt={'Download'}
                              onSelect={() => {
                                let _data = { ...data };
                                _data.musicList[key] = {
                                  ..._data.musicList[key],
                                  type: true
                                }
                                setData(_data);
                              }}
                              checkmark={true} />
                            <DizeeSelect selected={!music.type} txt={'Streaming'}
                              onSelect={() => {
                                let _data = { ...data };
                                _data.musicList[key] = {
                                  ..._data.musicList[key],
                                  type: false
                                }
                                setData(_data);
                              }}
                              checkmark={true} />
                          </div>
                          <div className="flex p-4 gap-x-8">
                            <DizeeInput2
                              label="Link"
                              type={"number"}
                              placeholder={`Price`}
                              className="dizee-input w-full"
                              value={music.price}
                              onChange={(e) => {
                                let _data = { ...data };
                                _data.musicList[key] = {
                                  ..._data.musicList[key],
                                  price: e.target.value
                                }
                                setData(_data);
                              }}
                            />

                            <div className='flex w-full'>
                              <button
                                onClick={(e) => {
                                  let _data = { ...data };
                                  _data.musicList.splice(key, 1);
                                  _data.musicList = [
                                    ..._data.musicList
                                  ]
                                  setData(_data);
                                }}
                                className='text-white'
                              >
                                Delete Music
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))
                  }

                </div>
              </div>
            </StyleOptions>
          </div>
        </div>
      </div>
    </LayoutHeader>
  );
} 