import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { updateCustomForFocusThunk, getDataForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';

export default function EditFormContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    // Get data from navigation state
    const pageData = location.state?.pageData;
    const pageId = location.state?.pageId;

    // Redirect if no pageData
    useEffect(() => {
        if (!pageData || !pageId) {
            navigate('/focus-page/custom-page');
        }
    }, [pageData, pageId, navigate]);

    // Initialize state with existing form content
    const [formItems, setFormItems] = useState(
        location.state?.formContent?.map(item => ({
            ...item
        })) || []
    );
    
    const [currentForm, setCurrentForm] = useState({
        sectionTitle: location.state?.sectionTitle || '',
        primaryText: '',
        secondaryText: '',
        buttonText: '',
        link: '',
        type: 'form'
    });

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Form validation
    const isFormComplete = currentForm.sectionTitle && 
                          currentForm.primaryText && 
                          currentForm.buttonText;

    const isDoneValid = () => {
        return formItems.length > 0 || isFormComplete;
    };

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Handle link change
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentForm({ ...currentForm, link: value });
    };

    // Handle form click for editing
    const handleFormClick = (item, index) => {
        setSelectedForm({
            ...item,
            type: 'form'
        });
        setSelectedIndex(index);
        
        // Populate the currentForm with the selected item's data
        setCurrentForm({
            sectionTitle: item.sectionTitle,
            primaryText: item.primaryText,
            secondaryText: item.secondaryText || '',
            buttonText: item.buttonText || '',
            link: item.link || '',
            type: 'form'
        });
        
        setIsOverlayVisible(true);
    };

    // Handle edit
    const handleEditForm = () => {
        if (selectedForm && selectedIndex !== null) {
            // Don't remove the item, just populate the form for editing
            setCurrentForm({
                sectionTitle: selectedForm.sectionTitle,
                primaryText: selectedForm.primaryText,
                secondaryText: selectedForm.secondaryText || '',
                buttonText: selectedForm.buttonText || '',
                link: selectedForm.link || '',
                type: 'form'
            });
            setIsOverlayVisible(false);
            // Keep selectedIndex for position tracking
        }
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in all required fields'));
            return;
        }

        // If editing (selectedIndex exists), update in place
        if (selectedIndex !== null) {
            const updatedItems = [...formItems];
            updatedItems[selectedIndex] = {
                type: 'form',
                sectionTitle: currentForm.sectionTitle,
                primaryText: currentForm.primaryText,
                secondaryText: currentForm.secondaryText,
                buttonText: currentForm.buttonText,
                link: currentForm.link || ''
            };
            setFormItems(updatedItems);
            setSelectedIndex(null); // Clear selected index after edit
        } else {
            // Add new item to end of list
            setFormItems([...formItems, {
                type: 'form',
                sectionTitle: currentForm.sectionTitle,
                primaryText: currentForm.primaryText,
                secondaryText: currentForm.secondaryText,
                buttonText: currentForm.buttonText,
                link: currentForm.link || ''
            }]);
        }

        // Reset form except section title
        setCurrentForm({
            sectionTitle: currentForm.sectionTitle,
            primaryText: '',
            secondaryText: '',
            buttonText: '',
            link: '',
            type: 'form'
        });
    };

    // Handle delete
    const handleDeleteForm = () => {
        const filteredItems = formItems.filter((_, index) => index !== selectedIndex);
        setFormItems(filteredItems);
        setIsOverlayVisible(false);
    };

    // Add these new handler functions
    const handleMoveUp = () => {
        if (selectedIndex > 0) {
            const updatedItems = [...formItems];
            // Swap current item with the one above it
            [updatedItems[selectedIndex], updatedItems[selectedIndex - 1]] = 
            [updatedItems[selectedIndex - 1], updatedItems[selectedIndex]];
            setFormItems(updatedItems);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (selectedIndex < formItems.length - 1) {
            const updatedItems = [...formItems];
            // Swap current item with the one below it
            [updatedItems[selectedIndex], updatedItems[selectedIndex + 1]] = 
            [updatedItems[selectedIndex + 1], updatedItems[selectedIndex]];
            setFormItems(updatedItems);
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle form submission
    const handleDone = async () => {
        if (!pageData?.page) {
            dispatch(setErrorAlert('Invalid page data'));
            return;
        }

        // Create final form items array
        let finalFormItems = [...formItems];
        
        // If we're editing, update in place (like handleAddAnother)
        if (selectedIndex !== null && isFormComplete) {
            finalFormItems[selectedIndex] = {
                type: 'form',
                sectionTitle: currentForm.sectionTitle,
                primaryText: currentForm.primaryText,
                secondaryText: currentForm.secondaryText,
                buttonText: currentForm.buttonText,
                link: currentForm.link || ''
            };
        } else if (isFormComplete) {
            // If not editing, add as new item
            finalFormItems.push({
                type: 'form',
                sectionTitle: currentForm.sectionTitle,
                primaryText: currentForm.primaryText,
                secondaryText: currentForm.secondaryText,
                buttonText: currentForm.buttonText,
                link: currentForm.link || ''
            });
        }

        if (finalFormItems.length === 0) {
            dispatch(setErrorAlert('Please add at least one form item'));
            return;
        }

        dispatch(setLoader(true));
        try {
            // Process form items
            const processedForms = finalFormItems.map(item => ({
                type: 'form',
                sectionTitle: item.sectionTitle,
                primaryText: item.primaryText,
                secondaryText: item.secondaryText,
                buttonText: item.buttonText,
                link: item.link || ''
            }));

            // Structure the update data
            const updateData = {
                token,
                payload: {
                    ...pageData,
                    page: pageData.page,
                    type: 'custom',
                    headeractive: pageData.headeractive ?? true,
                    background: pageData.background ?? '1A1A25',
                    accent: pageData.accent ?? 'FFFFFF',
                    content: [
                        ...(pageData.content || []).filter(item => item.type !== 'form'),
                        ...processedForms
                    ],
                    headerImage: pageData.headerImage,
                    headerImageUrl: pageData.headerImageUrl,
                    originalPage: location.state?.pageData?.page || ''
                }
            };

            const result = await dispatch(updateCustomForFocusThunk(updateData)).unwrap();
            
            if (result.status === 200) {
                await dispatch(getDataForFocusThunk({ token, pageId }));
                dispatch(clearFocusSection());
                navigate('/focus-page/custom-page');
            } else {
                throw new Error(result.message || 'Update failed');
            }
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(setErrorAlert(error?.message || 'Failed to update forms'));
            console.error('Error updating forms:', error);
        }
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Edit content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        {isFormComplete && (
                            <button 
                                onClick={handleAddAnother}
                                className="text-white cursor-pointer"
                            >
                                Another
                            </button>
                        )}
                        <button 
                            onClick={handleDone} 
                            className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}
                        >
                            Done
                        </button>
                        <p 
                            onClick={() => navigate(-1)} 
                            className='text-white cursor-pointer'
                        >
                            Go back
                        </p>
                    </div>
                </div>

                {/* Form Content */}
                <div className="w-full px-4 overflow-y-auto pb-[100px]">
                    {/* Input Section */}
                    <div className="mb-8">
                        {/* Section Title */}
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter section title (applies to all content)"
                                onChange={(e) => setCurrentForm({ 
                                    ...currentForm, 
                                    sectionTitle: e.target.value 
                                })}
                                value={currentForm.sectionTitle}
                            />
                            {currentForm.sectionTitle && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        {/* Primary Text */}
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter main text"
                                onChange={(e) => setCurrentForm({ 
                                    ...currentForm, 
                                    primaryText: e.target.value 
                                })}
                                value={currentForm.primaryText}
                            />
                            {currentForm.primaryText && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        {/* Secondary Text */}
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter optional text"
                                onChange={(e) => setCurrentForm({ 
                                    ...currentForm, 
                                    secondaryText: e.target.value 
                                })}
                                value={currentForm.secondaryText}
                            />
                            {currentForm.secondaryText && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        {/* Button Text */}
                        <div className="flex items-center mb-4">
                            <DizeeInput
                                placeholder="Enter button text"
                                onChange={(e) => setCurrentForm({ 
                                    ...currentForm, 
                                    buttonText: e.target.value 
                                })}
                                value={currentForm.buttonText}
                            />
                            {currentForm.buttonText && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>

                        {/* Link */}
                        <div className="flex items-center">
                            <DizeeInput
                                placeholder="Enter link (optional)"
                                onChange={handleLinkChange}
                                value={currentForm.link}
                            />
                            {isValidUrl(currentForm.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                        </div>
                    </div>

                    {/* Added Forms Section */}
                    {formItems.length > 0 && (
                        <div className="flex flex-col w-full mt-[40px]">
                            <div className='pb-[20px] flex w-full items-center'>
                                <div className='flex flex-row text-white w-full'>
                                    <p>Added Forms</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                {formItems.map((item, index) => (
                                    <AddedCustom
                                        key={index}
                                        item={item}
                                        setOverlayVisible={setIsOverlayVisible}
                                        setItemForBackend={(item) => handleFormClick(item, index)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Fixed Footer with Gradient */}
                <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Edit your form content.</p>
                            <p>Section title, main text, and button text are required.</p>
                        </div>
                    </div>
                </div>

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedForm}
                        handleEdit={handleEditForm}
                        handleDelete={handleDeleteForm}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                        canMoveUp={selectedIndex > 0}
                        canMoveDown={selectedIndex < formItems.length - 1}
                    />
                )}
            </div>
        </LayoutHeader>
    );
}