import React from 'react';
import CrossArrow from '../svg/CrossArrow';
import { maxLength } from './DizeeComponent/DixeeInput2';
import { getAccentStyle } from '../constants/constants';
import { postDataAPI } from '../utils/fetchData';
import { useParams } from 'react-router-dom';

const CusFocusProduct = ({ imgurl, item, userArticle }) => {
    const { domain: dynamicVar } = useParams();
    const accentColor = userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF';

    const handleClickProduct = async () => {
        if (!item?.link) return;
        
        let url = item.link;
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
        }
        window.open(url, '_blank');
        try {
            await postDataAPI('analytics/create-product-analytics', {
                link: url,
                domain: dynamicVar,
                title: item?.title
            }, null);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div 
            className="flex-shrink-0 flex w-full gap-x-[25px] cursor-pointer"
            onClick={handleClickProduct}
        >
            {/* Keep existing image container with current dimensions */}
            <div className="h-[130px] w-[130px] flex-shrink-0">
                <img
                    src={imgurl}
                    alt="product"
                    className="h-full w-full object-cover rounded-[3px]"
                />
            </div>
            
            {/* Product Details Section - Styled like MultipleProductPage */}
            <div className="flex flex-col w-full gap-y-[8px]">
                {item?.title && (
                    <p className="text-[12px]" style={getAccentStyle(userArticle?.accent)}>
                        {maxLength(item.title, 30)}
                    </p>
                )}
                {item?.price && (
                    <p className="text-[12px]" style={getAccentStyle(userArticle?.accent)}>
                        ${item?.price}
                    </p>
                )}
                {item?.description && (
                    <p className="text-[12px]" style={getAccentStyle(userArticle?.accent)}>
                        {maxLength(item.description, 80)}
                    </p>
                )}
            </div>
        </div>
    );
};

export default CusFocusProduct; 