import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DizeeInput2 } from "../../DizeeComponent/DixeeInput2";
import { uploadImage } from "../../../utils/upload";
import { setErrorAlert, setLoader } from "../../../store/alert/alertSlice";
import { useNavigate } from "react-router";
import { updateDescription, updateHeaderTitle, updateImage, updateImageOverlay, updateTitle } from "../../../store/vaultData/vaultdataSlice";
import ImageEditCard from "../../Main/ArticleEdit/ImageEditCard";
import { update_vault_data_thunk } from "../../../store/vaultData/vaultdataThunk";

export default function ImageOverlay({ vaultData }) {

  const token = localStorage.getItem("dizeeToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    if (vaultData) {
      setData({ ...vaultData })
    }
  }, [])

  const handleOverlayClick = (e) => {
    if (e.target.id === "overlay") {
      dispatch(updateImageOverlay(false));
    }
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedImage(file);
      // dispatch(updateImage(URL.createObjectURL(file)));
      setData({
        ...data,
        image: URL.createObjectURL(file)
      })
    }
  };

  const updateVaultData = async () => {
    let body;
    if (data?.image?.includes("blob")) {
      dispatch(setLoader(true));
      let url = await uploadImage(selectedImage);
      if (!url) {
        dispatch(
          setErrorAlert("Image cannot contain nudity , violence or drugs")
        );
        return;
      }
      body = { ...data, image: url };
    } else {
      body = { ...data }
    }
    dispatch(update_vault_data_thunk({ token, body }));
    dispatch(updateImageOverlay(false));
  };


  return (
    <div
      id="overlay"
      className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
      onClick={handleOverlayClick}
    >
      <div
        className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]"
        onClick={(e) => e.stopPropagation()}
      >
        <ImageEditCard
          txt="Edit Image"
          dotimgclss={false}
          onImageChange={handleImageChange}
          imagePreview={data?.image}
        />
        <div className="pb-[10px] flex justify-between">
          <p className="">Header title</p>
          <DizeeInput2
            label="Link"
            placeholder="Add a header title"
            className="dizee-input "
            value={data?.headerTitle}
            onChange={(e) =>
              setData({
                ...data,
                headerTitle: e.target.value
              })
            }
          />
        </div>

        <div className="pb-[10px] flex justify-between">
          <p className="">Title</p>
          <DizeeInput2
            label="Link"
            placeholder="Add a title"
            className="dizee-input "
            value={data?.title}
            onChange={(e) =>
              setData({
                ...data,
                title: e.target.value
              })
            }
          />
        </div>

        <div className="pb-[10px] flex justify-between">
          <p className="">Description</p>
          <DizeeInput2
            label="Link"
            placeholder="Add a description"
            className="dizee-input "
            value={data?.description}
            onChange={(e) =>
              setData({
                ...data,
                description: e.target.value
              })
            }
          />
        </div>

        <button className="cursor-pointer " onClick={updateVaultData}>
          Done
        </button>
      </div>
    </div>
  );
}
