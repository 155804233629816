import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { setFocusCustom } from '../../../../../store/focuspage/focuspageSlice';
import Check from '../../../../../svg/Check';
import AddedCustom from '../../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { addCustomForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';

export default function AddTextContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const custom = useSelector(state => state.focuspage.custom);

    // Consolidated state
    const [currentText, setCurrentText] = useState({
        sectionTitle: '',     // Section title
        secondaryText: '',    // Main text
        optionalText: '',     // Optional text
        link: '',
        type: 'text'
    });
    const [textItems, setTextItems] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedText, setSelectedText] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Form validation
    const isFormComplete = currentText.sectionTitle && currentText.secondaryText;
    
    const isDoneValid = () => {
        return textItems.length > 0 || isFormComplete;
    };

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentText({ ...currentText, link: value });
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in section title and main text'));
            return;
        }

        setTextItems([...textItems, {
            type: 'text',
            sectionTitle: currentText.sectionTitle,
            primaryText: currentText.secondaryText,
            secondaryText: currentText.optionalText,
            link: currentText.link || ''
        }]);

        // Reset form except section title
        setCurrentText({
            sectionTitle: currentText.sectionTitle, // Preserve section title
            secondaryText: '',
            optionalText: '',
            link: '',
            type: 'text'
        });
    };

    // Update handleEditText
    const handleEditText = () => {
        if (selectedText && selectedIndex !== null) {
            setCurrentText({
                sectionTitle: selectedText.sectionTitle,
                secondaryText: selectedText.primaryText,
                optionalText: selectedText.secondaryText || '',
                link: selectedText.link || '',
                type: 'text'
            });
            
            const updatedItems = textItems.filter((_, index) => index !== selectedIndex);
            setTextItems(updatedItems);
            setIsOverlayVisible(false);
        }
    };

    // Handle delete functionality
    const handleDeleteText = () => {
        if (selectedIndex !== null) {
            const updatedItems = textItems.filter((_, index) => index !== selectedIndex);
            setTextItems(updatedItems);
            setIsOverlayVisible(false);
        }
    };

    const handleAddToQueue = () => {
        if (isFormComplete) {
            setTextItems([...textItems, currentText]);
            setCurrentText({
                secondaryText: '',
                optionalText: '',
                link: '',
                type: 'text'
            });
        }
    };

    const handleDeleteFromQueue = (index) => {
        setTextItems(textItems.filter((_, i) => i !== index));
    };

    // Handle form submission
    const handleDone = async () => {
        if (!custom?.page) {
            dispatch(setErrorAlert("Please set a page name first"));
            return;
        }

        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one text content'));
            return;
        }

        dispatch(setLoader(true));
        try {
            let contentToSubmit = [...textItems];
            if (isFormComplete) {
                contentToSubmit.push({
                    type: 'text',
                    sectionTitle: currentText.sectionTitle,
                    primaryText: currentText.secondaryText,
                    secondaryText: currentText.optionalText,
                    link: currentText.link || ''
                });
            }

            // Maintain header content only
            const existingHeader = custom?.content?.find(item => item.type === 'header');
            
            const dataCopy = {
                page: custom.page,
                type: 'custom',
                headeractive: true,
                content: [
                    existingHeader || {
                        type: 'header',
                        avatar: custom?.avatar || '',
                        title: custom?.headerText || '',
                        subtitle: custom?.subheaderText || ''
                    },
                    ...contentToSubmit
                ]
            };

            const result = await dispatch(addCustomForFocusThunk({ 
                token, 
                payload: dataCopy 
            })).unwrap();
            
            if (result.status === 200) {
                dispatch(clearFocusSection());
                navigate("/focus-page");
            } else {
                throw new Error(result.message || 'Failed to create focus page');
            }
        } catch (error) {
            console.error('Error creating custom focus page:', error);
            dispatch(setErrorAlert(error?.message || 'Error creating focus page'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-y-auto pb-[120px]">
                        {/* Header Section */}
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div className="text-white cursor-pointer" onClick={handleAddAnother}>
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50' : ''}`}
                                    onClick={() => isDoneValid() && handleDone()}
                                >
                                    Done
                                </div>
                                <div className="text-white cursor-pointer" onClick={() => navigate(-1)}>
                                    Go back
                                </div>
                            </div>
                        </div>

                        {/* Content Section */}
                        <div className="w-full px-4 mt-[120px]">
                            {/* Section Title Input */}
                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter section title"
                                    onChange={(e) => setCurrentText({ ...currentText, sectionTitle: e.target.value })}
                                    value={currentText.sectionTitle}
                                    maxLength={30}
                                />
                                {currentText.sectionTitle && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            {/* Main Text Input */}
                            <div className="flex items-center mt-5">
                                <DizeeInput
                                    placeholder="Enter main text"
                                    onChange={(e) => setCurrentText({ ...currentText, secondaryText: e.target.value })}
                                    value={currentText.secondaryText}
                                    multiline
                                    maxLength={200}
                                />
                                {currentText.secondaryText && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            {/* Optional Text Input */}
                            <div className="flex items-center mt-5">
                                <DizeeInput
                                    placeholder="Enter optional text"
                                    onChange={(e) => setCurrentText({ ...currentText, optionalText: e.target.value })}
                                    value={currentText.optionalText}
                                    multiline
                                    maxLength={100}
                                />
                            </div>

                            {/* Link Input */}
                            <div className="flex items-center mt-5">
                                <DizeeInput
                                    placeholder="Enter link (optional)"
                                    onChange={handleLinkChange}
                                    value={currentText.link}
                                />
                                {isValidUrl(currentText.link) && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            {/* Added Texts List */}
                            {textItems.length > 0 && (
                                <div className="flex flex-col w-full mt-[40px]">
                                    <div className='pb-[20px] flex w-full items-center'>
                                        <div className='flex flex-row text-white w-full'>
                                            <p>Added Texts</p>
                                        </div>
                                    </div>
                                    {textItems.map((item, index) => (
                                        <AddedCustom
                                            key={index}
                                            item={item}
                                            setOverlayVisible={setIsOverlayVisible}
                                            setItemForBackend={(item) => {
                                                setSelectedText(item);
                                                setSelectedIndex(index);
                                                setIsOverlayVisible(true);
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Replace the existing footer with this new structure */}
                    <div className="w-full absolute bottom-0 left-0">
                        <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                        <div className="w-full bg-black px-4 py-6">
                            <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                                <p>Add your text content to display in your focus page.</p>
                                <p>Main text is required. Optional text and link are optional.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Overlay */}
            {isOverlayVisible && (
                <OverlayCustom
                    isOverlayVisible={isOverlayVisible}
                    setOverlayVisible={setIsOverlayVisible}
                    selectedItem={selectedText}
                    handleEdit={handleEditText}
                    handleDelete={handleDeleteText}
                />
            )}
        </LayoutHeader>
    );
} 