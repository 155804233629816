import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import LayoutHeader from '../../../../layout/LayoutHeader';
import More from '../../../../svg/More';
import Plus from '../../../../svg/Plus';
import FocusMusicOverlay from '../../../../components/Focuspage/Type/Music/FocusMusicOverlay';
import { getContrastColor, maxLength } from '../../../../components/DizeeComponent/DixeeInput2';
import CrossArrow from '../../../../svg/CrossArrow';
import { getAccentColor, getAccentStyle, MUSIC_PLATFORMS } from '../../../../constants/constants';
import MusicEdit from '../../../../components/Focuspage/MainEdit/MusicEdit';
import { updateMusicOverlay } from '../../../../store/focuspage/focuspageSlice';
import Spotify from '../../../../svg/MusicPlatform/Spotify';
import AppleMusic from '../../../../svg/MusicPlatform/AppleMusic';
import AmazonMusic from '../../../../svg/MusicPlatform/AmazonMusic';
import SoundCloud from '../../../../svg/MusicPlatform/SoundCloud';
import Tidal from '../../../../svg/MusicPlatform/Tidal';
import { getDataForFocusThunk } from '../../../../store/focuspage/focuspageThunk';
import { setUserArticle } from '../../../../store/user/userSlice';
import { update_user_article_thunk } from '../../../../store/user/userThunk';
import { debounce } from 'lodash';
const componentMapping = {
    spotify: (props) => <Spotify {...props} />,
    apple_music: (props) => <AppleMusic {...props} />,
    amazon: (props) => <AmazonMusic {...props} />,
    soundcloud: (props) => <SoundCloud {...props} />,
    tidal: (props) => <Tidal {...props} />
};

const bottomNavContainerStyle = {
    borderTop: '0.5px solid rgba(255, 255, 255, 0.1)',
    background: '#000000',
    position: 'relative',
    zIndex: 50
};

const hslToHex = (h, s, l) => {
    s /= 100;
    l /= 100;
    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = l - c / 2;
    let r = 0,
        g = 0,
        b = 0;
    if (h >= 0 && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (h >= 180 && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (h >= 240 && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (h >= 300 && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    r = Math.round((r + m) * 255)
        .toString(16)
        .padStart(2, "0");
    g = Math.round((g + m) * 255)
        .toString(16)
        .padStart(2, "0");
    b = Math.round((b + m) * 255)
        .toString(16)
        .padStart(2, "0");
    return `#${r}${g}${b}`;
};

// Add this helper function to convert hex to HSL
const hexToHSL = (hex) => {
    // Remove the # if present
    hex = hex.replace(/^#/, '');

    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16) / 255;
    let g = parseInt(hex.substring(2, 4), 16) / 255;
    let b = parseInt(hex.substring(4, 6), 16) / 255;

    // Find min and max values
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);

    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100)
    };
};


export default function MusicPage() {
    const dispatch = useDispatch();

    const musicArticle = useSelector((state) => state.focuspage.music);
    const musicPageOverlay = useSelector((state) => state.focuspage.isMusicOverlay);
    const userArticle = useSelector((state) => state.user.userArticle);

    console.log(`musicArticle::`, musicArticle);

    let token = localStorage.getItem("dizeeToken");
    const [color, setColor] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(false);
    const [backgroundShade, setBackgroundShade] = useState(false);
    const [accentColor, setAccentColor] = useState(false);
    const [accentShade, setAccentShade] = useState(false);
    const [hue, setHue] = useState(0); // Hue state for color sliders
    const [brightness, setBrightness] = useState(50); // Brightness state for shade sliders
    const [styleMenu, setStyleMenu] = useState(false);
    const [hexBackground, setHexBackground] = useState("");
    const [hexAccent, setHexAccent] = useState("");

    const [showEditOptions, setShowEditOptions] = useState(false);
    const navigate = useNavigate();

    // Add handler for Home click
    const handleHomeClick = () => {
        navigate('/');
    };

    const [grayScaleBar, setGrayScaleBar] = useState(false);
    const [noiseBar, setNoiseBar] = useState(false);

    const onChangeNoise = (e) => {
        try {
            const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
            dispatch(setUserArticle({
                ...userArticle,
                noise: value  // Keep as number like grayscale
            }));
        } catch (error) {
            console.error('Noise update error:', error);
            dispatch(setUserArticle({
                ...userArticle,
                noise: defaultValues.noise
            }));
        }
    };

    // Add this handler for accent color input
    const handleAccentColorInput = (e) => {
        const colorValue = e.target.value.toUpperCase();
        if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
            setHexAccent(colorValue);

            // Only process complete hex colors
            if (colorValue.length === 7) {
                // Convert hex to HSL and update slider
                const hsl = hexToHSL(colorValue);
                setHue(hsl.h);
                setBrightness(hsl.l);

                dispatch(setUserArticle({
                    ...userArticle,
                    accent: colorValue.slice(1)
                }));
            }
        }
    };

    // Update the color input handler
    const handleColorInput = (e) => {
        const colorValue = e.target.value.toUpperCase();
        if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
            setHexBackground(colorValue);

            // Only process complete hex colors
            if (colorValue.length === 7) {
                // Convert hex to HSL and update slider
                const hsl = hexToHSL(colorValue);
                setHue(hsl.h);
                setBrightness(hsl.l);

                dispatch(setUserArticle({
                    ...userArticle,
                    background: colorValue.slice(1)
                }));
            }
        }
    };

    const updateUserArticle = async (bool) => {
        dispatch(update_user_article_thunk({ token, body: { ...userArticle, unpublish: bool } }));
    };

    // Add initial state values
    const [defaultValues] = useState({
        grayscale: "0",
        noise: "0"
    });

    // Add debounce to prevent rapid state updates
    const debouncedUpdateArticle = useCallback(
        debounce((newValue) => {
            dispatch(setUserArticle(newValue));
        }, 100),
        [dispatch]
    );

    // Handle hue change
    const handleHueChangeBackground = (e) => {
        setHue(e.target.value);
        const hexColor = hslToHex(e.target.value, 100, brightness);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        setHexBackground(`#${colorValue}`);
        debouncedUpdateArticle({ ...userArticle, background: colorValue });
    };

    // Handle brightness change
    const handleBrightnessChangeBackground = (e) => {
        setBrightness(e.target.value);
        const hexColor = hslToHex(hue, 100, e.target.value);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        debouncedUpdateArticle({ ...userArticle, background: colorValue });
    };

    const handleHueChangeAccent = (e) => {
        setHue(e.target.value);
        const hexColor = hslToHex(e.target.value, 100, brightness);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        setHexAccent(`#${colorValue}`);
        dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
    };

    // Handle brightness change
    const handleBrightnessChangeAccent = (e) => {
        setBrightness(e.target.value);
        const hexColor = hslToHex(hue, 100, e.target.value);
        const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
        dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
    };

    // Update grayscale handler with safety checks
    const onChangeGrayScale = (e) => {
        try {
            const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
            dispatch(setUserArticle({
                ...userArticle,
                grayscale: value
            }));
        } catch (error) {
            console.error('Grayscale update error:', error);
            dispatch(setUserArticle({
                ...userArticle,
                grayscale: defaultValues.grayscale
            }));
        }
    };

    // Add reset function for when closing controls
    const resetEffects = () => {
        dispatch(setUserArticle({
            ...userArticle,
            grayscale: defaultValues.grayscale,
            noise: defaultValues.noise
        }));
    };

    useEffect(() => {
        if (musicArticle?._id) {
            dispatch(getDataForFocusThunk({
                token,
                pageId: musicArticle._id
            }));
        }
    }, [dispatch, token, musicArticle?._id]);

    const handleEditClick = () => {
        navigate('/focus-page/music-type/edit-single', {
            state: {
                musicData: musicArticle
            }
        });
    };

    return (
        <LayoutHeader>

            <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black" >
                <div className='flex py-4 z-1 ml-4 w-full items-center text-white'>
                    <div className="mx-auto">
                        diz.ee/{userArticle?.domain}/{musicArticle?.extension}
                    </div>
                    <div className="mr-4">
                        <More
                            className='h-[20px] w-[20px] cursor-pointer'
                            onClick={() => dispatch(updateMusicOverlay(true))}
                        />
                    </div>
                </div>

                {
                    musicArticle?.music &&
                    <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]" style={musicArticle?.background ? { backgroundColor: `#${musicArticle?.background}` } : { backgroundColor: '#000000' }}>
                        <div className="max-w-[350px] sm:max-w-[390px] h-[490px] flex relative">
                            <img src={musicArticle?.music[0]?.avatar} alt="photoimage" className="w-full h-full object-cover rounded-[20px]" />
                            <div
                                className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end p-4"
                                style={{
                                    backgroundImage: `linear-gradient(to top, ${musicArticle?.background ? `#${musicArticle.background}` : 'black'}, transparent)`,
                                }}
                            >
                                {musicArticle?.headeractive &&
                                    <div>
                                        <p className='text-[16px]'
                                            style={{ color: `#${musicArticle?.accent}` }}>
                                            {maxLength(musicArticle?.music[0]?.artist, 25)}
                                        </p>
                                        <p className='text-[20px] font-bold'
                                            style={{ color: `#${musicArticle?.accent}` }}>
                                            {maxLength(musicArticle?.music[0]?.title, 40)}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='w-full text-white flex flex-col px-4 justify-center items-start gap-y-[16px] mt-[25px]'>
                            <div className='w-full flex justify-end items-center'>
                                <button
                                    onClick={handleEditClick}
                                    className="text-[12px] opacity-50 hover:opacity-100 transition-opacity"
                                    style={{ color: `#${musicArticle?.accent}` }}
                                >
                                    Edit
                                </button>
                            </div>
                        </div>

                        <div className='w-full flex flex-col justify-center items-center gap-y-[24px] mt-[25px]'>
                            {musicArticle?.music && musicArticle?.music[0]?.links?.length > 0 &&
                                musicArticle?.music[0]?.links?.map((item, index) => (
                                    <div key={item.id || item.source || index} className='w-full'>
                                        <div className='flex w-full item-center justify-between px-4 mb-4'>
                                            <p style={{ color: `#${musicArticle?.accent}` }}>
                                                {MUSIC_PLATFORMS?.map((platform) =>
                                                    platform?.value === item.source ? platform?.label : null
                                                )}
                                            </p>
                                            <div
                                                className='flex gap-x-2 cursor-pointer items-center'
                                                onClick={() => {
                                                    let url = item.url;
                                                    if (!url.startsWith('http://') && !url.startsWith('https://')) {
                                                        url = 'https://' + url;
                                                    }
                                                    window.open(url, '_blank');
                                                }}
                                            >
                                                <p style={{ color: `#${musicArticle?.accent}` }}>
                                                    LISTEN NOW
                                                </p>
                                                <CrossArrow
                                                    color={`#${musicArticle?.accent}`}
                                                    className='h-[12px] w-[12px]'
                                                />
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <div
                                                className='mx-4'
                                                style={{
                                                    height: '0.5px',
                                                    background: `#${musicArticle?.accent}`,
                                                    opacity: '0.3',
                                                    marginTop: '8px'
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div className='w-full  flex flex-col justify-center items-start gap-y-[16px] px-4 mt-[50px] text-[12px]' style={getAccentStyle(musicArticle?.accent)}>
                            <div className='space-y-[15px]'>
                                <div className='flex flex-row gap-x-[10px] items-center'>
                                    <p className='uppercase font-bold'>{userArticle?.username}</p>
                                    <p className='pb-[2px]'>x</p>
                                    <p className='uppercase text-[11px] tracking-[3px]'>DIZEE</p>
                                </div>
                                <p>Privacy Policy</p>
                            </div>


                        </div>
                    </div>
                }
            </div>
            {!color && !showEditOptions && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setShowEditOptions(true)}
                        >
                            EDIT
                        </button>
                        <button
                            className="text-white text-center py-2 px-4 rounded-lg plus-button"
                            onClick={() => navigate("/add-section")}
                        >
                            <Plus className="h-[18px] w-[18px]" />
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={handleHomeClick}
                        >
                            HOME
                        </button>
                    </div>
                </div>
            )}

            {!color && showEditOptions && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setStyleMenu(true)}
                        >
                            RETOUCH
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setShowEditOptions(false)}
                        >
                            BACK
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70 color-button"
                            onClick={() => {
                                setColor(true);
                                setShowEditOptions(false);
                            }}
                        >
                            COLOR
                        </button>
                    </div>
                </div>
            )}

            {color && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setBackgroundColor(true);
                                setColor(false);
                            }}
                        >
                            BACKGROUND
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setAccentColor(true);
                                setColor(false);
                            }}
                        >
                            ACCENT
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setColor(false);
                                setShowEditOptions(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {backgroundColor && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setBackgroundShade(true)}
                        >
                            SHADE
                        </button>
                        <div className="flex items-center space-x-4">
                            <input
                                type="text"
                                value={(hexBackground || "#000000").toUpperCase()}
                                onChange={handleColorInput}
                                className="bg-transparent w-[60px] text-center outline-none border-none transition-colors duration-200"
                                style={{
                                    caretColor: 'white',
                                    appearance: 'none',
                                    WebkitAppearance: 'none'
                                }}
                            />
                            <input
                                type="range"
                                min="0"
                                max="360"
                                value={hue}
                                onChange={handleHueChangeBackground}
                                className="hue-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {/* Background Shade Selection */}
            {backgroundShade && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setBackgroundShade(false);
                                setBackgroundColor(true);
                            }}
                        >
                            COLOR
                        </button>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={brightness}
                            onChange={handleBrightnessChangeBackground}
                            className="brightness-slider"
                            style={{
                                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                                margin: 0,
                                width: "180px",
                            }}
                        />
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}
            {accentColor && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setAccentShade(true)}
                        >
                            SHADE
                        </button>
                        <div className="flex items-center space-x-4">
                            <input
                                type="text"
                                value={(hexAccent || "#000000").toUpperCase()}
                                onChange={handleAccentColorInput}
                                className="bg-transparent w-[60px] text-center outline-none border-none"
                                style={{
                                    caretColor: 'white',
                                    appearance: 'none',
                                    WebkitAppearance: 'none'
                                }}
                            />
                            <input
                                type="range"
                                min="0"
                                max="360"
                                value={hue}
                                onChange={handleHueChangeAccent}
                                className="hue-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {/* Background Shade Selection */}
            {accentShade && (
                <div className="fixed max-w-[350px] bottom-0 w-full text-white font-[410] text-[9px] leading-[10.26px] tracking-[0.02em]">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setAccentShade(false);
                                setAccentColor(true);
                            }}
                        >
                            COLOR
                        </button>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={brightness}
                            onChange={handleBrightnessChangeAccent}
                            className="brightness-slider"
                            style={{
                                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                                margin: 0,
                                width: "180px",
                            }}
                        />
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => updateUserArticle(false)}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2  rounded-lg"
                            onClick={() => {
                                setBackgroundColor(false);
                                setBackgroundShade(false);
                                setAccentColor(false);
                                setAccentShade(false);
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {styleMenu && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setGrayScaleBar(true);
                                setStyleMenu(false);
                            }}
                        >
                            GRAYSCALE
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setNoiseBar(true);
                                setStyleMenu(false);
                            }}
                        >
                            NOISE
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setStyleMenu(false)}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}
            {grayScaleBar && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button className="text-center py-2 rounded-lg">
                            GRAYSCALE
                        </button>
                        <div className="flex items-center space-x-4">
                            <span className="text-white min-w-[20px]">
                                {userArticle?.grayscale || 0}%
                            </span>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={userArticle?.grayscale ?? 0}
                                onChange={onChangeGrayScale}
                                className="grayscale-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => { updateUserArticle(false); setGrayScaleBar(false); }}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => setGrayScaleBar(false)}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}

            {noiseBar && (
                <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
                    <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
                        <button className="text-center py-2 rounded-lg">
                            NOISE
                        </button>
                        <div className="flex items-center space-x-4">
                            <span className="text-white min-w-[20px]">
                                {userArticle?.noise || 0}%
                            </span>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={userArticle?.noise ?? 0}
                                onChange={onChangeNoise}
                                className="grayscale-slider"
                                style={{ width: "140px" }}
                            />
                        </div>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => { updateUserArticle(false); setNoiseBar(false); }}
                        >
                            CONFIRM
                        </button>
                        <button
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            onClick={() => {
                                setNoiseBar(false);
                                resetEffects();
                            }}
                        >
                            BACK
                        </button>
                    </div>
                </div>
            )}
            {musicPageOverlay && <MusicEdit musicArticle={musicArticle} />}
        </LayoutHeader>
    );
}
