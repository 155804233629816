import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

// Using a different approach for the overlay + spotlight effect
const TutorialContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none; // Allow clicks to pass through by default
`;

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const SpotlightArea = styled.div`
  position: absolute;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7);
  border: 2px solid #FF2300;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  z-index: 1001;
  transition: all 0.3s ease;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2);
    pointer-events: none;
  }
`;

const MobileFullWidthSpotlight = styled(SpotlightArea)`
  @media (max-width: 767px) {
    width: 100% !important;
    left: 0 !important;
    transform: none !important;
    box-sizing: border-box;
    
    /* Make sure bottom is visible */
    height: auto !important;
    bottom: 0 !important;
    top: 100px !important;
  }
`;

const TutorialBox = styled.div`
  position: absolute;
  background-color: #FF2300;
  border-radius: 8px;
  padding: 16px;
  width: min(300px, 90vw);
  z-index: 1002;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  pointer-events: auto; // Enable interactions for this element
  transition: all 0.3s ease;
`;

const TutorialTitle = styled.h3`
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
  color: white;
`;

const TutorialDescription = styled.p`
  margin: 0 0 16px;
  font-size: 14px;
  color: white;
  line-height: 1.5;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: opacity 0.2s;
  background-color: transparent;
  color: white;
  
  &:hover {
    opacity: 0.8;
  }
`;

// NextButton now uses the same styling as the other buttons
const NextButton = styled(Button)`
  font-weight: 600; // Just making it slightly bolder for emphasis
`;

const BackButton = styled(Button)``;

const SkipButton = styled(Button)``;

const StepIndicator = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 12px;
  justify-content: center;
`;

const StepDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.active ? 'white' : 'rgba(255, 255, 255, 0.5)'};
  transition: background-color 0.3s;
`;

const DizeeTutorial = ({ onClose, startAtStep = 0 }) => {
  const [currentStep, setCurrentStep] = useState(startAtStep);
  const navigate = useNavigate();
  const location = useLocation();
  
  // Add state for screen dimensions and spotlight size
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  
  // Create refs for the spotlight and tutorial elements
  const spotlightRef = useRef(null);
  const tutorialBoxRef = useRef(null);
  
  // Fixed spotlight sizes for different steps
  const spotlightSizes = {
    // First step - adjusted to be taller than 300px but still contained
    0: {
      spotlight: {
        width: '375px',
        height: '550px', // Increased from 300px to 450px - more balanced height
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px'
      },
      tooltip: {
        bottom: '50px',
        left: '50%',
        transform: 'translateX(-50%)',
        top: 'auto'
      }
    }
  };
  
  // Add a new state for fade-in control (for step 0 and step 7)
  const [fadeIn, setFadeIn] = useState(false);
  // Add state to track step 7 fade specifically
  const [step7Fade, setStep7Fade] = useState(false);
  
  // Add a useEffect to trigger the initial fade-in animation on component mount
  useEffect(() => {
    // Longer delay to ensure DOM is ready before animation starts
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 100); // Increased from 50ms to 100ms
    
    return () => clearTimeout(timer);
  }, []);
  
  // Add a useEffect to handle the step 6->7 transition fade
  useEffect(() => {
    if (currentStep === 6) {
      // Reset fade when arriving at step 6
      setStep7Fade(false);
      
      // Small delay before triggering the fade in
      const timer = setTimeout(() => {
        setStep7Fade(true);
      }, 50);
      
      return () => clearTimeout(timer);
    }
  }, [currentStep]);
  
  // Calculate positions for elements based on the current step
  const calculatePositions = (step) => {
    // For first step, use our fixed size
    if (step === 0) {
      return spotlightSizes[0];
    }
    
    const positions = { spotlight: {}, tooltip: {} };
    
    if (step === 1) {
      // Builder app step - improved targeting
      const builderElement = document.querySelector('.builder-app-icon') || 
                            document.querySelector('[data-tutorial="builder-app"]') || 
                            document.querySelector('div[style*="FF3B30"]'); // Try to find by color if available
      
      if (builderElement) {
        const rect = builderElement.getBoundingClientRect();
        
        // Add a small padding around the element (5px on each side)
        positions.spotlight = {
          top: `${rect.top - 5}px`,
          left: `${rect.left - 5}px`,
          width: `${rect.width + 10}px`,
          height: `${rect.height + 10}px`,
          borderRadius: '8px'
        };
        
        // Position tooltip below the element
        positions.tooltip = {
          top: `${rect.bottom + 20}px`,
          left: `${rect.left + (rect.width / 2)}px`,
          transform: 'translateX(-50%)'
        };
        
        console.log("Builder element found:", rect);
      } else {
        console.log("Builder element not found, using fallback position");
        // Improved fallback positioning - more centered
        positions.spotlight = {
          top: '25%',
          left: '25%',
          width: '80px',
          height: '80px',
          borderRadius: '8px'
        };
        positions.tooltip = {
          top: 'calc(25% + 100px)',
          left: '25%'
        };
      }
    } else if (step === 2) {
      // Vault app step
      const vaultElement = document.querySelector('.vault-app-icon');
      if (vaultElement) {
        const rect = vaultElement.getBoundingClientRect();
        positions.spotlight = {
          top: `${rect.top}px`,
          left: `${rect.left}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`,
          borderRadius: '4px'
        };
        positions.tooltip = {
          top: `${rect.bottom + 20}px`,
          left: `${rect.left + (rect.width / 2)}px`,
          transform: 'translateX(-50%)'
        };
      } else {
        // Fallback when element not found
        positions.spotlight = {
          top: '20%',
          left: '60%',
          width: '70px',
          height: '70px'
        };
        positions.tooltip = {
          top: '30%',
          left: '60%'
        };
      }
    }
    
    return positions;
  };
  
  // Add resize event listener
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // Apply positions directly via imperative DOM updates
  useEffect(() => {
    if (spotlightRef.current) {
      // Apply a direct style override for the first step
      if (currentStep === 0) {
        spotlightRef.current.style.width = '375px';
        spotlightRef.current.style.height = '550px';
        spotlightRef.current.style.top = '50%';
        spotlightRef.current.style.left = '50%';
        spotlightRef.current.style.transform = 'translate(-50%, -50%)';
        spotlightRef.current.style.borderRadius = '20px';
        
        // Adjust tutorial box position based on screen height for better desktop experience
        if (tutorialBoxRef.current) {
          // For taller screens (desktop), position higher up
          if (window.innerHeight > 800) {
            const desktopBottomOffset = Math.max(125, window.innerHeight * 0.30); // At least 125px or 15% of height
            tutorialBoxRef.current.style.bottom = `${desktopBottomOffset}px`;
          } else {
            // For smaller screens (mobile), keep closer to bottom
            tutorialBoxRef.current.style.bottom = '75px';
          }
          
          tutorialBoxRef.current.style.top = 'auto';
          tutorialBoxRef.current.style.left = '50%';
          tutorialBoxRef.current.style.transform = 'translateX(-50%)';
          
          console.log("Applied tutorial box position with height:", window.innerHeight);
        }
      } else {
        // For other steps, use the normal calculation
        const positions = calculatePositions(currentStep);
        
        // Apply these positions...
      }
    }
  }, [currentStep, screenSize]);
  
  // Step 2 useEffect - redirect to root
  useEffect(() => {
    if (currentStep === 1 && spotlightRef.current && tutorialBoxRef.current) {
      // Find builder app element 
      const builderElement = document.querySelector('.builder-app-icon') || 
                            document.querySelector('[data-tutorial="builder-app"]') || 
                            document.querySelector('div[style*="FF3B30"]');
      
      if (builderElement) {
        const rect = builderElement.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '8px';
        
        // Make it clickable but redirect to root (/)
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        spotlightRef.current.onclick = () => {
          // Just navigate to root to stay on same page
          navigate('/');
        };
        
        // Position tooltip - adjust for mobile screens
        if (window.innerWidth < 768) { // Mobile breakpoint
          // Move tooltip more to the right for mobile
          tutorialBoxRef.current.style.top = `${rect.bottom + 15}px`;
          tutorialBoxRef.current.style.left = `${Math.min(rect.left + (rect.width / 2), window.innerWidth - 160)}px`;
          tutorialBoxRef.current.style.transform = 'translateX(-15%)'; // Shift less to the left
        } else {
          // Original positioning for desktop
          tutorialBoxRef.current.style.top = `${rect.bottom + 15}px`;
          tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
          tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        }
        tutorialBoxRef.current.style.bottom = 'auto';
      } else {
        // Fallback code...
        
        // Still ensure non-clickable
        spotlightRef.current.style.pointerEvents = 'none';
        spotlightRef.current.style.cursor = 'default';
        spotlightRef.current.onclick = null;
      }
    }
  }, [currentStep, screenSize, navigate]);
  
  // Step 3 useEffect - redirect to root
  useEffect(() => {
    if (currentStep === 2 && spotlightRef.current && tutorialBoxRef.current) {
      // Find vault element
      const vaultElement = document.querySelector('.vault-app-icon') || 
                          document.querySelector('[data-tutorial="vault-app"]') ||
                          document.querySelector('div[style*="30B644"]');
      
      if (vaultElement) {
        const rect = vaultElement.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '8px';
        
        // Make clickable but redirect to root (/)
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        spotlightRef.current.onclick = () => {
          // Just navigate to root to stay on same page
          navigate('/');
        };
        
        // Position tooltip - mobile adjustment
        if (window.innerWidth < 768) { // Mobile breakpoint
          // Move tooltip more to the right for mobile
          tutorialBoxRef.current.style.top = `${rect.bottom + 15}px`;
          tutorialBoxRef.current.style.left = `${Math.min(rect.left + rect.width, window.innerWidth - 150)}px`;
          tutorialBoxRef.current.style.transform = 'translateX(-40%)'; // Shift less to the left
        } else {
          // Original positioning for desktop
          tutorialBoxRef.current.style.top = `${rect.bottom + 15}px`;
          tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
          tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        }
        tutorialBoxRef.current.style.bottom = 'auto';
      } else {
        // Fallback code...
        
        // Still ensure non-clickable
        spotlightRef.current.style.pointerEvents = 'none';
        spotlightRef.current.style.cursor = 'default';
        spotlightRef.current.onclick = null;
      }
    }
  }, [currentStep, screenSize, navigate]);
  
  // Step 4 - SEARCH APPS - modeling after steps 10-12
  useEffect(() => {
    if (currentStep === 3 && spotlightRef.current && tutorialBoxRef.current) {
      // Find the SEARCH APPS element in the footer
      const searchButton = Array.from(document.querySelectorAll('div')).find(el => 
                             el.textContent && el.textContent.trim() === 'SEARCH APPS'
                           );
      
      if (searchButton) {
        const rect = searchButton.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '4px';
        
        // Make it clickable
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        spotlightRef.current.onclick = () => {
          setCurrentStep(4);
        };
        
        // Position tooltip - mobile adjustment
        if (window.innerWidth < 768) { // Mobile breakpoint
          // Move tooltip more to the right for mobile
          tutorialBoxRef.current.style.bottom = `${window.innerHeight - rect.top + 15}px`;
          tutorialBoxRef.current.style.left = `${Math.min(rect.left + rect.width, window.innerWidth - 150)}px`;
          tutorialBoxRef.current.style.transform = 'translateX(-25%)'; // Shift less to the left
        } else {
          // Original positioning for desktop
          tutorialBoxRef.current.style.bottom = `${window.innerHeight - rect.top + 15}px`;
          tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
          tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        }
        tutorialBoxRef.current.style.top = 'auto';
      } else {
        // Fallback positioning - mobile adjustment only for tooltip
        // Spotlight positioning remains the same
        spotlightRef.current.style.top = 'auto';
        spotlightRef.current.style.bottom = '20px';
        spotlightRef.current.style.left = '25%';
        spotlightRef.current.style.width = '80px';
        spotlightRef.current.style.height = '50px';
        spotlightRef.current.style.borderRadius = '4px';
        
        if (window.innerWidth < 768) {
          // More right-aligned tooltip on mobile
          tutorialBoxRef.current.style.top = 'auto';
          tutorialBoxRef.current.style.bottom = '80px';
          tutorialBoxRef.current.style.left = '40%'; // More to the right
          tutorialBoxRef.current.style.transform = 'translateX(-20%)';
        } else {
          // Original fallback for desktop
          tutorialBoxRef.current.style.top = 'auto';
          tutorialBoxRef.current.style.bottom = '65px';
          tutorialBoxRef.current.style.left = '25%';
          tutorialBoxRef.current.style.transform = 'translateX(50%)';
        }
      }
    }
  }, [currentStep, screenSize, setCurrentStep]);
  
  // Step 5 - PROFILE - modeling after steps 10-12
  useEffect(() => {
    if (currentStep === 4 && spotlightRef.current && tutorialBoxRef.current) {
      // Find the PROFILE element in the footer
      const profileButton = Array.from(document.querySelectorAll('div')).find(el => 
                             el.textContent && el.textContent.trim() === 'PROFILE'
                           );
      
      if (profileButton) {
        const rect = profileButton.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '4px';
        
        // Make it clickable
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        spotlightRef.current.onclick = () => {
          setCurrentStep(5);
        };
        
        // Position tooltip - mobile adjustment
        if (window.innerWidth < 768) { // Mobile breakpoint
          // Move tooltip more to the left for mobile
          tutorialBoxRef.current.style.bottom = `${window.innerHeight - rect.top + 15}px`;
          tutorialBoxRef.current.style.left = `${Math.max(rect.left - (rect.width / 2), 150)}px`;
          tutorialBoxRef.current.style.transform = 'translateX(-80%)'; 
        } else {
          // Original positioning for desktop
          tutorialBoxRef.current.style.bottom = `${window.innerHeight - rect.top + 15}px`;
          tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
          tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        }
        tutorialBoxRef.current.style.top = 'auto';
      } else {
        // Fallback positioning - mobile adjustment only for tooltip
        // Spotlight positioning remains the same
        spotlightRef.current.style.top = 'auto';
        spotlightRef.current.style.bottom = '20px';
        spotlightRef.current.style.right = '25%';
        spotlightRef.current.style.width = '80px';
        spotlightRef.current.style.height = '50px';
        spotlightRef.current.style.borderRadius = '4px';
        
        if (window.innerWidth < 768) {
          // More left-aligned tooltip on mobile
          tutorialBoxRef.current.style.top = 'auto';
          tutorialBoxRef.current.style.bottom = '80px';
          tutorialBoxRef.current.style.right = '30%'; // Less to the right (more to the left)
          tutorialBoxRef.current.style.transform = 'translateX(30%)'; // Shift less to the right
        } else {
          // Original fallback for desktop
          tutorialBoxRef.current.style.top = 'auto';
          tutorialBoxRef.current.style.bottom = '65px';
          tutorialBoxRef.current.style.right = '25%';
          tutorialBoxRef.current.style.transform = 'translateX(50%)';
        }
      }
    }
  }, [currentStep, screenSize, setCurrentStep]);
  
  // Step 6 useEffect - Builder button should be clickable
  useEffect(() => {
    if (currentStep === 5 && spotlightRef.current && tutorialBoxRef.current) {
      const builderElement = document.querySelector('.builder-app-icon') || 
                             document.querySelector('[data-tutorial="builder-app"]') || 
                             document.querySelector('div[style*="FF3B30"]');
      
      if (builderElement) {
        const rect = builderElement.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '8px';
        
        // ONLY HERE: Make spotlight clickable
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        
        // Add click handler to navigate to the correct path "/main"
        spotlightRef.current.onclick = () => {
          // Store tutorial state in localStorage
          localStorage.setItem('tutorialActive', 'true');
          localStorage.setItem('tutorialStep', '6'); // Next step (7) has index 6
          
          console.log("Builder clicked, navigating to /main...");
          
          // Navigate to the correct path for Main.jsx
          navigate('/main');
        };
        
        // Position tooltip
        tutorialBoxRef.current.style.top = `${rect.bottom + 20}px`;
        tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        tutorialBoxRef.current.style.bottom = 'auto';
      } else {
        // Fallback code...
      }
    }
  }, [currentStep, screenSize, navigate]);
  
  // Update useEffect for Builder App Overview (now step 7)
  useEffect(() => {
    if (currentStep === 6 && spotlightRef.current && tutorialBoxRef.current) {
      // Determine if we're on desktop or mobile
      const isDesktop = window.innerWidth > 768;
      
      // Target the entire content area of the builder app
      const builderContent = document.querySelector('.w-\\[350px\\]') || 
                             document.querySelector('.pb-\\[100px\\]') ||
                             document.querySelector('.rounded-\\[20px\\]');
      
      if (builderContent) {
        const rect = builderContent.getBoundingClientRect();
        
        // Create a large spotlight that highlights the overall app
        spotlightRef.current.style.top = `${rect.top + 50}px`; // Start below header
        spotlightRef.current.style.left = `${rect.left + 10}px`;
        spotlightRef.current.style.width = `${rect.width - 20}px`; // Slight padding
        spotlightRef.current.style.height = `${rect.height - 100}px`; // Exclude bottom nav
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '16px';
        
        // Position tooltip at the top of the app
        if (isDesktop) {
          tutorialBoxRef.current.style.bottom = 'auto';
          tutorialBoxRef.current.style.top = `${rect.top + 20}px`;
          tutorialBoxRef.current.style.left = `${rect.left + rect.width / 2}px`;
        } else {
          tutorialBoxRef.current.style.top = `${rect.top + 20}px`;
          tutorialBoxRef.current.style.bottom = 'auto';
          tutorialBoxRef.current.style.left = `${rect.left + rect.width / 2}px`;
        }
        
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
      } else {
        // Fallback
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        
        spotlightRef.current.style.top = '80px';
        spotlightRef.current.style.left = '50%';
        spotlightRef.current.style.width = '90%';
        spotlightRef.current.style.height = '70%';
        spotlightRef.current.style.transform = 'translateX(-50%)';
        spotlightRef.current.style.borderRadius = '16px';
        
        tutorialBoxRef.current.style.top = '100px';
        tutorialBoxRef.current.style.bottom = 'auto';
        tutorialBoxRef.current.style.left = '50%';
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
      }
    }
  }, [currentStep, screenSize]);
  
  // Update useEffect for Plus Button (now step 8)
  useEffect(() => {
    if (currentStep === 7 && spotlightRef.current && tutorialBoxRef.current) {
      // Find the Plus button in the bottom navigation
      const plusButton = document.querySelector('.plus-button') || 
                         document.querySelector('button svg[class*="h-[18px]"]')?.closest('button') ||
                         document.querySelector('button:nth-child(2)'); // Second button in nav
      
      if (plusButton) {
        const rect = plusButton.getBoundingClientRect();
        
        // Position spotlight on the Plus button
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '50%'; // Circular for button
        
        // Position tooltip above the button
        tutorialBoxRef.current.style.bottom = `${window.innerHeight - rect.top + 20}px`;
        tutorialBoxRef.current.style.top = 'auto';
        tutorialBoxRef.current.style.left = `${rect.left + rect.width / 2}px`;
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
      } else {
        // Fallback
        const bottomNav = document.querySelector('.fixed.max-w-\\[350px\\].bottom-0') ||
                         document.querySelector('[style*="borderTop"]');
        
        if (bottomNav) {
          const navRect = bottomNav.getBoundingClientRect();
          const centerX = navRect.left + navRect.width / 2;
          
          spotlightRef.current.style.top = `${navRect.top + 5}px`;
          spotlightRef.current.style.left = `${centerX - 25}px`;
          spotlightRef.current.style.width = '50px';
          spotlightRef.current.style.height = '50px';
          spotlightRef.current.style.borderRadius = '50%';
          
          tutorialBoxRef.current.style.bottom = `${window.innerHeight - navRect.top + 20}px`;
          tutorialBoxRef.current.style.top = 'auto';
          tutorialBoxRef.current.style.left = `${centerX}px`;
          tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        }
      }
    }
  }, [currentStep, screenSize]);
  
  // Updated tutorialSteps array with new steps 11 and 12
  const tutorialSteps = [
    {
      page: '/landing',
      title: 'Welcome to your home screen',
      description: 'This is your main dashboard where you can access all apps and navigation.',
      // Updated to cover the whole iPhone viewport area
      spotlightPosition: { 
        top: '50%', 
        left: '50%', 
        width: '375px',  // Standard iPhone width
        height: '667px', // Standard iPhone height
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px' // Rounded corners like an iPhone
      },
      tooltipPosition: { 
        bottom: '50px', 
        left: '50%', 
        transform: 'translateX(-50%)',
        top: 'auto' // Override any top value
      },
    },
    {
      page: '/landing',
      title: 'Builder App',
      description: 'Click here to create and customize your digital home for fans.',
      spotlightPosition: { top: '20%', left: '10%', width: '70px', height: '70px' },
      tooltipPosition: { top: '20%', left: '30%' },
      elementSelector: '.builder-app-icon',
    },
    {
      page: '/landing',
      title: 'Vault App',
      description: 'Your music vault allows you to share exclusive content with your fans and earn revenue.',
      spotlightPosition: { top: '20%', left: '40%', width: '70px', height: '70px' },
      tooltipPosition: { top: '20%', left: '60%' },
      elementSelector: '.vault-app-icon',
    },
    {
      page: '/marketplace',
      title: 'App Marketplace',
      description: 'Discover and install apps that can help you monetize your content and engage with fans.',
      spotlightPosition: { top: '70px', left: '50%', width: '90%', height: '80px', transform: 'translateX(-50%)' },
      tooltipPosition: { top: '170px', left: '50%', transform: 'translateX(-50%)' },
      isNavigationStep: true,
      navigateTo: '/marketplace',
    },
    {
      page: '/more/setting/profile',
      title: 'Profile Settings',
      description: 'Customize your profile details and customize how fans see you.',
      spotlightPosition: { top: '100px', left: '50%', width: '90%', height: '300px', transform: 'translateX(-50%)' },
      tooltipPosition: { top: '420px', left: '50%', transform: 'translateX(-50%)' },
      isNavigationStep: true,
      navigateTo: '/more/setting/profile',
    },
    {
      title: "Go to Builder",
      description: "Click on the Builder button to continue and start creating your custom page."
    },
    {
      title: "Builder App",
      description: "This is where you can build your custom page. You can add various types of content to make it unique."
    },
    {
      title: "Brand Page",
      description: "This button takes you back to edit your Brand Page, which is your main Dizee page. Here you can manage your brand's content and appearance."
    },
    {
      title: "Focus Page",
      description: "The Focus Page is where your audience will see your content. It's your public-facing page that showcases everything you've created in your brand. Click here to preview your page."
    },
    {
      title: "Start Building",
      description: "Click the + button to add new sections to your page. You can add images, text, videos, and more!"
    },
    {
      title: "Edit Your Page", // Step 11 (index 10)
      description: "Click EDIT to customize the look of your page. Dizee's built-in photo editing features give you creative control over your content's appearance."
    },
    {
      title: "Return Home", // Step 12 (index 11) 
      description: "The HOME button takes you back to your Dizee main dashboard where you can explore other apps and manage your digital presence."
    }
  ];
  
  // Find and measure the actual mobile container on mount
  useEffect(() => {
    if (currentStep === 0) {
      // Try to find the main content container
      const mobileContainer = document.querySelector('.MobileContainer') || 
                             document.querySelector('.MainContent') ||
                             document.querySelector('[class*="mobile"]') ||
                             document.querySelector('[class*="Mobile"]');
      
      if (mobileContainer) {
        const rect = mobileContainer.getBoundingClientRect();
        console.log("Found mobile container:", rect);
        
        // Could dynamically update spotlight position based on actual container
        // This would require additional state management
      }
    }
  }, [currentStep]);
  
  const currentStepConfig = tutorialSteps[currentStep];
  
  useEffect(() => {
    // If we have an element selector, find the element and update spotlight position
    if (currentStepConfig.elementSelector) {
      const targetElement = document.querySelector(currentStepConfig.elementSelector);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        
        // This would normally update state with the element's position
        console.log("Found element: ", currentStepConfig.elementSelector, rect);
        // We would update spotlightPosition here if we had it in state
      }
    }
  }, [currentStep, currentStepConfig]);
  
  // Modified handleBackClick function to handle step 7 navigation
  const handleBackClick = () => {
    // Special handling for step 7 (index 6) - navigate back to root with step 6
    if (currentStep === 6) {
      // Store tutorial state in localStorage
      localStorage.setItem('tutorialActive', 'true');
      localStorage.setItem('tutorialStep', '5'); // Previous step (6) has index 5
      
      // Navigate back to the root path
      navigate('/');
    } else {
      // Normal back step behavior for other steps
      if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
      }
    }
  };
  
  // Add state for final animation with enhanced fade effect
  const [showFinalAnimation, setShowFinalAnimation] = useState(false);
  const [finalAnimationFadeOut, setFinalAnimationFadeOut] = useState(false);

  // Modify handleNextClick with the enhanced fade
  const handleNextClick = () => {
    // Special handling for step 6 (index 5)
    if (currentStep === 5) {
      // Store tutorial state in localStorage
      localStorage.setItem('tutorialActive', 'true');
      localStorage.setItem('tutorialStep', '6'); // Next step (7) has index 6
      
      // Navigate to Main.jsx
      navigate('/main');
    } else {
      // Handle final step with animation
      if (currentStep === tutorialSteps.length - 1) {
        // Hide tutorial elements immediately but keep container
        if (tutorialBoxRef.current) tutorialBoxRef.current.style.opacity = '0';
        if (spotlightRef.current) spotlightRef.current.style.opacity = '0';
        
        // Show final animation
        setShowFinalAnimation(true);
        
        // Start fade out after 1.8 seconds
        setTimeout(() => {
          setFinalAnimationFadeOut(true);
        }, 1800);
        
        // Complete the tutorial after animation finishes
        setTimeout(() => {
          onClose(); // This should remove the entire tutorial component
        }, 3500);
      } else {
        // Normal next step behavior for other steps
        if (currentStep < tutorialSteps.length - 1) {
          setCurrentStep(currentStep + 1);
        } else {
          onClose();
        }
      }
    }
  };
  
  // Updated useEffect for Brand Page (now step 8, index 7)
  useEffect(() => {
    if (currentStep === 7 && spotlightRef.current && tutorialBoxRef.current) {
      // Find the brand page link in the header
      const brandPageLink = document.querySelector('a[href="/"]') || 
                           document.querySelector('a[to="/"]') ||
                           document.querySelector('a.text-\\[14px\\]') ||
                           Array.from(document.querySelectorAll('a')).find(el => 
                             el.textContent.toLowerCase().includes('brand page')
                           );
      
      if (brandPageLink) {
        const rect = brandPageLink.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '4px';
        
        // Make it clickable
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        spotlightRef.current.onclick = () => {
          // Store tutorial state to continue to next step
          localStorage.setItem('tutorialActive', 'true');
          localStorage.setItem('tutorialStep', '8'); // Next step (9) has index 8
          
          // Navigate to root path
          navigate('/');
        };
        
        // Position tooltip below the brand page link
        tutorialBoxRef.current.style.top = `${rect.bottom + 15}px`;
        tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        tutorialBoxRef.current.style.bottom = 'auto';
      } else {
        // Fallback - position near the top left where the header typically is
        spotlightRef.current.style.top = '20px';
        spotlightRef.current.style.left = '100px';
        spotlightRef.current.style.width = '100px';
        spotlightRef.current.style.height = '30px';
        spotlightRef.current.style.borderRadius = '4px';
        
        tutorialBoxRef.current.style.top = '60px';
        tutorialBoxRef.current.style.left = '150px';
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        tutorialBoxRef.current.style.bottom = 'auto';
      }
    }
  }, [currentStep, screenSize, navigate]);
  
  // Updated useEffect for Focus Page (now step 9, index 8)
  useEffect(() => {
    if (currentStep === 8 && spotlightRef.current && tutorialBoxRef.current) {
      // Find the focus page link in the header
      const focusPageLink = document.querySelector('a[href*="/focus"]') || 
                           document.querySelector('a[to*="/focus"]') ||
                           Array.from(document.querySelectorAll('a')).find(el => 
                             el.textContent.toLowerCase().includes('focus page')
                           );
      
      if (focusPageLink) {
        const rect = focusPageLink.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '4px';
        
        // Make it clickable
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        spotlightRef.current.onclick = () => {
          // Store tutorial state to continue to the last step
          localStorage.setItem('tutorialActive', 'true');
          localStorage.setItem('tutorialStep', '9'); // Last step (10) has index 9
          
          // Navigate to focus page
          const username = JSON.parse(localStorage.getItem('dizeeUser'))?.username || '';
          navigate(`/focus/${username}`);
        };
        
        // Position tooltip below the focus page link
        tutorialBoxRef.current.style.top = `${rect.bottom + 15}px`;
        tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        tutorialBoxRef.current.style.bottom = 'auto';
      } else {
        // Fallback positioning
        spotlightRef.current.style.top = '20px';
        spotlightRef.current.style.left = '200px';
        spotlightRef.current.style.width = '100px';
        spotlightRef.current.style.height = '30px';
        spotlightRef.current.style.borderRadius = '4px';
        
        tutorialBoxRef.current.style.top = '60px';
        tutorialBoxRef.current.style.left = '250px';
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        tutorialBoxRef.current.style.bottom = 'auto';
      }
    }
  }, [currentStep, screenSize, navigate, onClose]);
  
  // Updated useEffect for Start Building (now step 10, index 9)
  useEffect(() => {
    if (currentStep === 9 && spotlightRef.current && tutorialBoxRef.current) {
      // Find the add button (plus button)
      const addButton = document.querySelector('.plus-button') ||
                       document.querySelector('button:has(svg[class*="Plus"])') ||
                       document.querySelector('button:has(svg[class*="plus"])');
      
      if (addButton) {
        const rect = addButton.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '50%';
        
        // Make it clickable
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        spotlightRef.current.onclick = () => {
          // This is the last step, so finish the tutorial
          onClose();
          
          // Navigate to add section (or trigger the button click)
          // addButton.click(); // Uncomment if you want to trigger the actual button
          navigate('/add-section');
        };
        
        // Position tooltip above the plus button
        tutorialBoxRef.current.style.bottom = `${window.innerHeight - rect.top + 15}px`;
        tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        tutorialBoxRef.current.style.top = 'auto';
      } else {
        // Fallback positioning
        // ... existing fallback code ...
      }
    }
  }, [currentStep, screenSize, navigate, onClose]);
  
  // New useEffect for Edit Your Page (step 11, index 10)
  useEffect(() => {
    if (currentStep === 10 && spotlightRef.current && tutorialBoxRef.current) {
      // Find the EDIT button in the bottom nav
      const editButton = Array.from(document.querySelectorAll('button')).find(el => 
                           el.textContent.includes('EDIT')
                         );
      
      if (editButton) {
        const rect = editButton.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '4px';
        
        // Make it clickable but redirect to root
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        spotlightRef.current.onclick = () => {
          // Just navigate to next step instead of triggering actual click
          setCurrentStep(11);
        };
        
        // Position tooltip above the EDIT button
        tutorialBoxRef.current.style.bottom = `${window.innerHeight - rect.top + 15}px`;
        tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        tutorialBoxRef.current.style.top = 'auto';
      } else {
        // Fallback positioning
        spotlightRef.current.style.top = 'auto';
        spotlightRef.current.style.bottom = '20px';
        spotlightRef.current.style.left = '50px';
        spotlightRef.current.style.width = '80px';
        spotlightRef.current.style.height = '30px';
        spotlightRef.current.style.borderRadius = '4px';
        
        tutorialBoxRef.current.style.top = 'auto';
        tutorialBoxRef.current.style.bottom = '70px';
        tutorialBoxRef.current.style.left = '90px';
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
      }
    }
  }, [currentStep, screenSize, setCurrentStep]);
  
  // New useEffect for Return Home (step 12, index 11)
  useEffect(() => {
    if (currentStep === 11 && spotlightRef.current && tutorialBoxRef.current) {
      // Find the HOME button in the bottom nav
      const homeButton = Array.from(document.querySelectorAll('button')).find(el => 
                           el.textContent.includes('HOME')
                         );
      
      if (homeButton) {
        const rect = homeButton.getBoundingClientRect();
        
        // Position spotlight
        spotlightRef.current.style.top = `${rect.top - 5}px`;
        spotlightRef.current.style.left = `${rect.left - 5}px`;
        spotlightRef.current.style.width = `${rect.width + 10}px`;
        spotlightRef.current.style.height = `${rect.height + 10}px`;
        spotlightRef.current.style.transform = 'none';
        spotlightRef.current.style.borderRadius = '4px';
        
        // Make it clickable
        spotlightRef.current.style.pointerEvents = 'auto';
        spotlightRef.current.style.cursor = 'pointer';
        spotlightRef.current.onclick = () => {
          // This is the last step, so finish the tutorial
          onClose();
          
          // Navigate to root (home dashboard)
          navigate('/');
        };
        
        // Position tooltip above the HOME button
        tutorialBoxRef.current.style.bottom = `${window.innerHeight - rect.top + 15}px`;
        tutorialBoxRef.current.style.left = `${rect.left + (rect.width / 2)}px`;
        tutorialBoxRef.current.style.transform = 'translateX(-50%)';
        tutorialBoxRef.current.style.top = 'auto';
      } else {
        // Fallback positioning
        spotlightRef.current.style.top = 'auto';
        spotlightRef.current.style.bottom = '20px';
        spotlightRef.current.style.right = '50px';
        spotlightRef.current.style.width = '80px';
        spotlightRef.current.style.height = '30px';
        spotlightRef.current.style.borderRadius = '4px';
        
        tutorialBoxRef.current.style.top = 'auto';
        tutorialBoxRef.current.style.bottom = '70px';
        tutorialBoxRef.current.style.right = '90px';
        tutorialBoxRef.current.style.transform = 'translateX(50%)';
      }
    }
  }, [currentStep, screenSize, navigate, onClose]);
  
  // Move the tooltip box for step 12 (index 11) more to the left on mobile
  useEffect(() => {
    // Only for step 12
    if (currentStep === 11 && tutorialBoxRef.current) {
      // Only for mobile
      if (window.innerWidth < 768) {
        // Move the tooltip more to the left on mobile
        tutorialBoxRef.current.style.left = '30%'; // Position further to the left
        tutorialBoxRef.current.style.transform = 'translateX(-35%)'; // Center on that point
        
        // If the tooltip might go off screen on very small screens, adjust
        if (window.innerWidth < 400) {
          tutorialBoxRef.current.style.left = '35%'; // Not quite as far left on very small screens
        }
      }
    }
  }, [currentStep]);
  
  // Add scroll handling for step 7 on mobile
  useEffect(() => {
    // Only for step 7 (index 6)
    if (currentStep === 6) {
      // Function to ensure tooltip stays visible when scrolling
      const handleScroll = () => {
        // Only handle on mobile
        if (window.innerWidth < 768 && tutorialBoxRef.current) {
          // Ensure the tooltip stays in a visible position
          // Fixed position in the viewport that's always visible
          tutorialBoxRef.current.style.position = 'fixed';
          tutorialBoxRef.current.style.top = 'auto';
          tutorialBoxRef.current.style.bottom = '120px'; // Position from bottom
          tutorialBoxRef.current.style.left = '50%';     // Center horizontally
          tutorialBoxRef.current.style.transform = 'translateX(-50%)';
          tutorialBoxRef.current.style.zIndex = '1003';  // Ensure it's above other elements
        }
      };
      
      // Call once immediately to set initial position
      handleScroll();
      
      // Add scroll listener
      window.addEventListener('scroll', handleScroll);
      
      // Clean up
      return () => {
        window.removeEventListener('scroll', handleScroll);
        
        // Reset position style when leaving this step
        if (tutorialBoxRef.current) {
          tutorialBoxRef.current.style.position = 'absolute';
        }
      };
    }
  }, [currentStep]);
  
  // Make desktop spotlight match mobile appearance for step 7
  useEffect(() => {
    // Only for step 7
    if (currentStep === 6 && spotlightRef.current) {
      if (window.innerWidth < 768) {
        // Mobile version - working correctly
        spotlightRef.current.classList.add('full-width-spotlight-mobile');
      } else {
        // Desktop version - full screen with same appearance as mobile
        
        // Set styles for full screen with consistent appearance
        spotlightRef.current.style.position = 'fixed';
        spotlightRef.current.style.top = '0';
        spotlightRef.current.style.left = '0';
        spotlightRef.current.style.right = '0';
        spotlightRef.current.style.bottom = '0';
        spotlightRef.current.style.width = '100%';
        spotlightRef.current.style.height = '100%';
        spotlightRef.current.style.transform = 'none';
        
        // Match the mobile appearance
        spotlightRef.current.style.borderRadius = '0'; // No rounding for full screen
        spotlightRef.current.style.border = '2px solid #FF2300'; // Standard border
        spotlightRef.current.style.boxShadow = '0 0 0 9999px rgba(0, 0, 0, 0.7)'; // Dark overlay
        spotlightRef.current.style.backgroundColor = 'rgba(255, 255, 255, 0.15)'; // Subtle highlight
        spotlightRef.current.style.zIndex = '1001';
        
        // No orange glow effect
        spotlightRef.current.style.filter = 'none';
      }
    }
    
    return () => {
      // Clean up
      if (spotlightRef.current) {
        spotlightRef.current.classList.remove('full-width-spotlight-mobile');
        
        if (window.innerWidth >= 768) {
          // Reset all the styles we set
          spotlightRef.current.style.position = 'absolute';
          spotlightRef.current.style.boxShadow = '';
          spotlightRef.current.style.backgroundColor = '';
          spotlightRef.current.style.filter = '';
          spotlightRef.current.style.border = '';
        }
      }
    };
  }, [currentStep]);
  
  // Change step 10 (index 9) outline to rectangle for both mobile and desktop
  useEffect(() => {
    // Only for step 10
    if (currentStep === 9 && spotlightRef.current) {
      // Get current dimensions and position
      const currentTop = spotlightRef.current.style.top;
      const currentLeft = spotlightRef.current.style.left;
      const currentWidth = spotlightRef.current.style.width;
      const currentHeight = spotlightRef.current.style.height;
      
      // If it's currently a circle, it likely has equal width/height
      // and border-radius of 50% or a large value
      
      // Keep the same center position but make it rectangular
      let newWidth = currentWidth;
      let newHeight = currentHeight;
      
      // If width/height are the same (circle), adjust to make rectangular
      if (currentWidth === currentHeight) {
        // Make width 1.5x the height for a rectangular shape
        const size = parseInt(currentWidth);
        newWidth = `${size * 1.5}px`;
        newHeight = `${size * 0.8}px`;
      }
      
      // Apply rectangular shape
      spotlightRef.current.style.width = newWidth;
      spotlightRef.current.style.height = newHeight;
      spotlightRef.current.style.borderRadius = '4px'; // Small rounded corners
      
      // Make sure any circle-specific styling is removed
      spotlightRef.current.style.borderRadius = '4px';
      
      // Add specific class for any additional styling
      spotlightRef.current.classList.add('rectangular-spotlight');
    }
    
    return () => {
      // Clean up
      if (spotlightRef.current) {
        spotlightRef.current.classList.remove('rectangular-spotlight');
      }
    };
  }, [currentStep]);
  
  // Add supplementary style if needed
  <style>
    {`
      .rectangular-spotlight {
        border-radius: 4px !important;
      }
    `}
  </style>
  
  // Fix step 6 tooltip position (more to the right)
  useEffect(() => {
    // Only for step 6
    if (currentStep === 5 && tutorialBoxRef.current) {
      // Only for mobile
      if (window.innerWidth < 768) {
        // First let any existing positioning code run
        setTimeout(() => {
          if (tutorialBoxRef.current) {
            // Move further right with safety margin
            const screenWidth = window.innerWidth;
            const tooltipWidth = tutorialBoxRef.current.offsetWidth || 280; // Estimate if not available
            const safeRight = screenWidth - tooltipWidth - 20; // 20px safety margin
            
            // Calculate position that keeps tooltip fully on screen
            const rightPosition = Math.min(screenWidth * 0.85, safeRight);
            
            // Apply position
            tutorialBoxRef.current.style.left = `${rightPosition}px`;
            tutorialBoxRef.current.style.transform = 'translateX(-10%)'; // Shift left slightly
          }
        }, 50); // Small delay to run after other positioning code
      }
    }
  }, [currentStep]);
  
  // Fix step 8 tooltip position (more to the right)
  useEffect(() => {
    // Only for step 8
    if (currentStep === 7 && tutorialBoxRef.current) {
      // Only for mobile
      if (window.innerWidth < 768) {
        // First let any existing positioning code run
        setTimeout(() => {
          if (tutorialBoxRef.current) {
            // Move further right with safety margin
            const screenWidth = window.innerWidth;
            const tooltipWidth = tutorialBoxRef.current.offsetWidth || 280; // Estimate if not available
            const safeRight = screenWidth - tooltipWidth - 10; // 10px safety margin
            
            // Calculate position that keeps tooltip fully on screen
            const rightPosition = Math.min(screenWidth * 0.9, safeRight);
            
            // Apply position
            tutorialBoxRef.current.style.left = `${rightPosition}px`;
            tutorialBoxRef.current.style.transform = 'translateX(-20%)'; // Shift left slightly
          }
        }, 50); // Small delay to run after other positioning code
      }
    }
  }, [currentStep]);
  
  // Fix step 11 tooltip position (more to the right)
  useEffect(() => {
    // Only for step 11
    if (currentStep === 10 && tutorialBoxRef.current) {
      // Only for mobile
      if (window.innerWidth < 768) {
        // First let any existing positioning code run
        setTimeout(() => {
          if (tutorialBoxRef.current) {
            // Move further right with safety margin
            const screenWidth = window.innerWidth;
            const tooltipWidth = tutorialBoxRef.current.offsetWidth || 280; // Estimate if not available
            const safeRight = screenWidth - tooltipWidth - 10; // 10px safety margin
            
            // Calculate position that keeps tooltip fully on screen
            const rightPosition = Math.min(screenWidth * 0.95, safeRight);
            
            // Apply position
            tutorialBoxRef.current.style.left = `${rightPosition}px`;
            tutorialBoxRef.current.style.transform = 'translateX(-10%)'; // Shift left slightly
          }
        }, 50); // Small delay to run after other positioning code
      }
    }
  }, [currentStep]);
  
  return (
    <TutorialContainer>
      {/* Semi-transparent overlay with conditional opacity for step 0 */}
      <OverlayWrapper style={currentStep === 0 ? {
        opacity: fadeIn ? 1 : 0,
        transition: 'opacity 0.8s ease-in-out' // Increased from 0.6s to 0.8s
      } : {}} />
      
      {/* Spotlight area with conditional fade for step 0 and step 7 */}
      {currentStep === 0 ? (
        <SpotlightArea 
          ref={spotlightRef} 
          style={{
            opacity: fadeIn ? 1 : 0,
            transition: 'opacity 0.8s ease-in-out' // Added fade-in for spotlight
          }} 
        />
      ) : currentStep === 6 && window.innerWidth < 768 ? (
        <MobileFullWidthSpotlight ref={spotlightRef} />
      ) : (
        <SpotlightArea ref={spotlightRef} />
      )}
      
      {/* Tutorial tooltip with conditional fade for step 0 and step 7 */}
      <TutorialBox 
        ref={tutorialBoxRef}
        style={
          currentStep === 0 ? {
            opacity: fadeIn ? 1 : 0,
            transition: 'opacity 0.8s ease-in-out, top 0.3s ease, left 0.3s ease, bottom 0.3s ease' // Increased from 0.6s to 0.8s
          } : 
          currentStep === 6 ? {
            opacity: step7Fade ? 1 : 0,
            transition: 'opacity 0.6s ease-in-out, top 0.3s ease, left 0.3s ease, bottom 0.3s ease'
          } : {}
        }
      >
        <TutorialTitle>{currentStepConfig.title}</TutorialTitle>
        <TutorialDescription>{currentStepConfig.description}</TutorialDescription>
        
        {/* For first step, special button arrangement with Skip on left */}
        {currentStep === 0 ? (
          <ButtonGroup>
            <SkipButton onClick={onClose}>Skip tour</SkipButton>
            <div></div> {/* Empty div to maintain space-between layout */}
            <NextButton onClick={handleNextClick}>Next</NextButton>
          </ButtonGroup>
        ) : (
          /* Normal button arrangement for other steps */
          <ButtonGroup>
            <BackButton onClick={handleBackClick}>Back</BackButton>
            <SkipButton onClick={onClose}>Skip tour</SkipButton>
            <NextButton onClick={handleNextClick}>
              {currentStep === tutorialSteps.length - 1 ? 'Finish' : 'Next'}
            </NextButton>
          </ButtonGroup>
        )}
        
        <StepIndicator>
          {tutorialSteps.map((_, index) => (
            <StepDot key={index} active={index === currentStep} />
          ))}
        </StepIndicator>
      </TutorialBox>
      
      {/* Final Animation Screen with enhanced fade */}
      {showFinalAnimation && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: finalAnimationFadeOut 
              ? 'radial-gradient(circle, rgba(255,35,0,0) 0%, rgba(255,35,0,0) 100%)' 
              : 'radial-gradient(circle, rgba(255,35,0,1) 70%, rgba(255,35,0,1) 100%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1100, // Above everything else
            transition: 'background 1.7s ease-in-out',
            pointerEvents: 'none',
          }}
        >
          {/* Dizee Logo with enhanced fade */}
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            {/* First layer - radial background that fades from the center */}
            <div 
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: finalAnimationFadeOut 
                  ? 'radial-gradient(circle, rgba(255,35,0,0) 0%, rgba(255,35,0,0) 100%)' 
                  : 'radial-gradient(circle, rgba(255,35,0,1) 70%, rgba(255,35,0,0.9) 100%)',
                opacity: finalAnimationFadeOut ? 0 : 1,
                transition: 'opacity 1.7s ease-in-out, transform 1.7s ease-in-out',
                transform: finalAnimationFadeOut ? 'scale(1.5)' : 'scale(1)',
              }}
            />
            
            {/* DIZEE text that scales and fades */}
            <p
              style={{
                color: 'white',
                fontSize: '32px',
                letterSpacing: '4px',
                position: 'relative',
                zIndex: 1101,
                opacity: finalAnimationFadeOut ? 0 : 1,
                transform: finalAnimationFadeOut ? 'scale(2.5)' : 'scale(1)',
                transition: 'opacity 1.7s ease-in-out, transform 1.7s ease-in-out',
                textShadow: '0 0 15px rgba(255,255,255,0.5)',
              }}
            >
              D I Z E E
            </p>
          </div>
        </div>
      )}
    </TutorialContainer>
  );
};

export default DizeeTutorial; 