import React from 'react';
import { useNavigate } from 'react-router-dom';
import Plus from '../../../../svg/Plus';
import { maxLength } from '../../../DizeeComponent/DixeeInput2';
import { useSelector } from 'react-redux';

export default function EditFocusMusicCard({ item, onClick }) {
    const music = useSelector((state) => state.focuspage.music);

    return (
        <div className='w-[350px] sm:w-[390px] p-5 flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-x-[11px]'>
                <img
                    src={item?.avatar}
                    alt='alt'
                    className='object-cover h-[50px] w-[50px] rounded-[3px]'
                />
                <div className='flex flex-col items-start justify-start h-full p-2'>
                    <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
                        {maxLength(item?.title?.toString(), 30)}
                    </p>
                    <p className='text-[#696974]' style={{ fontSize: '12px' }}>
                        {maxLength(item?.artists[0]?.name.toString(), 30)}
                    </p>
                </div>
            </div>
            <button onClick={onClick}>
                <Plus className='h-[12px] w-[12px] cursor-pointer' />
            </button>
        </div>
    );
} 