import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the default stylesheet
import "../../../../css/datePickerStyle.css"
import { useLocation, useNavigate } from 'react-router-dom';
import LayoutHeader from '../../../../layout/LayoutHeader';
import { useDispatch, useSelector } from 'react-redux';
import { updateEventDate } from '../../../../store/focuseventdata/focuseventdataSlice';
import { formatDate } from '../../../../components/DizeeComponent/DixeeInput2';
import { setFocusEvent } from '../../../../store/focuspage/focuspageSlice';
import { updateEventForFocusThunk } from '../../../../store/focuspage/focuspageThunk';

export default function DatePickerFocus() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { route, prevData } = location.state;
    const eventDate = useSelector((state) => state.focuseventdata.date);
    // const eventDate1 = useSelector((state) => state.);
    console.log(`route::`, route, prevData);

    const [dateData, setData] = useState(eventDate)

    useEffect(() => {
        if (route == '/focus-page/event-type/edit-content') {
            if (prevData.date == '') {
                setData('')
            } else {
                setData(prevData.date)
            }
        } else if (route == '/focus-page/event-type/edit-multi-content') {
            if (prevData.currentProduct.date == '') {
                setData('')
            } else {
                setData(prevData.currentProduct.date)
            }
        }
    })

    const navigation = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault()
        // if (route == '/focus-page/event-type/edit-content') {
        //     dispatch(updateEventForFocusThunk({
        //         token,
        //         payload: {
        //             ...currentProduct
        //         }
        //     }));
        // } else {

        // }
        navigation(`${route}`);
    };
    const onBack = (e) => {
        e.preventDefault()
        navigation(route);
    };
    return (
        <LayoutHeader>
            <div className="h-[75vh] flex items-center justify-center">
                <div className="flex items-center justify-center gap-4 sm:gap-16">
                    {/* <BackIcon onClick={onBack} className={"mb-[250px]"} /> */}
                    <div className="datepicker-container flex flex-col justify-center items-start h-[80vh]">
                        <DatePicker
                            selected={dateData}
                            onChange={date => {
                                if (route == `/focus-page/event-type/edit-content`) {
                                    // dispatch(setFocusEvent())
                                    navigation('/focus-page/event-type/edit-content', {
                                        state: {
                                            ...prevData,
                                            date: formatDate(date)
                                        }
                                    })
                                } else if (route == `/focus-page/event-type/edit-multi-content`) {
                                    navigation('/focus-page/event-type/edit-multi-content', {
                                        state: {
                                            route: '/focus-page/event-type/date-picker',
                                            ...prevData,
                                            currentProduct: {
                                                ...prevData.currentProduct,
                                                date: formatDate(date)
                                            }
                                        }
                                    })
                                } else if (route != '/focus-page/event-type/edit-content') {
                                    dispatch(updateEventDate(date))
                                }
                            }}
                            inline
                        />
                        <div>

                            <button className="text-white text-[12px] px-4 py-2 rounded-md mt-4" onClick={handleSubmit}>Submit</button>
                            <button className="text-white text-[12px] px-4 py-2 rounded-md mt-4" onClick={onBack}>Go back</button>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
}
