import Check from "../../../../svg/Check";
import dizeeLogo from "../../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../../components/OnBoard/Typewriter";
import { NextButton } from "../../../../components/DizeeComponent/NextButton";
import styled from "styled-components";
import { useState, useEffect } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ColorOptionsContainer = styled.div`
  width: 200px;
  margin-bottom: 40px;
`;

const ColorOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 12px;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const FooterHeader = styled.div`
  position: relative;
  width: 100%;
`;

const ExampleLink = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 0px;
  display: none;
`;

const colorDescriptions = {
  default: "Select from our curated color palettes or create your own. Your Dizee color palette will be applied to the background and accent colors across all your pages.",
  classic: "Embrace timeless elegance with our Classic theme. The perfect balance of black and white creates a sophisticated, professional look that lets your content shine.",
  matrix: "Enter the digital realm with our Matrix theme. Bold neon green accents on a dark background create a cutting-edge, tech-savvy aesthetic that stands out.",
  ocean: "Dive into tranquility with our Ocean theme. Calming deep blues paired with serene teal accents create a peaceful, professional atmosphere for your content.",
  sunset: "Experience the warmth of our Sunset theme. Rich purple depths meet vibrant red accents to create a bold, creative space that captures attention. Select this theme to make your profile stand out.",
  custom: "Make it uniquely yours with our Custom theme. Choose your perfect color combination to express your personal brand exactly how you envision it."
};

const ColorTemplate = ({ setStep, setUser, user }) => {
  const [currentDescription, setCurrentDescription] = useState(colorDescriptions.default);
  const [isCustomSelected, setIsCustomSelected] = useState(false);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = originalStyle;
      }
    };
    
    handleResize();
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      document.body.style.overflow = originalStyle;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleColorSelect = (background, accent, theme) => {
    setUser({ ...user, background, accent });
    setCurrentDescription(colorDescriptions[theme]);
    setIsCustomSelected(theme === 'custom');
  };

  const handleExampleClick = () => {
    const prevBackground = user.background;
    const prevAccent = user.accent;
    
    setStep(7);

    const handleReturn = () => {
      setUser({ ...user, background: undefined, accent: undefined });
      setCurrentDescription(colorDescriptions.default);
      window.removeEventListener('popstate', handleReturn);
    };
    
    window.addEventListener('popstate', handleReturn);
  };

  return (
    <Container>
      <FormSection>
        <ColorOptionsContainer>
          <div className="mb-[68px]">
            <TypeWriter text={"Select a color"} speed={45} />
          </div>
          <ColorOptions>
            <div className="flex justify-between">
              <div
                className="cursor-pointer flex justify-center items-center"
                onClick={() => handleColorSelect("121212", "FFFFFF", "classic")}
              >
                Classic
                <div className="ml-2 mt-[-2px] h-[12px] w-[18px] flex items-center justify-center">
                  {user.background === "121212" && user.accent === "FFFFFF" && (
                    <Check className="h-full w-full mt-[2px]" />
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="w-[20px] h-[20px] border rounded-full z-10 bg-[#121212]"></div>
                <div className="w-[20px] h-[20px] border rounded-full bg-white ml-[-5px]"></div>
              </div>
            </div>

            <div className="flex justify-between">
              <div
                className="cursor-pointer flex justify-center items-center"
                onClick={() => handleColorSelect("1E1E1E", "00FF00", "matrix")}
              >
                Matrix
                <div className="ml-2 mt-[-2px] h-[12px] w-[18px] flex items-center justify-center">
                  {user.background === "1E1E1E" && user.accent === "00FF00" && (
                    <Check className="h-full w-full mt-[2px]" />
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="w-[20px] h-[20px] border rounded-full z-10 bg-[#1E1E1E]"></div>
                <div className="w-[20px] h-[20px] rounded-full bg-[#00FF00] ml-[-5px]"></div>
              </div>
            </div>

            <div className="flex justify-between">
              <div
                className="cursor-pointer flex justify-center items-center"
                onClick={() => handleColorSelect("1A1A2E", "4ECCA3", "ocean")}
              >
                Ocean
                <div className="ml-2 mt-[-2px] h-[12px] w-[18px] flex items-center justify-center">
                  {user.background === "1A1A2E" && user.accent === "4ECCA3" && (
                    <Check className="h-full w-full mt-[2px]" />
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="w-[20px] h-[20px] border rounded-full z-10 bg-[#1A1A2E]"></div>
                <div className="w-[20px] h-[20px] rounded-full bg-[#4ECCA3] ml-[-5px]"></div>
              </div>
            </div>

            <div className="flex justify-between">
              <div
                className="cursor-pointer flex justify-center items-center"
                onClick={() => handleColorSelect("2D142C", "EE4540", "sunset")}
              >
                Sunset
                <div className="ml-2 mt-[-2px] h-[12px] w-[18px] flex items-center justify-center">
                  {user.background === "2D142C" && user.accent === "EE4540" && (
                    <Check className="h-full w-full mt-[2px]" />
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="w-[20px] h-[20px] border rounded-full z-10 bg-[#2D142C]"></div>
                <div className="w-[20px] h-[20px] rounded-full bg-[#EE4540] ml-[-5px]"></div>
              </div>
            </div>

            <div className="flex justify-between">
              <div
                className="cursor-pointer flex justify-center items-center"
                onClick={() => handleColorSelect("000000", "FFFFFF", "custom")}
              >
                Custom
                <div className="ml-2 mt-[-2px] h-[12px] w-[18px] flex items-center justify-center">
                  {user.background === "000000" && user.accent === "FFFFFF" && (
                    <Check className="h-full w-full mt-[2px]" />
                  )}
                </div>
              </div>
            </div>
          </ColorOptions>

          <ButtonContainer>
            <NextButton
              className={`cursor-pointer mr-[22px] ${
                user.background !== undefined && user.accent !== undefined
                  ? "font-default"
                  : "font-gray"
              }`}
              onClick={() => {
                if (user.background !== undefined && user.accent !== undefined) {
                  if (user.background === "000000" && user.accent === "FFFFFF") {
                    setStep(20);
                  } else {
                    setStep(9);
                  }
                }
              }}
            >
              Next
            </NextButton>
            <NextButton className="cursor-pointer" onClick={() => setStep(5)}>
              Go back
            </NextButton>
          </ButtonContainer>
        </ColorOptionsContainer>
      </FormSection>
      
      <Footer>
        <FooterHeader>
          <LogoImage src={dizeeLogo} alt="DIZEE" />
          {!isCustomSelected && (
            <ExampleLink onClick={handleExampleClick}>
              Example
            </ExampleLink>
          )}
        </FooterHeader>
        <FooterText>
          {currentDescription}
        </FooterText>
      </Footer>
    </Container>
  );
};

export default ColorTemplate;
