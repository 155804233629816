import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { setErrorAlert, setLoader } from '../../../../store/alert/alertSlice';
import { updateCustomForFocusThunk, getDataForFocusThunk, updateProductForFocusThunk, updateMultiProductForFocusThunk, updateMultiEventForFocusThunk } from '../../../../store/focuspage/focuspageThunk';
import { clearFocusSection } from '../../../../store/focuspage/focuspageSlice';
import { uploadImage } from '../../../../utils/upload';
import LayoutHeader from '../../../../layout/LayoutHeader';
import DizeeInput from '../../../../components/DizeeComponent/DizeeInput';
import AddedCustom from '../../../../components/Focuspage/Type/Custom/AddedCustom';
import OverlayCustom from '../../../../components/Focuspage/Type/Custom/OverlayCustom';
import Check from '../../../../svg/Check';
import AddedProduct from '../../../../components/Focuspage/Type/Product/AddedProduct';
import AddedEvent from '../../../../components/AddSection/Event/AddedEvent';
import AddedEventComp from '../../../../components/Focuspage/Type/Event/AddedEventComp';
import { formatDate } from '../../../../components/DizeeComponent/DixeeInput2';

export default function EditMultiEventContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    // Get data from navigation state
    const extension = location.state?.extension;
    const page = location.state?.page;

    console.log(`location.state::`, location.state)
    // Redirect if no pageData
    // useEffect(() => {
    //     if (!pageData || !pageId) {
    //         navigate('/focus-page/multiple-product-page');
    //     }
    // }, [pageData, pageId, navigate]);

    // Initialize state with existing product content

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const [productItems, setProductItems] = useState(
        location.state?.productContent?.map(item => ({
            ...item,
            preview: item.image,
        })) || []
    );

    console.log(`productItems::`, productItems);

    const [currentProduct, setCurrentProduct] = useState({
        link: '',
        location: '',
        venue: '',
        date: '',
    });

    // if (location.state.route == '/focus-page/event-type/date-picker') {

    // }

    useEffect(() => {
        if (location.state.route == '/focus-page/event-type/date-picker') {
            setCurrentProduct(location.state.currentProduct)
            setProductItems(location.state?.productItems?.map(item => ({
                ...item,
                preview: item.image,
            })) || [])
            setSelectedIndex(location.state.selectedIndex)
        }
    }, [location.state.route])


    // Form validation flags
    const isFormComplete = currentProduct.link != '' && currentProduct.location != '' && currentProduct.venue != '';

    const isDoneValid = () => {
        return productItems.length > 0 || isFormComplete;
    };

    // URL validation
    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    // Handle link change
    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setCurrentProduct({ ...currentProduct, link: value });
    };

    // Handle product click for editing
    const handleProductClick = (item, index) => {
        console.log(`item::`, item);
        setSelectedProduct({
            ...item
        });
        setSelectedIndex(index);
        setCurrentProduct({
            ...currentProduct,
            link: item.link,
            location: item.location,
            venue: item.venue || '',
            date: item.date || '',
        });
        setIsOverlayVisible(true);
    };

    // Handle edit
    const handleEditProduct = () => {
        if (selectedProduct && selectedIndex !== null) {
            setCurrentProduct({
                ...currentProduct,
                link: selectedProduct.link,
                location: selectedProduct.location,
                venue: selectedProduct.venue || '',
                date: selectedProduct.date || '',
            });
            setIsOverlayVisible(false);
        }
    };

    // Handle adding another item
    const handleAddAnother = () => {
        if (!isFormComplete) {
            dispatch(setErrorAlert('Please fill in all required fields'));
            return;
        }

        if (selectedIndex !== null) {
            const updatedItems = [...productItems];
            updatedItems[selectedIndex] = {
                link: currentProduct.link,
                location: currentProduct.location,
                venue: currentProduct.venue || '',
                date: currentProduct.date || '',
            };
            setProductItems(updatedItems);
            setSelectedIndex(null);
        } else {
            setProductItems([...productItems, {
                link: currentProduct.link,
                location: currentProduct.location,
                venue: currentProduct.venue || '',
                date: currentProduct.date || '',
            }]);
        }

        setCurrentProduct({
            link: '',
            location: '',
            venue: '',
            date: '',
        });
    };

    // Handle delete
    const handleDeleteProduct = () => {
        const filteredItems = productItems.filter((_, index) => index !== selectedIndex);
        setProductItems(filteredItems);
        setIsOverlayVisible(false);
    };

    // Handle move up/down
    const handleMoveUp = () => {
        if (selectedIndex > 0) {
            const updatedItems = [...productItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex - 1]] =
                [updatedItems[selectedIndex - 1], updatedItems[selectedIndex]];
            setProductItems(updatedItems);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleMoveDown = () => {
        if (selectedIndex < productItems.length - 1) {
            const updatedItems = [...productItems];
            [updatedItems[selectedIndex], updatedItems[selectedIndex + 1]] =
                [updatedItems[selectedIndex + 1], updatedItems[selectedIndex]];
            setProductItems(updatedItems);
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle done
    const handleDone = async () => {
        if (!isDoneValid()) {
            dispatch(setErrorAlert('Please add at least one product'));
            return;
        }

        dispatch(setLoader(true));

        try {
            let finalProductItems = [...productItems];

            if (selectedIndex !== null && isFormComplete) {
                finalProductItems[selectedIndex] = {
                    link: currentProduct.link,
                    location: currentProduct.location,
                    venue: currentProduct.venue,
                    date: currentProduct.date,
                };
            } else if (isFormComplete) {
                finalProductItems.push({
                    link: currentProduct.link,
                    location: currentProduct.location,
                    venue: currentProduct.venue,
                    date: currentProduct.date,
                });
            }

            console.log(`finalProductItems::`, finalProductItems);

            const updateData = {
                token,
                payload: {
                    finalProductItems,
                    page,
                    extension
                }
            };

            const result = await dispatch(updateMultiEventForFocusThunk(updateData)).unwrap();

            if (result.status === 200) {
                // dispatch(getDataForFocusThunk({ token, pageId }));
                // dispatch(clearFocusSection());
                navigate('/focus-page/multiple-event-page');
            } else {
                throw new Error(result.message || 'Update failed');
            }

        } catch (error) {
            dispatch(setLoader(false));
            dispatch(setErrorAlert(error?.message || 'Failed to update products'));
            console.error('Error updating products:', error);
        }
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                {/* Fixed Header */}
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Edit content</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        {isFormComplete && (
                            <button
                                onClick={handleAddAnother}
                                className="text-white cursor-pointer"
                            >
                                Another
                            </button>
                        )}
                        <button
                            onClick={handleDone}
                            className={`text-white cursor-pointer ${!isDoneValid() ? 'opacity-50 pointer-events-none' : ''}`}
                        >
                            Done
                        </button>
                        <p
                            onClick={() => navigate(-1)}
                            className="text-white cursor-pointer"
                        >
                            Go back
                        </p>
                    </div>
                </div>

                {/* Form Content */}
                <div className="w-full px-4">
                    {/* Section Title */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Enter Link"
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                link: e.target.value
                            })}
                            value={currentProduct.link}
                        />
                        {currentProduct.link && <Check className="h-[12px] w-[18px] ml-2" />}
                    </div>

                    {/* Description */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Enter Location"
                            value={currentProduct.location}
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                location: e.target.value
                            })}
                            className="w-full bg-transparent text-white placeholder-[rgba(255,255,255,0.5)] px-0 py-3 min-h-[50px] resize-y outline-none text-[12px]"
                        />
                    </div>

                    {/* Link */}
                    <div className="flex items-center mb-4">
                        <DizeeInput
                            placeholder="Enter Venue"
                            onChange={(e) => setCurrentProduct({
                                ...currentProduct,
                                venue: e.target.value
                            })}
                            value={currentProduct.venue}
                        />
                        {(currentProduct.venue) && <Check className="h-[12px] w-[18px] ml-2" />}
                    </div>

                    {!currentProduct.date ?
                        <div className='py-4 flex w-full justify-between items-center' >
                            <div className='items-center flex flex-row text-white w-full'>
                                <button className='text-white text-[12px]' style={{ fontSize: '12px' }} onClick={(e) => {
                                    e.preventDefault()
                                    navigate('/focus-page/event-type/date-picker', {
                                        state: {
                                            route: '/focus-page/event-type/edit-multi-content', prevData: {
                                                currentProduct, productItems, selectedIndex, page, extension
                                            }
                                        }
                                    })
                                }
                                }
                                >Select a date</button>
                            </div>
                        </div>
                        :
                        <div className="flex flex-col justify-center items-center w-full ">
                            <div className='py-4 flex w-full justify-between items-center ' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white'>
                                    <button className='text-white text-[12px]' style={{ fontSize: '12px' }} onClick={(e) => {
                                        e.preventDefault()
                                        navigate('/focus-page/event-type/date-picker', {
                                            state: {
                                                route: '/focus-page/event-type/edit-multi-content', prevData: {
                                                    currentProduct, productItems, selectedIndex, page, extension
                                                }
                                            }
                                        })
                                    }
                                    }
                                    >Change date</button>
                                </div>
                                <div className=''>
                                    <p className='text-white text-[12px]'>{formatDate(currentProduct.date)}</p>
                                </div>
                            </div>
                        </div>

                    }


                    {/* Added Products */}
                    {productItems.length > 0 && (
                        <div className="flex flex-col w-full mt-[40px]">
                            <div className='pb-[20px] flex w-full items-center'>
                                <div className='flex flex-row text-white w-full'>
                                    <p>Added Products</p>
                                </div>
                            </div>
                            <div className="space-y-4">
                                {productItems.map((item, index) => (
                                    <AddedEventComp
                                        key={index}
                                        item={item}
                                        setOverlayVisible={setIsOverlayVisible}
                                        setItemForBackend={(item) => handleProductClick(item, index)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                </div>

                {/* Footer */}
                {/* <div className="w-full absolute bottom-0 left-0">
                    <div className="w-full h-[20px] bg-gradient-to-t from-black to-transparent" />
                    <div className="w-full bg-black px-4 py-6">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Edit your product content.</p>
                            <p>Image is required. Add description and link to enhance the presentation.</p>
                        </div>
                    </div>
                </div> */}

                {/* Overlay */}
                {isOverlayVisible && (
                    <OverlayCustom
                        isOverlayVisible={isOverlayVisible}
                        setOverlayVisible={setIsOverlayVisible}
                        selectedItem={selectedProduct}
                        handleEdit={handleEditProduct}
                        handleDelete={handleDeleteProduct}
                        handleMoveUp={handleMoveUp}
                        handleMoveDown={handleMoveDown}
                        canMoveUp={selectedIndex > 0}
                        canMoveDown={selectedIndex < productItems.length - 1}
                    />
                )}
            </div>
        </LayoutHeader>
    );
} 