import React from 'react';

const FocusNewsletterOverlay = ({ isOverlayVisible, setOverlayVisible, selectedNewsletter, handleEdit, handleDelete }) => {
    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setOverlayVisible(false);
        }
    };

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >
            <div 
                className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" 
                onClick={e => e.stopPropagation()}
            >
                <p className="cursor-pointer pb-[10px]" onClick={handleEdit}>Edit</p>
                <p className="cursor-pointer pb-[10px]" onClick={handleDelete}>Delete</p>
                <p className="cursor-pointer pb-[10px]" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div>
        </div>
    );
};

export default FocusNewsletterOverlay; 