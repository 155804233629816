import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import Check from '../../../../../svg/Check';

const StyleOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 12px;
  color: white;
`;

const StyleOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
`;

const PreviewBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
  align-items: center;
`;

const PreviewBox = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  width: ${props => props.width};
  height: ${props => props.height};
  margin-bottom: ${props => props.marginBottom || '0'};
`;

const PreviewContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Line = styled.div`
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
  width: ${props => props.width};
  margin-bottom: ${props => props.marginBottom || '0'};
`;

const Footer = styled.div`
  position: absolute;
  bottom: 27px;
  width: 100%;
  padding: 0 16px;
  left: 0;
  transform: none;
  margin-left: 0;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.5;
`;

const STYLE_LAYOUTS = {
  music: [
    { width: '40px', height: '40px' },
    { width: '40px', height: '40px' },
    { width: '40px', height: '40px' }
  ],
  video: [
    { width: '62px', height: '80px' }
  ],
  event: [
    { type: 'container', style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      children: [
        { type: 'line', width: '100px' },
        { type: 'line', width: '100px' },
        { type: 'line', width: '100px' }
      ]
    }}
  ],
  product: [
    { width: '40px', height: '40px' },
    { type: 'container', style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginLeft: '8px',
      children: [
        { type: 'line', width: '100px' },
        { type: 'line', width: '80px' },
        { type: 'line', width: '60px' }
      ]
    }}
  ],
  social: [
    { width: '40px', height: '52px' },
    { width: '40px', height: '52px' },
    { width: '40px', height: '52px' }
  ],
  text: [
    { type: 'container', style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      children: [
        { type: 'line', width: '100px' },
        { type: 'line', width: '100px' }
      ]
    }}
  ],
  form: [
    { type: 'container', style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      children: [
        { type: 'line', width: '100px' },
        { width: '40px', height: '12px' }
      ]
    }}
  ],
  gallery: [
    { type: 'container', style: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '2px',
      width: '62px',
      height: '80px',
      children: [
        { width: '40px', height: '40px' },     // Large square (top left)
        { width: '20px', height: '40px' },     // Tall rectangle (top right)
        { width: '20px', height: '40px' },     // Tall rectangle (middle)
        { width: '40px', height: '40px' },     // Large square (middle right)
        { width: '62px', height: '38px' }      // Full-width rectangle (bottom)
      ]
    }}
  ]
};

const FOCUS_PAGE_STYLES = {
  music: "Display your music with cover art, title, and artist information in a horizontal scrolling layout.",
  video: "Present videos with thumbnails and titles in a clean, engaging format.",
  event: "Show upcoming events with dates, locations, and details in an organized list view.",
  product: "Showcase products with images and details in a grid layout.",
  social: "Display social media posts in a dynamic feed format.",
  text: "Add formatted text content with customizable styles and layouts.",
  form: "Create interactive forms to collect information from your audience.",
  gallery: "Display your images in a beautiful gallery layout with clickable links."
};

export default function Step6() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedStyle, setSelectedStyle] = useState(null);
    const custom = useSelector(state => state.focuspage.custom);

    const handleNext = () => {
        if (selectedStyle) {
            navigate(`/focus-page/custom-type/${selectedStyle}/add-content`, {
                state: { 
                    style: selectedStyle,
                    returnPath: '/focus-page/custom-type/preview'
                }
            });
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                    <div className="px-4 my-[50px] flex w-full justify-between">
                        <p className="text-white">Select Style</p>
                        <div className="flex gap-[30px]">
                            <button 
                                onClick={handleNext}
                                disabled={!selectedStyle}
                                className={`text-white cursor-pointer ${!selectedStyle ? 'opacity-50' : ''}`}
                            >
                                Next
                            </button>
                            <div 
                                className="text-white cursor-pointer"
                                onClick={() => navigate("/focus-page/custom-type/step5")}
                            >
                                Go back
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-4">
                        <StyleOptions>
                            {Object.entries(FOCUS_PAGE_STYLES).map(([style, description]) => (
                                <StyleOption key={style} onClick={() => setSelectedStyle(style)}>
                                    <div className="flex items-center gap-2">
                                        <span className="capitalize">{style} Style</span>
                                        {selectedStyle === style && (
                                            <Check className="h-3 w-3" />
                                        )}
                                    </div>
                                    {selectedStyle === style && (
                                        <PreviewBoxes>
                                            {STYLE_LAYOUTS[style].map((item, i) => (
                                                item.type === 'container' ? (
                                                    <PreviewContainer key={i} style={item.style}>
                                                        {item.style.children.map((child, j) => (
                                                            child.type === 'line' ? 
                                                                <Line key={`${i}-${j}`} {...child} /> :
                                                                <PreviewBox key={`${i}-${j}`} {...child} />
                                                        ))}
                                                    </PreviewContainer>
                                                ) : item.type === 'line' ? (
                                                    <Line key={i} {...item} />
                                                ) : (
                                                    <PreviewBox key={i} {...item} />
                                                )
                                            ))}
                                        </PreviewBoxes>
                                    )}
                                </StyleOption>
                            ))}
                        </StyleOptions>
                    </div>

                    <Footer>
                        <FooterText>
                            {selectedStyle ? FOCUS_PAGE_STYLES[selectedStyle] : 
                             "Select a style that best fits your content. Each style is optimized for different types of media and information."}
                        </FooterText>
                    </Footer>
                </div>
            </div>
        </LayoutHeader>
    );
} 