import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_user_articles_thunk,
  update_user_article_thunk,
} from "../../store/user/userThunk";
import { clearAlerts } from "../../store/alert/alertSlice";
import { useNavigate, useParams } from "react-router";
import LayoutHeader from "../../layout/LayoutHeader";
import {
  getContrastColor,
  removeUploadsFromUrl,
} from "../../components/DizeeComponent/DixeeInput2";
import { getAllSectionsThunk } from "../../store/addsection/addsectionThunk";
import Plus from "../../svg/Plus";
import {
  setUserArticle,
} from "../../store/user/userSlice";
import noiseTexture from '../../assets/images/Noise.png';
import debounce from 'lodash/debounce';
import { clearVault, updateCurPlayStreamData, updateImageOverlay } from "../../store/vaultData/vaultdataSlice";
import PublishOverlay from "../../components/PublishOverlay";
import More from "../../svg/More";
import ImageOverlay from "../../components/Vault/Edit/ImageOverlay";
import { get_valut_data_by_username_thunk, get_valut_data_thunk } from "../../store/vaultData/vaultdataThunk";
import StreamOverlay from "./StreamOverlay";

const hslToHex = (h, s, l) => {
  s /= 100;
  l /= 100;
  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;
  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (h >= 300 && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255)
    .toString(16)
    .padStart(2, "0");
  g = Math.round((g + m) * 255)
    .toString(16)
    .padStart(2, "0");
  b = Math.round((b + m) * 255)
    .toString(16)
    .padStart(2, "0");
  return `#${r}${g}${b}`;
};

// Add this helper function to convert hex to HSL
const hexToHSL = (hex) => {
  // Remove the # if present
  hex = hex.replace(/^#/, '');

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find min and max values
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);

  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100)
  };
};

// Update the bottomNavContainerStyle object at the top of the component
const bottomNavContainerStyle = {
  borderTop: '0.5px solid rgba(255, 255, 255, 0.1)',
  background: '#000000',
  position: 'relative',
  zIndex: 50
};

export default function Vault() {
  const dispatch = useDispatch();

  const { username } = useParams();
  console.log(`username:`, username);


  const [isOverlayVisible, setOverlayVisible] = useState(false);
  let token = localStorage.getItem("dizeeToken");
  const userArticle = useSelector((state) => state.user.userArticle);

  const [isOwner, setOwner] = useState(false);

  useEffect(() => {
    if (userArticle.username === username) {
      setOwner(true)
    }
  }, [userArticle])

  useEffect(() => {
    if (token) {
      dispatch(get_user_articles_thunk({ token }));
      if (isOwner) {
        dispatch(get_valut_data_thunk({ token }));
      } else {
        let body = {
          username
        }
        console.log(`body::`, body);
        dispatch(get_valut_data_by_username_thunk({ token, body }))
      }
    }
  }, [token, dispatch]);



  console.log(`userarticle::`, userArticle, userArticle.username === username, userArticle.username === username ? true : false);
  const vaultData = useSelector((state) => state.vaultData);
  console.log(`valutData::`, vaultData);
  const imageOverlay = useSelector((state) => state.vaultData.isImageOverlay);

  const [color, setColor] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(false);
  const [backgroundShade, setBackgroundShade] = useState(false);
  const [accentColor, setAccentColor] = useState(false);
  const [accentShade, setAccentShade] = useState(false);
  const [hue, setHue] = useState(0); // Hue state for color sliders
  const [brightness, setBrightness] = useState(50); // Brightness state for shade sliders
  const [styleMenu, setStyleMenu] = useState(false);
  const [hexBackground, setHexBackground] = useState("");
  const [hexAccent, setHexAccent] = useState("");

  const [showPublish, setShowPublish] = useState(false);

  const [showDetail, setShowDetail] = useState(false);

  const handleShowDetail = () => {
    setShowDetail(!showDetail);
  }

  // Add initial state values
  const [defaultValues] = useState({
    grayscale: "0",
    noise: "0"
  });

  // Add debounce to prevent rapid state updates
  const debouncedUpdateArticle = useCallback(
    debounce((newValue) => {
      dispatch(setUserArticle(newValue));
    }, 100),
    [dispatch]
  );

  // Handle hue change
  const handleHueChangeBackground = (e) => {
    setHue(e.target.value);
    const hexColor = hslToHex(e.target.value, 100, brightness);
    const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
    setHexBackground(`#${colorValue}`);
    debouncedUpdateArticle({ ...userArticle, background: colorValue });
  };

  // Handle brightness change
  const handleBrightnessChangeBackground = (e) => {
    setBrightness(e.target.value);
    const hexColor = hslToHex(hue, 100, e.target.value);
    const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
    debouncedUpdateArticle({ ...userArticle, background: colorValue });
  };
  const handleHueChangeAccent = (e) => {
    setHue(e.target.value);
    const hexColor = hslToHex(e.target.value, 100, brightness);
    const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
    setHexAccent(`#${colorValue}`);
    dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
  };

  // Handle brightness change
  const handleBrightnessChangeAccent = (e) => {
    setBrightness(e.target.value);
    const hexColor = hslToHex(hue, 100, e.target.value);
    const colorValue = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
    dispatch(setUserArticle({ ...userArticle, accent: colorValue }));
  };

  // Update grayscale handler with safety checks
  const onChangeGrayScale = (e) => {
    try {
      const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
      dispatch(setUserArticle({
        ...userArticle,
        grayscale: value
      }));
    } catch (error) {
      console.error('Grayscale update error:', error);
      dispatch(setUserArticle({
        ...userArticle,
        grayscale: defaultValues.grayscale
      }));
    }
  };

  // Add reset function for when closing controls
  const resetEffects = () => {
    dispatch(setUserArticle({
      ...userArticle,
      grayscale: defaultValues.grayscale,
      noise: defaultValues.noise
    }));
  };

  const updateUserArticle = async (bool) => {
    dispatch(update_user_article_thunk({ token, body: { ...userArticle, unpublish: bool } }));
  };

  const navigation = useNavigate();

  useEffect(() => {
    document.body.classList.toggle("no-scroll", isOverlayVisible);
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOverlayVisible]);

  const [grayScaleBar, setGrayScaleBar] = useState(false);
  const [noiseBar, setNoiseBar] = useState(false);

  const [streamData, setStreamData] = useState('');

  const onChangeNoise = (e) => {
    try {
      const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100);
      dispatch(setUserArticle({
        ...userArticle,
        noise: value  // Keep as number like grayscale
      }));
    } catch (error) {
      console.error('Noise update error:', error);
      dispatch(setUserArticle({
        ...userArticle,
        noise: defaultValues.noise
      }));
    }
  };

  // Update the color input handler
  const handleColorInput = (e) => {
    const colorValue = e.target.value.toUpperCase();
    if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
      setHexBackground(colorValue);

      // Only process complete hex colors
      if (colorValue.length === 7) {
        // Convert hex to HSL and update slider
        const hsl = hexToHSL(colorValue);
        setHue(hsl.h);
        setBrightness(hsl.l);

        dispatch(setUserArticle({
          ...userArticle,
          background: colorValue.slice(1)
        }));
      }
    }
  };

  // Add this handler for accent color input
  const handleAccentColorInput = (e) => {
    const colorValue = e.target.value.toUpperCase();
    if (/^#[0-9A-F]{0,6}$/.test(colorValue)) {
      setHexAccent(colorValue);

      // Only process complete hex colors
      if (colorValue.length === 7) {
        // Convert hex to HSL and update slider
        const hsl = hexToHSL(colorValue);
        setHue(hsl.h);
        setBrightness(hsl.l);

        dispatch(setUserArticle({
          ...userArticle,
          accent: colorValue.slice(1)
        }));
      }
    }
  };

  // Add new state for edit menu
  const [showEditOptions, setShowEditOptions] = useState(false);

  // Add handler for Home click
  const handleHomeClick = () => {
    navigation('/');
  };

  const handleStream = (data) => {
    console.log(`handleStream::`, data);
    dispatch(updateCurPlayStreamData(data))
    navigation('/vault/stream');
  }

  const handleDownload = (link) => {
    const a_tag = document.createElement("a");
    a_tag.href = link;  // Replace with your file URL
    a_tag.download = "downloaded-file.jpg";  // Optional: specify the file name you want for the download
    // link.download = 'downloaded-file.jpg'; 

    document.body.appendChild(a_tag);

    a_tag.click();

    // Remove the link after the download is triggered
    document.body.removeChild(a_tag);
  }

  return (
    <LayoutHeader>
      {
        isOwner ?
          <div className="w-[350px] sm:max-w-[390px] pb-[100px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black overflow-hidden" >
            <div className="absolute top-6 left-4" style={{ zIndex: 1 }}>
              <button
                onClick={() => setShowPublish(true)}
                className="text-[#FFFFFF] text-[12px] hover:text-white transition-colors duration-200"
              >
                publish
              </button>
            </div>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${noiseTexture})`,
                opacity: (userArticle?.noise || 0) / 100,
                mixBlendMode: 'overlay',
                pointerEvents: 'none',
                zIndex: 2,
                borderRadius: '20px'
              }}
            />
            <div className="flex py-4 z-1 ml-4 w-full justify-center items-center text-white">
              <div className="cursor-pointer mx-auto flex justify-center items-center gap-x-[4px] text-xl font-medium">
                {vaultData?.headerTitle ? vaultData?.headerTitle : userArticle?.username}
              </div>
              <div className="mr-4 ">
                <More
                  className="h-[20px] w-[20px] absolute top-6 right-4 cursor-pointer"
                  onClick={() => dispatch(updateImageOverlay(true))}
                />
              </div>
            </div>
            <div
              className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]"
              style={{
                filter: `grayscale(${userArticle?.grayscale}%)`,
                backgroundColor: `${userArticle?.background ? '#' + userArticle?.background : '#000000'}`,
                position: 'relative'
              }}
            >
              <div className="relative z-2 w-full">
                <div className="w-full h-[550px] relative rounded-t-[20px] overflow-hidden mb-[40px]">
                  <img
                    src={vaultData.image ? vaultData.image : removeUploadsFromUrl(`${userArticle?.avatar}`)}
                    alt="photoimage"
                    className="w-full h-full object-cover object-center"
                    style={{ minHeight: '550px' }}
                  />
                  <div
                    className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end p-4"
                    style={{
                      backgroundImage: `linear-gradient(to top, #000000, transparent)`,
                    }}
                  >
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full p-2 mt-[-90px] z-10">
              <div
                className="text-[14px] text-white flex flex-row justify-between"
                style={{ color: "#FBFBFD" }}
              >
                <span className="font-medium">{vaultData?.title}</span>
                <button className="text-[13px] pr-[10px]" onClick={handleShowDetail}>
                  {!showDetail ? 'Show all' : 'Hide'}
                </button>
              </div>
              {
                !showDetail ?
                  <div className="flex flex-col">
                    <div className="flex flex-row mt-5 space-x-2" style={{ color: "#FBFBFD" }}>
                      {
                        vaultData?.description
                      }
                    </div>
                    {/* <div className="flex flex-row mt-5 space-x-10" style={{ color: "#FBFBFD" }}>
                      <button
                        className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                        onClick={() => { }}
                      >
                        Subscribe
                      </button>
                      <span
                        className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                      >
                        $20.99
                      </span>
                    </div> */}
                  </div> :
                  <div className="flex flex-col">
                    {
                      vaultData && vaultData.musicList.length > 0 && vaultData.musicList.map((music, key) => (
                        <div className="flex flex-row mt-5 justify-between" style={{ color: "#FBFBFD" }}>
                          <span
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                          >
                            {
                              music.title?.length > 20 ? music.title.slice(0, 20) + '...' : music.title
                            }
                          </span>
                          <div className="flex flex-row items-center gap-x-[20px]">
                            <button
                              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                              onClick={() => handleStream(music)}
                            >
                              Stream
                            </button>
                            <a
                              href={music.file}
                              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                              // onClick={() => handleDownload(music.file)}
                              download
                            >
                              Download
                            </a>
                            <span
                              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            >
                              ${music.price}
                            </span>
                          </div>
                        </div>
                      ))
                    }
                  </div>
              }
            </div>
          </div> :
          <div className="w-[350px] sm:max-w-[390px] pb-[100px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black overflow-hidden" >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${noiseTexture})`,
                opacity: (userArticle?.noise || 0) / 100,
                mixBlendMode: 'overlay',
                pointerEvents: 'none',
                zIndex: 2,
                borderRadius: '20px'
              }}
            />
            <div className="flex py-4 z-1 ml-4 w-full justify-center items-center text-white">
              <div className="cursor-pointer mx-auto flex justify-center items-center gap-x-[4px] text-xl font-medium">
                {vaultData?.headerTitle != '' ? vaultData?.headerTitle : userArticle?.username}
              </div>
            </div>
            <div
              className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]"
              style={{
                filter: `grayscale(${userArticle?.grayscale}%)`,
                backgroundColor: `${userArticle?.background ? '#' + userArticle?.background : '#000000'}`,
                position: 'relative'
              }}
            >
              <div className="relative z-2 w-full">
                <div className="w-full h-[550px] relative rounded-t-[20px] overflow-hidden mb-[40px]">
                  <img
                    src={vaultData.image ? vaultData.image : removeUploadsFromUrl(`${userArticle?.avatar}`)}
                    alt="photoimage"
                    className="w-full h-full object-cover object-center"
                    style={{ minHeight: '550px' }}
                  />
                  <div
                    className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end p-4"
                    style={{
                      backgroundImage: `linear-gradient(to top, #000000, transparent)`,
                    }}
                  >
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full p-2 mt-[-90px] z-10">
              <div
                className="text-[14px] text-white flex flex-row justify-between"
                style={{ color: "#FBFBFD" }}
              >
                <span className="font-medium">{vaultData?.title}</span>
                <button className="text-[13px] pr-[10px]" onClick={handleShowDetail}>
                  {!showDetail ? 'Show all' : 'Hide'}
                </button>
              </div>
              {
                !showDetail ?
                  <div className="flex flex-col">
                    <div className="flex flex-row mt-5 space-x-2" style={{ color: "#FBFBFD" }}>
                      {
                        vaultData?.description
                      }
                    </div>
                    <div className="flex flex-row mt-5 space-x-10" style={{ color: "#FBFBFD" }}>
                      <button
                        className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                        onClick={() => { }}
                      >
                        Subscribe
                      </button>
                      <span
                        className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                      >
                        $20.99
                      </span>
                    </div>
                  </div> :
                  <div className="flex flex-col">
                    {
                      vaultData && vaultData.musicList.length > 0 && vaultData.musicList.map((music, key) => (
                        <div className="flex flex-row mt-5 justify-between" style={{ color: "#FBFBFD" }}>
                          <span
                            className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                          >
                            {
                              music.title?.length > 20 ? music.title.slice(0, 20) + '...' : music.title
                            }
                          </span>
                          <div className="flex flex-row items-center gap-x-[20px]">
                            <button
                              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                              onClick={() => handleStream(music)}
                            >
                              Stream
                            </button>
                            <a
                              href={music.file}
                              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                              // onClick={() => handleDownload(music.file)}
                              download
                            >
                              Download
                            </a>
                            <span
                              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
                            >
                              ${music.price}
                            </span>
                          </div>
                        </div>
                      ))
                    }
                  </div>
              }
            </div>
          </div>
      }

      {!color && !showEditOptions && isOwner && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setShowEditOptions(true)}
            >
              EDIT
            </button>
            <button
              className="text-white text-center py-2 px-4 rounded-lg "
              onClick={() => navigation("/vault/add")}
            >
              <Plus className="h-[18px] w-[18px]" />
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={handleHomeClick}
            >
              HOME
            </button>
          </div>
        </div>
      )}

      {!color && showEditOptions && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setStyleMenu(true)}
            >
              RETOUCH
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setColor(true);
                setShowEditOptions(false);
              }}
            >
              COLOR
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setShowEditOptions(false)}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {color && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setBackgroundColor(true);
                setColor(false);
              }}
            >
              BACKGROUND
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setAccentColor(true);
                setColor(false);
              }}
            >
              ACCENT
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setColor(false);
                setShowEditOptions(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {backgroundColor && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setBackgroundShade(true)}
            >
              SHADE
            </button>
            <div className="flex items-center space-x-4">
              <input
                type="text"
                value={(hexBackground || "#000000").toUpperCase()}
                onChange={handleColorInput}
                className="bg-transparent w-[60px] text-center outline-none border-none transition-colors duration-200"
                style={{
                  caretColor: 'white',
                  appearance: 'none',
                  WebkitAppearance: 'none'
                }}
              />
              <input
                type="range"
                min="0"
                max="360"
                value={hue}
                onChange={handleHueChangeBackground}
                className="hue-slider"
                style={{ width: "140px" }}
              />
            </div>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => updateUserArticle(false)}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setBackgroundColor(false);
                setBackgroundShade(false);
                setAccentColor(false);
                setAccentShade(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {/* Background Shade Selection */}
      {backgroundShade && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => {
                setBackgroundShade(false);
                setBackgroundColor(true);
              }}
            >
              COLOR
            </button>
            <input
              type="range"
              min="0"
              max="100"
              value={brightness}
              onChange={handleBrightnessChangeBackground}
              className="brightness-slider"
              style={{
                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                margin: 0,
                width: "180px",
              }}
            />
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => updateUserArticle(false)}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => {
                setBackgroundColor(false);
                setBackgroundShade(false);
                setAccentColor(false);
                setAccentShade(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}
      {accentColor && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setAccentShade(true)}
            >
              SHADE
            </button>
            <div className="flex items-center space-x-4">
              <input
                type="text"
                value={(hexAccent || "#000000").toUpperCase()}
                onChange={handleAccentColorInput}
                className="bg-transparent w-[60px] text-center outline-none border-none"
                style={{
                  caretColor: 'white',
                  appearance: 'none',
                  WebkitAppearance: 'none'
                }}
              />
              <input
                type="range"
                min="0"
                max="360"
                value={hue}
                onChange={handleHueChangeAccent}
                className="hue-slider"
                style={{ width: "140px" }}
              />
            </div>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => updateUserArticle(false)}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setBackgroundColor(false);
                setBackgroundShade(false);
                setAccentColor(false);
                setAccentShade(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {/* Background Shade Selection */}
      {accentShade && (
        <div className="fixed max-w-[350px] bottom-0 w-full text-white font-[410] text-[9px] leading-[10.26px] tracking-[0.02em]">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => {
                setAccentShade(false);
                setAccentColor(true);
              }}
            >
              COLOR
            </button>
            <input
              type="range"
              min="0"
              max="100"
              value={brightness}
              onChange={handleBrightnessChangeAccent}
              className="brightness-slider"
              style={{
                background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                margin: 0,
                width: "180px",
              }}
            />
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => updateUserArticle(false)}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2  rounded-lg"
              onClick={() => {
                setBackgroundColor(false);
                setBackgroundShade(false);
                setAccentColor(false);
                setAccentShade(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {styleMenu && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setGrayScaleBar(true);
                setStyleMenu(false);
              }}
            >
              GRAYSCALE
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setNoiseBar(true);
                setStyleMenu(false);
              }}
            >
              NOISE
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setStyleMenu(false)}
            >
              BACK
            </button>
          </div>
        </div>
      )}
      {grayScaleBar && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button className="text-center py-2 rounded-lg">
              GRAYSCALE
            </button>
            <div className="flex items-center space-x-4">
              <span className="text-white min-w-[20px]">
                {userArticle?.grayscale || 0}%
              </span>
              <input
                type="range"
                min="0"
                max="100"
                value={userArticle?.grayscale ?? 0}
                onChange={onChangeGrayScale}
                className="grayscale-slider"
                style={{ width: "140px" }}
              />
            </div>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => { updateUserArticle(false); setGrayScaleBar(false); }}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => setGrayScaleBar(false)}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {noiseBar && (
        <div className="fixed max-w-[350px] bottom-0 w-full font-[410] text-[9px] leading-[10.26px] tracking-[0.02em] text-white">
          <div className="flex flex-row justify-around items-center h-[50px]" style={bottomNavContainerStyle}>
            <button className="text-center py-2 rounded-lg">
              NOISE
            </button>
            <div className="flex items-center space-x-4">
              <span className="text-white min-w-[20px]">
                {userArticle?.noise || 0}%
              </span>
              <input
                type="range"
                min="0"
                max="100"
                value={userArticle?.noise ?? 0}
                onChange={onChangeNoise}
                className="grayscale-slider"
                style={{ width: "140px" }}
              />
            </div>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => { updateUserArticle(false); setNoiseBar(false); }}
            >
              CONFIRM
            </button>
            <button
              className="text-center py-2 rounded-lg transition-opacity duration-200 hover:opacity-70"
              onClick={() => {
                setNoiseBar(false);
                resetEffects();
              }}
            >
              BACK
            </button>
          </div>
        </div>
      )}

      {imageOverlay && <ImageOverlay vaultData={vaultData} />}

      {showPublish && <PublishOverlay setShowPublish={setShowPublish} />}
    </LayoutHeader>
  );
}
