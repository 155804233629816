import React from 'react';
import { postDataAPI } from '../utils/fetchData';
import { useParams } from 'react-router-dom';

const CusFocusForm = ({ item, userArticle, onFormClick }) => {
    const { domain: dynamicVar } = useParams();
    const accentColor = userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF';

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form submit clicked, item:", item); // Debug log

        try {
            // Try to log analytics but don't block on failure
            try {
                await postDataAPI('analytics/create-form-analytics', {
                    title: item?.sectionTitle,
                    domain: dynamicVar,
                    type: 'form'
                }, null);
            } catch (analyticsError) {
                console.log("Analytics error (non-blocking):", analyticsError);
            }

            // Always proceed with navigation
            if (onFormClick) {
                console.log("Calling onFormClick with item:", item); // Debug log
                onFormClick(item);
            }
        } catch (err) {
            console.log("Error in handleSubmit:", err);
        }
    };

    return (
        <div className="w-full flex-shrink-0">
            <div className="w-full flex flex-col gap-y-2">
                {/* Main Text */}
                <div 
                    className="text-[10px]"
                    style={{ color: accentColor }}
                >
                    {item.primaryText}
                </div>

                {/* Optional Text */}
                {item.secondaryText && (
                    <div 
                        className="text-[10px]"
                        style={{ color: accentColor }}
                    >
                        {item.secondaryText}
                    </div>
                )}

                {/* Button with increased gap */}
                <div className="mt-5">
                    <button
                        onClick={handleSubmit}
                        className="text-[10px] font-medium w-fit transition-opacity hover:opacity-80"
                        style={{ color: accentColor }}
                    >
                        {item.buttonText || 'BUTTON TEXT'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CusFocusForm; 