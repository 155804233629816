import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import DashboardPreview from '../assets/images/components/dash.svg';
import WeekndBrandPreview from '../assets/images/components/weekndbrand.svg';
import MktplacePreview from '../assets/images/components/mktplace.svg';
import SwipePreview from '../assets/images/components/swipe.svg';
import VaultDiscoverPreview from '../assets/images/components/vaultdiscover.svg';
import AnalyticsPreview from '../assets/images/components/analytics.svg';
import VaultPurchasePreview from '../assets/images/components/vaultpurchase.svg';
import TopArtistsPreview from '../assets/images/components/topartists.svg';

// Simple animation
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// New animations for the demo frames
const fadeInScale = keyframes`
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
`;

// Add these animation keyframes at the top with the other animations
const pulse = keyframes`
  0% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 0.8; }
`;

const moveToBuilder = keyframes`
  0% { top: 50%; left: 50%; }
  100% { top: 40%; left: 35%; }
`;

const moveToVault = keyframes`
  0% { top: 40%; left: 35%; }
  100% { top: 40%; left: 65%; }
`;

// Styled components with mobile-first design
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.3s ease;
`;

const MobileContainer = styled.div`
  width: 375px;
  height: 667px;
  background-color: ${props => props.backgroundColor || 'black'};
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

// Header component
const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 40px;
  position: absolute;
  top: 40px;
  z-index: 10;
  background-color: ${props => props.backgroundColor || 'transparent'};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  margin-top: 150px;
  flex: 1;
`;

const Title = styled.h1`
  color: white;
  font-size: 26px;
  font-weight: 500;
  margin: 0 0 16px;
  text-align: left;
  display: flex;
  align-items: center;
`;

const RedDot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #FF2300;
  margin-left: 15px;
`;

const Subtitle = styled.p`
  color: white;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.3;
  margin: 0;
  opacity: 0.9;
  text-align: left;
  white-space: ${props => props.nowrap ? 'nowrap' : 'normal'};
  ${props => props.style}
`;

// Demo frame components
const DemoContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  background-color: #121212;
  height: 240px;
  position: relative;
  animation: ${fadeInScale} 0.4s ease forwards;
  outline: 1px solid rgba(255, 255, 255, 0.15);
`;

const DemoFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.bgColor || '#121212'};
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.4s ease;
`;

const DemoHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: black;
`;

const DemoLogo = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: white;
  letter-spacing: 1px;
`;

const DemoProfileImage = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #444;
`;

const DemoMainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10%;
  position: relative;
  background-color: black;
`;

const DemoUrl = styled.div`
  font-size: 10px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 auto;
`;

const DemoContent = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'center'};
`;

// Demo option containers styled similar to landing page
const DemoOptionsContainer = styled.div`
  display: flex;
  gap: 130px;
  margin: 0 auto;
  margin-bottom: 150px;
`;

const DemoOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DemoOptionBox = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${props => props.color};
  border-radius: 4px;
  cursor: pointer;
`;

const DemoOptionLabel = styled.div`
  margin-top: 8px;
  font-size: 10px;
  color: white;
  text-align: center;
`;

const DemoDot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.color || '#FF2300'};
  top: ${props => props.top || '50%'};
  left: ${props => props.left || '50%'};
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 4px rgba(255, 35, 0, 0.3);
  z-index: 5;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

// Existing UI components
const Logo = styled.div`
  font-size: 14px;
  letter-spacing: 4px;
  font-weight: 500;
  color: white;
  margin-right: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const NextButton = styled.span`
  color: white;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const BackButton = styled.span`
  color: white;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const UrlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 140px;
  color: white;
  font-size: 20px;
`;

const DesignOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
`;

const DesignOption = styled.div`
  color: white;
  font-size: 13px;
  cursor: pointer;
`;

const BottomNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 140px;
`;

const NavOption = styled.div`
  color: white;
  font-size: 13px;
  cursor: pointer;
`;

const PlusButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PlusIcon = styled.div`
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-top: -3px;
`;

const DemoFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  padding: 8px 0;
`;

const DemoFooterItem = styled.div`
  color: white;
  font-size: 8px;
  display: flex;
  align-items: center;
  position: relative;
`;

const DemoActiveDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #FF3B30;
  margin-right: 4px;
`;

const AnimatedDemoDot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #FF2300;
  top: ${props => props.isBuilderActive ? '20%' : '20%'};
  left: ${props => props.isBuilderActive ? '35%' : '65%'};
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 4px rgba(255, 35, 0, 0.3);
  z-index: 5;
  animation: ${pulse} 1s ease infinite;
  transition: left 1s ease, top 1s ease;
`;

// Builder app specific styled components
const BuilderDemoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  position: relative;
  overflow: hidden;
`;

const BuilderTopNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  font-size: 10px;
`;

const BuilderNavItem = styled.div`
  opacity: 0.7;
  padding: 0 8px;
`;

const BuilderNavItems = styled.div`
  display: flex;
`;

const BuilderProfilePic = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333;
`;

const BuilderToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-top: 8px;
`;

const BuilderPublishButton = styled.div`
  font-size: 10px;
  opacity: 0.7;
`;

const BuilderUrl = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.7;
`;

const BuilderMenuDots = styled.div`
  font-size: 16px;
`;

const BuilderContentArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const BuilderMusicSection = styled.div`
  margin-top: 16px;
  padding: 10px 16px;
  background-color: #1C1C25;
  border-radius: 8px;
  margin: 16px;
`;

const BuilderMusicHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const BuilderMusicTitle = styled.div`
  font-size: 11px;
  letter-spacing: 1px;
  color: #5EE2FF;
`;

const BuilderMusicThumbnails = styled.div`
  display: flex;
  gap: 8px;
`;

const BuilderMusicThumb = styled.div`
  width: 70px;
  height: 40px;
  background-color: #222;
  border-radius: 4px;
  overflow: hidden;
`;

const BuilderBottomNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #222;
  margin-top: auto;
`;

const BuilderEditButton = styled.div`
  font-size: 10px;
  opacity: 0.7;
`;

const BuilderPlusButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
`;

const BuilderHomeButton = styled.div`
  font-size: 10px;
  opacity: 0.7;
`;

// Keep just the orange-outlined container
const ImagePreviewContainer = styled.div`
  border: 1px solid #FF4500;
  border-radius: 16px;
  width: 100%;
  max-width: 150px;
  height: 300px;
  margin: 30px auto 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const DoublePreviewContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  animation: ${fadeInScale} 0.5s ease-out forwards;
  margin-top: 40px;
`;

const PreviewSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
`;

const PreviewTitle = styled.p`
  font-size: 13px;
  font-weight: 400;
  margin-top: 12px;
  color: white;
  text-align: center;
  opacity: 0.9;
  line-height: 1.3;
`;

const DizeeWalkthrough = ({ onClose, startAtStep = 0 }) => {
  // Get username from Redux store with fallback to "User"
  const userArticle = useSelector((state) => state.user.userArticle);
  const username = userArticle?.username || "User";
  
  const walkthroughSteps = [
    {
      title: `${username},`,
      background: '#FF2300',
      fullPageBackground: true,
      paragraphs: [
        "Welcome to your new digital home and congrats on getting into Dizee!",
        "We know how hard it is to make a living as a musician today. That's why we built Dizee: to help artists like you grow your career and earn directly from your fans.",
        "By using Dizee, our goal is that you'll be able to earn more passive income, free up more time for your music and build a business you can truly own and control.",
        "Up next is a quick tour of the platform's main sections to help you get started."
      ]
    },
    {
      title: "Home",
      subtitle: 'This is your Dashboard. Create collections by dragging collections to your feed. Reorder collections by dragging them around your feed.',
      background: '#000000',
      showHomePreview: true,
    },
    {
      title: 'Page Builder',
      subtitle: 'Build and Curate your Brand and Focus Pages. Use modules to connect all your platforms and display the content you already have.',
      background: '#000000',
      showHomePreview: true,
      paragraphs: [],
      secondarySubtitle: null,
      fullPageBackground: false
    },
    {
      title: 'Mini-App Marketplace',
      subtitle: 'Browse the marketplace of powerful mini apps to start earning passive income from your existing fan base.',
      background: '#000000',
      showHomePreview: true,
      image: MktplacePreview,
    },
    {
      title: "Discovery",
      subtitle: "You don't have to rely on just your current fans. Dizee brings new fans to you through Curated Lists and Mini-App Discovery.",
      background: '#000000',
      showPreview: true,
      darkBg: true,
      outlineBox: true
    },
    {
      title: "Analytics",
      subtitle: "The place to view all of your statistics and important data driving your career. Use these to make informed decisions about how to grow your career.",
      background: '#000000',
      showHomePreview: true,
      darkBg: true
    },
    {
      title: "Monetize",
      subtitle: "Start earning consistent income directly from your fans using Mini-Apps.",
      background: '#FF2300',
      showDoublePreview: true,
      fullPageBackground: true,
      darkBg: true
    }
  ];
  
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedApp, setSelectedApp] = useState(null);
  const [dotPosition, setDotPosition] = useState('builder');
  
  // Force update mechanism to ensure the component re-renders if needed
  const [, forceUpdate] = useState({});
  useEffect(() => {
    const timer = setTimeout(() => forceUpdate({}), 1000);
    return () => clearTimeout(timer);
  }, []);
  
  // No need for the dotPosition effect since we removed the apps slide
  
  const handleNext = () => {
    if (currentStep < walkthroughSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };
  
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  
  // Simplified demo frame renderer - only needed for basic functionality
  const renderDemoFrame = () => {
    return null; // We don't need any demo frames now
  };
  
  return (
    <Overlay style={(currentStep === 0 || currentStep === 3 || currentStep === 6) && walkthroughSteps[currentStep].fullPageBackground ? 
      { backgroundColor: walkthroughSteps[currentStep].background } : {}}>
      <MobileContainer backgroundColor={walkthroughSteps[currentStep].background}>
        <Header backgroundColor={(currentStep === 1 || currentStep === 2) ? walkthroughSteps[currentStep].background : 'transparent'}>
          <Logo>DIZEE</Logo>
          <ButtonGroup>
            {currentStep > 0 && (
              <BackButton onClick={handleBack}>Go back</BackButton>
            )}
            {currentStep < walkthroughSteps.length - 1 ? (
              <NextButton onClick={handleNext}>
                {walkthroughSteps[currentStep].isLastStep ? 'Get started' : 'Next'}
              </NextButton>
            ) : (
              <NextButton onClick={handleNext}>
                Get started
              </NextButton>
            )}
          </ButtonGroup>
        </Header>
        
        <ContentContainer 
          style={currentStep === 0 ? {
            paddingTop: '0px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
          } : {}}
        >
          <Title>{walkthroughSteps[currentStep].title}</Title>
          
          {walkthroughSteps[currentStep].subtitle && (
            <Subtitle>{walkthroughSteps[currentStep].subtitle}</Subtitle>
          )}
          
          {currentStep === 1 && walkthroughSteps[currentStep].secondarySubtitle && (
            <Subtitle style={{ marginTop: '20px' }}>
              {walkthroughSteps[currentStep].secondarySubtitle}
            </Subtitle>
          )}
          
          {walkthroughSteps[currentStep].paragraphs && (
            <div style={{ 
              marginTop: currentStep === 0 ? '20px' : '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: currentStep === 0 ? '24px' : '20px'
            }}>
              {walkthroughSteps[currentStep].paragraphs.map((paragraph, index) => (
                <Subtitle key={index} style={{ lineHeight: '1.5' }}>
                  {paragraph}
                </Subtitle>
              ))}
            </div>
          )}
          
          {(currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 5 || currentStep === 6) && walkthroughSteps[currentStep].showHomePreview && (
            <ImagePreviewContainer 
              style={{
                marginTop: currentStep === 1 ? '50px' : 
                          currentStep === 2 ? '30px' : 
                          currentStep === 3 ? '50px' :
                          currentStep === 5 ? '50px' :
                          currentStep === 6 ? '50px' : '50px',
              }}
            >
              <img 
                src={currentStep === 1 ? DashboardPreview : 
                     currentStep === 2 ? WeekndBrandPreview : 
                     currentStep === 3 ? MktplacePreview :
                     currentStep === 5 ? AnalyticsPreview :
                     currentStep === 6 ? VaultPurchasePreview : null} 
                alt={currentStep === 1 ? "Dashboard preview" : 
                     currentStep === 2 ? "Page Builder preview" : 
                     currentStep === 3 ? "Mini-App Marketplace preview" :
                     currentStep === 5 ? "Analytics preview" :
                     currentStep === 6 ? "Vault Purchase preview" : ""} 
                style={{ 
                  width: '100%', 
                  height: '100%',
                  objectFit: 'contain',
                  objectPosition: 'top',
                  padding: currentStep === 3 ? '10px' : 
                           currentStep === 2 ? '10px' : 
                           currentStep === 5 ? '10px' :
                           currentStep === 6 ? '10px' : '0',
                  display: currentStep === 4 ? 'none' : 'block',
                }} 
              />
            </ImagePreviewContainer>
          )}
          
          {currentStep === 4 && walkthroughSteps[currentStep].showPreview && (
            <DoublePreviewContainer>
              <PreviewSection>
                <ImagePreviewContainer smallPreview>
                  <img
                    src={SwipePreview}
                    alt="Swipe to Explore preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'top',
                    }}
                  />
                </ImagePreviewContainer>
                <PreviewTitle>Swipe to Explore</PreviewTitle>
              </PreviewSection>
              <PreviewSection>
                <ImagePreviewContainer smallPreview>
                  <img
                    src={VaultDiscoverPreview}
                    alt="Vault App Discovery preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'top',
                    }}
                  />
                </ImagePreviewContainer>
                <PreviewTitle>Vault App Discovery</PreviewTitle>
              </PreviewSection>
            </DoublePreviewContainer>
          )}
          
          {currentStep === 6 && walkthroughSteps[currentStep].showDoublePreview && (
            <DoublePreviewContainer>
              <PreviewSection>
                <ImagePreviewContainer smallPreview style={{ border: 'transparent' }}>
                  <img
                    src={VaultPurchasePreview}
                    alt="Vault Purchase preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'top',
                    }}
                  />
                </ImagePreviewContainer>
              </PreviewSection>
              <PreviewSection>
                <ImagePreviewContainer smallPreview style={{ border: 'transparent' }}>
                  <img
                    src={TopArtistsPreview}
                    alt="Top Artists preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'top',
                    }}
                  />
                </ImagePreviewContainer>
              </PreviewSection>
            </DoublePreviewContainer>
          )}
        </ContentContainer>
      </MobileContainer>
    </Overlay>
  );
};

export default DizeeWalkthrough;
