import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { DizeeInput2, DizeeNumberInput } from '../../../../../components/DizeeComponent/DixeeInput2';
import CopyLink from '../../../../../svg/CopyLink';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlerts, setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { clearFocusSection, setFocusProduct } from '../../../../../store/focuspage/focuspageSlice';
import { addProductForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import FocusAddedProduct from '../../../../../components/Focuspage/Type/Product/FocusAddedProduct';
import FocusProductOverlay from '../../../../../components/Focuspage/Type/Product/FocusProductOverlay';
import Check from '../../../../../svg/Check';

export default function Step6() {
    const product = useSelector((state) => state.focuspage?.product);
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const [link, setLink] = useState('');
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [previousUrl, setPreviousUrl] = useState('');
    const [previousSource, setPreviousSource] = useState('');
    const dispatch = useDispatch();

    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [itemForBackend, setItemForBackend] = useState(null);

    useEffect(() => {
        dispatch(clearAlerts())
    }, [dispatch, token])

    const filterLink = (link) => {
        if (product?.length > 0) {
            for (let m of product) {
                for (let productLink of m.links) {
                    if (productLink.url === link) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setLink(value);
    };

    const handleAddProduct = async () => {
        const isExistLink = filterLink(link);
        dispatch(clearAlerts());

        if (!previousUrl && isExistLink) {
            dispatch(setErrorAlert('Link already exists'));
            return;
        }

        // Ensure all necessary fields are filled
        if (!productName || !link || !price || !description) {
            dispatch(setErrorAlert('Please fill all the fields'));
            return;
        }

        // Create the product payload
        let payload = {
            productName: productName,
            description: description,
            price: price,
            image: product?.avatar || '', // Add null check
            links: [
                {
                    source: previousSource ? previousSource : 'custom',
                    url: link
                }
            ]
        };

        // Update the product state with proper structure
        dispatch(setFocusProduct({ 
            ...product,
            product: [payload],
            type: 'single'  // Ensure type is set
        }));

        handleAddProductFocus();
    };

    const handleAddProductFocus = async () => {
        dispatch(setLoader(true));
        try {
            const dataCopy = { 
                ...product,
                headeractive: true,
                type: 'single',
                product: [{
                    productName: productName,
                    description: description,
                    price: price,
                    image: product?.avatar || '',
                    links: [
                        {
                            source: previousSource ? previousSource : 'custom',
                            url: link
                        }
                    ]
                }]
            };

            dispatch(addProductForFocusThunk({ token: token, payload: dataCopy })).then((res) => {
                if (res.payload.status === 200) {
                    navigate("/focus-page");
                }
            });
        } catch (error) {
            console.error('Error adding product:', error);
            dispatch(setErrorAlert('Error adding product'));
        } finally {
            dispatch(setLoader(false));
        }
    }

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <div className='flex items-center gap-x-[16px]'>
                        <span className='text-white'>Add product</span>
                    </div>
                    <div className='flex gap-[20px]'>
                        <button onClick={() => handleAddProduct()} className='text-white cursor-pointer'>
                            Confirm
                        </button>
                        <p onClick={() => navigate('/focus-page/product-type/step5')} className='text-white cursor-pointer'>
                            Go back
                        </p>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center w-full gap-y-[50px]">
                    <div className='w-full'>
                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <div className="flex items-center w-full">
                                    <DizeeInput2
                                        label="Link"
                                        placeholder="Enter ticket link"
                                        className="dizee-input w-full"
                                        value={link}
                                        onChange={handleLinkChange}
                                    />
                                    {isValidUrl(link) && <Check className="h-[12px] w-[18px] ml-2" />}
                                </div>
                            </div>
                        </div>

                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Product name"
                                    className="dizee-input w-full"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    label="Link"
                                    placeholder="Description"
                                    className="dizee-input w-full"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='p-4 flex w-full justify-between items-center' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeNumberInput
                                    label="Link"
                                    placeholder="Price"
                                    type="number"
                                    className="dizee-input w-full"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOverlayVisible && (
                <FocusProductOverlay 
                    isOverlayVisible={isOverlayVisible} 
                    setOverlayVisible={setOverlayVisible} 
                    setLink={setLink} 
                    setProductName={setProductName} 
                    setPrice={setPrice} 
                    setDescription={setDescription} 
                    itemForBackend={itemForBackend} 
                />
            )}
        </LayoutHeader>
    );
}
