import React, { useState } from 'react';
import '../../../../css/ColorPicker.css';
import styled from "styled-components";
import dizeeLogo from "../../../../assets/images/dizee logo.svg";
import TypeWriter from "../../../../components/OnBoard/Typewriter";
import colorValidation from "../../../../assets/config/colorValidation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
  
  @media (max-width: 640px) {
    margin-top: 20px;
  }
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: white;
  cursor: pointer;
  height: 18px;
  line-height: 18px;
  position: relative;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
  @media (max-width: 640px) {
    padding-left: 0;
    width: 100%;
    max-width: 300px;
  }
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const ColorPickerContainer = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  
  @media (max-width: 640px) {
    gap: 30px;
  }
`;

const HexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
  @media (max-width: 640px) {
    margin-top: -10px;
  }
`;

const HexLabel = styled.span`
  text-transform: uppercase;
  font-family: inherit;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
`;

const HexInput = styled.input`
  background: transparent;
  border: none;
  color: white;
  font-size: 12px;
  width: 70px;
  text-transform: uppercase;
  font-family: inherit;
  line-height: 18px;
  height: 18px;
  
  &:focus {
    outline: none;
  }
`;

const ColorDisplay = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid white;
  align-self: center;
`;

// Utility function to convert HSL to Hex
export const hslToHex = (h, s, l) => {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0'); // Convert to hex
    };
    return `#${f(0)}${f(8)}${f(4)}`;
};

// Add hex validation function
const isValidHexChar = (char) => {
    return /^[0-9A-Fa-f]$/.test(char);
};

const MainColorPicker = ({ setStep, setUser, user }) => {
    const [hue, setHue] = useState(0);
    const [saturation, setSaturation] = useState(100);
    const [brightness, setBrightness] = useState(50);
    const [inputValue, setInputValue] = useState("");
    const [validationMessage, setValidationMessage] = useState("");
    
    // Reference for the color field and context
    const canvasRef = React.useRef(null);
    
    // Function to handle hue changes
    const handleHueChange = (e) => {
        setHue(e.target.value);
        setInputValue(''); // Clear input value to show live hex updates
        updateColorField(e.target.value);
    };

    // Function to update the 2D color field based on hue
    const updateColorField = (hueValue) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        
        const ctx = canvas.getContext('2d');
        
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        // Create horizontal gradient (saturation: 0% to 100%)
        const gradientH = ctx.createLinearGradient(0, 0, canvas.width, 0);
        gradientH.addColorStop(0, `hsl(${hueValue}, 0%, 50%)`); // Left: gray (0% saturation)
        gradientH.addColorStop(1, `hsl(${hueValue}, 100%, 50%)`); // Right: full saturation
        
        ctx.fillStyle = gradientH;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        // Create vertical gradient (brightness: 0% to 100%)
        const gradientV = ctx.createLinearGradient(0, canvas.height, 0, 0);
        gradientV.addColorStop(0, "rgba(0, 0, 0, 1)"); // Bottom: black (0% brightness)
        gradientV.addColorStop(0.5, "rgba(0, 0, 0, 0)"); // Middle: no effect
        gradientV.addColorStop(1, "rgba(255, 255, 255, 1)"); // Top: white (100% brightness)
        
        ctx.fillStyle = gradientV;
        ctx.globalCompositeOperation = 'overlay'; // Better blend mode for color picking
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    };
    
    // Handle click or drag on color field
    const handleColorFieldClick = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        
        // Get mouse position relative to canvas
        const x = Math.max(0, Math.min(canvas.width, e.clientX - rect.left));
        const y = Math.max(0, Math.min(canvas.height, e.clientY - rect.top));
        
        // Calculate saturation (x-axis, 0-100)
        const s = Math.round((x / canvas.width) * 100);
        
        // Calculate brightness (y-axis, 0-100)
        // In Figma's model, top is 100% brightness, bottom is 0%
        const l = Math.round((1 - y / canvas.height) * 100);
        
        setSaturation(s);
        setBrightness(l);
        setInputValue('');
    };
    
    // Initialize color field on component mount and when hue changes
    React.useEffect(() => {
        updateColorField(hue);
    }, [hue]);

    const handleHexInput = (e) => {
        let value = e.target.value.toUpperCase();
        
        // Remove non-hex characters
        value = value.replace(/[^0-9A-F#]/g, '');
        
        // Ensure # is at the start
        if (!value.startsWith('#') && value !== '') {
            value = '#' + value;
        }
        
        setInputValue(value);
        
        // Only update color if we have a valid hex code
        if (/^#[0-9A-F]{6}$/.test(value)) {
            const rgb = hexToRgb(value);
            const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
            
            // Update sliders based on HSL values
            setHue(hsl.h);
            setSaturation(hsl.s);
            setBrightness(hsl.l);
            
            // Make sure to update the color field
            updateColorField(hsl.h);
        }
    };

    // Calculating the current color using HSL
    const selectedColor = `hsl(${hue}, ${saturation}%, ${brightness}%)`;
    const hexColor = hslToHex(hue, saturation, brightness);

    const handleConfirm = () => {
        const mainColor = hexColor.toUpperCase();
        
        // No validation needed for main color
        setUser({
            ...user,
            background: hexColor.replace("#", "")
        });
        
        if (hexColor.replace("#", "")) {
            setStep(21);
        }
    };

    // Create a new function to handle vertical slider touch
    const handleVerticalSliderTouch = (e) => {
        // Get the coordinates
        const sliderContainer = e.currentTarget;
        const rect = sliderContainer.getBoundingClientRect();
        
        // Get the touch or click position
        const clientY = e.touches ? e.touches[0].clientY : e.clientY;
        const y = Math.max(0, Math.min(rect.height, clientY - rect.top));
        
        // Convert to hue (0-360), INVERTED: top is 360, bottom is 0
        const newHue = Math.round(360 - (y / rect.height) * 360);
        
        // Update the state and color field
        setHue(newHue);
        updateColorField(newHue);
        setInputValue('');
    };

    // Add this effect to completely disable scrolling
    React.useEffect(() => {
        // Save original body styles
        const originalStyle = window.getComputedStyle(document.body).overflow;
        
        // Disable scrolling on mount
        document.body.style.overflow = 'hidden';
        
        // Re-enable scrolling on unmount
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []);

    return (
        <Container>
            <FormSection>
                <div className="w-full max-w-[300px]">
                    <div className="mb-[40px] sm:mb-[68px]">
                        <TypeWriter text="Select main color" speed={45} />
                    </div>

                    <ColorPickerContainer>
                        <div className='flex justify-between items-start gap-x-[15px] flex-nowrap'>
                            {/* 2D Color Field */}
                            <div 
                                style={{
                                    position: 'relative',
                                    width: '280px',
                                    height: '260px',
                                    maxWidth: '85%',
                                    flexShrink: 1,
                                    touchAction: 'none' // Prevent scrolling
                                }}
                            >
                                <canvas 
                                    ref={canvasRef}
                                    width={280}
                                    height={260}
                                    style={{
                                        cursor: 'crosshair',
                                        border: '0px solid rgba(255, 255, 255, 0.2)',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    onClick={handleColorFieldClick}
                                    onMouseDown={(e) => {
                                        handleColorFieldClick(e);
                                        
                                        const handleMouseMove = (moveEvent) => {
                                            handleColorFieldClick(moveEvent);
                                        };
                                        
                                        const handleMouseUp = () => {
                                            document.removeEventListener('mousemove', handleMouseMove);
                                            document.removeEventListener('mouseup', handleMouseUp);
                                        };
                                        
                                        document.addEventListener('mousemove', handleMouseMove);
                                        document.addEventListener('mouseup', handleMouseUp);
                                    }}
                                    onTouchStart={(e) => {
                                        e.preventDefault(); // Prevent scrolling
                                        const touch = e.touches[0];
                                        const touchEvent = {
                                            clientX: touch.clientX,
                                            clientY: touch.clientY
                                        };
                                        handleColorFieldClick(touchEvent);
                                    }}
                                    onTouchMove={(e) => {
                                        e.preventDefault(); // Prevent scrolling
                                        const touch = e.touches[0];
                                        const touchEvent = {
                                            clientX: touch.clientX,
                                            clientY: touch.clientY
                                        };
                                        handleColorFieldClick(touchEvent);
                                    }}
                                />
                                <div 
                                    style={{
                                        position: 'absolute',
                                        width: '10px',
                                        height: '10px',
                                        border: '2px solid white',
                                        borderRadius: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        pointerEvents: 'none',
                                        left: `${Math.min((saturation / 100) * 280, 255)}px`,
                                        top: `${(1 - brightness / 100) * 260}px`
                                    }}
                                />
                            </div>
                            
                            {/* Vertical Hue Slider */}
                            <div 
                                style={{
                                    position: 'relative',
                                    height: '260px',
                                    width: '20px',
                                    flexShrink: 0,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                onTouchStart={handleVerticalSliderTouch}
                                onTouchMove={handleVerticalSliderTouch}
                            >
                                <div style={{
                                    position: 'absolute',
                                    transform: 'rotate(-90deg) translate(-130px, 0)',
                                    transformOrigin: 'left center',
                                    width: '260px',
                                    height: '20px'
                                }}>
                                    <input
                                        type="range"
                                        min="0"
                                        max="360"
                                        value={hue}
                                        onChange={handleHueChange}
                                        className="hue-slider"
                                        style={{ width: '260px' }}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <HexContainer>
                            <HexLabel className="font-default">HEX</HexLabel>
                            <HexInput
                                value={inputValue || hexColor.toUpperCase()}
                                onChange={handleHexInput}
                                maxLength={7}
                                placeholder="#000000"
                            />
                            <ColorDisplay
                                style={{
                                    backgroundColor: selectedColor
                                }}
                            />
                        </HexContainer>
                    </ColorPickerContainer>

                    <ButtonContainer className="mt-[20px] sm:mt-[40px]">
                        <ActionButton
                            className="mr-[30px] sm:mr-[43px] text-sm sm:text-base"
                            onClick={handleConfirm}
                        >
                            Confirm
                        </ActionButton>
                        <ActionButton 
                            onClick={() => setStep(6)}
                            className="text-sm sm:text-base"
                        >
                            Go back
                        </ActionButton>
                    </ButtonContainer>
                </div>
            </FormSection>
            
            <Footer className="sm:px-0">
                <LogoImage src={dizeeLogo} alt="DIZEE" />
                <FooterText>
                    {validationMessage || "This will be used as the main color in your dizee brand kit, used for backgrounds"}
                </FooterText>
            </Footer>
        </Container>
    );
};

// Utility functions for color conversion
const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0;
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: break;
        }
        h /= 6;
    }

    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100)
    };
};

export default MainColorPicker;
