import React from 'react';
import CrossArrow from '../svg/CrossArrow';
import { maxLength } from './DizeeComponent/DixeeInput2';
import { getAccentStyle } from '../constants/constants';
import { postDataAPI } from '../utils/fetchData';
import { useParams } from 'react-router-dom';

const CusFocusEvent = ({ imgurl, item, userArticle }) => {
    const { domain: dynamicVar } = useParams();
    const accentColor = userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF';

    const handleClickEvent = async () => {
        if (!item?.link) return;
        
        let url = item.link;
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
        }
        window.open(url, '_blank');
        try {
            await postDataAPI('analytics/create-event-analytics', {
                link: url,
                domain: dynamicVar,
                venue: item?.venue
            }, null);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="w-full flex-shrink-0">
            {/* Title Section */}
            <div className="w-full px-4">
                <div className="space-y-1">
                    {item?.subtitle && (
                        <p className='text-[16px]' style={{ color: accentColor }}>
                            {item.subtitle}
                        </p>
                    )}
                    {item?.title && (
                        <p className='text-[20px] font-bold' style={{ color: accentColor }}>
                            {item.title}
                        </p>
                    )}
                </div>
            </div>

            {/* Event Details Grid */}
            <div className="w-full px-4 mt-2 mb-4">
                <div>
                    <div 
                        className="grid grid-cols-4 gap-2 items-center" 
                        style={{ color: accentColor, fontSize: '10px' }}
                    >
                        <span className="truncate">{item?.time}</span>
                        <span className="truncate">{item?.date?.split(',')[0]}</span>
                        <span className="truncate">{maxLength(item?.location, 10)}</span>
                        <button 
                            onClick={handleClickEvent}
                            className="flex items-center justify-end gap-x-1"
                        >
                            <span className="truncate">{maxLength(item?.venue, 10)}</span>
                            <CrossArrow color={accentColor} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CusFocusEvent; 