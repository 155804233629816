import React from 'react';

export default function OverlayCustom({ 
    isOverlayVisible, 
    setOverlayVisible, 
    selectedItem, 
    handleEdit, 
    handleDelete,
    handleMoveUp,
    handleMoveDown,
    canMoveUp,
    canMoveDown
}) {
    const handleOverlayClick = (e) => {
        if (e.target.id === 'overlay') {
            setOverlayVisible(false);
        }
    };

    return (
        <div
            id="overlay"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-20"
            onClick={handleOverlayClick}
        >
            <div 
                className="p-6 rounded-md w-[90%] max-w-[390px] space-y-[50px] text-white text-[12px]" 
                onClick={e => e.stopPropagation()}
            >
                <p className="cursor-pointer pb-[10px]" onClick={handleEdit}>Edit</p>
                {canMoveUp && (
                    <p className="cursor-pointer pb-[10px]" onClick={handleMoveUp}>Move Up</p>
                )}
                {canMoveDown && (
                    <p className="cursor-pointer pb-[10px]" onClick={handleMoveDown}>Move Down</p>
                )}
                <p className="cursor-pointer pb-[10px] text-red-500" onClick={handleDelete}>Delete</p>
                <p className="cursor-pointer pb-[10px] text-gray-400" onClick={() => setOverlayVisible(false)}>Cancel</p>
            </div>
        </div>
    );
} 