import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import { DizeeInput2 } from '../../../../../components/DizeeComponent/DixeeInput2';
import { searchMusicForReleaseFocusThunk, update_focus_data_thunk, updateMusicForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { clearSearchMusic } from '../../../../../store/focuspage/focuspageSlice';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import FocusMusicCard from '../../../../../components/Focuspage/Type/Music/FocusMusicCard';
import FocusAddedMusicCard from '../../../../../components/Focuspage/Type/Music/FocusAddedMusicCard';
import { ClipLoader } from 'react-spinners';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import searchImg from '../../../../../assets/images/components/search.png';
import EditFocusMusicCard from '../../../../../components/Focuspage/Type/Music/EditFocusMusicCard';
import OverlayCustom from '../../../../../components/Focuspage/Type/Custom/OverlayCustom';

export default function EditMultipleMusic() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('dizeeToken');

    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const musicData = useSelector((state) => state.focuspage.music)
    const [musicItems, setMusicItems] = useState(
        musicData?.music || []
    );

    const musicLoading = useSelector((state) => state.focuspage.musicLoading);
    const searchMusic = useSelector((state) => state.focuspage.searchMusic);

    const originalMusicData = location.state?.musicData;

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Search debouncing and handling (same as EditSingleMusic)
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 300);
        return () => clearTimeout(handler);
    }, [search]);

    useEffect(() => {
        if (debouncedSearch.length < 6) {
            dispatch(clearSearchMusic());
            return;
        }
        dispatch(searchMusicForReleaseFocusThunk({ token, search: debouncedSearch }));
    }, [debouncedSearch, dispatch, token]);

    // Add effect to update music items when returning from platforms page
    useEffect(() => {
        if (location.state?.updatedMusic) {
            setMusicItems(location.state.updatedMusic.music);
        }
    }, [location.state]);

    const handleMusicSelect = (selectedMusic) => {
        navigate('/focus-page/music-type/edit-multiple-platforms', {
            state: {
                musicItem: selectedMusic,
                originalData: originalMusicData,
                currentMusic: musicItems
            }
        });
    };

    const handleDeleteMusic = async (index) => {
        let data = musicItems.filter((_, i) => i !== index);
        setMusicItems([
            ...data
        ]);
        if (data.length > 0) {
            dispatch(setLoader(true));
            try {
                console.log(`originalMusicData::, `, {
                    ...originalMusicData,
                    music: [...data]
                });
                await dispatch(updateMusicForFocusThunk({
                    token,
                    body: {
                        ...originalMusicData,
                        music: [...data]
                    }
                })).unwrap();
                // navigate(-1);
            } catch (error) {
                dispatch(setErrorAlert(error.message || "Failed to update music"));
            }
            dispatch(setLoader(false));
        }
    };

    const handleUpdateMusic = async () => {
        if (musicItems.length === 0) {
            dispatch(setErrorAlert("Please add at least one music item"));
            return;
        }

        dispatch(setLoader(true));
        try {
            await dispatch(updateMusicForFocusThunk({
                token,
                body: {
                    ...originalMusicData,
                    music: [...musicItems]
                }
            })).unwrap();
            navigate(-1);
        } catch (error) {
            dispatch(setErrorAlert(error.message || "Failed to update music"));
        }
        dispatch(setLoader(false));
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(musicItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setMusicItems(items);
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[90vh] bg-black flex flex-col items-center relative">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white' style={{ fontSize: '12px' }}>Search for a release</p>
                    <div className='flex justify-center items-center gap-4'>
                        <button onClick={() => navigate(-1)} className='text-white cursor-pointer' style={{ fontSize: '12px' }}>
                            Done
                        </button>
                        <button onClick={() => navigate(-1)} className='text-white cursor-pointer' style={{ fontSize: '12px' }}>
                            Go back
                        </button>
                    </div>
                </div>

                <div className='flex w-full flex-col gap-y-[50px]'>
                    <div className="flex flex-col justify-center items-center w-full">
                        <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    placeholder="Search by song, album, URL, UPC or ISRC"
                                    className="dizee-input w-full"
                                    search={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                            <img src={searchImg} alt='sp' className='h-[12px] w-[12px] mx-1' />
                        </div>

                        {musicLoading ? (
                            <ClipLoader
                                color="white"
                                loading={true}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        ) : (
                            searchMusic?.length > 0 &&
                            searchMusic?.map((item, index) => (
                                <EditFocusMusicCard
                                    key={index}
                                    item={item}
                                    onClick={() => handleMusicSelect(item)}
                                />
                            ))
                        )}
                    </div>

                    {musicItems?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>Added Music</p>
                                </div>
                            </div>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="music-list">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {musicItems.map((item, index) => (
                                                <Draggable
                                                    key={item.id || index}
                                                    draggableId={item.id || `item-${index}`}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <FocusAddedMusicCard
                                                                music={item}
                                                                setOverlayVisible={setIsOverlayVisible}
                                                                onDelete={() => handleDeleteMusic(index)}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    )}
                    {isOverlayVisible && (
                        <OverlayCustom
                            isOverlayVisible={isOverlayVisible}
                            setOverlayVisible={setIsOverlayVisible}
                            selectedItem={selectedProduct}
                            handleEdit={() => { }}
                            handleDelete={handleDeleteMusic}
                            handleMoveUp={() => { }}
                            handleMoveDown={() => { }}
                            canMoveUp={false}
                            canMoveDown={false}
                        />
                    )}
                </div>
            </div>
        </LayoutHeader>
    );
} 