import { createSlice } from "@reduxjs/toolkit";
import { addMusicForFocusThunk, addProductForFocusThunk, getDataForFocusThunk, searchMusicForReleaseFocusThunk, update_focus_data_thunk, checkExtensionAvailableForCustomThunk, updateCustomForFocusThunk, updateMultiProductForFocusThunk, updateProductForFocusThunk, updateEventForFocusThunk, updateMultiEventForFocusThunk, updateNewsletterForFocusThunk, updateMultiNewsletterForFocusThunk, updateMusicForFocusThunk, deleteFocusEvenThunk, deleteFocusProductThunk, deleteFocusMusicThunk, deleteFocusNewsletterThunk } from "./focuspageThunk";
// import { searchMusicForReleaseThunk } from "./addsectionThunk";

const initialState = {
    error: null,
    searchMusic: null,
    musicLoading: false,

    music: {},
    product: {},
    event: {},
    newsletter: {},
    custom: {
        page: '',
        type: 'custom',
        headeractive: true,
        content: []
    },

    isMusicOverlay: false,
    isProductOverlay: false,
    isEventOverlay: false,
    isNewsletterOverlay: false,
    isCustomOverlay: false,

    focusData: null
};


const getDataForFocusCases = (builder) => {
    builder.addCase(getDataForFocusThunk.pending, (state) => {
        // state.musicLoading = true;
        state.error = null;
    }).addCase(getDataForFocusThunk.fulfilled, (state, action) => {
        // state.musicLoading = false;
        state.focusData = action.payload
    }).addCase(getDataForFocusThunk.rejected, (state, action) => {
        // state.musicLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const searchForReleaseMusicCases = (builder) => {
    builder.addCase(searchMusicForReleaseFocusThunk.pending, (state) => {
        state.musicLoading = true;
        state.error = null;
    }).addCase(searchMusicForReleaseFocusThunk.fulfilled, (state, action) => {
        state.musicLoading = false;
        state.searchMusic = action.payload
    }).addCase(searchMusicForReleaseFocusThunk.rejected, (state, action) => {
        state.musicLoading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const addMusicForFocusCases = (builder) => {
    builder.addCase(addMusicForFocusThunk.pending, (state) => {
        state.error = null;
    }).addCase(addMusicForFocusThunk.fulfilled, (state, action) => {
        // state.searchMusic = action.payload
    }).addCase(addMusicForFocusThunk.rejected, (state, action) => {
        state.error = action.payload || 'Redux Cases Error'
    })
}

const updateFocusDataCases = (builder) => {
    builder.addCase(update_focus_data_thunk.pending, (state) => {
        state.loading = true;
        state.error = null;
    }).addCase(update_focus_data_thunk.fulfilled, (state, action) => {
        state.loading = false;
        state.focusData = action.payload;
    }).addCase(update_focus_data_thunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Redux Cases Error'
    })
}

const updateCustomForFocusCases = (builder) => {
    builder
        .addCase(updateCustomForFocusThunk.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(updateCustomForFocusThunk.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload?.response) {
                // Update the entire focusData
                state.focusData = action.payload.response;

                // Also update the current custom state if it exists
                if (action.payload.response.custom) {
                    const updatedCustom = action.payload.response.custom.find(
                        item => item.page === action.payload.response.custom[0].page
                    );
                    if (updatedCustom) {
                        state.custom = updatedCustom;
                    }
                }
            }
        })
        .addCase(updateCustomForFocusThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || 'Update failed';
        });
};

const slice = createSlice({
    name: "focuspage",
    initialState,
    reducers: {
        clearSearchMusic: (state) => {
            state.searchMusic = null
        },
        setFocusMusic: (state, action) => {
            state.music = { ...(state.music || {}), ...action.payload };
        },
        setWholeFocusMusic: (state, action) => {
            state.music = action.payload
        },
        setFocusProduct: (state, action) => {
            state.product = { ...(state.product || {}), ...action.payload };
        },
        setWholeFocusProduct: (state, action) => {
            state.product = action.payload
        },
        setFocusEvent: (state, action) => {
            state.event = { ...(state.event || {}), ...action.payload };
        },
        setWholeFocusEvent: (state, action) => {
            state.event = action.payload
        },
        setFocusNewsletter: (state, action) => {
            state.newsletter = { ...(state.newsletter || {}), ...action.payload };
        },
        setWholeFocusNewsletter: (state, action) => {
            state.newsletter = action.payload
        },
        setFocusCustom: (state, action) => {
            state.custom = { ...(state.custom || {}), ...action.payload };
        },
        setWholeFocusCustom: (state, action) => {
            state.custom = action.payload
        },
        updateMusicOverlay(state, action) {
            state.isMusicOverlay = action.payload
        },
        updateProductOverlay(state, action) {
            state.isProductOverlay = action.payload
        },
        updateEventOverlay(state, action) {
            state.isEventOverlay = action.payload
        },
        updateNewsletterOverlay(state, action) {
            state.isNewsletterOverlay = action.payload
        },
        updateCustomOverlay(state, action) {
            state.isCustomOverlay = action.payload
        },
        clearFocusSection: (state) => {
            state.music = {}
            state.product = {}
            state.event = {}
            state.newsletter = {}
            state.custom = {}
        },
        clearFocusCustom: (state) => {
            state.custom = initialState.custom;
        }
    },
    extraReducers: (builder) => {
        searchForReleaseMusicCases(builder)
        addMusicForFocusCases(builder)
        getDataForFocusCases(builder)
        updateFocusDataCases(builder)
        updateCustomForFocusCases(builder)
        builder
            .addCase(checkExtensionAvailableForCustomThunk.pending, (state) => {
                state.custom.isExtensionValid = false;
            })
            .addCase(checkExtensionAvailableForCustomThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.custom.isExtensionValid = action.payload.status === 200 || action.payload.data?.status === 200;
            })
            .addCase(checkExtensionAvailableForCustomThunk.rejected, (state) => {
                state.custom.isExtensionValid = false;
            });

        builder
            .addCase(updateMultiProductForFocusThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMultiProductForFocusThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                if (action.payload?.data?.product) {
                    state.product = action.payload?.data.product;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(updateMultiProductForFocusThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });

        builder
            .addCase(updateProductForFocusThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProductForFocusThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                if (action.payload?.data?.product) {
                    state.product = action.payload?.data.product;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(updateProductForFocusThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });


        builder
            .addCase(updateNewsletterForFocusThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateNewsletterForFocusThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                if (action.payload?.data?.newsletter) {
                    state.newsletter = action.payload?.data.newsletter;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(updateNewsletterForFocusThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });

        builder
            .addCase(updateMultiEventForFocusThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMultiEventForFocusThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                console.log(`action.payload?.data?.event::`, action.payload?.data?.event);
                if (action.payload?.data?.event) {
                    state.event = action.payload?.data.event;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(updateMultiEventForFocusThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });

        builder
            .addCase(updateMultiNewsletterForFocusThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMultiNewsletterForFocusThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                if (action.payload?.data?.newsletter) {
                    state.newsletter = action.payload?.data.newsletter;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(updateMultiNewsletterForFocusThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });

        builder
            .addCase(updateEventForFocusThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateEventForFocusThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                if (action.payload?.data?.event) {
                    state.event = action.payload?.data.event;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(updateEventForFocusThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });


        builder
            .addCase(updateMusicForFocusThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMusicForFocusThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                console.log(`updateMusicForFocusThunk::`, action.payload)
                console.log(`updateMusicForFocusThunk::`, action.payload?.data.music)
                if (action.payload?.data?.music) {
                    state.music = action.payload?.data.music;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(updateMusicForFocusThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });


        builder
            .addCase(deleteFocusEvenThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteFocusEvenThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                console.log(`deleteFocusEvenThunk::`, action.payload)
                console.log(`deleteFocusEvenThunk::`, action.payload?.data.event)
                if (action.payload?.data?.event) {
                    state.event = action.payload?.data.event;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(deleteFocusEvenThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });

        builder
            .addCase(deleteFocusProductThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteFocusProductThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                console.log(`deleteFocusProductThunk::`, action.payload)
                console.log(`deleteFocusProductThunk::`, action.payload?.data.event)
                if (action.payload?.data?.event) {
                    state.event = action.payload?.data.event;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(deleteFocusProductThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });

        builder
            .addCase(deleteFocusMusicThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteFocusMusicThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                console.log(`deleteFocusMusicThunk::`, action.payload)
                console.log(`deleteFocusMusicThunk::`, action.payload?.data.event)
                if (action.payload?.data?.event) {
                    state.event = action.payload?.data.event;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(deleteFocusMusicThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });

        builder
            .addCase(deleteFocusNewsletterThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteFocusNewsletterThunk.fulfilled, (state, action) => {
                // Check both status codes that indicate success
                state.loading = true;
                console.log(`deleteFocusNewsletterThunk::`, action.payload)
                console.log(`deleteFocusNewsletterThunk::`, action.payload?.data.event)
                if (action.payload?.data?.event) {
                    state.event = action.payload?.data.event;
                }
                state.focusData = action.payload?.data.response;
            })
            .addCase(deleteFocusNewsletterThunk.rejected, (state) => {
                state.loading = true;
                state.error = 'Update failed';
            });
    },
});

export default slice.reducer;

export const { clearSearchMusic, setFocusMusic, clearFocusSection, setWholeFocusMusic, setFocusProduct, setWholeFocusProduct, setFocusEvent, setWholeFocusEvent, setFocusNewsletter, setWholeFocusNewsletter, setFocusCustom, setWholeFocusCustom, updateMusicOverlay, updateProductOverlay, updateEventOverlay, updateNewsletterOverlay, updateCustomOverlay } = slice.actions;
