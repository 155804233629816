import React from 'react';
import CrossArrow from '../svg/CrossArrow';
import { getAccentStyle } from '../constants/constants';
import { postDataAPI } from '../utils/fetchData';
import { useParams } from 'react-router-dom';

const CusFocusGallery = ({ imgurl, item, userArticle, totalItems, index }) => {
    const { domain: dynamicVar } = useParams();
    const accentColor = userArticle?.accent ? `#${userArticle.accent}` : '#FFFFFF';

    const handleClickGallery = async () => {
        if (!item?.link) return;
        
        let url = item.link;
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
        }
        window.open(url, '_blank');
        
        try {
            await postDataAPI('analytics/create-gallery-analytics', {
                link: url,
                domain: dynamicVar
            }, null);
        } catch (err) {
            console.log(err);
        }
    };

    const getImageStyle = () => {
        if (totalItems === undefined || index === undefined) {
            return { width: 'calc(100% - 8px)', height: '700px', top: '4px', left: '4px' };
        }

        const generateLayout = (numImages) => {
            const layouts = {
                1: [{ 
                    width: 'calc(100% - 8px)',
                    height: '700px',
                    top: '4px',
                    left: '4px'
                }],
                2: [
                    { width: '100%', height: '350px', top: '0', left: '0' },
                    { width: '100%', height: '350px', top: '350px', left: '0' }
                ],
                3: [
                    { width: '100%', height: '400px', top: '0', left: '0' },
                    { width: '50%', height: '300px', top: '400px', left: '0' },
                    { width: '50%', height: '300px', top: '400px', left: '50%' }
                ],
                4: [
                    { width: '50%', height: '350px', top: '0', left: '0' },
                    { width: '50%', height: '350px', top: '0', left: '50%' },
                    { width: '50%', height: '350px', top: '350px', left: '0' },
                    { width: '50%', height: '350px', top: '350px', left: '50%' }
                ],
                5: [
                    { width: '40%', height: '420px', top: '0', left: '0' },
                    { width: '60%', height: '210px', top: '0', left: '40%' },
                    { width: '30%', height: '210px', top: '210px', left: '40%' },
                    { width: '30%', height: '210px', top: '210px', left: '70%' },
                    { width: '100%', height: '280px', top: '420px', left: '0' }
                ],
                6: [
                    { width: '50%', height: '350px', top: '0', left: '0' },
                    { width: '50%', height: '350px', top: '0', left: '50%' },
                    { width: '33.33%', height: '350px', top: '350px', left: '0' },
                    { width: '33.33%', height: '175px', top: '350px', left: '33.33%' },
                    { width: '33.33%', height: '175px', top: '525px', left: '33.33%' },
                    { width: '33.34%', height: '350px', top: '350px', left: '66.66%' }
                ]
            };

            if (numImages > 6) {
                const positions = [];
                const rows = Math.ceil(numImages / 3);
                const rowHeight = Math.floor(700 / rows);

                for (let i = 0; i < numImages; i++) {
                    const row = Math.floor(i / 3);
                    const col = i % 3;
                    
                    const isLastRow = row === rows - 1;
                    const itemsInLastRow = numImages - (rows - 1) * 3;
                    const colWidth = isLastRow ? (100 / itemsInLastRow) : 33.33;
                    
                    positions.push({
                        width: `${colWidth}%`,
                        height: `${rowHeight}px`,
                        top: `${row * rowHeight}px`,
                        left: `${col * (isLastRow ? 100 / itemsInLastRow : 33.33)}%`
                    });
                }
                return positions;
            }

            return layouts[numImages] || layouts[6];
        };

        const layout = generateLayout(totalItems);
        const position = layout[index];

        if (totalItems === 1) {
            return {
                width: position.width,
                height: position.height,
                top: position.top,
                left: position.left
            };
        }

        return {
            width: `calc(${position.width} - 8px)`,
            height: `calc(${position.height} - 8px)`,
            top: `calc(${position.top} + 4px)`,
            left: `calc(${position.left} + 4px)`
        };
    };

    return (
        <div 
            className={`absolute rounded-[3px] overflow-hidden gallery-image group ${
                `shuffle-variant-${Math.floor(Math.random() * 3) + 1}`
            }`}
            style={{
                ...getImageStyle(),
                transition: 'all 0.8s cubic-bezier(0.22, 1, 0.36, 1)',
                backgroundColor: `#${userArticle?.background || '1A1A25'}`,
            }}
            onClick={handleClickGallery}
        >
            <div className="w-full h-full relative">
                <img
                    src={imgurl}
                    alt="gallery"
                    className="w-full h-full object-cover"
                    style={{
                        transform: 'scale(1.0)',
                        transition: 'all 0.8s cubic-bezier(0.22, 1, 0.36, 1)',
                    }}
                />
                <div 
                    className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-all duration-500"
                    style={{
                        transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
                    }}
                />
                <div 
                    className="absolute bottom-0 right-0 p-2 opacity-0 group-hover:opacity-100"
                    style={{
                        transition: 'opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
                    }}
                >
                    <CrossArrow color={accentColor} />
                </div>
                <div 
                    className="absolute inset-0 bg-gradient-to-b from-transparent to-black/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500"
                    style={{
                        transition: 'opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
                    }}
                />
            </div>
        </div>
    );
};

export default CusFocusGallery; 