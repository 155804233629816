import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import LayoutHeader from "../../../../../layout/LayoutHeader";
import Check from "../../../../../svg/Check";
import DizeeInput from "../../../../../components/DizeeComponent/DizeeInput";
import { setFocusNewsletter } from "../../../../../store/focuspage/focuspageSlice";
import { checkPageAvailableForNewsletterThunk } from "../../../../../store/focuspage/focuspageThunk";
import { useState } from "react";

export default function Step2() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let newsletter = useSelector(state => state.focuspage.newsletter)
    const [page, setPage] = useState(true);
    let token = localStorage.getItem("dizeeToken")

    const handleChange = (e) => {
        dispatch(setFocusNewsletter({ page: e.target.value }));
        dispatch(checkPageAvailableForNewsletterThunk({ page: e.target.value, token }))
            .then((res) => {
                if (res.payload.status === 200) {
                    setPage(true);
                } else {
                    setPage(false);
                }
            })
            .catch((error) => {
                console.error('Page check error:', error);
                setPage(false);
            });
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                    <div className="px-4 my-[50px] flex w-full justify-between">
                        <p className="text-white">Name your page</p>
                        <div className="flex gap-[30px]">
                            <div
                                className={`text-white cursor-pointer ${newsletter?.page !== undefined && newsletter?.page !== "" && page
                                    ? "opacity-100"
                                    : "opacity-50"
                                    }`}
                                onClick={() => (newsletter?.page !== undefined && newsletter?.page !== "") && page && navigate("/focus-page/newsletter-type/step3")}
                            >
                                Next
                            </div>
                            <div 
                                className="text-white cursor-pointer"
                                onClick={() => navigate("/focus-page/newsletter-type/step1")}
                            >
                                Go back
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-4 flex items-center">
                        <DizeeInput
                            placeholder="my focus page"
                            onChange={handleChange}
                            value={newsletter?.page}
                            className="w-[100px]"
                        />
                        {newsletter?.page !== undefined && newsletter?.page !== "" && page && 
                            <Check className="h-[12px] w-[18px] ml-2" />
                        }
                    </div>

                    <div className="w-full px-4 absolute bottom-[27px] left-0">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Give your focus page a name that helps you identify it easily.</p>
                            <p>You'll use this name to locate and manage your page in the dashboard.</p>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
}

