import { useEffect, useState } from "react";
import LayoutHeader from "../../../../../layout/LayoutHeader";
import { useDispatch, useSelector } from "react-redux";
import { setFocusEvent } from "../../../../../store/focuspage/focuspageSlice";
import { useNavigate } from "react-router";
import { uploadImage } from "../../../../../utils/upload";
import { setLoader, setErrorAlert } from "../../../../../store/alert/alertSlice";
import DizeeInput from "../../../../../components/DizeeComponent/DizeeInput";
import Check from '../../../../../svg/Check';

export default function Step5() {
    const [preview, setPreview] = useState(null);
    const [file, setFile] = useState(null);
    const [headerTitle, setHeaderTitle] = useState('');
    const [headerSubtitle, setHeaderSubtitle] = useState('');
    const [headerLink, setHeaderLink] = useState('');
    const [showHeaderContent, setShowHeaderContent] = useState(false);
    
    let event = useSelector(state => state.focuspage.event);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMultipleFlow = event?.type === 'multiple';

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setHeaderLink(value);
    };

    useEffect(() => {
        if (event?.avatarPreview) {
            setPreview(event?.avatarPreview);
        }
        if (event?.headerTitle) setHeaderTitle(event.headerTitle);
        if (event?.headerSubtitle) setHeaderSubtitle(event.headerSubtitle);
        if (event?.headerLink) setHeaderLink(event.headerLink);
    }, [event]);

    const changeAvatar = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreview(URL.createObjectURL(selectedFile));
            dispatch(setFocusEvent({ 
                avatar: selectedFile,
                avatarPreview: URL.createObjectURL(selectedFile)
            }));
        }
    };

    const handleNext = async () => {
        if (showHeaderContent && isMultipleFlow) {
            if (!headerTitle || !headerSubtitle || !isValidUrl(headerLink)) {
                dispatch(setErrorAlert('Please fill in all header fields with valid data'));
                return;
            }
        }

        if (file) {
            dispatch(setLoader(true));
            try {
                const url = await uploadImage(file);
                if (!url) {
                    dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                    return;
                }
                
                const eventData = { 
                    avatar: url,
                    headeractive: showHeaderContent
                };

                if (showHeaderContent && isMultipleFlow) {
                    eventData.headerTitle = headerTitle;
                    eventData.headerSubtitle = headerSubtitle;
                    eventData.headerLink = headerLink;
                }
                
                dispatch(setFocusEvent(eventData));
                navigate(isMultipleFlow ? "/focus-page/event-type/step7" : "/focus-page/event-type/step6");
            } catch (error) {
                console.error('Error uploading image:', error);
                dispatch(setErrorAlert('Error uploading image'));
            } finally {
                dispatch(setLoader(false));
            }
        } else {
            if (showHeaderContent && isMultipleFlow) {
                const eventData = {
                    headeractive: showHeaderContent,
                    headerTitle,
                    headerSubtitle,
                    headerLink
                };
                dispatch(setFocusEvent(eventData));
            }
            navigate(isMultipleFlow ? "/focus-page/event-type/step7" : "/focus-page/event-type/step6");
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                    <div className="px-4 my-[50px] flex w-full justify-between">
                        <p className="text-white">Add header image</p>
                        <div className="flex gap-[30px]">
                            <div
                                className={`text-white cursor-pointer ${
                                    (showHeaderContent && (!headerTitle || !headerSubtitle || !isValidUrl(headerLink))) 
                                    ? "opacity-50 pointer-events-none" 
                                    : file || event?.avatar 
                                    ? "opacity-100" 
                                    : "opacity-50"
                                }`}
                                onClick={handleNext}
                            >
                                Next
                            </div>
                            <div 
                                className="text-white cursor-pointer"
                                onClick={() => navigate("/focus-page/event-type/step4")}
                            >
                                Go back
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-4">
                        <div
                            className="text-white cursor-pointer"
                            onClick={() => document.getElementById("avatar").click()}
                        >
                            {preview ? "Change header image" : "Upload header image"}
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            id="avatar"
                            onChange={changeAvatar}
                        />
                        {preview ? (
                            <div className="profile-picture my-8 min-h-[100px]">
                                <div className="avatar">
                                    <img
                                        src={preview}
                                        className="h-[80px] w-[80px] rounded-full"
                                        alt="header"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="mb-8" />
                        )}

                        {isMultipleFlow && (
                            <>
                                <div className="mt-6 flex items-center gap-x-2">
                                    <button 
                                        onClick={() => setShowHeaderContent(!showHeaderContent)}
                                        className="text-white flex items-center gap-x-2"
                                    >
                                        Add header content
                                        {showHeaderContent && <Check className="h-[12px] w-[18px]" />}
                                    </button>
                                </div>

                                {showHeaderContent && (
                                    <>
                                        <div className="mt-6">
                                            <DizeeInput
                                                placeholder="Add header title"
                                                onChange={(e) => setHeaderTitle(e.target.value)}
                                                value={headerTitle}
                                                className="w-full"
                                            />
                                        </div>

                                        <div className="mt-6">
                                            <DizeeInput
                                                placeholder="Add header subtitle"
                                                onChange={(e) => setHeaderSubtitle(e.target.value)}
                                                value={headerSubtitle}
                                                className="w-full"
                                            />
                                        </div>

                                        <div className="mt-6">
                                            <div className="flex items-center">
                                                <DizeeInput
                                                    placeholder="Upload link"
                                                    onChange={handleLinkChange}
                                                    value={headerLink}
                                                    className="w-full"
                                                />
                                                {isValidUrl(headerLink) && <Check className="h-[12px] w-[18px] ml-2" />}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>

                    <div className="w-full px-4 absolute bottom-[27px] left-0">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Upload an image for your focus page header.</p>
                            {isMultipleFlow && (
                                <p>Optional: Add a title, subtitle, and an upload link for your header section.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
}

