import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import LayoutHeader from '../../layout/LayoutHeader';
import DizeeModal from '../../components/DizeeComponent/DizeeModal';
import { DizeeInput2 } from '../../components/DizeeComponent/DixeeInput2';

const SearchBar = styled.div`
  width: 100%;
  padding: 0 16px;
`;

const CategoryFilter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  width: 100%;
  padding: 0 16px;
`;

const CategoryButton = styled.button`
  font-size: 12px;
  color: ${props => props.active ? 'white' : 'rgba(255, 255, 255, 0.5)'};
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease;
`;

const AppGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px 0;
  width: 100%;
  padding: 0 16px;
  margin: 32px 0 32px 0;
  min-height: 400px;
  height: 400px;
`;

const AppColumn = styled.div`
  display: flex;
  justify-content: ${props => {
    if (props.position === 'left') return 'flex-start';
    if (props.position === 'center') return 'center';
    return 'flex-end';
  }};
  width: 100%;
  padding: ${props => {
    if (props.position === 'left') return '0 24px 0 0';
    if (props.position === 'center') return '0 24px';
    return '0 0 0 24px';
  }};
`;

const AppCard = styled(motion.div)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: ${props => props.position === 'right' ? '0 -8px 0 0' : '0'};
`;

const AppIcon = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background: ${props => props.color || '#333'};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AppName = styled.div`
  font-size: 10px;
  color: white;
  text-align: center;
  width: 56px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ModalContent = styled.div`
  background: transparent;
  width: 390px;
  color: white;
  padding: 0 16px;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
  width: 100%;
`;

const ModalAppInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ModalAppName = styled.div`
  font-size: 12px;
  color: white;
  text-align: left;
`;

const ModalAppAuthor = styled.div`
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4px;
`;

const ModalDetails = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  text-align: left;
  width: 100%;
`;

const ModalAppIcon = styled(AppIcon)`
  border-radius: 8px;
`;

const FooterText = styled.p`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
  line-height: 1.5;
  padding: 0 16px;
  width: 100%;
  margin-top: 180px;
  position: relative;
`;

const categories = [
  'all',
  'tools',
  'sales',
  'premium',
  'ads',
  'other'
];

const apps = [
  {
    name: 'Booking',
    description: 'Manage and monetize bookings for performances, sessions, and events.',
    color: '#FFB84D',
    category: 'tools',
    author: 'Dizee Team',
    version: '1.1.0',
    released: '2024-03-18',
    size: '1.9 MB'
  },
  {
    name: 'Beats',
    description: 'Sell beats and instrumentals with instant delivery.',
    color: '#FF6B6B',
    category: 'sales',
    author: 'Dizee Team',
    version: '1.0.2',
    released: '2024-03-25',
    size: '2.0 MB'
  },
  {
    name: 'VIP',
    description: 'Create and sell exclusive VIP experiences and digital passes.',
    color: '#845EF7',
    category: 'premium',
    author: 'Dizee Team',
    version: '1.1.1',
    released: '2024-03-14',
    size: '2.2 MB'
  },
  {
    name: 'Billboard',
    description: 'Track your music performance and revenue across major streaming platforms and charts.',
    color: '#7B61FF',
    category: 'ads',
    author: 'Dizee Team',
    version: '1.0.0',
    released: '2024-03-20',
    size: '2.1 MB'
  },
  {
    name: 'Gated Files',
    description: 'Securely share exclusive content with your fans. Control access to unreleased tracks, stems, and special editions.',
    color: '#FF4D4D',
    category: 'content',
    author: 'Dizee Team',
    version: '1.2.0',
    released: '2024-03-15',
    size: '2.4 MB'
  },
  {
    name: 'Tip Jar',
    description: 'Accept tips and donations from your supporters during live streams and performances.',
    color: '#4DA6FF',
    category: 'sales',
    author: 'Dizee Team',
    version: '2.0.1',
    released: '2024-03-10',
    size: '1.8 MB'
  },
  {
    name: 'Shop Plus',
    description: 'Advanced e-commerce platform for your brand.',
    color: '#FF922B',
    category: 'sales',
    author: 'Dizee Team',
    version: '1.0.1',
    released: '2024-03-19',
    size: '1.7 MB'
  },
  {
    name: 'Newsletter',
    description: 'Create and manage paid newsletter subscriptions for your fans.',
    color: '#F06595',
    category: 'premium',
    author: 'Dizee Team',
    version: '1.1.2',
    released: '2024-03-17',
    size: '2.0 MB'
  },
  {
    name: 'Vault',
    description: 'Sell access to your exclusive content vault with tiered pricing.',
    color: '#50E3C2',
    category: 'content',
    author: 'Dizee Team',
    version: '1.3.0',
    released: '2024-03-12',
    size: '2.8 MB'
  }
];

const chunk = (array, size) => {
  const chunked = [];
  for (let i = 0; i < array.length; i += size) {
    chunked.push(array.slice(i, i + size));
  }
  return chunked;
};

export default function MarketplaceHub() {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedApp, setSelectedApp] = useState(null);

  const filteredApps = apps
    .filter(app => {
      const matchesCategory = selectedCategory === 'all' || app.category === selectedCategory;
      const matchesSearch = app.name.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesCategory && matchesSearch;
    });

  return (
    <LayoutHeader>
      <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
        <div className='px-4 my-[50px] flex w-full justify-between'>
          <p className='text-white'>Marketplace</p>
        </div>

        <SearchBar>
          <DizeeInput2
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search apps..."
            className="w-full bg-transparent border-none text-white text-[12px] h-[36px]"
          />
        </SearchBar>

        <CategoryFilter>
          {categories.map(category => (
            <CategoryButton
              key={category}
              active={category === selectedCategory}
              onClick={() => setSelectedCategory(category)}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </CategoryButton>
          ))}
        </CategoryFilter>

        <div className="w-full" style={{ height: '400px' }}>
          <AppGrid>
            {chunk(filteredApps, 3).map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                {row.map((app, index) => (
                  <AppColumn 
                    key={app.name}
                    position={index === 0 ? 'left' : index === 1 ? 'center' : 'right'}
                  >
                    <AppCard
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setSelectedApp(app)}
                      position={index === 0 ? 'left' : index === 1 ? 'center' : 'right'}
                    >
                      <AppIcon color={app.color} />
                      <AppName>{app.name}</AppName>
                    </AppCard>
                  </AppColumn>
                ))}
              </React.Fragment>
            ))}
          </AppGrid>
        </div>

        <div className="w-full">
          <FooterText>
            Discover and install apps to enhance your Dizee profile.
            Each app is designed to help you monetize and grow your audience.
          </FooterText>
        </div>

        {selectedApp && (
          <DizeeModal setOverlayVisible={() => setSelectedApp(null)}>
            <div className="w-[390px] flex flex-col justify-start items-center relative">
              <ModalContent>
                <ModalHeader>
                  <ModalAppIcon color={selectedApp.color} />
                  <ModalAppInfo>
                    <ModalAppName>{selectedApp.name}</ModalAppName>
                    <ModalAppAuthor>By {selectedApp.author}</ModalAppAuthor>
                  </ModalAppInfo>
                </ModalHeader>
                <ModalDetails>
                  <p className="mb-4">{selectedApp.description}</p>
                  <div className="grid grid-cols-2 gap-2">
                    <div>Version: {selectedApp.version}</div>
                    <div>Size: {selectedApp.size}</div>
                    <div>Released: {new Date(selectedApp.released).toLocaleDateString()}</div>
                  </div>
                </ModalDetails>
              </ModalContent>
            </div>
          </DizeeModal>
        )}
      </div>
    </LayoutHeader>
  );
} 