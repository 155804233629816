import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearAlerts, setErrorAlert } from "../../../store/alert/alertSlice";
import { add_personal_user_userInfo_thunk } from "../../../store/auth/authThunk";
import TypeWriter from "../../../components/OnBoard/Typewriter";
import dizeeLogo from "../../../assets/images/dizee logo.svg";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 350px;
  margin: 0 auto;
  padding-left: 35px;
  padding-top: 0;
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  gap: 2px;
  margin-top: -40px;
  height: 100%;
  position: relative;
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  height: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ActionButton = styled.div`
  font-size: 12px;
  color: ${props => props.disabled ? 'rgba(255, 255, 255, 0.3)' : 'white'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  height: 18px;
  line-height: 18px;
  position: relative;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 280px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: auto;
  margin-bottom: 16px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  max-width: 280px;
  line-height: 1.5;
`;

const Step12 = ({ setStep, setUser, user }) => {
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const persistedData = localStorage.getItem("persist:root");
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.avatar) {
      if (typeof user.avatar === "string") {
        setPreview(user.avatar);
      } else if (user.avatar instanceof Blob) {
        setPreview(URL.createObjectURL(user.avatar));
      }
    }
  }, [user?.avatar]);

  const changeAvatar = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreview(URL.createObjectURL(selectedFile));
      setUser({ ...user, avatar: selectedFile });
    }
  };

  const handleSubmit = async () => {
    // e.preventDefault(); // Prevent default form submission
    dispatch(clearAlerts());

    if (persistedData) {
      try {
        const parsedData = JSON.parse(persistedData);
        let userData = JSON.parse(parsedData.user);
        user.phone = userData.phone;
      } catch (error) {
        console.error("Failed to parse persisted data:", error);
        dispatch(setErrorAlert("Email and Phone number are required"));
        return; // Exit if there is an error
      }
    } else {
      console.log('No data found for the key "persist:root".');
      dispatch(setErrorAlert("Email and Phone number are required"));
      return; // Exit if there is no data
    }

    const url = new URL(window.location.href);
    const emailParam = url.searchParams.get("email");
    const userParam = JSON.parse(url.searchParams.get("user"));

    user.email = userParam ? userParam.email : emailParam;

    if (user.phone && user.email) {
      dispatch(add_personal_user_userInfo_thunk(user)).then((res) => {
        if (res?.payload?.status === 200) {
          setStep(32);
        }
      });
    } else {
      dispatch(setErrorAlert("Email and Phone number are required"));
    }
  };

  const handleNext = async () => {
    if (user.professionRole === "personal") {
      handleSubmit(); // Ensure handleSubmit is called correctly
    } else {
      setStep(13);
    }
  };

  // Handle key press events
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNext(); // Call your next step function
    }
  };

  return (
    <Container>
      <FormSection>
        <div className="w-[200px]">
          <div
            className="mb-[68px] cursor-pointer"
            onClick={() => document.getElementById("avatar").click()}
          >
            <TypeWriter
              text={`${preview ? "Change cover image" : "Upload a cover image"}`}
              speed={45}
            />
          </div>
          
          <input
            type="file"
            className="hidden"
            id="avatar"
            onChange={changeAvatar}
          />
          
          {preview && (
            <div className="profile-picture mb-9 min-h-[100px]">
              <div className="avatar">
                <img
                  src={preview}
                  className="h-[80px] w-[80px]"
                  alt="avatar"
                  style={{ 
                    objectFit: 'cover',
                    borderRadius: '4px'
                  }}
                />
              </div>
            </div>
          )}

          <ButtonContainer>
            <ActionButton
              disabled={!(file || user?.avatar)}
              onClick={() => {
                if (file || user?.avatar) {
                  handleNext();
                }
              }}
              className="mr-[43px]"
            >
              Next
            </ActionButton>
            
            <ActionButton
              onClick={() => {
                if (user.professionRole !== undefined) {
                  setStep(user.professionRole === "personal" ? 22 : 9);
                }
              }}
            >
              Go back
            </ActionButton>
          </ButtonContainer>
        </div>
      </FormSection>
      
      <Footer>
        <LogoImage src={dizeeLogo} alt="DIZEE" />
        <FooterText>
          Upload a profile picture that represents you or your brand. This will be displayed on your profile page.
        </FooterText>
      </Footer>
    </Container>
  );
};

export default Step12;
