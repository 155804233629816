import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { DizeeInput2, removeUploadsFromUrl } from "../../components/DizeeComponent/DixeeInput2";
import { uploadImage } from "../../utils/upload";
import { setErrorAlert, setLoader } from "../../store/alert/alertSlice";
import { useNavigate } from "react-router";
import { updateDescription, updateHeaderTitle, updateImage, updateImageOverlay, updateStreamOverlay, updateTitle } from "../../store/vaultData/vaultdataSlice";
import ImageEditCard from "../../components/Main/ArticleEdit/ImageEditCard";
import { update_vault_data_thunk } from "../../store/vaultData/vaultdataThunk";
import { useSelector } from "react-redux";
import LayoutHeader from "../../layout/LayoutHeader";
import { Play, Pause, SkipBack, SkipForward } from "lucide-react";

export function Card({ children, className }) {
  return (
    <div className={`bg-gray-900 p-4 rounded-lg shadow ${className}`}>{children}</div>
  );
}

export function CardContent({ children, className }) {
  return <div className={`p-2 ${className}`}>{children}</div>;
}

export function Slider({ value, max, onChange, className }) {
  return (
    <input
      type="range"
      min="0"
      max={max}
      value={value[0]}
      onChange={(e) => onChange([parseFloat(e.target.value)])}
      className={`w-full h-1 bg-gray-700 rounded-lg appearance-none cursor-pointer ${className}`}
    />
  );
}

export default function StreamOverlay() {

  const token = localStorage.getItem("dizeeToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.vaultData.curPlayStreamData);
  useSelector((state) => {
    console.log(`stream data::`, state.vaultData);
  });
  const userArticle = useSelector((state) => state.user.userArticle);

  console.log(`streamData::`, data);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(new Audio(data.file));

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };


  useEffect(() => {
    const audio = audioRef.current;

    const updateTime = () => {
      setCurrentTime(audio.currentTime)
      console.log(`updateTime:`, audio.currentTime)
    };
    const setAudioDuration = () => {
      setDuration(audio.duration)
      console.log(`setAudioDuration: `, audio.duration);
    };

    audio.addEventListener("timeupdate", updateTime);
    audio.addEventListener("loadedmetadata", setAudioDuration);

    return () => {
      audio.removeEventListener("timeupdate", updateTime);
      audio.removeEventListener("loadedmetadata", setAudioDuration);
    };
  }, []);

  return (
    <LayoutHeader>
      <div className="w-[350px] sm:max-w-[390px] pb-[100px] h-full flex flex-col justify-start items-center relative rounded-[20px] bg-black overflow-hidden" >
        <div className="absolute top-6 left-4" style={{ zIndex: 1 }}>
          <button
            onClick={() => navigate(-1)}
            className="text-[#FFFFFF] text-[12px] hover:text-white transition-colors duration-200"
          >
            BACK
          </button>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            // backgroundImage: `url(${noiseTexture})`,
            opacity: (userArticle?.noise || 0) / 100,
            mixBlendMode: 'overlay',
            pointerEvents: 'none',
            zIndex: 2,
            borderRadius: '20px'
          }}
        />
        <div className="flex py-4 z-1 ml-4 w-full justify-center items-center text-white">
          <div className="cursor-pointer mx-auto flex justify-center items-center gap-x-[4px] text-xl font-medium">
            {data?.title ? data?.title : "Stream Player"}
          </div>
        </div>
        <div
          className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]"
          style={{
            filter: `grayscale(${userArticle?.grayscale}%)`,
            backgroundColor: `${userArticle?.background ? '#' + userArticle?.background : '#000000'}`,
            position: 'relative'
          }}
        >
          <div className="relative z-2 w-full">
            <div className="w-full h-[550px] relative rounded-t-[20px] overflow-hidden mb-[40px]">
              <img
                src={data.image ? data.image : removeUploadsFromUrl(`${userArticle?.avatar}`)}
                alt="photoimage"
                className="w-full h-full object-cover object-center"
                style={{ minHeight: '550px' }}
              />
              <div
                className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end p-4"
                style={{
                  backgroundImage: `linear-gradient(to top, #000000, transparent)`,
                }}
              >
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full p-2 mt-[-90px] z-10">
          <CardContent className="mt-4 text-center">
            <h2 className="text-xl font-bold">{data.title}</h2>
            <p className="text-sm text-gray-400">{data.artist}</p>
            <Slider
              value={[currentTime]}
              max={audioRef.current.duration || 100}
              onChange={(value) => (audioRef.current.currentTime = value[0])}
              className="mt-4"
            />
            <div className="flex justify-between items-center mt-4">
              <SkipBack className="w-6 h-6 text-gray-400" />
              <button
                onClick={togglePlayPause}
                className="w-12 h-12 bg-gray-800 rounded-full flex items-center justify-center"
              >
                {isPlaying ? <Pause className="w-6 h-6" /> : <Play className="w-6 h-6" />}
              </button>
              <SkipForward className="w-6 h-6 text-gray-400" />
            </div>
          </CardContent>
        </div>
      </div>
    </LayoutHeader>

  );
}
